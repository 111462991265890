/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteProductOptionItem } from '../fn/product-option/admin-delete-product-option-item';
import { AdminDeleteProductOptionItem$Params } from '../fn/product-option/admin-delete-product-option-item';
import { adminGetProductOptionCollection$Html } from '../fn/product-option/admin-get-product-option-collection-html';
import { AdminGetProductOptionCollection$Html$Params } from '../fn/product-option/admin-get-product-option-collection-html';
import { adminGetProductOptionCollection$Json } from '../fn/product-option/admin-get-product-option-collection-json';
import { AdminGetProductOptionCollection$Json$Params } from '../fn/product-option/admin-get-product-option-collection-json';
import { adminGetProductOptionItem$Html } from '../fn/product-option/admin-get-product-option-item-html';
import { AdminGetProductOptionItem$Html$Params } from '../fn/product-option/admin-get-product-option-item-html';
import { adminGetProductOptionItem$Json } from '../fn/product-option/admin-get-product-option-item-json';
import { AdminGetProductOptionItem$Json$Params } from '../fn/product-option/admin-get-product-option-item-json';
import { adminPostProductOptionCollection$Html$Html } from '../fn/product-option/admin-post-product-option-collection-html-html';
import { AdminPostProductOptionCollection$Html$Html$Params } from '../fn/product-option/admin-post-product-option-collection-html-html';
import { adminPostProductOptionCollection$Html$Json } from '../fn/product-option/admin-post-product-option-collection-html-json';
import { AdminPostProductOptionCollection$Html$Json$Params } from '../fn/product-option/admin-post-product-option-collection-html-json';
import { adminPostProductOptionCollection$Json$Html } from '../fn/product-option/admin-post-product-option-collection-json-html';
import { AdminPostProductOptionCollection$Json$Html$Params } from '../fn/product-option/admin-post-product-option-collection-json-html';
import { adminPostProductOptionCollection$Json$Json } from '../fn/product-option/admin-post-product-option-collection-json-json';
import { AdminPostProductOptionCollection$Json$Json$Params } from '../fn/product-option/admin-post-product-option-collection-json-json';
import { adminPutProductOptionItem$Html$Html } from '../fn/product-option/admin-put-product-option-item-html-html';
import { AdminPutProductOptionItem$Html$Html$Params } from '../fn/product-option/admin-put-product-option-item-html-html';
import { adminPutProductOptionItem$Html$Json } from '../fn/product-option/admin-put-product-option-item-html-json';
import { AdminPutProductOptionItem$Html$Json$Params } from '../fn/product-option/admin-put-product-option-item-html-json';
import { adminPutProductOptionItem$Json$Html } from '../fn/product-option/admin-put-product-option-item-json-html';
import { AdminPutProductOptionItem$Json$Html$Params } from '../fn/product-option/admin-put-product-option-item-json-html';
import { adminPutProductOptionItem$Json$Json } from '../fn/product-option/admin-put-product-option-item-json-json';
import { AdminPutProductOptionItem$Json$Json$Params } from '../fn/product-option/admin-put-product-option-item-json-json';
import { apiProductOptionsValuesGetSubresourceProductOptionSubresource$Html } from '../fn/product-option-value/api-product-options-values-get-subresource-product-option-subresource-html';
import { ApiProductOptionsValuesGetSubresourceProductOptionSubresource$Html$Params } from '../fn/product-option-value/api-product-options-values-get-subresource-product-option-subresource-html';
import { apiProductOptionsValuesGetSubresourceProductOptionSubresource$Json } from '../fn/product-option-value/api-product-options-values-get-subresource-product-option-subresource-json';
import { ApiProductOptionsValuesGetSubresourceProductOptionSubresource$Json$Params } from '../fn/product-option-value/api-product-options-values-get-subresource-product-option-subresource-json';
import { Index as ProductOptionAdminProductOptionIndexSyliusAdminProductOptionIndex } from '../models/ProductOption-admin/product_option/index_sylius/admin/product_option/index';
import { Show as ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow } from '../models/ProductOption-admin/product_option/show_sylius/admin/product_option/show';
import { Show as ProductOptionShopProductOptionShowSyliusShopProductOptionShow } from '../models/ProductOption-shop/product_option/show_sylius/shop/product_option/show';
import { Show as ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow } from '../models/ProductOptionValue-admin/product_option_value/show_sylius/admin/product_option_value/show';
import { shopGetProductOptionItem$Html } from '../fn/product-option/shop-get-product-option-item-html';
import { ShopGetProductOptionItem$Html$Params } from '../fn/product-option/shop-get-product-option-item-html';
import { shopGetProductOptionItem$Json } from '../fn/product-option/shop-get-product-option-item-json';
import { ShopGetProductOptionItem$Json$Params } from '../fn/product-option/shop-get-product-option-item-json';

@Injectable({ providedIn: 'root' })
export class ProductOptionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetProductOptionCollection()` */
  static readonly AdminGetProductOptionCollectionPath = '/api/v2/admin/product-options';

  /**
   * Retrieves the collection of ProductOption resources.
   *
   * Retrieves the collection of ProductOption resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductOptionCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductOptionCollection$Json$Response(params?: AdminGetProductOptionCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductOptionAdminProductOptionIndexSyliusAdminProductOptionIndex>>> {
    return adminGetProductOptionCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductOption resources.
   *
   * Retrieves the collection of ProductOption resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductOptionCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductOptionCollection$Json(params?: AdminGetProductOptionCollection$Json$Params, context?: HttpContext): Observable<Array<ProductOptionAdminProductOptionIndexSyliusAdminProductOptionIndex>> {
    return this.adminGetProductOptionCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductOptionAdminProductOptionIndexSyliusAdminProductOptionIndex>>): Array<ProductOptionAdminProductOptionIndexSyliusAdminProductOptionIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ProductOption resources.
   *
   * Retrieves the collection of ProductOption resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductOptionCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductOptionCollection$Html$Response(params?: AdminGetProductOptionCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductOptionAdminProductOptionIndexSyliusAdminProductOptionIndex>>> {
    return adminGetProductOptionCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductOption resources.
   *
   * Retrieves the collection of ProductOption resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductOptionCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductOptionCollection$Html(params?: AdminGetProductOptionCollection$Html$Params, context?: HttpContext): Observable<Array<ProductOptionAdminProductOptionIndexSyliusAdminProductOptionIndex>> {
    return this.adminGetProductOptionCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductOptionAdminProductOptionIndexSyliusAdminProductOptionIndex>>): Array<ProductOptionAdminProductOptionIndexSyliusAdminProductOptionIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostProductOptionCollection()` */
  static readonly AdminPostProductOptionCollectionPath = '/api/v2/admin/product-options';

  /**
   * Creates a ProductOption resource.
   *
   * Creates a ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductOptionCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductOptionCollection$Json$Json$Response(params: AdminPostProductOptionCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>> {
    return adminPostProductOptionCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductOption resource.
   *
   * Creates a ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductOptionCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductOptionCollection$Json$Json(params: AdminPostProductOptionCollection$Json$Json$Params, context?: HttpContext): Observable<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow> {
    return this.adminPostProductOptionCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>): ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow => r.body)
    );
  }

  /**
   * Creates a ProductOption resource.
   *
   * Creates a ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductOptionCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductOptionCollection$Json$Html$Response(params: AdminPostProductOptionCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>> {
    return adminPostProductOptionCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductOption resource.
   *
   * Creates a ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductOptionCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductOptionCollection$Json$Html(params: AdminPostProductOptionCollection$Json$Html$Params, context?: HttpContext): Observable<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow> {
    return this.adminPostProductOptionCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>): ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow => r.body)
    );
  }

  /**
   * Creates a ProductOption resource.
   *
   * Creates a ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductOptionCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductOptionCollection$Html$Json$Response(params: AdminPostProductOptionCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>> {
    return adminPostProductOptionCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductOption resource.
   *
   * Creates a ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductOptionCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductOptionCollection$Html$Json(params: AdminPostProductOptionCollection$Html$Json$Params, context?: HttpContext): Observable<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow> {
    return this.adminPostProductOptionCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>): ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow => r.body)
    );
  }

  /**
   * Creates a ProductOption resource.
   *
   * Creates a ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductOptionCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductOptionCollection$Html$Html$Response(params: AdminPostProductOptionCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>> {
    return adminPostProductOptionCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductOption resource.
   *
   * Creates a ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductOptionCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductOptionCollection$Html$Html(params: AdminPostProductOptionCollection$Html$Html$Params, context?: HttpContext): Observable<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow> {
    return this.adminPostProductOptionCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>): ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow => r.body)
    );
  }

  /** Path part for operation `adminGetProductOptionItem()` */
  static readonly AdminGetProductOptionItemPath = '/api/v2/admin/product-options/{code}';

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductOptionItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductOptionItem$Json$Response(params: AdminGetProductOptionItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>> {
    return adminGetProductOptionItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductOptionItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductOptionItem$Json(params: AdminGetProductOptionItem$Json$Params, context?: HttpContext): Observable<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow> {
    return this.adminGetProductOptionItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>): ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow => r.body)
    );
  }

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductOptionItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductOptionItem$Html$Response(params: AdminGetProductOptionItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>> {
    return adminGetProductOptionItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductOptionItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductOptionItem$Html(params: AdminGetProductOptionItem$Html$Params, context?: HttpContext): Observable<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow> {
    return this.adminGetProductOptionItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>): ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow => r.body)
    );
  }

  /** Path part for operation `adminPutProductOptionItem()` */
  static readonly AdminPutProductOptionItemPath = '/api/v2/admin/product-options/{code}';

  /**
   * Replaces the ProductOption resource.
   *
   * Replaces the ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductOptionItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductOptionItem$Json$Json$Response(params: AdminPutProductOptionItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>> {
    return adminPutProductOptionItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductOption resource.
   *
   * Replaces the ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductOptionItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductOptionItem$Json$Json(params: AdminPutProductOptionItem$Json$Json$Params, context?: HttpContext): Observable<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow> {
    return this.adminPutProductOptionItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>): ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow => r.body)
    );
  }

  /**
   * Replaces the ProductOption resource.
   *
   * Replaces the ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductOptionItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductOptionItem$Json$Html$Response(params: AdminPutProductOptionItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>> {
    return adminPutProductOptionItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductOption resource.
   *
   * Replaces the ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductOptionItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductOptionItem$Json$Html(params: AdminPutProductOptionItem$Json$Html$Params, context?: HttpContext): Observable<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow> {
    return this.adminPutProductOptionItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>): ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow => r.body)
    );
  }

  /**
   * Replaces the ProductOption resource.
   *
   * Replaces the ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductOptionItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductOptionItem$Html$Json$Response(params: AdminPutProductOptionItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>> {
    return adminPutProductOptionItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductOption resource.
   *
   * Replaces the ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductOptionItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductOptionItem$Html$Json(params: AdminPutProductOptionItem$Html$Json$Params, context?: HttpContext): Observable<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow> {
    return this.adminPutProductOptionItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>): ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow => r.body)
    );
  }

  /**
   * Replaces the ProductOption resource.
   *
   * Replaces the ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductOptionItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductOptionItem$Html$Html$Response(params: AdminPutProductOptionItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>> {
    return adminPutProductOptionItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductOption resource.
   *
   * Replaces the ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductOptionItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductOptionItem$Html$Html(params: AdminPutProductOptionItem$Html$Html$Params, context?: HttpContext): Observable<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow> {
    return this.adminPutProductOptionItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow>): ProductOptionAdminProductOptionShowSyliusAdminProductOptionShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteProductOptionItem()` */
  static readonly AdminDeleteProductOptionItemPath = '/api/v2/admin/product-options/{code}';

  /**
   * Removes the ProductOption resource.
   *
   * Removes the ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteProductOptionItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductOptionItem$Response(params: AdminDeleteProductOptionItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteProductOptionItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the ProductOption resource.
   *
   * Removes the ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteProductOptionItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductOptionItem(params: AdminDeleteProductOptionItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteProductOptionItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiProductOptionsValuesGetSubresourceProductOptionSubresource()` */
  static readonly ApiProductOptionsValuesGetSubresourceProductOptionSubresourcePath = '/api/v2/admin/product-options/{code}/values';

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductOptionsValuesGetSubresourceProductOptionSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductOptionsValuesGetSubresourceProductOptionSubresource$Json$Response(params: ApiProductOptionsValuesGetSubresourceProductOptionSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>>> {
    return apiProductOptionsValuesGetSubresourceProductOptionSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductOptionsValuesGetSubresourceProductOptionSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductOptionsValuesGetSubresourceProductOptionSubresource$Json(params: ApiProductOptionsValuesGetSubresourceProductOptionSubresource$Json$Params, context?: HttpContext): Observable<Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>> {
    return this.apiProductOptionsValuesGetSubresourceProductOptionSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>>): Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow> => r.body)
    );
  }

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductOptionsValuesGetSubresourceProductOptionSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductOptionsValuesGetSubresourceProductOptionSubresource$Html$Response(params: ApiProductOptionsValuesGetSubresourceProductOptionSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>>> {
    return apiProductOptionsValuesGetSubresourceProductOptionSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductOptionsValuesGetSubresourceProductOptionSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductOptionsValuesGetSubresourceProductOptionSubresource$Html(params: ApiProductOptionsValuesGetSubresourceProductOptionSubresource$Html$Params, context?: HttpContext): Observable<Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>> {
    return this.apiProductOptionsValuesGetSubresourceProductOptionSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>>): Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow> => r.body)
    );
  }

  /** Path part for operation `shopGetProductOptionItem()` */
  static readonly ShopGetProductOptionItemPath = '/api/v2/shop/product-options/{code}';

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductOptionItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductOptionItem$Json$Response(params: ShopGetProductOptionItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionShopProductOptionShowSyliusShopProductOptionShow>> {
    return shopGetProductOptionItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductOptionItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductOptionItem$Json(params: ShopGetProductOptionItem$Json$Params, context?: HttpContext): Observable<ProductOptionShopProductOptionShowSyliusShopProductOptionShow> {
    return this.shopGetProductOptionItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionShopProductOptionShowSyliusShopProductOptionShow>): ProductOptionShopProductOptionShowSyliusShopProductOptionShow => r.body)
    );
  }

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductOptionItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductOptionItem$Html$Response(params: ShopGetProductOptionItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionShopProductOptionShowSyliusShopProductOptionShow>> {
    return shopGetProductOptionItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductOptionItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductOptionItem$Html(params: ShopGetProductOptionItem$Html$Params, context?: HttpContext): Observable<ProductOptionShopProductOptionShowSyliusShopProductOptionShow> {
    return this.shopGetProductOptionItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionShopProductOptionShowSyliusShopProductOptionShow>): ProductOptionShopProductOptionShowSyliusShopProductOptionShow => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteProductTaxonItem } from '../fn/product-taxon/admin-delete-product-taxon-item';
import { AdminDeleteProductTaxonItem$Params } from '../fn/product-taxon/admin-delete-product-taxon-item';
import { adminGetProductTaxonCollection$Html } from '../fn/product-taxon/admin-get-product-taxon-collection-html';
import { AdminGetProductTaxonCollection$Html$Params } from '../fn/product-taxon/admin-get-product-taxon-collection-html';
import { adminGetProductTaxonCollection$Json } from '../fn/product-taxon/admin-get-product-taxon-collection-json';
import { AdminGetProductTaxonCollection$Json$Params } from '../fn/product-taxon/admin-get-product-taxon-collection-json';
import { adminGetProductTaxonItem$Html } from '../fn/product-taxon/admin-get-product-taxon-item-html';
import { AdminGetProductTaxonItem$Html$Params } from '../fn/product-taxon/admin-get-product-taxon-item-html';
import { adminGetProductTaxonItem$Json } from '../fn/product-taxon/admin-get-product-taxon-item-json';
import { AdminGetProductTaxonItem$Json$Params } from '../fn/product-taxon/admin-get-product-taxon-item-json';
import { adminPostProductTaxonCollection$Html$Html } from '../fn/product-taxon/admin-post-product-taxon-collection-html-html';
import { AdminPostProductTaxonCollection$Html$Html$Params } from '../fn/product-taxon/admin-post-product-taxon-collection-html-html';
import { adminPostProductTaxonCollection$Html$Json } from '../fn/product-taxon/admin-post-product-taxon-collection-html-json';
import { AdminPostProductTaxonCollection$Html$Json$Params } from '../fn/product-taxon/admin-post-product-taxon-collection-html-json';
import { adminPostProductTaxonCollection$Json$Html } from '../fn/product-taxon/admin-post-product-taxon-collection-json-html';
import { AdminPostProductTaxonCollection$Json$Html$Params } from '../fn/product-taxon/admin-post-product-taxon-collection-json-html';
import { adminPostProductTaxonCollection$Json$Json } from '../fn/product-taxon/admin-post-product-taxon-collection-json-json';
import { AdminPostProductTaxonCollection$Json$Json$Params } from '../fn/product-taxon/admin-post-product-taxon-collection-json-json';
import { adminPutProductTaxonItem$Html$Html } from '../fn/product-taxon/admin-put-product-taxon-item-html-html';
import { AdminPutProductTaxonItem$Html$Html$Params } from '../fn/product-taxon/admin-put-product-taxon-item-html-html';
import { adminPutProductTaxonItem$Html$Json } from '../fn/product-taxon/admin-put-product-taxon-item-html-json';
import { AdminPutProductTaxonItem$Html$Json$Params } from '../fn/product-taxon/admin-put-product-taxon-item-html-json';
import { adminPutProductTaxonItem$Json$Html } from '../fn/product-taxon/admin-put-product-taxon-item-json-html';
import { AdminPutProductTaxonItem$Json$Html$Params } from '../fn/product-taxon/admin-put-product-taxon-item-json-html';
import { adminPutProductTaxonItem$Json$Json } from '../fn/product-taxon/admin-put-product-taxon-item-json-json';
import { AdminPutProductTaxonItem$Json$Json$Params } from '../fn/product-taxon/admin-put-product-taxon-item-json-json';
import { Index as ProductTaxonAdminProductTaxonIndexSyliusAdminProductTaxonIndex } from '../models/ProductTaxon-admin/product_taxon/index_sylius/admin/product_taxon/index';
import { Show as ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow } from '../models/ProductTaxon-admin/product_taxon/show_sylius/admin/product_taxon/show';
import { Show as ProductTaxonShopProductTaxonShowSyliusShopProductTaxonShow } from '../models/ProductTaxon-shop/product_taxon/show_sylius/shop/product_taxon/show';
import { shopGetProductTaxonItem$Html } from '../fn/product-taxon/shop-get-product-taxon-item-html';
import { ShopGetProductTaxonItem$Html$Params } from '../fn/product-taxon/shop-get-product-taxon-item-html';
import { shopGetProductTaxonItem$Json } from '../fn/product-taxon/shop-get-product-taxon-item-json';
import { ShopGetProductTaxonItem$Json$Params } from '../fn/product-taxon/shop-get-product-taxon-item-json';

@Injectable({ providedIn: 'root' })
export class ProductTaxonService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetProductTaxonCollection()` */
  static readonly AdminGetProductTaxonCollectionPath = '/api/v2/admin/product-taxons';

  /**
   * Retrieves the collection of ProductTaxon resources.
   *
   * Retrieves the collection of ProductTaxon resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductTaxonCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductTaxonCollection$Json$Response(params?: AdminGetProductTaxonCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductTaxonAdminProductTaxonIndexSyliusAdminProductTaxonIndex>>> {
    return adminGetProductTaxonCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductTaxon resources.
   *
   * Retrieves the collection of ProductTaxon resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductTaxonCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductTaxonCollection$Json(params?: AdminGetProductTaxonCollection$Json$Params, context?: HttpContext): Observable<Array<ProductTaxonAdminProductTaxonIndexSyliusAdminProductTaxonIndex>> {
    return this.adminGetProductTaxonCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductTaxonAdminProductTaxonIndexSyliusAdminProductTaxonIndex>>): Array<ProductTaxonAdminProductTaxonIndexSyliusAdminProductTaxonIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ProductTaxon resources.
   *
   * Retrieves the collection of ProductTaxon resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductTaxonCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductTaxonCollection$Html$Response(params?: AdminGetProductTaxonCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductTaxonAdminProductTaxonIndexSyliusAdminProductTaxonIndex>>> {
    return adminGetProductTaxonCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductTaxon resources.
   *
   * Retrieves the collection of ProductTaxon resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductTaxonCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductTaxonCollection$Html(params?: AdminGetProductTaxonCollection$Html$Params, context?: HttpContext): Observable<Array<ProductTaxonAdminProductTaxonIndexSyliusAdminProductTaxonIndex>> {
    return this.adminGetProductTaxonCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductTaxonAdminProductTaxonIndexSyliusAdminProductTaxonIndex>>): Array<ProductTaxonAdminProductTaxonIndexSyliusAdminProductTaxonIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostProductTaxonCollection()` */
  static readonly AdminPostProductTaxonCollectionPath = '/api/v2/admin/product-taxons';

  /**
   * Creates a ProductTaxon resource.
   *
   * Creates a ProductTaxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductTaxonCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductTaxonCollection$Json$Json$Response(params: AdminPostProductTaxonCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>> {
    return adminPostProductTaxonCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductTaxon resource.
   *
   * Creates a ProductTaxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductTaxonCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductTaxonCollection$Json$Json(params: AdminPostProductTaxonCollection$Json$Json$Params, context?: HttpContext): Observable<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow> {
    return this.adminPostProductTaxonCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>): ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow => r.body)
    );
  }

  /**
   * Creates a ProductTaxon resource.
   *
   * Creates a ProductTaxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductTaxonCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductTaxonCollection$Json$Html$Response(params: AdminPostProductTaxonCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>> {
    return adminPostProductTaxonCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductTaxon resource.
   *
   * Creates a ProductTaxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductTaxonCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductTaxonCollection$Json$Html(params: AdminPostProductTaxonCollection$Json$Html$Params, context?: HttpContext): Observable<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow> {
    return this.adminPostProductTaxonCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>): ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow => r.body)
    );
  }

  /**
   * Creates a ProductTaxon resource.
   *
   * Creates a ProductTaxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductTaxonCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductTaxonCollection$Html$Json$Response(params: AdminPostProductTaxonCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>> {
    return adminPostProductTaxonCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductTaxon resource.
   *
   * Creates a ProductTaxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductTaxonCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductTaxonCollection$Html$Json(params: AdminPostProductTaxonCollection$Html$Json$Params, context?: HttpContext): Observable<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow> {
    return this.adminPostProductTaxonCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>): ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow => r.body)
    );
  }

  /**
   * Creates a ProductTaxon resource.
   *
   * Creates a ProductTaxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductTaxonCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductTaxonCollection$Html$Html$Response(params: AdminPostProductTaxonCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>> {
    return adminPostProductTaxonCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductTaxon resource.
   *
   * Creates a ProductTaxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductTaxonCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductTaxonCollection$Html$Html(params: AdminPostProductTaxonCollection$Html$Html$Params, context?: HttpContext): Observable<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow> {
    return this.adminPostProductTaxonCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>): ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow => r.body)
    );
  }

  /** Path part for operation `adminGetProductTaxonItem()` */
  static readonly AdminGetProductTaxonItemPath = '/api/v2/admin/product-taxons/{id}';

  /**
   * Retrieves a ProductTaxon resource.
   *
   * Retrieves a ProductTaxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductTaxonItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductTaxonItem$Json$Response(params: AdminGetProductTaxonItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>> {
    return adminGetProductTaxonItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductTaxon resource.
   *
   * Retrieves a ProductTaxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductTaxonItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductTaxonItem$Json(params: AdminGetProductTaxonItem$Json$Params, context?: HttpContext): Observable<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow> {
    return this.adminGetProductTaxonItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>): ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow => r.body)
    );
  }

  /**
   * Retrieves a ProductTaxon resource.
   *
   * Retrieves a ProductTaxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductTaxonItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductTaxonItem$Html$Response(params: AdminGetProductTaxonItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>> {
    return adminGetProductTaxonItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductTaxon resource.
   *
   * Retrieves a ProductTaxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductTaxonItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductTaxonItem$Html(params: AdminGetProductTaxonItem$Html$Params, context?: HttpContext): Observable<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow> {
    return this.adminGetProductTaxonItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>): ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow => r.body)
    );
  }

  /** Path part for operation `adminPutProductTaxonItem()` */
  static readonly AdminPutProductTaxonItemPath = '/api/v2/admin/product-taxons/{id}';

  /**
   * Replaces the ProductTaxon resource.
   *
   * Replaces the ProductTaxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductTaxonItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductTaxonItem$Json$Json$Response(params: AdminPutProductTaxonItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>> {
    return adminPutProductTaxonItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductTaxon resource.
   *
   * Replaces the ProductTaxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductTaxonItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductTaxonItem$Json$Json(params: AdminPutProductTaxonItem$Json$Json$Params, context?: HttpContext): Observable<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow> {
    return this.adminPutProductTaxonItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>): ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow => r.body)
    );
  }

  /**
   * Replaces the ProductTaxon resource.
   *
   * Replaces the ProductTaxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductTaxonItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductTaxonItem$Json$Html$Response(params: AdminPutProductTaxonItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>> {
    return adminPutProductTaxonItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductTaxon resource.
   *
   * Replaces the ProductTaxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductTaxonItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductTaxonItem$Json$Html(params: AdminPutProductTaxonItem$Json$Html$Params, context?: HttpContext): Observable<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow> {
    return this.adminPutProductTaxonItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>): ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow => r.body)
    );
  }

  /**
   * Replaces the ProductTaxon resource.
   *
   * Replaces the ProductTaxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductTaxonItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductTaxonItem$Html$Json$Response(params: AdminPutProductTaxonItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>> {
    return adminPutProductTaxonItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductTaxon resource.
   *
   * Replaces the ProductTaxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductTaxonItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductTaxonItem$Html$Json(params: AdminPutProductTaxonItem$Html$Json$Params, context?: HttpContext): Observable<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow> {
    return this.adminPutProductTaxonItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>): ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow => r.body)
    );
  }

  /**
   * Replaces the ProductTaxon resource.
   *
   * Replaces the ProductTaxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductTaxonItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductTaxonItem$Html$Html$Response(params: AdminPutProductTaxonItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>> {
    return adminPutProductTaxonItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductTaxon resource.
   *
   * Replaces the ProductTaxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductTaxonItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductTaxonItem$Html$Html(params: AdminPutProductTaxonItem$Html$Html$Params, context?: HttpContext): Observable<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow> {
    return this.adminPutProductTaxonItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>): ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteProductTaxonItem()` */
  static readonly AdminDeleteProductTaxonItemPath = '/api/v2/admin/product-taxons/{id}';

  /**
   * Removes the ProductTaxon resource.
   *
   * Removes the ProductTaxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteProductTaxonItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductTaxonItem$Response(params: AdminDeleteProductTaxonItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteProductTaxonItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the ProductTaxon resource.
   *
   * Removes the ProductTaxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteProductTaxonItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductTaxonItem(params: AdminDeleteProductTaxonItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteProductTaxonItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `shopGetProductTaxonItem()` */
  static readonly ShopGetProductTaxonItemPath = '/api/v2/shop/product-taxons/{id}';

  /**
   * Retrieves a ProductTaxon resource.
   *
   * Retrieves a ProductTaxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductTaxonItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductTaxonItem$Json$Response(params: ShopGetProductTaxonItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductTaxonShopProductTaxonShowSyliusShopProductTaxonShow>> {
    return shopGetProductTaxonItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductTaxon resource.
   *
   * Retrieves a ProductTaxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductTaxonItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductTaxonItem$Json(params: ShopGetProductTaxonItem$Json$Params, context?: HttpContext): Observable<ProductTaxonShopProductTaxonShowSyliusShopProductTaxonShow> {
    return this.shopGetProductTaxonItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductTaxonShopProductTaxonShowSyliusShopProductTaxonShow>): ProductTaxonShopProductTaxonShowSyliusShopProductTaxonShow => r.body)
    );
  }

  /**
   * Retrieves a ProductTaxon resource.
   *
   * Retrieves a ProductTaxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductTaxonItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductTaxonItem$Html$Response(params: ShopGetProductTaxonItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductTaxonShopProductTaxonShowSyliusShopProductTaxonShow>> {
    return shopGetProductTaxonItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductTaxon resource.
   *
   * Retrieves a ProductTaxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductTaxonItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductTaxonItem$Html(params: ShopGetProductTaxonItem$Html$Params, context?: HttpContext): Observable<ProductTaxonShopProductTaxonShowSyliusShopProductTaxonShow> {
    return this.shopGetProductTaxonItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductTaxonShopProductTaxonShowSyliusShopProductTaxonShow>): ProductTaxonShopProductTaxonShowSyliusShopProductTaxonShow => r.body)
    );
  }

}

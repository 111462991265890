/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteProductAssociationItem } from '../fn/product-association/admin-delete-product-association-item';
import { AdminDeleteProductAssociationItem$Params } from '../fn/product-association/admin-delete-product-association-item';
import { adminGetProductAssociationCollection$Html } from '../fn/product-association/admin-get-product-association-collection-html';
import { AdminGetProductAssociationCollection$Html$Params } from '../fn/product-association/admin-get-product-association-collection-html';
import { adminGetProductAssociationCollection$Json } from '../fn/product-association/admin-get-product-association-collection-json';
import { AdminGetProductAssociationCollection$Json$Params } from '../fn/product-association/admin-get-product-association-collection-json';
import { adminGetProductAssociationItem$Html } from '../fn/product-association/admin-get-product-association-item-html';
import { AdminGetProductAssociationItem$Html$Params } from '../fn/product-association/admin-get-product-association-item-html';
import { adminGetProductAssociationItem$Json } from '../fn/product-association/admin-get-product-association-item-json';
import { AdminGetProductAssociationItem$Json$Params } from '../fn/product-association/admin-get-product-association-item-json';
import { adminPostProductAssociationCollection$Html$Html } from '../fn/product-association/admin-post-product-association-collection-html-html';
import { AdminPostProductAssociationCollection$Html$Html$Params } from '../fn/product-association/admin-post-product-association-collection-html-html';
import { adminPostProductAssociationCollection$Html$Json } from '../fn/product-association/admin-post-product-association-collection-html-json';
import { AdminPostProductAssociationCollection$Html$Json$Params } from '../fn/product-association/admin-post-product-association-collection-html-json';
import { adminPostProductAssociationCollection$Json$Html } from '../fn/product-association/admin-post-product-association-collection-json-html';
import { AdminPostProductAssociationCollection$Json$Html$Params } from '../fn/product-association/admin-post-product-association-collection-json-html';
import { adminPostProductAssociationCollection$Json$Json } from '../fn/product-association/admin-post-product-association-collection-json-json';
import { AdminPostProductAssociationCollection$Json$Json$Params } from '../fn/product-association/admin-post-product-association-collection-json-json';
import { adminPutProductAssociationItem$Html$Html } from '../fn/product-association/admin-put-product-association-item-html-html';
import { AdminPutProductAssociationItem$Html$Html$Params } from '../fn/product-association/admin-put-product-association-item-html-html';
import { adminPutProductAssociationItem$Html$Json } from '../fn/product-association/admin-put-product-association-item-html-json';
import { AdminPutProductAssociationItem$Html$Json$Params } from '../fn/product-association/admin-put-product-association-item-html-json';
import { adminPutProductAssociationItem$Json$Html } from '../fn/product-association/admin-put-product-association-item-json-html';
import { AdminPutProductAssociationItem$Json$Html$Params } from '../fn/product-association/admin-put-product-association-item-json-html';
import { adminPutProductAssociationItem$Json$Json } from '../fn/product-association/admin-put-product-association-item-json-json';
import { AdminPutProductAssociationItem$Json$Json$Params } from '../fn/product-association/admin-put-product-association-item-json-json';
import { Index as ProductAssociationAdminProductAssociationIndexSyliusAdminProductAssociationIndex } from '../models/ProductAssociation-admin/product_association/index_sylius/admin/product_association/index';
import { Show as ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow } from '../models/ProductAssociation-admin/product_association/show_sylius/admin/product_association/show';
import { Show as ProductAssociationShopProductAssociationShowSyliusShopProductAssociationShow } from '../models/ProductAssociation-shop/product_association/show_sylius/shop/product_association/show';
import { shopGetProductAssociationItem$Html } from '../fn/product-association/shop-get-product-association-item-html';
import { ShopGetProductAssociationItem$Html$Params } from '../fn/product-association/shop-get-product-association-item-html';
import { shopGetProductAssociationItem$Json } from '../fn/product-association/shop-get-product-association-item-json';
import { ShopGetProductAssociationItem$Json$Params } from '../fn/product-association/shop-get-product-association-item-json';

@Injectable({ providedIn: 'root' })
export class ProductAssociationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetProductAssociationCollection()` */
  static readonly AdminGetProductAssociationCollectionPath = '/api/v2/admin/product-associations';

  /**
   * Retrieves the collection of ProductAssociation resources.
   *
   * Retrieves the collection of ProductAssociation resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAssociationCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationCollection$Json$Response(params?: AdminGetProductAssociationCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductAssociationAdminProductAssociationIndexSyliusAdminProductAssociationIndex>>> {
    return adminGetProductAssociationCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductAssociation resources.
   *
   * Retrieves the collection of ProductAssociation resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAssociationCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationCollection$Json(params?: AdminGetProductAssociationCollection$Json$Params, context?: HttpContext): Observable<Array<ProductAssociationAdminProductAssociationIndexSyliusAdminProductAssociationIndex>> {
    return this.adminGetProductAssociationCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductAssociationAdminProductAssociationIndexSyliusAdminProductAssociationIndex>>): Array<ProductAssociationAdminProductAssociationIndexSyliusAdminProductAssociationIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ProductAssociation resources.
   *
   * Retrieves the collection of ProductAssociation resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAssociationCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationCollection$Html$Response(params?: AdminGetProductAssociationCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductAssociationAdminProductAssociationIndexSyliusAdminProductAssociationIndex>>> {
    return adminGetProductAssociationCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductAssociation resources.
   *
   * Retrieves the collection of ProductAssociation resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAssociationCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationCollection$Html(params?: AdminGetProductAssociationCollection$Html$Params, context?: HttpContext): Observable<Array<ProductAssociationAdminProductAssociationIndexSyliusAdminProductAssociationIndex>> {
    return this.adminGetProductAssociationCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductAssociationAdminProductAssociationIndexSyliusAdminProductAssociationIndex>>): Array<ProductAssociationAdminProductAssociationIndexSyliusAdminProductAssociationIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostProductAssociationCollection()` */
  static readonly AdminPostProductAssociationCollectionPath = '/api/v2/admin/product-associations';

  /**
   * Creates a ProductAssociation resource.
   *
   * Creates a ProductAssociation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductAssociationCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductAssociationCollection$Json$Json$Response(params: AdminPostProductAssociationCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>> {
    return adminPostProductAssociationCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductAssociation resource.
   *
   * Creates a ProductAssociation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductAssociationCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductAssociationCollection$Json$Json(params: AdminPostProductAssociationCollection$Json$Json$Params, context?: HttpContext): Observable<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow> {
    return this.adminPostProductAssociationCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>): ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow => r.body)
    );
  }

  /**
   * Creates a ProductAssociation resource.
   *
   * Creates a ProductAssociation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductAssociationCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductAssociationCollection$Json$Html$Response(params: AdminPostProductAssociationCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>> {
    return adminPostProductAssociationCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductAssociation resource.
   *
   * Creates a ProductAssociation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductAssociationCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductAssociationCollection$Json$Html(params: AdminPostProductAssociationCollection$Json$Html$Params, context?: HttpContext): Observable<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow> {
    return this.adminPostProductAssociationCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>): ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow => r.body)
    );
  }

  /**
   * Creates a ProductAssociation resource.
   *
   * Creates a ProductAssociation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductAssociationCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductAssociationCollection$Html$Json$Response(params: AdminPostProductAssociationCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>> {
    return adminPostProductAssociationCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductAssociation resource.
   *
   * Creates a ProductAssociation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductAssociationCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductAssociationCollection$Html$Json(params: AdminPostProductAssociationCollection$Html$Json$Params, context?: HttpContext): Observable<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow> {
    return this.adminPostProductAssociationCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>): ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow => r.body)
    );
  }

  /**
   * Creates a ProductAssociation resource.
   *
   * Creates a ProductAssociation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductAssociationCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductAssociationCollection$Html$Html$Response(params: AdminPostProductAssociationCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>> {
    return adminPostProductAssociationCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductAssociation resource.
   *
   * Creates a ProductAssociation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductAssociationCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductAssociationCollection$Html$Html(params: AdminPostProductAssociationCollection$Html$Html$Params, context?: HttpContext): Observable<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow> {
    return this.adminPostProductAssociationCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>): ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow => r.body)
    );
  }

  /** Path part for operation `adminGetProductAssociationItem()` */
  static readonly AdminGetProductAssociationItemPath = '/api/v2/admin/product-associations/{id}';

  /**
   * Retrieves a ProductAssociation resource.
   *
   * Retrieves a ProductAssociation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAssociationItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationItem$Json$Response(params: AdminGetProductAssociationItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>> {
    return adminGetProductAssociationItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAssociation resource.
   *
   * Retrieves a ProductAssociation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAssociationItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationItem$Json(params: AdminGetProductAssociationItem$Json$Params, context?: HttpContext): Observable<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow> {
    return this.adminGetProductAssociationItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>): ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow => r.body)
    );
  }

  /**
   * Retrieves a ProductAssociation resource.
   *
   * Retrieves a ProductAssociation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAssociationItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationItem$Html$Response(params: AdminGetProductAssociationItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>> {
    return adminGetProductAssociationItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAssociation resource.
   *
   * Retrieves a ProductAssociation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAssociationItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationItem$Html(params: AdminGetProductAssociationItem$Html$Params, context?: HttpContext): Observable<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow> {
    return this.adminGetProductAssociationItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>): ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow => r.body)
    );
  }

  /** Path part for operation `adminPutProductAssociationItem()` */
  static readonly AdminPutProductAssociationItemPath = '/api/v2/admin/product-associations/{id}';

  /**
   * Replaces the ProductAssociation resource.
   *
   * Replaces the ProductAssociation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductAssociationItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductAssociationItem$Json$Json$Response(params: AdminPutProductAssociationItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>> {
    return adminPutProductAssociationItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductAssociation resource.
   *
   * Replaces the ProductAssociation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductAssociationItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductAssociationItem$Json$Json(params: AdminPutProductAssociationItem$Json$Json$Params, context?: HttpContext): Observable<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow> {
    return this.adminPutProductAssociationItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>): ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow => r.body)
    );
  }

  /**
   * Replaces the ProductAssociation resource.
   *
   * Replaces the ProductAssociation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductAssociationItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductAssociationItem$Json$Html$Response(params: AdminPutProductAssociationItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>> {
    return adminPutProductAssociationItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductAssociation resource.
   *
   * Replaces the ProductAssociation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductAssociationItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductAssociationItem$Json$Html(params: AdminPutProductAssociationItem$Json$Html$Params, context?: HttpContext): Observable<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow> {
    return this.adminPutProductAssociationItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>): ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow => r.body)
    );
  }

  /**
   * Replaces the ProductAssociation resource.
   *
   * Replaces the ProductAssociation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductAssociationItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductAssociationItem$Html$Json$Response(params: AdminPutProductAssociationItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>> {
    return adminPutProductAssociationItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductAssociation resource.
   *
   * Replaces the ProductAssociation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductAssociationItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductAssociationItem$Html$Json(params: AdminPutProductAssociationItem$Html$Json$Params, context?: HttpContext): Observable<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow> {
    return this.adminPutProductAssociationItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>): ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow => r.body)
    );
  }

  /**
   * Replaces the ProductAssociation resource.
   *
   * Replaces the ProductAssociation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductAssociationItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductAssociationItem$Html$Html$Response(params: AdminPutProductAssociationItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>> {
    return adminPutProductAssociationItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductAssociation resource.
   *
   * Replaces the ProductAssociation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductAssociationItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductAssociationItem$Html$Html(params: AdminPutProductAssociationItem$Html$Html$Params, context?: HttpContext): Observable<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow> {
    return this.adminPutProductAssociationItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>): ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteProductAssociationItem()` */
  static readonly AdminDeleteProductAssociationItemPath = '/api/v2/admin/product-associations/{id}';

  /**
   * Removes the ProductAssociation resource.
   *
   * Removes the ProductAssociation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteProductAssociationItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductAssociationItem$Response(params: AdminDeleteProductAssociationItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteProductAssociationItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the ProductAssociation resource.
   *
   * Removes the ProductAssociation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteProductAssociationItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductAssociationItem(params: AdminDeleteProductAssociationItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteProductAssociationItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `shopGetProductAssociationItem()` */
  static readonly ShopGetProductAssociationItemPath = '/api/v2/shop/product-associations/{id}';

  /**
   * Retrieves a ProductAssociation resource.
   *
   * Retrieves a ProductAssociation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductAssociationItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAssociationItem$Json$Response(params: ShopGetProductAssociationItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationShopProductAssociationShowSyliusShopProductAssociationShow>> {
    return shopGetProductAssociationItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAssociation resource.
   *
   * Retrieves a ProductAssociation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductAssociationItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAssociationItem$Json(params: ShopGetProductAssociationItem$Json$Params, context?: HttpContext): Observable<ProductAssociationShopProductAssociationShowSyliusShopProductAssociationShow> {
    return this.shopGetProductAssociationItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationShopProductAssociationShowSyliusShopProductAssociationShow>): ProductAssociationShopProductAssociationShowSyliusShopProductAssociationShow => r.body)
    );
  }

  /**
   * Retrieves a ProductAssociation resource.
   *
   * Retrieves a ProductAssociation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductAssociationItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAssociationItem$Html$Response(params: ShopGetProductAssociationItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationShopProductAssociationShowSyliusShopProductAssociationShow>> {
    return shopGetProductAssociationItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAssociation resource.
   *
   * Retrieves a ProductAssociation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductAssociationItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAssociationItem$Html(params: ShopGetProductAssociationItem$Html$Params, context?: HttpContext): Observable<ProductAssociationShopProductAssociationShowSyliusShopProductAssociationShow> {
    return this.shopGetProductAssociationItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationShopProductAssociationShowSyliusShopProductAssociationShow>): ProductAssociationShopProductAssociationShowSyliusShopProductAssociationShow => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Index as TaxRateAdminTaxRateIndexSyliusAdminTaxRateIndex } from '../../models/TaxRate-admin/tax_rate/index_sylius/admin/tax_rate/index';

export interface AdminGetTaxRateCollection$Json$Params {

/**
 * The collection page number
 */
  page?: number;

/**
 * The number of items per page
 */
  itemsPerPage?: number;
  'startDate[before]'?: string;
  'startDate[strictly_before]'?: string;
  'startDate[after]'?: string;
  'startDate[strictly_after]'?: string;
  'endDate[before]'?: string;
  'endDate[strictly_before]'?: string;
  'endDate[after]'?: string;
  'endDate[strictly_after]'?: string;
}

export function adminGetTaxRateCollection$Json(http: HttpClient, rootUrl: string, params?: AdminGetTaxRateCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxRateAdminTaxRateIndexSyliusAdminTaxRateIndex>>> {
  const rb = new RequestBuilder(rootUrl, adminGetTaxRateCollection$Json.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
    rb.query('startDate[before]', params['startDate[before]'], {"style":"form","explode":false});
    rb.query('startDate[strictly_before]', params['startDate[strictly_before]'], {"style":"form","explode":false});
    rb.query('startDate[after]', params['startDate[after]'], {"style":"form","explode":false});
    rb.query('startDate[strictly_after]', params['startDate[strictly_after]'], {"style":"form","explode":false});
    rb.query('endDate[before]', params['endDate[before]'], {"style":"form","explode":false});
    rb.query('endDate[strictly_before]', params['endDate[strictly_before]'], {"style":"form","explode":false});
    rb.query('endDate[after]', params['endDate[after]'], {"style":"form","explode":false});
    rb.query('endDate[strictly_after]', params['endDate[strictly_after]'], {"style":"form","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<TaxRateAdminTaxRateIndexSyliusAdminTaxRateIndex>>;
    })
  );
}

adminGetTaxRateCollection$Json.PATH = '/api/v2/admin/tax-rates';

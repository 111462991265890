/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetProductAttributeValueItem$Html } from '../fn/product-attribute-value/admin-get-product-attribute-value-item-html';
import { AdminGetProductAttributeValueItem$Html$Params } from '../fn/product-attribute-value/admin-get-product-attribute-value-item-html';
import { adminGetProductAttributeValueItem$Json } from '../fn/product-attribute-value/admin-get-product-attribute-value-item-json';
import { AdminGetProductAttributeValueItem$Json$Params } from '../fn/product-attribute-value/admin-get-product-attribute-value-item-json';
import { apiProductsAttributesGetSubresourceProductSubresource$Html } from '../fn/product-attribute-value/api-products-attributes-get-subresource-product-subresource-html';
import { ApiProductsAttributesGetSubresourceProductSubresource$Html$Params } from '../fn/product-attribute-value/api-products-attributes-get-subresource-product-subresource-html';
import { apiProductsAttributesGetSubresourceProductSubresource$Json } from '../fn/product-attribute-value/api-products-attributes-get-subresource-product-subresource-json';
import { ApiProductsAttributesGetSubresourceProductSubresource$Json$Params } from '../fn/product-attribute-value/api-products-attributes-get-subresource-product-subresource-json';
import { Show as ProductAttributeValueAdminProductAttributeValueShowSyliusAdminProductAttributeValueShow } from '../models/ProductAttributeValue-admin/product_attribute_value/show_sylius/admin/product_attribute_value/show';
import { Show as ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow } from '../models/ProductAttributeValue-shop/product_attribute_value/show_sylius/shop/product_attribute_value/show';
import { shopGetProductAttributeValueItem$Html } from '../fn/product-attribute-value/shop-get-product-attribute-value-item-html';
import { ShopGetProductAttributeValueItem$Html$Params } from '../fn/product-attribute-value/shop-get-product-attribute-value-item-html';
import { shopGetProductAttributeValueItem$Json } from '../fn/product-attribute-value/shop-get-product-attribute-value-item-json';
import { ShopGetProductAttributeValueItem$Json$Params } from '../fn/product-attribute-value/shop-get-product-attribute-value-item-json';

@Injectable({ providedIn: 'root' })
export class ProductAttributeValueService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetProductAttributeValueItem()` */
  static readonly AdminGetProductAttributeValueItemPath = '/api/v2/admin/product-attribute-values/{id}';

  /**
   * Retrieves a ProductAttributeValue resource.
   *
   * Retrieves a ProductAttributeValue resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAttributeValueItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeValueItem$Json$Response(params: AdminGetProductAttributeValueItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeValueAdminProductAttributeValueShowSyliusAdminProductAttributeValueShow>> {
    return adminGetProductAttributeValueItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAttributeValue resource.
   *
   * Retrieves a ProductAttributeValue resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAttributeValueItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeValueItem$Json(params: AdminGetProductAttributeValueItem$Json$Params, context?: HttpContext): Observable<ProductAttributeValueAdminProductAttributeValueShowSyliusAdminProductAttributeValueShow> {
    return this.adminGetProductAttributeValueItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeValueAdminProductAttributeValueShowSyliusAdminProductAttributeValueShow>): ProductAttributeValueAdminProductAttributeValueShowSyliusAdminProductAttributeValueShow => r.body)
    );
  }

  /**
   * Retrieves a ProductAttributeValue resource.
   *
   * Retrieves a ProductAttributeValue resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAttributeValueItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeValueItem$Html$Response(params: AdminGetProductAttributeValueItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeValueAdminProductAttributeValueShowSyliusAdminProductAttributeValueShow>> {
    return adminGetProductAttributeValueItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAttributeValue resource.
   *
   * Retrieves a ProductAttributeValue resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAttributeValueItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeValueItem$Html(params: AdminGetProductAttributeValueItem$Html$Params, context?: HttpContext): Observable<ProductAttributeValueAdminProductAttributeValueShowSyliusAdminProductAttributeValueShow> {
    return this.adminGetProductAttributeValueItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeValueAdminProductAttributeValueShowSyliusAdminProductAttributeValueShow>): ProductAttributeValueAdminProductAttributeValueShowSyliusAdminProductAttributeValueShow => r.body)
    );
  }

  /** Path part for operation `shopGetProductAttributeValueItem()` */
  static readonly ShopGetProductAttributeValueItemPath = '/api/v2/shop/product-attribute-values/{id}';

  /**
   * Retrieves a ProductAttributeValue resource.
   *
   * Retrieves a ProductAttributeValue resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductAttributeValueItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAttributeValueItem$Json$Response(params: ShopGetProductAttributeValueItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>> {
    return shopGetProductAttributeValueItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAttributeValue resource.
   *
   * Retrieves a ProductAttributeValue resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductAttributeValueItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAttributeValueItem$Json(params: ShopGetProductAttributeValueItem$Json$Params, context?: HttpContext): Observable<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow> {
    return this.shopGetProductAttributeValueItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>): ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow => r.body)
    );
  }

  /**
   * Retrieves a ProductAttributeValue resource.
   *
   * Retrieves a ProductAttributeValue resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductAttributeValueItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAttributeValueItem$Html$Response(params: ShopGetProductAttributeValueItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>> {
    return shopGetProductAttributeValueItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAttributeValue resource.
   *
   * Retrieves a ProductAttributeValue resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductAttributeValueItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAttributeValueItem$Html(params: ShopGetProductAttributeValueItem$Html$Params, context?: HttpContext): Observable<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow> {
    return this.shopGetProductAttributeValueItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>): ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow => r.body)
    );
  }

  /** Path part for operation `apiProductsAttributesGetSubresourceProductSubresource()` */
  static readonly ApiProductsAttributesGetSubresourceProductSubresourcePath = '/api/v2/shop/products/{code}/attributes';

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductsAttributesGetSubresourceProductSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsAttributesGetSubresourceProductSubresource$Json$Response(params: ApiProductsAttributesGetSubresourceProductSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>>> {
    return apiProductsAttributesGetSubresourceProductSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductsAttributesGetSubresourceProductSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsAttributesGetSubresourceProductSubresource$Json(params: ApiProductsAttributesGetSubresourceProductSubresource$Json$Params, context?: HttpContext): Observable<Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>> {
    return this.apiProductsAttributesGetSubresourceProductSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>>): Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow> => r.body)
    );
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductsAttributesGetSubresourceProductSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsAttributesGetSubresourceProductSubresource$Html$Response(params: ApiProductsAttributesGetSubresourceProductSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>>> {
    return apiProductsAttributesGetSubresourceProductSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductsAttributesGetSubresourceProductSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsAttributesGetSubresourceProductSubresource$Html(params: ApiProductsAttributesGetSubresourceProductSubresource$Html$Params, context?: HttpContext): Observable<Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>> {
    return this.apiProductsAttributesGetSubresourceProductSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>>): Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow> => r.body)
    );
  }

}

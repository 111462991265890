/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetProductAttributeTranslationItem$Html } from '../fn/product-attribute-translation/admin-get-product-attribute-translation-item-html';
import { AdminGetProductAttributeTranslationItem$Html$Params } from '../fn/product-attribute-translation/admin-get-product-attribute-translation-item-html';
import { adminGetProductAttributeTranslationItem$Json } from '../fn/product-attribute-translation/admin-get-product-attribute-translation-item-json';
import { AdminGetProductAttributeTranslationItem$Json$Params } from '../fn/product-attribute-translation/admin-get-product-attribute-translation-item-json';
import { Show as ProductAttributeTranslationAdminProductAttributeShowSyliusAdminProductAttributeShowAdminProductAttributeTranslationShowSyliusAdminProductAttributeTranslationShow } from '../models/ProductAttributeTranslation-admin/product_attribute/show_sylius/admin/product_attribute/show_admin/product_attribute_translation/show_sylius/admin/product_attribute_translation/show';

@Injectable({ providedIn: 'root' })
export class ProductAttributeTranslationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetProductAttributeTranslationItem()` */
  static readonly AdminGetProductAttributeTranslationItemPath = '/api/v2/admin/product-attribute-translations/{id}';

  /**
   * Retrieves a ProductAttributeTranslation resource.
   *
   * Retrieves a ProductAttributeTranslation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAttributeTranslationItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeTranslationItem$Json$Response(params: AdminGetProductAttributeTranslationItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeTranslationAdminProductAttributeShowSyliusAdminProductAttributeShowAdminProductAttributeTranslationShowSyliusAdminProductAttributeTranslationShow>> {
    return adminGetProductAttributeTranslationItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAttributeTranslation resource.
   *
   * Retrieves a ProductAttributeTranslation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAttributeTranslationItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeTranslationItem$Json(params: AdminGetProductAttributeTranslationItem$Json$Params, context?: HttpContext): Observable<ProductAttributeTranslationAdminProductAttributeShowSyliusAdminProductAttributeShowAdminProductAttributeTranslationShowSyliusAdminProductAttributeTranslationShow> {
    return this.adminGetProductAttributeTranslationItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeTranslationAdminProductAttributeShowSyliusAdminProductAttributeShowAdminProductAttributeTranslationShowSyliusAdminProductAttributeTranslationShow>): ProductAttributeTranslationAdminProductAttributeShowSyliusAdminProductAttributeShowAdminProductAttributeTranslationShowSyliusAdminProductAttributeTranslationShow => r.body)
    );
  }

  /**
   * Retrieves a ProductAttributeTranslation resource.
   *
   * Retrieves a ProductAttributeTranslation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAttributeTranslationItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeTranslationItem$Html$Response(params: AdminGetProductAttributeTranslationItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeTranslationAdminProductAttributeShowSyliusAdminProductAttributeShowAdminProductAttributeTranslationShowSyliusAdminProductAttributeTranslationShow>> {
    return adminGetProductAttributeTranslationItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAttributeTranslation resource.
   *
   * Retrieves a ProductAttributeTranslation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAttributeTranslationItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeTranslationItem$Html(params: AdminGetProductAttributeTranslationItem$Html$Params, context?: HttpContext): Observable<ProductAttributeTranslationAdminProductAttributeShowSyliusAdminProductAttributeShowAdminProductAttributeTranslationShowSyliusAdminProductAttributeTranslationShow> {
    return this.adminGetProductAttributeTranslationItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeTranslationAdminProductAttributeShowSyliusAdminProductAttributeShowAdminProductAttributeTranslationShowSyliusAdminProductAttributeTranslationShow>): ProductAttributeTranslationAdminProductAttributeShowSyliusAdminProductAttributeShowAdminProductAttributeTranslationShowSyliusAdminProductAttributeTranslationShow => r.body)
    );
  }

}

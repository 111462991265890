/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteZoneItem } from '../fn/zone/admin-delete-zone-item';
import { AdminDeleteZoneItem$Params } from '../fn/zone/admin-delete-zone-item';
import { adminGetZoneCollection$Html } from '../fn/zone/admin-get-zone-collection-html';
import { AdminGetZoneCollection$Html$Params } from '../fn/zone/admin-get-zone-collection-html';
import { adminGetZoneCollection$Json } from '../fn/zone/admin-get-zone-collection-json';
import { AdminGetZoneCollection$Json$Params } from '../fn/zone/admin-get-zone-collection-json';
import { adminGetZoneItem$Html } from '../fn/zone/admin-get-zone-item-html';
import { AdminGetZoneItem$Html$Params } from '../fn/zone/admin-get-zone-item-html';
import { adminGetZoneItem$Json } from '../fn/zone/admin-get-zone-item-json';
import { AdminGetZoneItem$Json$Params } from '../fn/zone/admin-get-zone-item-json';
import { adminPostZoneCollection$Html$Html } from '../fn/zone/admin-post-zone-collection-html-html';
import { AdminPostZoneCollection$Html$Html$Params } from '../fn/zone/admin-post-zone-collection-html-html';
import { adminPostZoneCollection$Html$Json } from '../fn/zone/admin-post-zone-collection-html-json';
import { AdminPostZoneCollection$Html$Json$Params } from '../fn/zone/admin-post-zone-collection-html-json';
import { adminPostZoneCollection$Json$Html } from '../fn/zone/admin-post-zone-collection-json-html';
import { AdminPostZoneCollection$Json$Html$Params } from '../fn/zone/admin-post-zone-collection-json-html';
import { adminPostZoneCollection$Json$Json } from '../fn/zone/admin-post-zone-collection-json-json';
import { AdminPostZoneCollection$Json$Json$Params } from '../fn/zone/admin-post-zone-collection-json-json';
import { adminPutZoneItem$Html$Html } from '../fn/zone/admin-put-zone-item-html-html';
import { AdminPutZoneItem$Html$Html$Params } from '../fn/zone/admin-put-zone-item-html-html';
import { adminPutZoneItem$Html$Json } from '../fn/zone/admin-put-zone-item-html-json';
import { AdminPutZoneItem$Html$Json$Params } from '../fn/zone/admin-put-zone-item-html-json';
import { adminPutZoneItem$Json$Html } from '../fn/zone/admin-put-zone-item-json-html';
import { AdminPutZoneItem$Json$Html$Params } from '../fn/zone/admin-put-zone-item-json-html';
import { adminPutZoneItem$Json$Json } from '../fn/zone/admin-put-zone-item-json-json';
import { AdminPutZoneItem$Json$Json$Params } from '../fn/zone/admin-put-zone-item-json-json';
import { apiZonesMembersGetSubresourceZoneSubresource$Html } from '../fn/zone-member/api-zones-members-get-subresource-zone-subresource-html';
import { ApiZonesMembersGetSubresourceZoneSubresource$Html$Params } from '../fn/zone-member/api-zones-members-get-subresource-zone-subresource-html';
import { apiZonesMembersGetSubresourceZoneSubresource$Json } from '../fn/zone-member/api-zones-members-get-subresource-zone-subresource-json';
import { ApiZonesMembersGetSubresourceZoneSubresource$Json$Params } from '../fn/zone-member/api-zones-members-get-subresource-zone-subresource-json';
import { Index as ZoneAdminZoneIndexSyliusAdminZoneIndex } from '../models/Zone-admin/zone/index_sylius/admin/zone/index';
import { Show as ZoneAdminZoneShowSyliusAdminZoneShow } from '../models/Zone-admin/zone/show_sylius/admin/zone/show';
import { Show as ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow } from '../models/ZoneMember-admin/zone_member/show_sylius/admin/zone_member/show';

@Injectable({ providedIn: 'root' })
export class ZoneService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetZoneCollection()` */
  static readonly AdminGetZoneCollectionPath = '/api/v2/admin/zones';

  /**
   * Retrieves the collection of Zone resources.
   *
   * Retrieves the collection of Zone resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetZoneCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetZoneCollection$Json$Response(params?: AdminGetZoneCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ZoneAdminZoneIndexSyliusAdminZoneIndex>>> {
    return adminGetZoneCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Zone resources.
   *
   * Retrieves the collection of Zone resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetZoneCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetZoneCollection$Json(params?: AdminGetZoneCollection$Json$Params, context?: HttpContext): Observable<Array<ZoneAdminZoneIndexSyliusAdminZoneIndex>> {
    return this.adminGetZoneCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ZoneAdminZoneIndexSyliusAdminZoneIndex>>): Array<ZoneAdminZoneIndexSyliusAdminZoneIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Zone resources.
   *
   * Retrieves the collection of Zone resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetZoneCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetZoneCollection$Html$Response(params?: AdminGetZoneCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ZoneAdminZoneIndexSyliusAdminZoneIndex>>> {
    return adminGetZoneCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Zone resources.
   *
   * Retrieves the collection of Zone resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetZoneCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetZoneCollection$Html(params?: AdminGetZoneCollection$Html$Params, context?: HttpContext): Observable<Array<ZoneAdminZoneIndexSyliusAdminZoneIndex>> {
    return this.adminGetZoneCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ZoneAdminZoneIndexSyliusAdminZoneIndex>>): Array<ZoneAdminZoneIndexSyliusAdminZoneIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostZoneCollection()` */
  static readonly AdminPostZoneCollectionPath = '/api/v2/admin/zones';

  /**
   * Creates a Zone resource.
   *
   * Creates a Zone resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostZoneCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostZoneCollection$Json$Json$Response(params: AdminPostZoneCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>> {
    return adminPostZoneCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Zone resource.
   *
   * Creates a Zone resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostZoneCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostZoneCollection$Json$Json(params: AdminPostZoneCollection$Json$Json$Params, context?: HttpContext): Observable<ZoneAdminZoneShowSyliusAdminZoneShow> {
    return this.adminPostZoneCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>): ZoneAdminZoneShowSyliusAdminZoneShow => r.body)
    );
  }

  /**
   * Creates a Zone resource.
   *
   * Creates a Zone resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostZoneCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostZoneCollection$Json$Html$Response(params: AdminPostZoneCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>> {
    return adminPostZoneCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Zone resource.
   *
   * Creates a Zone resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostZoneCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostZoneCollection$Json$Html(params: AdminPostZoneCollection$Json$Html$Params, context?: HttpContext): Observable<ZoneAdminZoneShowSyliusAdminZoneShow> {
    return this.adminPostZoneCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>): ZoneAdminZoneShowSyliusAdminZoneShow => r.body)
    );
  }

  /**
   * Creates a Zone resource.
   *
   * Creates a Zone resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostZoneCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostZoneCollection$Html$Json$Response(params: AdminPostZoneCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>> {
    return adminPostZoneCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Zone resource.
   *
   * Creates a Zone resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostZoneCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostZoneCollection$Html$Json(params: AdminPostZoneCollection$Html$Json$Params, context?: HttpContext): Observable<ZoneAdminZoneShowSyliusAdminZoneShow> {
    return this.adminPostZoneCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>): ZoneAdminZoneShowSyliusAdminZoneShow => r.body)
    );
  }

  /**
   * Creates a Zone resource.
   *
   * Creates a Zone resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostZoneCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostZoneCollection$Html$Html$Response(params: AdminPostZoneCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>> {
    return adminPostZoneCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Zone resource.
   *
   * Creates a Zone resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostZoneCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostZoneCollection$Html$Html(params: AdminPostZoneCollection$Html$Html$Params, context?: HttpContext): Observable<ZoneAdminZoneShowSyliusAdminZoneShow> {
    return this.adminPostZoneCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>): ZoneAdminZoneShowSyliusAdminZoneShow => r.body)
    );
  }

  /** Path part for operation `adminGetZoneItem()` */
  static readonly AdminGetZoneItemPath = '/api/v2/admin/zones/{code}';

  /**
   * Retrieves a Zone resource.
   *
   * Retrieves a Zone resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetZoneItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetZoneItem$Json$Response(params: AdminGetZoneItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>> {
    return adminGetZoneItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Zone resource.
   *
   * Retrieves a Zone resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetZoneItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetZoneItem$Json(params: AdminGetZoneItem$Json$Params, context?: HttpContext): Observable<ZoneAdminZoneShowSyliusAdminZoneShow> {
    return this.adminGetZoneItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>): ZoneAdminZoneShowSyliusAdminZoneShow => r.body)
    );
  }

  /**
   * Retrieves a Zone resource.
   *
   * Retrieves a Zone resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetZoneItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetZoneItem$Html$Response(params: AdminGetZoneItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>> {
    return adminGetZoneItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Zone resource.
   *
   * Retrieves a Zone resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetZoneItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetZoneItem$Html(params: AdminGetZoneItem$Html$Params, context?: HttpContext): Observable<ZoneAdminZoneShowSyliusAdminZoneShow> {
    return this.adminGetZoneItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>): ZoneAdminZoneShowSyliusAdminZoneShow => r.body)
    );
  }

  /** Path part for operation `adminPutZoneItem()` */
  static readonly AdminPutZoneItemPath = '/api/v2/admin/zones/{code}';

  /**
   * Replaces the Zone resource.
   *
   * Replaces the Zone resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutZoneItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutZoneItem$Json$Json$Response(params: AdminPutZoneItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>> {
    return adminPutZoneItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Zone resource.
   *
   * Replaces the Zone resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutZoneItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutZoneItem$Json$Json(params: AdminPutZoneItem$Json$Json$Params, context?: HttpContext): Observable<ZoneAdminZoneShowSyliusAdminZoneShow> {
    return this.adminPutZoneItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>): ZoneAdminZoneShowSyliusAdminZoneShow => r.body)
    );
  }

  /**
   * Replaces the Zone resource.
   *
   * Replaces the Zone resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutZoneItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutZoneItem$Json$Html$Response(params: AdminPutZoneItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>> {
    return adminPutZoneItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Zone resource.
   *
   * Replaces the Zone resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutZoneItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutZoneItem$Json$Html(params: AdminPutZoneItem$Json$Html$Params, context?: HttpContext): Observable<ZoneAdminZoneShowSyliusAdminZoneShow> {
    return this.adminPutZoneItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>): ZoneAdminZoneShowSyliusAdminZoneShow => r.body)
    );
  }

  /**
   * Replaces the Zone resource.
   *
   * Replaces the Zone resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutZoneItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutZoneItem$Html$Json$Response(params: AdminPutZoneItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>> {
    return adminPutZoneItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Zone resource.
   *
   * Replaces the Zone resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutZoneItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutZoneItem$Html$Json(params: AdminPutZoneItem$Html$Json$Params, context?: HttpContext): Observable<ZoneAdminZoneShowSyliusAdminZoneShow> {
    return this.adminPutZoneItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>): ZoneAdminZoneShowSyliusAdminZoneShow => r.body)
    );
  }

  /**
   * Replaces the Zone resource.
   *
   * Replaces the Zone resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutZoneItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutZoneItem$Html$Html$Response(params: AdminPutZoneItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>> {
    return adminPutZoneItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Zone resource.
   *
   * Replaces the Zone resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutZoneItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutZoneItem$Html$Html(params: AdminPutZoneItem$Html$Html$Params, context?: HttpContext): Observable<ZoneAdminZoneShowSyliusAdminZoneShow> {
    return this.adminPutZoneItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>): ZoneAdminZoneShowSyliusAdminZoneShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteZoneItem()` */
  static readonly AdminDeleteZoneItemPath = '/api/v2/admin/zones/{code}';

  /**
   * Removes the Zone resource.
   *
   * Removes the Zone resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteZoneItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteZoneItem$Response(params: AdminDeleteZoneItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteZoneItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Zone resource.
   *
   * Removes the Zone resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteZoneItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteZoneItem(params: AdminDeleteZoneItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteZoneItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiZonesMembersGetSubresourceZoneSubresource()` */
  static readonly ApiZonesMembersGetSubresourceZoneSubresourcePath = '/api/v2/admin/zones/{code}/members';

  /**
   * Retrieves a Zone resource.
   *
   * Retrieves a Zone resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiZonesMembersGetSubresourceZoneSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiZonesMembersGetSubresourceZoneSubresource$Json$Response(params: ApiZonesMembersGetSubresourceZoneSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>>> {
    return apiZonesMembersGetSubresourceZoneSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Zone resource.
   *
   * Retrieves a Zone resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiZonesMembersGetSubresourceZoneSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiZonesMembersGetSubresourceZoneSubresource$Json(params: ApiZonesMembersGetSubresourceZoneSubresource$Json$Params, context?: HttpContext): Observable<Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>> {
    return this.apiZonesMembersGetSubresourceZoneSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>>): Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow> => r.body)
    );
  }

  /**
   * Retrieves a Zone resource.
   *
   * Retrieves a Zone resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiZonesMembersGetSubresourceZoneSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiZonesMembersGetSubresourceZoneSubresource$Html$Response(params: ApiZonesMembersGetSubresourceZoneSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>>> {
    return apiZonesMembersGetSubresourceZoneSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Zone resource.
   *
   * Retrieves a Zone resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiZonesMembersGetSubresourceZoneSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiZonesMembersGetSubresourceZoneSubresource$Html(params: ApiZonesMembersGetSubresourceZoneSubresource$Html$Params, context?: HttpContext): Observable<Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>> {
    return this.apiZonesMembersGetSubresourceZoneSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>>): Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow> => r.body)
    );
  }

}

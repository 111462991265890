/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Read as AdministratorAdminAdministratorTokenRead } from '../models/Administrator-admin/administrator/token/read';
import { Read as CustomerShopCustomerTokenRead } from '../models/Customer-shop/customer/token/read';
import { postAdministratorCredentialsItem } from '../fn/administrator/post-administrator-credentials-item';
import { PostAdministratorCredentialsItem$Params } from '../fn/administrator/post-administrator-credentials-item';
import { postCustomerCredentialsItem } from '../fn/customer/post-customer-credentials-item';
import { PostCustomerCredentialsItem$Params } from '../fn/customer/post-customer-credentials-item';

@Injectable({ providedIn: 'root' })
export class SecurityService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `postAdministratorCredentialsItem()` */
  static readonly PostAdministratorCredentialsItemPath = '/api/v2/admin/administrators/token';

  /**
   * Retrieves the JWT token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAdministratorCredentialsItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAdministratorCredentialsItem$Response(params?: PostAdministratorCredentialsItem$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministratorAdminAdministratorTokenRead>> {
    return postAdministratorCredentialsItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the JWT token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postAdministratorCredentialsItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAdministratorCredentialsItem(params?: PostAdministratorCredentialsItem$Params, context?: HttpContext): Observable<AdministratorAdminAdministratorTokenRead> {
    return this.postAdministratorCredentialsItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministratorAdminAdministratorTokenRead>): AdministratorAdminAdministratorTokenRead => r.body)
    );
  }

  /** Path part for operation `postCustomerCredentialsItem()` */
  static readonly PostCustomerCredentialsItemPath = '/api/v2/shop/customers/token';

  /**
   * Retrieves the JWT token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerCredentialsItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerCredentialsItem$Response(params?: PostCustomerCredentialsItem$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerShopCustomerTokenRead>> {
    return postCustomerCredentialsItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the JWT token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCustomerCredentialsItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerCredentialsItem(params?: PostCustomerCredentialsItem$Params, context?: HttpContext): Observable<CustomerShopCustomerTokenRead> {
    return this.postCustomerCredentialsItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerShopCustomerTokenRead>): CustomerShopCustomerTokenRead => r.body)
    );
  }

}

import { Component } from '@angular/core';

@Component({
  selector: 'bn-spinloader',
  standalone: true,
  imports: [],
  template: `
    <svg class="spinloader" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
  `,
  styles: `
    :host {
      background-image: linear-gradient(-105deg, rgba(255, 240, 213, 0.5), rgba(252, 253, 253, 0.5));
      background-clip: content-box, padding-box;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      min-height: 2em;
    }

    :host.no-bg {
      background: transparent;
    }

    .spinloader {
      animation: rotate 2s linear infinite;
      z-index: 2;
      width: 50px;
      height: 50px;

      & .path {
        stroke: #feb72e;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
      }
    }

    :host.small {
      min-height: auto;
      .spinloader {
        width: 1em;
        height: 1em;
      }
    }

    :host.medium {
      min-height: auto;
      .spinloader {
        width: 2em;
        height: 2em;
      }
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    }
  `
})
export class SpinloaderComponent {

}

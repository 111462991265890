/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Statistics } from '../../models/statistics';

export interface GetStatistics$Params {

/**
 * Channel to get statistics for
 */
  channelCode: string;

/**
 * Start date for statistics
 */
  startDate: string;

/**
 * Interval type for statistics
 */
  interval: 'day' | 'month' | 'year';

/**
 * End date for statistics
 */
  endDate: string;
}

export function getStatistics(http: HttpClient, rootUrl: string, params: GetStatistics$Params, context?: HttpContext): Observable<StrictHttpResponse<Statistics>> {
  const rb = new RequestBuilder(rootUrl, getStatistics.PATH, 'get');
  if (params) {
    rb.query('channelCode', params.channelCode, {"style":"form","explode":false});
    rb.query('startDate', params.startDate, {"style":"form","explode":false});
    rb.query('interval', params.interval, {"style":"form","explode":false});
    rb.query('endDate', params.endDate, {"style":"form","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Statistics>;
    })
  );
}

getStatistics.PATH = '/api/v2/admin/statistics';

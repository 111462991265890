/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminAcceptProductReviewItem$Html } from '../fn/product-review/admin-accept-product-review-item-html';
import { AdminAcceptProductReviewItem$Html$Params } from '../fn/product-review/admin-accept-product-review-item-html';
import { adminAcceptProductReviewItem$Json } from '../fn/product-review/admin-accept-product-review-item-json';
import { AdminAcceptProductReviewItem$Json$Params } from '../fn/product-review/admin-accept-product-review-item-json';
import { adminDeleteProductReviewItem } from '../fn/product-review/admin-delete-product-review-item';
import { AdminDeleteProductReviewItem$Params } from '../fn/product-review/admin-delete-product-review-item';
import { adminGetProductReviewCollection$Html } from '../fn/product-review/admin-get-product-review-collection-html';
import { AdminGetProductReviewCollection$Html$Params } from '../fn/product-review/admin-get-product-review-collection-html';
import { adminGetProductReviewCollection$Json } from '../fn/product-review/admin-get-product-review-collection-json';
import { AdminGetProductReviewCollection$Json$Params } from '../fn/product-review/admin-get-product-review-collection-json';
import { adminGetProductReviewItem$Html } from '../fn/product-review/admin-get-product-review-item-html';
import { AdminGetProductReviewItem$Html$Params } from '../fn/product-review/admin-get-product-review-item-html';
import { adminGetProductReviewItem$Json } from '../fn/product-review/admin-get-product-review-item-json';
import { AdminGetProductReviewItem$Json$Params } from '../fn/product-review/admin-get-product-review-item-json';
import { adminPutProductReviewItem$Html$Html } from '../fn/product-review/admin-put-product-review-item-html-html';
import { AdminPutProductReviewItem$Html$Html$Params } from '../fn/product-review/admin-put-product-review-item-html-html';
import { adminPutProductReviewItem$Html$Json } from '../fn/product-review/admin-put-product-review-item-html-json';
import { AdminPutProductReviewItem$Html$Json$Params } from '../fn/product-review/admin-put-product-review-item-html-json';
import { adminPutProductReviewItem$Json$Html } from '../fn/product-review/admin-put-product-review-item-json-html';
import { AdminPutProductReviewItem$Json$Html$Params } from '../fn/product-review/admin-put-product-review-item-json-html';
import { adminPutProductReviewItem$Json$Json } from '../fn/product-review/admin-put-product-review-item-json-json';
import { AdminPutProductReviewItem$Json$Json$Params } from '../fn/product-review/admin-put-product-review-item-json-json';
import { adminRejectProductReviewItem$Html } from '../fn/product-review/admin-reject-product-review-item-html';
import { AdminRejectProductReviewItem$Html$Params } from '../fn/product-review/admin-reject-product-review-item-html';
import { adminRejectProductReviewItem$Json } from '../fn/product-review/admin-reject-product-review-item-json';
import { AdminRejectProductReviewItem$Json$Params } from '../fn/product-review/admin-reject-product-review-item-json';
import { Index as ProductReviewAdminProductReviewIndexSyliusAdminProductReviewIndex } from '../models/ProductReview-admin/product_review/index_sylius/admin/product_review/index';
import { Show as ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow } from '../models/ProductReview-admin/product_review/show_sylius/admin/product_review/show';
import { Index as ProductReviewShopProductReviewIndexSyliusShopProductReviewIndex } from '../models/ProductReview-shop/product_review/index_sylius/shop/product_review/index';
import { Show as ProductReviewShopProductReviewShowSyliusShopProductReviewShow } from '../models/ProductReview-shop/product_review/show_sylius/shop/product_review/show';
import { shopGetProductReviewCollection$Html } from '../fn/product-review/shop-get-product-review-collection-html';
import { ShopGetProductReviewCollection$Html$Params } from '../fn/product-review/shop-get-product-review-collection-html';
import { shopGetProductReviewCollection$Json } from '../fn/product-review/shop-get-product-review-collection-json';
import { ShopGetProductReviewCollection$Json$Params } from '../fn/product-review/shop-get-product-review-collection-json';
import { shopGetProductReviewItem$Html } from '../fn/product-review/shop-get-product-review-item-html';
import { ShopGetProductReviewItem$Html$Params } from '../fn/product-review/shop-get-product-review-item-html';
import { shopGetProductReviewItem$Json } from '../fn/product-review/shop-get-product-review-item-json';
import { ShopGetProductReviewItem$Json$Params } from '../fn/product-review/shop-get-product-review-item-json';
import { shopPostProductReviewCollection$Html$Html } from '../fn/product-review/shop-post-product-review-collection-html-html';
import { ShopPostProductReviewCollection$Html$Html$Params } from '../fn/product-review/shop-post-product-review-collection-html-html';
import { shopPostProductReviewCollection$Html$Json } from '../fn/product-review/shop-post-product-review-collection-html-json';
import { ShopPostProductReviewCollection$Html$Json$Params } from '../fn/product-review/shop-post-product-review-collection-html-json';
import { shopPostProductReviewCollection$Json$Html } from '../fn/product-review/shop-post-product-review-collection-json-html';
import { ShopPostProductReviewCollection$Json$Html$Params } from '../fn/product-review/shop-post-product-review-collection-json-html';
import { shopPostProductReviewCollection$Json$Json } from '../fn/product-review/shop-post-product-review-collection-json-json';
import { ShopPostProductReviewCollection$Json$Json$Params } from '../fn/product-review/shop-post-product-review-collection-json-json';

@Injectable({ providedIn: 'root' })
export class ProductReviewService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetProductReviewCollection()` */
  static readonly AdminGetProductReviewCollectionPath = '/api/v2/admin/product-reviews';

  /**
   * Retrieves the collection of ProductReview resources.
   *
   * Retrieves the collection of ProductReview resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductReviewCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductReviewCollection$Json$Response(params?: AdminGetProductReviewCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductReviewAdminProductReviewIndexSyliusAdminProductReviewIndex>>> {
    return adminGetProductReviewCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductReview resources.
   *
   * Retrieves the collection of ProductReview resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductReviewCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductReviewCollection$Json(params?: AdminGetProductReviewCollection$Json$Params, context?: HttpContext): Observable<Array<ProductReviewAdminProductReviewIndexSyliusAdminProductReviewIndex>> {
    return this.adminGetProductReviewCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductReviewAdminProductReviewIndexSyliusAdminProductReviewIndex>>): Array<ProductReviewAdminProductReviewIndexSyliusAdminProductReviewIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ProductReview resources.
   *
   * Retrieves the collection of ProductReview resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductReviewCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductReviewCollection$Html$Response(params?: AdminGetProductReviewCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductReviewAdminProductReviewIndexSyliusAdminProductReviewIndex>>> {
    return adminGetProductReviewCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductReview resources.
   *
   * Retrieves the collection of ProductReview resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductReviewCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductReviewCollection$Html(params?: AdminGetProductReviewCollection$Html$Params, context?: HttpContext): Observable<Array<ProductReviewAdminProductReviewIndexSyliusAdminProductReviewIndex>> {
    return this.adminGetProductReviewCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductReviewAdminProductReviewIndexSyliusAdminProductReviewIndex>>): Array<ProductReviewAdminProductReviewIndexSyliusAdminProductReviewIndex> => r.body)
    );
  }

  /** Path part for operation `adminGetProductReviewItem()` */
  static readonly AdminGetProductReviewItemPath = '/api/v2/admin/product-reviews/{id}';

  /**
   * Retrieves a ProductReview resource.
   *
   * Retrieves a ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductReviewItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductReviewItem$Json$Response(params: AdminGetProductReviewItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>> {
    return adminGetProductReviewItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductReview resource.
   *
   * Retrieves a ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductReviewItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductReviewItem$Json(params: AdminGetProductReviewItem$Json$Params, context?: HttpContext): Observable<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow> {
    return this.adminGetProductReviewItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>): ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow => r.body)
    );
  }

  /**
   * Retrieves a ProductReview resource.
   *
   * Retrieves a ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductReviewItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductReviewItem$Html$Response(params: AdminGetProductReviewItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>> {
    return adminGetProductReviewItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductReview resource.
   *
   * Retrieves a ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductReviewItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductReviewItem$Html(params: AdminGetProductReviewItem$Html$Params, context?: HttpContext): Observable<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow> {
    return this.adminGetProductReviewItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>): ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow => r.body)
    );
  }

  /** Path part for operation `adminPutProductReviewItem()` */
  static readonly AdminPutProductReviewItemPath = '/api/v2/admin/product-reviews/{id}';

  /**
   * Replaces the ProductReview resource.
   *
   * Replaces the ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductReviewItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductReviewItem$Json$Json$Response(params: AdminPutProductReviewItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>> {
    return adminPutProductReviewItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductReview resource.
   *
   * Replaces the ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductReviewItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductReviewItem$Json$Json(params: AdminPutProductReviewItem$Json$Json$Params, context?: HttpContext): Observable<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow> {
    return this.adminPutProductReviewItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>): ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow => r.body)
    );
  }

  /**
   * Replaces the ProductReview resource.
   *
   * Replaces the ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductReviewItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductReviewItem$Json$Html$Response(params: AdminPutProductReviewItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>> {
    return adminPutProductReviewItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductReview resource.
   *
   * Replaces the ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductReviewItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductReviewItem$Json$Html(params: AdminPutProductReviewItem$Json$Html$Params, context?: HttpContext): Observable<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow> {
    return this.adminPutProductReviewItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>): ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow => r.body)
    );
  }

  /**
   * Replaces the ProductReview resource.
   *
   * Replaces the ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductReviewItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductReviewItem$Html$Json$Response(params: AdminPutProductReviewItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>> {
    return adminPutProductReviewItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductReview resource.
   *
   * Replaces the ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductReviewItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductReviewItem$Html$Json(params: AdminPutProductReviewItem$Html$Json$Params, context?: HttpContext): Observable<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow> {
    return this.adminPutProductReviewItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>): ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow => r.body)
    );
  }

  /**
   * Replaces the ProductReview resource.
   *
   * Replaces the ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductReviewItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductReviewItem$Html$Html$Response(params: AdminPutProductReviewItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>> {
    return adminPutProductReviewItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductReview resource.
   *
   * Replaces the ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductReviewItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductReviewItem$Html$Html(params: AdminPutProductReviewItem$Html$Html$Params, context?: HttpContext): Observable<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow> {
    return this.adminPutProductReviewItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>): ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteProductReviewItem()` */
  static readonly AdminDeleteProductReviewItemPath = '/api/v2/admin/product-reviews/{id}';

  /**
   * Removes the ProductReview resource.
   *
   * Removes the ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteProductReviewItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductReviewItem$Response(params: AdminDeleteProductReviewItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteProductReviewItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the ProductReview resource.
   *
   * Removes the ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteProductReviewItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductReviewItem(params: AdminDeleteProductReviewItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteProductReviewItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminAcceptProductReviewItem()` */
  static readonly AdminAcceptProductReviewItemPath = '/api/v2/admin/product-reviews/{id}/accept';

  /**
   * Accepts Product Review.
   *
   * Updates the ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminAcceptProductReviewItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminAcceptProductReviewItem$Json$Response(params: AdminAcceptProductReviewItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>> {
    return adminAcceptProductReviewItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Accepts Product Review.
   *
   * Updates the ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminAcceptProductReviewItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminAcceptProductReviewItem$Json(params: AdminAcceptProductReviewItem$Json$Params, context?: HttpContext): Observable<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow> {
    return this.adminAcceptProductReviewItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>): ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow => r.body)
    );
  }

  /**
   * Accepts Product Review.
   *
   * Updates the ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminAcceptProductReviewItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminAcceptProductReviewItem$Html$Response(params: AdminAcceptProductReviewItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>> {
    return adminAcceptProductReviewItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Accepts Product Review.
   *
   * Updates the ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminAcceptProductReviewItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminAcceptProductReviewItem$Html(params: AdminAcceptProductReviewItem$Html$Params, context?: HttpContext): Observable<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow> {
    return this.adminAcceptProductReviewItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>): ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow => r.body)
    );
  }

  /** Path part for operation `adminRejectProductReviewItem()` */
  static readonly AdminRejectProductReviewItemPath = '/api/v2/admin/product-reviews/{id}/reject';

  /**
   * Rejects Product Review.
   *
   * Updates the ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminRejectProductReviewItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminRejectProductReviewItem$Json$Response(params: AdminRejectProductReviewItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>> {
    return adminRejectProductReviewItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Rejects Product Review.
   *
   * Updates the ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminRejectProductReviewItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminRejectProductReviewItem$Json(params: AdminRejectProductReviewItem$Json$Params, context?: HttpContext): Observable<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow> {
    return this.adminRejectProductReviewItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>): ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow => r.body)
    );
  }

  /**
   * Rejects Product Review.
   *
   * Updates the ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminRejectProductReviewItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminRejectProductReviewItem$Html$Response(params: AdminRejectProductReviewItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>> {
    return adminRejectProductReviewItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Rejects Product Review.
   *
   * Updates the ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminRejectProductReviewItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminRejectProductReviewItem$Html(params: AdminRejectProductReviewItem$Html$Params, context?: HttpContext): Observable<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow> {
    return this.adminRejectProductReviewItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow>): ProductReviewAdminProductReviewShowSyliusAdminProductReviewShow => r.body)
    );
  }

  /** Path part for operation `shopGetProductReviewCollection()` */
  static readonly ShopGetProductReviewCollectionPath = '/api/v2/shop/product-reviews';

  /**
   * Retrieves the collection of ProductReview resources.
   *
   * Retrieves the collection of ProductReview resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductReviewCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductReviewCollection$Json$Response(params?: ShopGetProductReviewCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductReviewShopProductReviewIndexSyliusShopProductReviewIndex>>> {
    return shopGetProductReviewCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductReview resources.
   *
   * Retrieves the collection of ProductReview resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductReviewCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductReviewCollection$Json(params?: ShopGetProductReviewCollection$Json$Params, context?: HttpContext): Observable<Array<ProductReviewShopProductReviewIndexSyliusShopProductReviewIndex>> {
    return this.shopGetProductReviewCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductReviewShopProductReviewIndexSyliusShopProductReviewIndex>>): Array<ProductReviewShopProductReviewIndexSyliusShopProductReviewIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ProductReview resources.
   *
   * Retrieves the collection of ProductReview resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductReviewCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductReviewCollection$Html$Response(params?: ShopGetProductReviewCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductReviewShopProductReviewIndexSyliusShopProductReviewIndex>>> {
    return shopGetProductReviewCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductReview resources.
   *
   * Retrieves the collection of ProductReview resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductReviewCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductReviewCollection$Html(params?: ShopGetProductReviewCollection$Html$Params, context?: HttpContext): Observable<Array<ProductReviewShopProductReviewIndexSyliusShopProductReviewIndex>> {
    return this.shopGetProductReviewCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductReviewShopProductReviewIndexSyliusShopProductReviewIndex>>): Array<ProductReviewShopProductReviewIndexSyliusShopProductReviewIndex> => r.body)
    );
  }

  /** Path part for operation `shopPostProductReviewCollection()` */
  static readonly ShopPostProductReviewCollectionPath = '/api/v2/shop/product-reviews';

  /**
   * Creates a ProductReview resource.
   *
   * Creates a ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostProductReviewCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostProductReviewCollection$Json$Json$Response(params: ShopPostProductReviewCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewShopProductReviewShowSyliusShopProductReviewShow>> {
    return shopPostProductReviewCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductReview resource.
   *
   * Creates a ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostProductReviewCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostProductReviewCollection$Json$Json(params: ShopPostProductReviewCollection$Json$Json$Params, context?: HttpContext): Observable<ProductReviewShopProductReviewShowSyliusShopProductReviewShow> {
    return this.shopPostProductReviewCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewShopProductReviewShowSyliusShopProductReviewShow>): ProductReviewShopProductReviewShowSyliusShopProductReviewShow => r.body)
    );
  }

  /**
   * Creates a ProductReview resource.
   *
   * Creates a ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostProductReviewCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostProductReviewCollection$Json$Html$Response(params: ShopPostProductReviewCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewShopProductReviewShowSyliusShopProductReviewShow>> {
    return shopPostProductReviewCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductReview resource.
   *
   * Creates a ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostProductReviewCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostProductReviewCollection$Json$Html(params: ShopPostProductReviewCollection$Json$Html$Params, context?: HttpContext): Observable<ProductReviewShopProductReviewShowSyliusShopProductReviewShow> {
    return this.shopPostProductReviewCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewShopProductReviewShowSyliusShopProductReviewShow>): ProductReviewShopProductReviewShowSyliusShopProductReviewShow => r.body)
    );
  }

  /**
   * Creates a ProductReview resource.
   *
   * Creates a ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostProductReviewCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostProductReviewCollection$Html$Json$Response(params: ShopPostProductReviewCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewShopProductReviewShowSyliusShopProductReviewShow>> {
    return shopPostProductReviewCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductReview resource.
   *
   * Creates a ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostProductReviewCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostProductReviewCollection$Html$Json(params: ShopPostProductReviewCollection$Html$Json$Params, context?: HttpContext): Observable<ProductReviewShopProductReviewShowSyliusShopProductReviewShow> {
    return this.shopPostProductReviewCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewShopProductReviewShowSyliusShopProductReviewShow>): ProductReviewShopProductReviewShowSyliusShopProductReviewShow => r.body)
    );
  }

  /**
   * Creates a ProductReview resource.
   *
   * Creates a ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostProductReviewCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostProductReviewCollection$Html$Html$Response(params: ShopPostProductReviewCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewShopProductReviewShowSyliusShopProductReviewShow>> {
    return shopPostProductReviewCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductReview resource.
   *
   * Creates a ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostProductReviewCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostProductReviewCollection$Html$Html(params: ShopPostProductReviewCollection$Html$Html$Params, context?: HttpContext): Observable<ProductReviewShopProductReviewShowSyliusShopProductReviewShow> {
    return this.shopPostProductReviewCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewShopProductReviewShowSyliusShopProductReviewShow>): ProductReviewShopProductReviewShowSyliusShopProductReviewShow => r.body)
    );
  }

  /** Path part for operation `shopGetProductReviewItem()` */
  static readonly ShopGetProductReviewItemPath = '/api/v2/shop/product-reviews/{id}';

  /**
   * Retrieves a ProductReview resource.
   *
   * Retrieves a ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductReviewItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductReviewItem$Json$Response(params: ShopGetProductReviewItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewShopProductReviewShowSyliusShopProductReviewShow>> {
    return shopGetProductReviewItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductReview resource.
   *
   * Retrieves a ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductReviewItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductReviewItem$Json(params: ShopGetProductReviewItem$Json$Params, context?: HttpContext): Observable<ProductReviewShopProductReviewShowSyliusShopProductReviewShow> {
    return this.shopGetProductReviewItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewShopProductReviewShowSyliusShopProductReviewShow>): ProductReviewShopProductReviewShowSyliusShopProductReviewShow => r.body)
    );
  }

  /**
   * Retrieves a ProductReview resource.
   *
   * Retrieves a ProductReview resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductReviewItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductReviewItem$Html$Response(params: ShopGetProductReviewItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductReviewShopProductReviewShowSyliusShopProductReviewShow>> {
    return shopGetProductReviewItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductReview resource.
   *
   * Retrieves a ProductReview resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductReviewItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductReviewItem$Html(params: ShopGetProductReviewItem$Html$Params, context?: HttpContext): Observable<ProductReviewShopProductReviewShowSyliusShopProductReviewShow> {
    return this.shopGetProductReviewItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductReviewShopProductReviewShowSyliusShopProductReviewShow>): ProductReviewShopProductReviewShowSyliusShopProductReviewShow => r.body)
    );
  }

}

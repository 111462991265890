/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteProductAssociationTypeItem } from '../fn/product-association-type/admin-delete-product-association-type-item';
import { AdminDeleteProductAssociationTypeItem$Params } from '../fn/product-association-type/admin-delete-product-association-type-item';
import { adminGetProductAssociationTypeCollection$Html } from '../fn/product-association-type/admin-get-product-association-type-collection-html';
import { AdminGetProductAssociationTypeCollection$Html$Params } from '../fn/product-association-type/admin-get-product-association-type-collection-html';
import { adminGetProductAssociationTypeCollection$Json } from '../fn/product-association-type/admin-get-product-association-type-collection-json';
import { AdminGetProductAssociationTypeCollection$Json$Params } from '../fn/product-association-type/admin-get-product-association-type-collection-json';
import { adminGetProductAssociationTypeItem$Html } from '../fn/product-association-type/admin-get-product-association-type-item-html';
import { AdminGetProductAssociationTypeItem$Html$Params } from '../fn/product-association-type/admin-get-product-association-type-item-html';
import { adminGetProductAssociationTypeItem$Json } from '../fn/product-association-type/admin-get-product-association-type-item-json';
import { AdminGetProductAssociationTypeItem$Json$Params } from '../fn/product-association-type/admin-get-product-association-type-item-json';
import { adminPostProductAssociationTypeCollection$Html$Html } from '../fn/product-association-type/admin-post-product-association-type-collection-html-html';
import { AdminPostProductAssociationTypeCollection$Html$Html$Params } from '../fn/product-association-type/admin-post-product-association-type-collection-html-html';
import { adminPostProductAssociationTypeCollection$Html$Json } from '../fn/product-association-type/admin-post-product-association-type-collection-html-json';
import { AdminPostProductAssociationTypeCollection$Html$Json$Params } from '../fn/product-association-type/admin-post-product-association-type-collection-html-json';
import { adminPostProductAssociationTypeCollection$Json$Html } from '../fn/product-association-type/admin-post-product-association-type-collection-json-html';
import { AdminPostProductAssociationTypeCollection$Json$Html$Params } from '../fn/product-association-type/admin-post-product-association-type-collection-json-html';
import { adminPostProductAssociationTypeCollection$Json$Json } from '../fn/product-association-type/admin-post-product-association-type-collection-json-json';
import { AdminPostProductAssociationTypeCollection$Json$Json$Params } from '../fn/product-association-type/admin-post-product-association-type-collection-json-json';
import { adminPutProductAssociationTypeItem$Html$Html } from '../fn/product-association-type/admin-put-product-association-type-item-html-html';
import { AdminPutProductAssociationTypeItem$Html$Html$Params } from '../fn/product-association-type/admin-put-product-association-type-item-html-html';
import { adminPutProductAssociationTypeItem$Html$Json } from '../fn/product-association-type/admin-put-product-association-type-item-html-json';
import { AdminPutProductAssociationTypeItem$Html$Json$Params } from '../fn/product-association-type/admin-put-product-association-type-item-html-json';
import { adminPutProductAssociationTypeItem$Json$Html } from '../fn/product-association-type/admin-put-product-association-type-item-json-html';
import { AdminPutProductAssociationTypeItem$Json$Html$Params } from '../fn/product-association-type/admin-put-product-association-type-item-json-html';
import { adminPutProductAssociationTypeItem$Json$Json } from '../fn/product-association-type/admin-put-product-association-type-item-json-json';
import { AdminPutProductAssociationTypeItem$Json$Json$Params } from '../fn/product-association-type/admin-put-product-association-type-item-json-json';
import { Index as ProductAssociationTypeAdminProductAssociationTypeIndexSyliusAdminProductAssociationTypeIndex } from '../models/ProductAssociationType-admin/product_association_type/index_sylius/admin/product_association_type/index';
import { Show as ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow } from '../models/ProductAssociationType-admin/product_association_type/show_sylius/admin/product_association_type/show';
import { Show as ProductAssociationTypeShopProductAssociationTypeShowSyliusShopProductAssociationTypeShow } from '../models/ProductAssociationType-shop/product_association_type/show_sylius/shop/product_association_type/show';
import { shopGetProductAssociationTypeItem$Html } from '../fn/product-association-type/shop-get-product-association-type-item-html';
import { ShopGetProductAssociationTypeItem$Html$Params } from '../fn/product-association-type/shop-get-product-association-type-item-html';
import { shopGetProductAssociationTypeItem$Json } from '../fn/product-association-type/shop-get-product-association-type-item-json';
import { ShopGetProductAssociationTypeItem$Json$Params } from '../fn/product-association-type/shop-get-product-association-type-item-json';

@Injectable({ providedIn: 'root' })
export class ProductAssociationTypeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetProductAssociationTypeCollection()` */
  static readonly AdminGetProductAssociationTypeCollectionPath = '/api/v2/admin/product-association-types';

  /**
   * Retrieves the collection of ProductAssociationType resources.
   *
   * Retrieves the collection of ProductAssociationType resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAssociationTypeCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationTypeCollection$Json$Response(params?: AdminGetProductAssociationTypeCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductAssociationTypeAdminProductAssociationTypeIndexSyliusAdminProductAssociationTypeIndex>>> {
    return adminGetProductAssociationTypeCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductAssociationType resources.
   *
   * Retrieves the collection of ProductAssociationType resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAssociationTypeCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationTypeCollection$Json(params?: AdminGetProductAssociationTypeCollection$Json$Params, context?: HttpContext): Observable<Array<ProductAssociationTypeAdminProductAssociationTypeIndexSyliusAdminProductAssociationTypeIndex>> {
    return this.adminGetProductAssociationTypeCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductAssociationTypeAdminProductAssociationTypeIndexSyliusAdminProductAssociationTypeIndex>>): Array<ProductAssociationTypeAdminProductAssociationTypeIndexSyliusAdminProductAssociationTypeIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ProductAssociationType resources.
   *
   * Retrieves the collection of ProductAssociationType resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAssociationTypeCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationTypeCollection$Html$Response(params?: AdminGetProductAssociationTypeCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductAssociationTypeAdminProductAssociationTypeIndexSyliusAdminProductAssociationTypeIndex>>> {
    return adminGetProductAssociationTypeCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductAssociationType resources.
   *
   * Retrieves the collection of ProductAssociationType resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAssociationTypeCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationTypeCollection$Html(params?: AdminGetProductAssociationTypeCollection$Html$Params, context?: HttpContext): Observable<Array<ProductAssociationTypeAdminProductAssociationTypeIndexSyliusAdminProductAssociationTypeIndex>> {
    return this.adminGetProductAssociationTypeCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductAssociationTypeAdminProductAssociationTypeIndexSyliusAdminProductAssociationTypeIndex>>): Array<ProductAssociationTypeAdminProductAssociationTypeIndexSyliusAdminProductAssociationTypeIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostProductAssociationTypeCollection()` */
  static readonly AdminPostProductAssociationTypeCollectionPath = '/api/v2/admin/product-association-types';

  /**
   * Creates a ProductAssociationType resource.
   *
   * Creates a ProductAssociationType resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductAssociationTypeCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductAssociationTypeCollection$Json$Json$Response(params: AdminPostProductAssociationTypeCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>> {
    return adminPostProductAssociationTypeCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductAssociationType resource.
   *
   * Creates a ProductAssociationType resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductAssociationTypeCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductAssociationTypeCollection$Json$Json(params: AdminPostProductAssociationTypeCollection$Json$Json$Params, context?: HttpContext): Observable<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow> {
    return this.adminPostProductAssociationTypeCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>): ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow => r.body)
    );
  }

  /**
   * Creates a ProductAssociationType resource.
   *
   * Creates a ProductAssociationType resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductAssociationTypeCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductAssociationTypeCollection$Json$Html$Response(params: AdminPostProductAssociationTypeCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>> {
    return adminPostProductAssociationTypeCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductAssociationType resource.
   *
   * Creates a ProductAssociationType resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductAssociationTypeCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductAssociationTypeCollection$Json$Html(params: AdminPostProductAssociationTypeCollection$Json$Html$Params, context?: HttpContext): Observable<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow> {
    return this.adminPostProductAssociationTypeCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>): ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow => r.body)
    );
  }

  /**
   * Creates a ProductAssociationType resource.
   *
   * Creates a ProductAssociationType resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductAssociationTypeCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductAssociationTypeCollection$Html$Json$Response(params: AdminPostProductAssociationTypeCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>> {
    return adminPostProductAssociationTypeCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductAssociationType resource.
   *
   * Creates a ProductAssociationType resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductAssociationTypeCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductAssociationTypeCollection$Html$Json(params: AdminPostProductAssociationTypeCollection$Html$Json$Params, context?: HttpContext): Observable<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow> {
    return this.adminPostProductAssociationTypeCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>): ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow => r.body)
    );
  }

  /**
   * Creates a ProductAssociationType resource.
   *
   * Creates a ProductAssociationType resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductAssociationTypeCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductAssociationTypeCollection$Html$Html$Response(params: AdminPostProductAssociationTypeCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>> {
    return adminPostProductAssociationTypeCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductAssociationType resource.
   *
   * Creates a ProductAssociationType resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductAssociationTypeCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductAssociationTypeCollection$Html$Html(params: AdminPostProductAssociationTypeCollection$Html$Html$Params, context?: HttpContext): Observable<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow> {
    return this.adminPostProductAssociationTypeCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>): ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow => r.body)
    );
  }

  /** Path part for operation `adminGetProductAssociationTypeItem()` */
  static readonly AdminGetProductAssociationTypeItemPath = '/api/v2/admin/product-association-types/{code}';

  /**
   * Retrieves a ProductAssociationType resource.
   *
   * Retrieves a ProductAssociationType resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAssociationTypeItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationTypeItem$Json$Response(params: AdminGetProductAssociationTypeItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>> {
    return adminGetProductAssociationTypeItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAssociationType resource.
   *
   * Retrieves a ProductAssociationType resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAssociationTypeItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationTypeItem$Json(params: AdminGetProductAssociationTypeItem$Json$Params, context?: HttpContext): Observable<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow> {
    return this.adminGetProductAssociationTypeItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>): ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow => r.body)
    );
  }

  /**
   * Retrieves a ProductAssociationType resource.
   *
   * Retrieves a ProductAssociationType resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAssociationTypeItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationTypeItem$Html$Response(params: AdminGetProductAssociationTypeItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>> {
    return adminGetProductAssociationTypeItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAssociationType resource.
   *
   * Retrieves a ProductAssociationType resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAssociationTypeItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationTypeItem$Html(params: AdminGetProductAssociationTypeItem$Html$Params, context?: HttpContext): Observable<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow> {
    return this.adminGetProductAssociationTypeItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>): ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow => r.body)
    );
  }

  /** Path part for operation `adminPutProductAssociationTypeItem()` */
  static readonly AdminPutProductAssociationTypeItemPath = '/api/v2/admin/product-association-types/{code}';

  /**
   * Replaces the ProductAssociationType resource.
   *
   * Replaces the ProductAssociationType resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductAssociationTypeItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductAssociationTypeItem$Json$Json$Response(params: AdminPutProductAssociationTypeItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>> {
    return adminPutProductAssociationTypeItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductAssociationType resource.
   *
   * Replaces the ProductAssociationType resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductAssociationTypeItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductAssociationTypeItem$Json$Json(params: AdminPutProductAssociationTypeItem$Json$Json$Params, context?: HttpContext): Observable<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow> {
    return this.adminPutProductAssociationTypeItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>): ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow => r.body)
    );
  }

  /**
   * Replaces the ProductAssociationType resource.
   *
   * Replaces the ProductAssociationType resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductAssociationTypeItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductAssociationTypeItem$Json$Html$Response(params: AdminPutProductAssociationTypeItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>> {
    return adminPutProductAssociationTypeItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductAssociationType resource.
   *
   * Replaces the ProductAssociationType resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductAssociationTypeItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductAssociationTypeItem$Json$Html(params: AdminPutProductAssociationTypeItem$Json$Html$Params, context?: HttpContext): Observable<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow> {
    return this.adminPutProductAssociationTypeItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>): ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow => r.body)
    );
  }

  /**
   * Replaces the ProductAssociationType resource.
   *
   * Replaces the ProductAssociationType resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductAssociationTypeItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductAssociationTypeItem$Html$Json$Response(params: AdminPutProductAssociationTypeItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>> {
    return adminPutProductAssociationTypeItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductAssociationType resource.
   *
   * Replaces the ProductAssociationType resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductAssociationTypeItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductAssociationTypeItem$Html$Json(params: AdminPutProductAssociationTypeItem$Html$Json$Params, context?: HttpContext): Observable<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow> {
    return this.adminPutProductAssociationTypeItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>): ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow => r.body)
    );
  }

  /**
   * Replaces the ProductAssociationType resource.
   *
   * Replaces the ProductAssociationType resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductAssociationTypeItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductAssociationTypeItem$Html$Html$Response(params: AdminPutProductAssociationTypeItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>> {
    return adminPutProductAssociationTypeItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductAssociationType resource.
   *
   * Replaces the ProductAssociationType resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductAssociationTypeItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductAssociationTypeItem$Html$Html(params: AdminPutProductAssociationTypeItem$Html$Html$Params, context?: HttpContext): Observable<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow> {
    return this.adminPutProductAssociationTypeItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow>): ProductAssociationTypeAdminProductAssociationTypeShowSyliusAdminProductAssociationTypeShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteProductAssociationTypeItem()` */
  static readonly AdminDeleteProductAssociationTypeItemPath = '/api/v2/admin/product-association-types/{code}';

  /**
   * Removes the ProductAssociationType resource.
   *
   * Removes the ProductAssociationType resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteProductAssociationTypeItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductAssociationTypeItem$Response(params: AdminDeleteProductAssociationTypeItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteProductAssociationTypeItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the ProductAssociationType resource.
   *
   * Removes the ProductAssociationType resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteProductAssociationTypeItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductAssociationTypeItem(params: AdminDeleteProductAssociationTypeItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteProductAssociationTypeItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `shopGetProductAssociationTypeItem()` */
  static readonly ShopGetProductAssociationTypeItemPath = '/api/v2/shop/product-association-types/{code}';

  /**
   * Retrieves a ProductAssociationType resource.
   *
   * Retrieves a ProductAssociationType resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductAssociationTypeItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAssociationTypeItem$Json$Response(params: ShopGetProductAssociationTypeItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationTypeShopProductAssociationTypeShowSyliusShopProductAssociationTypeShow>> {
    return shopGetProductAssociationTypeItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAssociationType resource.
   *
   * Retrieves a ProductAssociationType resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductAssociationTypeItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAssociationTypeItem$Json(params: ShopGetProductAssociationTypeItem$Json$Params, context?: HttpContext): Observable<ProductAssociationTypeShopProductAssociationTypeShowSyliusShopProductAssociationTypeShow> {
    return this.shopGetProductAssociationTypeItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationTypeShopProductAssociationTypeShowSyliusShopProductAssociationTypeShow>): ProductAssociationTypeShopProductAssociationTypeShowSyliusShopProductAssociationTypeShow => r.body)
    );
  }

  /**
   * Retrieves a ProductAssociationType resource.
   *
   * Retrieves a ProductAssociationType resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductAssociationTypeItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAssociationTypeItem$Html$Response(params: ShopGetProductAssociationTypeItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationTypeShopProductAssociationTypeShowSyliusShopProductAssociationTypeShow>> {
    return shopGetProductAssociationTypeItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAssociationType resource.
   *
   * Retrieves a ProductAssociationType resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductAssociationTypeItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAssociationTypeItem$Html(params: ShopGetProductAssociationTypeItem$Html$Params, context?: HttpContext): Observable<ProductAssociationTypeShopProductAssociationTypeShowSyliusShopProductAssociationTypeShow> {
    return this.shopGetProductAssociationTypeItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationTypeShopProductAssociationTypeShowSyliusShopProductAssociationTypeShow>): ProductAssociationTypeShopProductAssociationTypeShowSyliusShopProductAssociationTypeShow => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Index as AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex } from '../models/Adjustment-admin/order_item/index_sylius/admin/order_item/index';
import { Show as AdjustmentShopCartShowSyliusShopCartShow } from '../models/Adjustment-shop/cart/show_sylius/shop/cart/show';
import { adminGetOrderItemItem$Html } from '../fn/order-item/admin-get-order-item-item-html';
import { AdminGetOrderItemItem$Html$Params } from '../fn/order-item/admin-get-order-item-item-html';
import { adminGetOrderItemItem$Json } from '../fn/order-item/admin-get-order-item-item-json';
import { AdminGetOrderItemItem$Json$Params } from '../fn/order-item/admin-get-order-item-item-json';
import { apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html } from '../fn/adjustment/api-order-items-adjustments-get-subresource-order-item-subresource-html';
import { ApiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html$Params } from '../fn/adjustment/api-order-items-adjustments-get-subresource-order-item-subresource-html';
import { apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json } from '../fn/adjustment/api-order-items-adjustments-get-subresource-order-item-subresource-json';
import { ApiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json$Params } from '../fn/adjustment/api-order-items-adjustments-get-subresource-order-item-subresource-json';
import { apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html } from '../fn/adjustment/api-orders-items-adjustments-get-subresource-order-subresource-html';
import { ApiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html$Params } from '../fn/adjustment/api-orders-items-adjustments-get-subresource-order-subresource-html';
import { apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json } from '../fn/adjustment/api-orders-items-adjustments-get-subresource-order-subresource-json';
import { ApiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json$Params } from '../fn/adjustment/api-orders-items-adjustments-get-subresource-order-subresource-json';
import { apiOrdersItemsGetSubresourceOrderSubresource$Html } from '../fn/order-item/api-orders-items-get-subresource-order-subresource-html';
import { ApiOrdersItemsGetSubresourceOrderSubresource$Html$Params } from '../fn/order-item/api-orders-items-get-subresource-order-subresource-html';
import { apiOrdersItemsGetSubresourceOrderSubresource$Json } from '../fn/order-item/api-orders-items-get-subresource-order-subresource-json';
import { ApiOrdersItemsGetSubresourceOrderSubresource$Json$Params } from '../fn/order-item/api-orders-items-get-subresource-order-subresource-json';
import { Show as OrderItemAdminOrderItemShowSyliusAdminOrderItemShow } from '../models/OrderItem-admin/order_item/show_sylius/admin/order_item/show';
import { Show as OrderItemShopCartShowSyliusShopCartShow } from '../models/OrderItem-shop/cart/show_sylius/shop/cart/show';
import { Show as OrderItemShopOrderItemShowSyliusShopOrderItemShow } from '../models/OrderItem-shop/order_item/show_sylius/shop/order_item/show';
import { shopGetOrderItemItem$Html } from '../fn/order-item/shop-get-order-item-item-html';
import { ShopGetOrderItemItem$Html$Params } from '../fn/order-item/shop-get-order-item-item-html';
import { shopGetOrderItemItem$Json } from '../fn/order-item/shop-get-order-item-item-json';
import { ShopGetOrderItemItem$Json$Params } from '../fn/order-item/shop-get-order-item-item-json';

@Injectable({ providedIn: 'root' })
export class OrderItemService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetOrderItemItem()` */
  static readonly AdminGetOrderItemItemPath = '/api/v2/admin/order-items/{id}';

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetOrderItemItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderItemItem$Json$Response(params: AdminGetOrderItemItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderItemAdminOrderItemShowSyliusAdminOrderItemShow>> {
    return adminGetOrderItemItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetOrderItemItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderItemItem$Json(params: AdminGetOrderItemItem$Json$Params, context?: HttpContext): Observable<OrderItemAdminOrderItemShowSyliusAdminOrderItemShow> {
    return this.adminGetOrderItemItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderItemAdminOrderItemShowSyliusAdminOrderItemShow>): OrderItemAdminOrderItemShowSyliusAdminOrderItemShow => r.body)
    );
  }

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetOrderItemItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderItemItem$Html$Response(params: AdminGetOrderItemItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderItemAdminOrderItemShowSyliusAdminOrderItemShow>> {
    return adminGetOrderItemItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetOrderItemItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderItemItem$Html(params: AdminGetOrderItemItem$Html$Params, context?: HttpContext): Observable<OrderItemAdminOrderItemShowSyliusAdminOrderItemShow> {
    return this.adminGetOrderItemItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderItemAdminOrderItemShowSyliusAdminOrderItemShow>): OrderItemAdminOrderItemShowSyliusAdminOrderItemShow => r.body)
    );
  }

  /** Path part for operation `apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource()` */
  static readonly ApiOrderItemsAdjustmentsGetSubresourceOrderItemSubresourcePath = '/api/v2/admin/order-items/{id}/adjustments';

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json$Response(params: ApiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex>>> {
    return apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json(params: ApiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json$Params, context?: HttpContext): Observable<Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex>> {
    return this.apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex>>): Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex> => r.body)
    );
  }

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html$Response(params: ApiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex>>> {
    return apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html(params: ApiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html$Params, context?: HttpContext): Observable<Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex>> {
    return this.apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex>>): Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex> => r.body)
    );
  }

  /** Path part for operation `shopGetOrderItemItem()` */
  static readonly ShopGetOrderItemItemPath = '/api/v2/shop/order-items/{id}';

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetOrderItemItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderItemItem$Json$Response(params: ShopGetOrderItemItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderItemShopOrderItemShowSyliusShopOrderItemShow>> {
    return shopGetOrderItemItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetOrderItemItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderItemItem$Json(params: ShopGetOrderItemItem$Json$Params, context?: HttpContext): Observable<OrderItemShopOrderItemShowSyliusShopOrderItemShow> {
    return this.shopGetOrderItemItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderItemShopOrderItemShowSyliusShopOrderItemShow>): OrderItemShopOrderItemShowSyliusShopOrderItemShow => r.body)
    );
  }

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetOrderItemItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderItemItem$Html$Response(params: ShopGetOrderItemItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderItemShopOrderItemShowSyliusShopOrderItemShow>> {
    return shopGetOrderItemItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetOrderItemItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderItemItem$Html(params: ShopGetOrderItemItem$Html$Params, context?: HttpContext): Observable<OrderItemShopOrderItemShowSyliusShopOrderItemShow> {
    return this.shopGetOrderItemItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderItemShopOrderItemShowSyliusShopOrderItemShow>): OrderItemShopOrderItemShowSyliusShopOrderItemShow => r.body)
    );
  }

  /** Path part for operation `apiOrdersItemsGetSubresourceOrderSubresource()` */
  static readonly ApiOrdersItemsGetSubresourceOrderSubresourcePath = '/api/v2/shop/orders/{tokenValue}/items';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersItemsGetSubresourceOrderSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsGetSubresourceOrderSubresource$Json$Response(params: ApiOrdersItemsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderItemShopCartShowSyliusShopCartShow>>> {
    return apiOrdersItemsGetSubresourceOrderSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersItemsGetSubresourceOrderSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsGetSubresourceOrderSubresource$Json(params: ApiOrdersItemsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<Array<OrderItemShopCartShowSyliusShopCartShow>> {
    return this.apiOrdersItemsGetSubresourceOrderSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrderItemShopCartShowSyliusShopCartShow>>): Array<OrderItemShopCartShowSyliusShopCartShow> => r.body)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersItemsGetSubresourceOrderSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsGetSubresourceOrderSubresource$Html$Response(params: ApiOrdersItemsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderItemShopCartShowSyliusShopCartShow>>> {
    return apiOrdersItemsGetSubresourceOrderSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersItemsGetSubresourceOrderSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsGetSubresourceOrderSubresource$Html(params: ApiOrdersItemsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<Array<OrderItemShopCartShowSyliusShopCartShow>> {
    return this.apiOrdersItemsGetSubresourceOrderSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrderItemShopCartShowSyliusShopCartShow>>): Array<OrderItemShopCartShowSyliusShopCartShow> => r.body)
    );
  }

  /** Path part for operation `apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource()` */
  static readonly ApiOrdersItemsAdjustmentsGetSubresourceOrderSubresourcePath = '/api/v2/shop/orders/{tokenValue}/items/{items}/adjustments';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json$Response(params: ApiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>> {
    return apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json(params: ApiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<Array<AdjustmentShopCartShowSyliusShopCartShow>> {
    return this.apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>): Array<AdjustmentShopCartShowSyliusShopCartShow> => r.body)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html$Response(params: ApiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>> {
    return apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html(params: ApiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<Array<AdjustmentShopCartShowSyliusShopCartShow>> {
    return this.apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>): Array<AdjustmentShopCartShowSyliusShopCartShow> => r.body)
    );
  }

}

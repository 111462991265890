/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Show as AddressAdminAddressLogEntryShowSyliusAdminAddressLogEntryShow } from '../models/Address-admin/address/log_entry/show_sylius/admin/address/log_entry/show';
import { Show as AddressAdminAddressShowSyliusAdminAddressShow } from '../models/Address-admin/address/show_sylius/admin/address/show';
import { Index as AddressShopAddressIndexSyliusShopAddressIndex } from '../models/Address-shop/address/index_sylius/shop/address/index';
import { Show as AddressShopAddressShowSyliusShopAddressShow } from '../models/Address-shop/address/show_sylius/shop/address/show';
import { adminGetAddressItem$Html } from '../fn/address/admin-get-address-item-html';
import { AdminGetAddressItem$Html$Params } from '../fn/address/admin-get-address-item-html';
import { adminGetAddressItem$Json } from '../fn/address/admin-get-address-item-json';
import { AdminGetAddressItem$Json$Params } from '../fn/address/admin-get-address-item-json';
import { adminGetLogEntriesAddressItem$Html } from '../fn/address/admin-get-log-entries-address-item-html';
import { AdminGetLogEntriesAddressItem$Html$Params } from '../fn/address/admin-get-log-entries-address-item-html';
import { adminGetLogEntriesAddressItem$Json } from '../fn/address/admin-get-log-entries-address-item-json';
import { AdminGetLogEntriesAddressItem$Json$Params } from '../fn/address/admin-get-log-entries-address-item-json';
import { adminPutAddressItem$Html$Html } from '../fn/address/admin-put-address-item-html-html';
import { AdminPutAddressItem$Html$Html$Params } from '../fn/address/admin-put-address-item-html-html';
import { adminPutAddressItem$Html$Json } from '../fn/address/admin-put-address-item-html-json';
import { AdminPutAddressItem$Html$Json$Params } from '../fn/address/admin-put-address-item-html-json';
import { adminPutAddressItem$Json$Html } from '../fn/address/admin-put-address-item-json-html';
import { AdminPutAddressItem$Json$Html$Params } from '../fn/address/admin-put-address-item-json-html';
import { adminPutAddressItem$Json$Json } from '../fn/address/admin-put-address-item-json-json';
import { AdminPutAddressItem$Json$Json$Params } from '../fn/address/admin-put-address-item-json-json';
import { shopDeleteAddressItem } from '../fn/address/shop-delete-address-item';
import { ShopDeleteAddressItem$Params } from '../fn/address/shop-delete-address-item';
import { shopGetAddressCollection$Html } from '../fn/address/shop-get-address-collection-html';
import { ShopGetAddressCollection$Html$Params } from '../fn/address/shop-get-address-collection-html';
import { shopGetAddressCollection$Json } from '../fn/address/shop-get-address-collection-json';
import { ShopGetAddressCollection$Json$Params } from '../fn/address/shop-get-address-collection-json';
import { shopGetAddressItem$Html } from '../fn/address/shop-get-address-item-html';
import { ShopGetAddressItem$Html$Params } from '../fn/address/shop-get-address-item-html';
import { shopGetAddressItem$Json } from '../fn/address/shop-get-address-item-json';
import { ShopGetAddressItem$Json$Params } from '../fn/address/shop-get-address-item-json';
import { shopPostAddressCollection$Html$Html } from '../fn/address/shop-post-address-collection-html-html';
import { ShopPostAddressCollection$Html$Html$Params } from '../fn/address/shop-post-address-collection-html-html';
import { shopPostAddressCollection$Html$Json } from '../fn/address/shop-post-address-collection-html-json';
import { ShopPostAddressCollection$Html$Json$Params } from '../fn/address/shop-post-address-collection-html-json';
import { shopPostAddressCollection$Json$Html } from '../fn/address/shop-post-address-collection-json-html';
import { ShopPostAddressCollection$Json$Html$Params } from '../fn/address/shop-post-address-collection-json-html';
import { shopPostAddressCollection$Json$Json } from '../fn/address/shop-post-address-collection-json-json';
import { ShopPostAddressCollection$Json$Json$Params } from '../fn/address/shop-post-address-collection-json-json';
import { shopPutAddressItem$Html$Html } from '../fn/address/shop-put-address-item-html-html';
import { ShopPutAddressItem$Html$Html$Params } from '../fn/address/shop-put-address-item-html-html';
import { shopPutAddressItem$Html$Json } from '../fn/address/shop-put-address-item-html-json';
import { ShopPutAddressItem$Html$Json$Params } from '../fn/address/shop-put-address-item-html-json';
import { shopPutAddressItem$Json$Html } from '../fn/address/shop-put-address-item-json-html';
import { ShopPutAddressItem$Json$Html$Params } from '../fn/address/shop-put-address-item-json-html';
import { shopPutAddressItem$Json$Json } from '../fn/address/shop-put-address-item-json-json';
import { ShopPutAddressItem$Json$Json$Params } from '../fn/address/shop-put-address-item-json-json';

@Injectable({ providedIn: 'root' })
export class AddressService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetAddressItem()` */
  static readonly AdminGetAddressItemPath = '/api/v2/admin/addresses/{id}';

  /**
   * Retrieves a Address resource.
   *
   * Retrieves a Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetAddressItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAddressItem$Json$Response(params: AdminGetAddressItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressAdminAddressShowSyliusAdminAddressShow>> {
    return adminGetAddressItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Address resource.
   *
   * Retrieves a Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetAddressItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAddressItem$Json(params: AdminGetAddressItem$Json$Params, context?: HttpContext): Observable<AddressAdminAddressShowSyliusAdminAddressShow> {
    return this.adminGetAddressItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressAdminAddressShowSyliusAdminAddressShow>): AddressAdminAddressShowSyliusAdminAddressShow => r.body)
    );
  }

  /**
   * Retrieves a Address resource.
   *
   * Retrieves a Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetAddressItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAddressItem$Html$Response(params: AdminGetAddressItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressAdminAddressShowSyliusAdminAddressShow>> {
    return adminGetAddressItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Address resource.
   *
   * Retrieves a Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetAddressItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAddressItem$Html(params: AdminGetAddressItem$Html$Params, context?: HttpContext): Observable<AddressAdminAddressShowSyliusAdminAddressShow> {
    return this.adminGetAddressItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressAdminAddressShowSyliusAdminAddressShow>): AddressAdminAddressShowSyliusAdminAddressShow => r.body)
    );
  }

  /** Path part for operation `adminPutAddressItem()` */
  static readonly AdminPutAddressItemPath = '/api/v2/admin/addresses/{id}';

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutAddressItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutAddressItem$Json$Json$Response(params: AdminPutAddressItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressAdminAddressShowSyliusAdminAddressShow>> {
    return adminPutAddressItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutAddressItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutAddressItem$Json$Json(params: AdminPutAddressItem$Json$Json$Params, context?: HttpContext): Observable<AddressAdminAddressShowSyliusAdminAddressShow> {
    return this.adminPutAddressItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressAdminAddressShowSyliusAdminAddressShow>): AddressAdminAddressShowSyliusAdminAddressShow => r.body)
    );
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutAddressItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutAddressItem$Json$Html$Response(params: AdminPutAddressItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressAdminAddressShowSyliusAdminAddressShow>> {
    return adminPutAddressItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutAddressItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutAddressItem$Json$Html(params: AdminPutAddressItem$Json$Html$Params, context?: HttpContext): Observable<AddressAdminAddressShowSyliusAdminAddressShow> {
    return this.adminPutAddressItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressAdminAddressShowSyliusAdminAddressShow>): AddressAdminAddressShowSyliusAdminAddressShow => r.body)
    );
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutAddressItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutAddressItem$Html$Json$Response(params: AdminPutAddressItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressAdminAddressShowSyliusAdminAddressShow>> {
    return adminPutAddressItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutAddressItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutAddressItem$Html$Json(params: AdminPutAddressItem$Html$Json$Params, context?: HttpContext): Observable<AddressAdminAddressShowSyliusAdminAddressShow> {
    return this.adminPutAddressItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressAdminAddressShowSyliusAdminAddressShow>): AddressAdminAddressShowSyliusAdminAddressShow => r.body)
    );
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutAddressItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutAddressItem$Html$Html$Response(params: AdminPutAddressItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressAdminAddressShowSyliusAdminAddressShow>> {
    return adminPutAddressItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutAddressItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutAddressItem$Html$Html(params: AdminPutAddressItem$Html$Html$Params, context?: HttpContext): Observable<AddressAdminAddressShowSyliusAdminAddressShow> {
    return this.adminPutAddressItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressAdminAddressShowSyliusAdminAddressShow>): AddressAdminAddressShowSyliusAdminAddressShow => r.body)
    );
  }

  /** Path part for operation `adminGetLogEntriesAddressItem()` */
  static readonly AdminGetLogEntriesAddressItemPath = '/api/v2/admin/addresses/{id}/log-entries';

  /**
   * Retrieves the collection of AddressLogEntry resources.
   *
   * Retrieves the collection of AddressLogEntry resources
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetLogEntriesAddressItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetLogEntriesAddressItem$Json$Response(params: AdminGetLogEntriesAddressItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressAdminAddressLogEntryShowSyliusAdminAddressLogEntryShow>> {
    return adminGetLogEntriesAddressItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of AddressLogEntry resources.
   *
   * Retrieves the collection of AddressLogEntry resources
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetLogEntriesAddressItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetLogEntriesAddressItem$Json(params: AdminGetLogEntriesAddressItem$Json$Params, context?: HttpContext): Observable<AddressAdminAddressLogEntryShowSyliusAdminAddressLogEntryShow> {
    return this.adminGetLogEntriesAddressItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressAdminAddressLogEntryShowSyliusAdminAddressLogEntryShow>): AddressAdminAddressLogEntryShowSyliusAdminAddressLogEntryShow => r.body)
    );
  }

  /**
   * Retrieves the collection of AddressLogEntry resources.
   *
   * Retrieves the collection of AddressLogEntry resources
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetLogEntriesAddressItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetLogEntriesAddressItem$Html$Response(params: AdminGetLogEntriesAddressItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressAdminAddressLogEntryShowSyliusAdminAddressLogEntryShow>> {
    return adminGetLogEntriesAddressItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of AddressLogEntry resources.
   *
   * Retrieves the collection of AddressLogEntry resources
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetLogEntriesAddressItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetLogEntriesAddressItem$Html(params: AdminGetLogEntriesAddressItem$Html$Params, context?: HttpContext): Observable<AddressAdminAddressLogEntryShowSyliusAdminAddressLogEntryShow> {
    return this.adminGetLogEntriesAddressItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressAdminAddressLogEntryShowSyliusAdminAddressLogEntryShow>): AddressAdminAddressLogEntryShowSyliusAdminAddressLogEntryShow => r.body)
    );
  }

  /** Path part for operation `shopGetAddressCollection()` */
  static readonly ShopGetAddressCollectionPath = '/api/v2/shop/addresses';

  /**
   * Retrieves the collection of Address resources.
   *
   * Retrieves the collection of Address resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetAddressCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetAddressCollection$Json$Response(params?: ShopGetAddressCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AddressShopAddressIndexSyliusShopAddressIndex>>> {
    return shopGetAddressCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Address resources.
   *
   * Retrieves the collection of Address resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetAddressCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetAddressCollection$Json(params?: ShopGetAddressCollection$Json$Params, context?: HttpContext): Observable<Array<AddressShopAddressIndexSyliusShopAddressIndex>> {
    return this.shopGetAddressCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AddressShopAddressIndexSyliusShopAddressIndex>>): Array<AddressShopAddressIndexSyliusShopAddressIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Address resources.
   *
   * Retrieves the collection of Address resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetAddressCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetAddressCollection$Html$Response(params?: ShopGetAddressCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AddressShopAddressIndexSyliusShopAddressIndex>>> {
    return shopGetAddressCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Address resources.
   *
   * Retrieves the collection of Address resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetAddressCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetAddressCollection$Html(params?: ShopGetAddressCollection$Html$Params, context?: HttpContext): Observable<Array<AddressShopAddressIndexSyliusShopAddressIndex>> {
    return this.shopGetAddressCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AddressShopAddressIndexSyliusShopAddressIndex>>): Array<AddressShopAddressIndexSyliusShopAddressIndex> => r.body)
    );
  }

  /** Path part for operation `shopPostAddressCollection()` */
  static readonly ShopPostAddressCollectionPath = '/api/v2/shop/addresses';

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostAddressCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostAddressCollection$Json$Json$Response(params: ShopPostAddressCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>> {
    return shopPostAddressCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostAddressCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostAddressCollection$Json$Json(params: ShopPostAddressCollection$Json$Json$Params, context?: HttpContext): Observable<AddressShopAddressShowSyliusShopAddressShow> {
    return this.shopPostAddressCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>): AddressShopAddressShowSyliusShopAddressShow => r.body)
    );
  }

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostAddressCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostAddressCollection$Json$Html$Response(params: ShopPostAddressCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>> {
    return shopPostAddressCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostAddressCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostAddressCollection$Json$Html(params: ShopPostAddressCollection$Json$Html$Params, context?: HttpContext): Observable<AddressShopAddressShowSyliusShopAddressShow> {
    return this.shopPostAddressCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>): AddressShopAddressShowSyliusShopAddressShow => r.body)
    );
  }

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostAddressCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostAddressCollection$Html$Json$Response(params: ShopPostAddressCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>> {
    return shopPostAddressCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostAddressCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostAddressCollection$Html$Json(params: ShopPostAddressCollection$Html$Json$Params, context?: HttpContext): Observable<AddressShopAddressShowSyliusShopAddressShow> {
    return this.shopPostAddressCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>): AddressShopAddressShowSyliusShopAddressShow => r.body)
    );
  }

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostAddressCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostAddressCollection$Html$Html$Response(params: ShopPostAddressCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>> {
    return shopPostAddressCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Address resource.
   *
   * Creates a Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostAddressCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostAddressCollection$Html$Html(params: ShopPostAddressCollection$Html$Html$Params, context?: HttpContext): Observable<AddressShopAddressShowSyliusShopAddressShow> {
    return this.shopPostAddressCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>): AddressShopAddressShowSyliusShopAddressShow => r.body)
    );
  }

  /** Path part for operation `shopGetAddressItem()` */
  static readonly ShopGetAddressItemPath = '/api/v2/shop/addresses/{id}';

  /**
   * Retrieves a Address resource.
   *
   * Retrieves a Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetAddressItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetAddressItem$Json$Response(params: ShopGetAddressItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>> {
    return shopGetAddressItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Address resource.
   *
   * Retrieves a Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetAddressItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetAddressItem$Json(params: ShopGetAddressItem$Json$Params, context?: HttpContext): Observable<AddressShopAddressShowSyliusShopAddressShow> {
    return this.shopGetAddressItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>): AddressShopAddressShowSyliusShopAddressShow => r.body)
    );
  }

  /**
   * Retrieves a Address resource.
   *
   * Retrieves a Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetAddressItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetAddressItem$Html$Response(params: ShopGetAddressItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>> {
    return shopGetAddressItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Address resource.
   *
   * Retrieves a Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetAddressItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetAddressItem$Html(params: ShopGetAddressItem$Html$Params, context?: HttpContext): Observable<AddressShopAddressShowSyliusShopAddressShow> {
    return this.shopGetAddressItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>): AddressShopAddressShowSyliusShopAddressShow => r.body)
    );
  }

  /** Path part for operation `shopPutAddressItem()` */
  static readonly ShopPutAddressItemPath = '/api/v2/shop/addresses/{id}';

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPutAddressItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPutAddressItem$Json$Json$Response(params: ShopPutAddressItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>> {
    return shopPutAddressItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPutAddressItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPutAddressItem$Json$Json(params: ShopPutAddressItem$Json$Json$Params, context?: HttpContext): Observable<AddressShopAddressShowSyliusShopAddressShow> {
    return this.shopPutAddressItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>): AddressShopAddressShowSyliusShopAddressShow => r.body)
    );
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPutAddressItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPutAddressItem$Json$Html$Response(params: ShopPutAddressItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>> {
    return shopPutAddressItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPutAddressItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPutAddressItem$Json$Html(params: ShopPutAddressItem$Json$Html$Params, context?: HttpContext): Observable<AddressShopAddressShowSyliusShopAddressShow> {
    return this.shopPutAddressItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>): AddressShopAddressShowSyliusShopAddressShow => r.body)
    );
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPutAddressItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPutAddressItem$Html$Json$Response(params: ShopPutAddressItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>> {
    return shopPutAddressItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPutAddressItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPutAddressItem$Html$Json(params: ShopPutAddressItem$Html$Json$Params, context?: HttpContext): Observable<AddressShopAddressShowSyliusShopAddressShow> {
    return this.shopPutAddressItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>): AddressShopAddressShowSyliusShopAddressShow => r.body)
    );
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPutAddressItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPutAddressItem$Html$Html$Response(params: ShopPutAddressItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>> {
    return shopPutAddressItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Address resource.
   *
   * Replaces the Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPutAddressItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPutAddressItem$Html$Html(params: ShopPutAddressItem$Html$Html$Params, context?: HttpContext): Observable<AddressShopAddressShowSyliusShopAddressShow> {
    return this.shopPutAddressItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressShopAddressShowSyliusShopAddressShow>): AddressShopAddressShowSyliusShopAddressShow => r.body)
    );
  }

  /** Path part for operation `shopDeleteAddressItem()` */
  static readonly ShopDeleteAddressItemPath = '/api/v2/shop/addresses/{id}';

  /**
   * Removes the Address resource.
   *
   * Removes the Address resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopDeleteAddressItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopDeleteAddressItem$Response(params: ShopDeleteAddressItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return shopDeleteAddressItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Address resource.
   *
   * Removes the Address resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopDeleteAddressItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopDeleteAddressItem(params: ShopDeleteAddressItem$Params, context?: HttpContext): Observable<void> {
    return this.shopDeleteAddressItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

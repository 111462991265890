/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Create as ProductAssociationAdminProductAssociationCreateSyliusAdminProductAssociationCreate } from '../../models/ProductAssociation-admin/product_association/create_sylius/admin/product_association/create';
import { Show as ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow } from '../../models/ProductAssociation-admin/product_association/show_sylius/admin/product_association/show';

export interface AdminPostProductAssociationCollection$Html$Json$Params {

/**
 * Locales in this enum are all locales defined in the shop and only enabled ones will work in the given channel in the shop.
 */
  'Accept-Language'?: 'hu_HU';
  
    /**
     * The new ProductAssociation resource
     */
    body: ProductAssociationAdminProductAssociationCreateSyliusAdminProductAssociationCreate
}

export function adminPostProductAssociationCollection$Html$Json(http: HttpClient, rootUrl: string, params: AdminPostProductAssociationCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>> {
  const rb = new RequestBuilder(rootUrl, adminPostProductAssociationCollection$Html$Json.PATH, 'post');
  if (params) {
    rb.header('Accept-Language', params['Accept-Language'], {"style":"simple","explode":false});
    rb.body(params.body, 'text/html');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ProductAssociationAdminProductAssociationShowSyliusAdminProductAssociationShow>;
    })
  );
}

adminPostProductAssociationCollection$Html$Json.PATH = '/api/v2/admin/product-associations';

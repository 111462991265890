/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Adjustment } from '../models/adjustment';
import { Show as AdjustmentAdminAdjustmentShowSyliusAdminAdjustmentShow } from '../models/Adjustment-admin/adjustment/show_sylius/admin/adjustment/show';
import { Index as AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex } from '../models/Adjustment-admin/order_item_unit/index_sylius/admin/order_item_unit/index';
import { Index as AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex } from '../models/Adjustment-admin/order_item/index_sylius/admin/order_item/index';
import { Index as AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex } from '../models/Adjustment-admin/shipment/index_sylius/admin/shipment/index';
import { Show as AdjustmentShopCartShowSyliusShopCartShow } from '../models/Adjustment-shop/cart/show_sylius/shop/cart/show';
import { adminGetAdjustmentItem$Html } from '../fn/adjustment/admin-get-adjustment-item-html';
import { AdminGetAdjustmentItem$Html$Params } from '../fn/adjustment/admin-get-adjustment-item-html';
import { adminGetAdjustmentItem$Json } from '../fn/adjustment/admin-get-adjustment-item-json';
import { AdminGetAdjustmentItem$Json$Params } from '../fn/adjustment/admin-get-adjustment-item-json';
import { apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html } from '../fn/adjustment/api-order-items-adjustments-get-subresource-order-item-subresource-html';
import { ApiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html$Params } from '../fn/adjustment/api-order-items-adjustments-get-subresource-order-item-subresource-html';
import { apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json } from '../fn/adjustment/api-order-items-adjustments-get-subresource-order-item-subresource-json';
import { ApiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json$Params } from '../fn/adjustment/api-order-items-adjustments-get-subresource-order-item-subresource-json';
import { apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html } from '../fn/adjustment/api-order-item-units-adjustments-get-subresource-order-item-unit-subresource-html';
import { ApiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html$Params } from '../fn/adjustment/api-order-item-units-adjustments-get-subresource-order-item-unit-subresource-html';
import { apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json } from '../fn/adjustment/api-order-item-units-adjustments-get-subresource-order-item-unit-subresource-json';
import { ApiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json$Params } from '../fn/adjustment/api-order-item-units-adjustments-get-subresource-order-item-unit-subresource-json';
import { apiOrdersAdjustmentsGetSubresourceOrderSubresource$Html } from '../fn/adjustment/api-orders-adjustments-get-subresource-order-subresource-html';
import { ApiOrdersAdjustmentsGetSubresourceOrderSubresource$Html$Params } from '../fn/adjustment/api-orders-adjustments-get-subresource-order-subresource-html';
import { apiOrdersAdjustmentsGetSubresourceOrderSubresource$Json } from '../fn/adjustment/api-orders-adjustments-get-subresource-order-subresource-json';
import { ApiOrdersAdjustmentsGetSubresourceOrderSubresource$Json$Params } from '../fn/adjustment/api-orders-adjustments-get-subresource-order-subresource-json';
import { apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html } from '../fn/adjustment/api-orders-items-adjustments-get-subresource-order-subresource-html';
import { ApiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html$Params } from '../fn/adjustment/api-orders-items-adjustments-get-subresource-order-subresource-html';
import { apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json } from '../fn/adjustment/api-orders-items-adjustments-get-subresource-order-subresource-json';
import { ApiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json$Params } from '../fn/adjustment/api-orders-items-adjustments-get-subresource-order-subresource-json';
import { apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html } from '../fn/adjustment/api-orders-shipments-adjustments-get-subresource-order-subresource-html';
import { ApiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html$Params } from '../fn/adjustment/api-orders-shipments-adjustments-get-subresource-order-subresource-html';
import { apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json } from '../fn/adjustment/api-orders-shipments-adjustments-get-subresource-order-subresource-json';
import { ApiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json$Params } from '../fn/adjustment/api-orders-shipments-adjustments-get-subresource-order-subresource-json';
import { apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html } from '../fn/adjustment/api-shipments-adjustments-get-subresource-shipment-subresource-html';
import { ApiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html$Params } from '../fn/adjustment/api-shipments-adjustments-get-subresource-shipment-subresource-html';
import { apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json } from '../fn/adjustment/api-shipments-adjustments-get-subresource-shipment-subresource-json';
import { ApiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json$Params } from '../fn/adjustment/api-shipments-adjustments-get-subresource-shipment-subresource-json';

@Injectable({ providedIn: 'root' })
export class AdjustmentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetAdjustmentItem()` */
  static readonly AdminGetAdjustmentItemPath = '/api/v2/admin/adjustments/{id}';

  /**
   * Retrieves a Adjustment resource.
   *
   * Retrieves a Adjustment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetAdjustmentItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAdjustmentItem$Json$Response(params: AdminGetAdjustmentItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AdjustmentAdminAdjustmentShowSyliusAdminAdjustmentShow>> {
    return adminGetAdjustmentItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Adjustment resource.
   *
   * Retrieves a Adjustment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetAdjustmentItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAdjustmentItem$Json(params: AdminGetAdjustmentItem$Json$Params, context?: HttpContext): Observable<AdjustmentAdminAdjustmentShowSyliusAdminAdjustmentShow> {
    return this.adminGetAdjustmentItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdjustmentAdminAdjustmentShowSyliusAdminAdjustmentShow>): AdjustmentAdminAdjustmentShowSyliusAdminAdjustmentShow => r.body)
    );
  }

  /**
   * Retrieves a Adjustment resource.
   *
   * Retrieves a Adjustment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetAdjustmentItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAdjustmentItem$Html$Response(params: AdminGetAdjustmentItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AdjustmentAdminAdjustmentShowSyliusAdminAdjustmentShow>> {
    return adminGetAdjustmentItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Adjustment resource.
   *
   * Retrieves a Adjustment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetAdjustmentItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAdjustmentItem$Html(params: AdminGetAdjustmentItem$Html$Params, context?: HttpContext): Observable<AdjustmentAdminAdjustmentShowSyliusAdminAdjustmentShow> {
    return this.adminGetAdjustmentItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdjustmentAdminAdjustmentShowSyliusAdminAdjustmentShow>): AdjustmentAdminAdjustmentShowSyliusAdminAdjustmentShow => r.body)
    );
  }

  /** Path part for operation `apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource()` */
  static readonly ApiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresourcePath = '/api/v2/admin/order-item-units/{id}/adjustments';

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json$Response(params: ApiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex>>> {
    return apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json(params: ApiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json$Params, context?: HttpContext): Observable<Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex>> {
    return this.apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex>>): Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex> => r.body)
    );
  }

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html$Response(params: ApiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex>>> {
    return apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html(params: ApiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html$Params, context?: HttpContext): Observable<Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex>> {
    return this.apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex>>): Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex> => r.body)
    );
  }

  /** Path part for operation `apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource()` */
  static readonly ApiOrderItemsAdjustmentsGetSubresourceOrderItemSubresourcePath = '/api/v2/admin/order-items/{id}/adjustments';

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json$Response(params: ApiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex>>> {
    return apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json(params: ApiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json$Params, context?: HttpContext): Observable<Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex>> {
    return this.apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex>>): Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex> => r.body)
    );
  }

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html$Response(params: ApiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex>>> {
    return apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItem resource.
   *
   * Retrieves a OrderItem resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html(params: ApiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html$Params, context?: HttpContext): Observable<Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex>> {
    return this.apiOrderItemsAdjustmentsGetSubresourceOrderItemSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex>>): Array<AdjustmentAdminOrderItemIndexSyliusAdminOrderItemIndex> => r.body)
    );
  }

  /** Path part for operation `apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource()` */
  static readonly ApiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresourcePath = '/api/v2/admin/orders/{tokenValue}/shipments/{shipments}/adjustments';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json$Response(params: ApiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Adjustment>>> {
    return apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json(params: ApiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<Array<Adjustment>> {
    return this.apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Adjustment>>): Array<Adjustment> => r.body)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html$Response(params: ApiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Adjustment>>> {
    return apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html(params: ApiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<Array<Adjustment>> {
    return this.apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Adjustment>>): Array<Adjustment> => r.body)
    );
  }

  /** Path part for operation `apiShipmentsAdjustmentsGetSubresourceShipmentSubresource()` */
  static readonly ApiShipmentsAdjustmentsGetSubresourceShipmentSubresourcePath = '/api/v2/admin/shipments/{id}/adjustments';

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json$Response(params: ApiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex>>> {
    return apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json(params: ApiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json$Params, context?: HttpContext): Observable<Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex>> {
    return this.apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex>>): Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex> => r.body)
    );
  }

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html$Response(params: ApiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex>>> {
    return apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html(params: ApiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html$Params, context?: HttpContext): Observable<Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex>> {
    return this.apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex>>): Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex> => r.body)
    );
  }

  /** Path part for operation `apiOrdersAdjustmentsGetSubresourceOrderSubresource()` */
  static readonly ApiOrdersAdjustmentsGetSubresourceOrderSubresourcePath = '/api/v2/shop/orders/{tokenValue}/adjustments';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersAdjustmentsGetSubresourceOrderSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersAdjustmentsGetSubresourceOrderSubresource$Json$Response(params: ApiOrdersAdjustmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>> {
    return apiOrdersAdjustmentsGetSubresourceOrderSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersAdjustmentsGetSubresourceOrderSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersAdjustmentsGetSubresourceOrderSubresource$Json(params: ApiOrdersAdjustmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<Array<AdjustmentShopCartShowSyliusShopCartShow>> {
    return this.apiOrdersAdjustmentsGetSubresourceOrderSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>): Array<AdjustmentShopCartShowSyliusShopCartShow> => r.body)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersAdjustmentsGetSubresourceOrderSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersAdjustmentsGetSubresourceOrderSubresource$Html$Response(params: ApiOrdersAdjustmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>> {
    return apiOrdersAdjustmentsGetSubresourceOrderSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersAdjustmentsGetSubresourceOrderSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersAdjustmentsGetSubresourceOrderSubresource$Html(params: ApiOrdersAdjustmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<Array<AdjustmentShopCartShowSyliusShopCartShow>> {
    return this.apiOrdersAdjustmentsGetSubresourceOrderSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>): Array<AdjustmentShopCartShowSyliusShopCartShow> => r.body)
    );
  }

  /** Path part for operation `apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource()` */
  static readonly ApiOrdersItemsAdjustmentsGetSubresourceOrderSubresourcePath = '/api/v2/shop/orders/{tokenValue}/items/{items}/adjustments';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json$Response(params: ApiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>> {
    return apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json(params: ApiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<Array<AdjustmentShopCartShowSyliusShopCartShow>> {
    return this.apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>): Array<AdjustmentShopCartShowSyliusShopCartShow> => r.body)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html$Response(params: ApiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>> {
    return apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html(params: ApiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<Array<AdjustmentShopCartShowSyliusShopCartShow>> {
    return this.apiOrdersItemsAdjustmentsGetSubresourceOrderSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>): Array<AdjustmentShopCartShowSyliusShopCartShow> => r.body)
    );
  }

}

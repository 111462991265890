/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Index as OrderAdminOrderIndexSyliusAdminOrderIndex } from '../../models/Order-admin/order/index_sylius/admin/order/index';

export interface AdminGetOrderCollection$Json$Params {

/**
 * The collection page number
 */
  page?: number;

/**
 * The number of items per page
 */
  itemsPerPage?: number;
  'channel.code'?: string;
  currencyCode?: string;
  'currencyCode[]'?: Array<string>;
  'customer.id'?: number;
  'customer.id[]'?: Array<number>;
  'checkoutCompletedAt[before]'?: string;
  'checkoutCompletedAt[strictly_before]'?: string;
  'checkoutCompletedAt[after]'?: string;
  'checkoutCompletedAt[strictly_after]'?: string;
  'order[number]'?: 'asc' | 'desc';
  'items.productName'?: string;
  'items.productName[]'?: Array<string>;
  'shipments.method.code'?: string;
  'shipments.method.code[]'?: Array<string>;
  'total[between]'?: string;
  'total[gt]'?: string;
  'total[gte]'?: string;
  'total[lt]'?: string;
  'total[lte]'?: string;
  'items.variant.translations.name'?: string;
  'items.variant.translations.name[]'?: Array<string>;

/**
 * Locales in this enum are all locales defined in the shop and only enabled ones will work in the given channel in the shop.
 */
  'Accept-Language'?: 'hu_HU';
}

export function adminGetOrderCollection$Json(http: HttpClient, rootUrl: string, params?: AdminGetOrderCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderAdminOrderIndexSyliusAdminOrderIndex>>> {
  const rb = new RequestBuilder(rootUrl, adminGetOrderCollection$Json.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
    rb.query('channel.code', params['channel.code'], {"style":"form","explode":false});
    rb.query('currencyCode', params.currencyCode, {"style":"form","explode":false});
    rb.query('currencyCode[]', params['currencyCode[]'], {"style":"form","explode":true});
    rb.query('customer.id', params['customer.id'], {"style":"form","explode":false});
    rb.query('customer.id[]', params['customer.id[]'], {"style":"form","explode":true});
    rb.query('checkoutCompletedAt[before]', params['checkoutCompletedAt[before]'], {"style":"form","explode":false});
    rb.query('checkoutCompletedAt[strictly_before]', params['checkoutCompletedAt[strictly_before]'], {"style":"form","explode":false});
    rb.query('checkoutCompletedAt[after]', params['checkoutCompletedAt[after]'], {"style":"form","explode":false});
    rb.query('checkoutCompletedAt[strictly_after]', params['checkoutCompletedAt[strictly_after]'], {"style":"form","explode":false});
    rb.query('order[number]', params['order[number]'], {"style":"form","explode":false});
    rb.query('items.productName', params['items.productName'], {"style":"form","explode":false});
    rb.query('items.productName[]', params['items.productName[]'], {"style":"form","explode":true});
    rb.query('shipments.method.code', params['shipments.method.code'], {"style":"form","explode":false});
    rb.query('shipments.method.code[]', params['shipments.method.code[]'], {"style":"form","explode":true});
    rb.query('total[between]', params['total[between]'], {"style":"form","explode":false});
    rb.query('total[gt]', params['total[gt]'], {"style":"form","explode":false});
    rb.query('total[gte]', params['total[gte]'], {"style":"form","explode":false});
    rb.query('total[lt]', params['total[lt]'], {"style":"form","explode":false});
    rb.query('total[lte]', params['total[lte]'], {"style":"form","explode":false});
    rb.query('items.variant.translations.name', params['items.variant.translations.name'], {"style":"form","explode":false});
    rb.query('items.variant.translations.name[]', params['items.variant.translations.name[]'], {"style":"form","explode":true});
    rb.header('Accept-Language', params['Accept-Language'], {"style":"simple","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<OrderAdminOrderIndexSyliusAdminOrderIndex>>;
    })
  );
}

adminGetOrderCollection$Json.PATH = '/api/v2/admin/orders';

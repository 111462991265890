/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Create as TaxonAdminTaxonCreateSyliusAdminTaxonCreate } from '../../models/Taxon-admin/taxon/create_sylius/admin/taxon/create';
import { Show as TaxonAdminTaxonShowSyliusAdminTaxonShow } from '../../models/Taxon-admin/taxon/show_sylius/admin/taxon/show';

export interface AdminPostTaxonCollection$Json$Json$Params {

/**
 * Locales in this enum are all locales defined in the shop and only enabled ones will work in the given channel in the shop.
 */
  'Accept-Language'?: 'hu_HU';
  
    /**
     * The new Taxon resource
     */
    body: TaxonAdminTaxonCreateSyliusAdminTaxonCreate
}

export function adminPostTaxonCollection$Json$Json(http: HttpClient, rootUrl: string, params: AdminPostTaxonCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>> {
  const rb = new RequestBuilder(rootUrl, adminPostTaxonCollection$Json$Json.PATH, 'post');
  if (params) {
    rb.header('Accept-Language', params['Accept-Language'], {"style":"simple","explode":false});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>;
    })
  );
}

adminPostTaxonCollection$Json$Json.PATH = '/api/v2/admin/taxons';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetCountryCollection$Html } from '../fn/country/admin-get-country-collection-html';
import { AdminGetCountryCollection$Html$Params } from '../fn/country/admin-get-country-collection-html';
import { adminGetCountryCollection$Json } from '../fn/country/admin-get-country-collection-json';
import { AdminGetCountryCollection$Json$Params } from '../fn/country/admin-get-country-collection-json';
import { adminGetCountryItem$Html } from '../fn/country/admin-get-country-item-html';
import { AdminGetCountryItem$Html$Params } from '../fn/country/admin-get-country-item-html';
import { adminGetCountryItem$Json } from '../fn/country/admin-get-country-item-json';
import { AdminGetCountryItem$Json$Params } from '../fn/country/admin-get-country-item-json';
import { adminPostCountryCollection$Html$Html } from '../fn/country/admin-post-country-collection-html-html';
import { AdminPostCountryCollection$Html$Html$Params } from '../fn/country/admin-post-country-collection-html-html';
import { adminPostCountryCollection$Html$Json } from '../fn/country/admin-post-country-collection-html-json';
import { AdminPostCountryCollection$Html$Json$Params } from '../fn/country/admin-post-country-collection-html-json';
import { adminPostCountryCollection$Json$Html } from '../fn/country/admin-post-country-collection-json-html';
import { AdminPostCountryCollection$Json$Html$Params } from '../fn/country/admin-post-country-collection-json-html';
import { adminPostCountryCollection$Json$Json } from '../fn/country/admin-post-country-collection-json-json';
import { AdminPostCountryCollection$Json$Json$Params } from '../fn/country/admin-post-country-collection-json-json';
import { adminPutCountryItem$Html$Html } from '../fn/country/admin-put-country-item-html-html';
import { AdminPutCountryItem$Html$Html$Params } from '../fn/country/admin-put-country-item-html-html';
import { adminPutCountryItem$Html$Json } from '../fn/country/admin-put-country-item-html-json';
import { AdminPutCountryItem$Html$Json$Params } from '../fn/country/admin-put-country-item-html-json';
import { adminPutCountryItem$Json$Html } from '../fn/country/admin-put-country-item-json-html';
import { AdminPutCountryItem$Json$Html$Params } from '../fn/country/admin-put-country-item-json-html';
import { adminPutCountryItem$Json$Json } from '../fn/country/admin-put-country-item-json-json';
import { AdminPutCountryItem$Json$Json$Params } from '../fn/country/admin-put-country-item-json-json';
import { apiCountriesProvincesGetSubresourceCountrySubresource$Html } from '../fn/province/api-countries-provinces-get-subresource-country-subresource-html';
import { ApiCountriesProvincesGetSubresourceCountrySubresource$Html$Params } from '../fn/province/api-countries-provinces-get-subresource-country-subresource-html';
import { apiCountriesProvincesGetSubresourceCountrySubresource$Json } from '../fn/province/api-countries-provinces-get-subresource-country-subresource-json';
import { ApiCountriesProvincesGetSubresourceCountrySubresource$Json$Params } from '../fn/province/api-countries-provinces-get-subresource-country-subresource-json';
import { Index as CountryAdminCountryIndexSyliusAdminCountryIndex } from '../models/Country-admin/country/index_sylius/admin/country/index';
import { Show as CountryAdminCountryShowSyliusAdminCountryShow } from '../models/Country-admin/country/show_sylius/admin/country/show';
import { Index as CountryShopCountryIndexSyliusShopCountryIndex } from '../models/Country-shop/country/index_sylius/shop/country/index';
import { Show as CountryShopCountryShowSyliusShopCountryShow } from '../models/Country-shop/country/show_sylius/shop/country/show';
import { Show as ProvinceAdminProvinceShowSyliusAdminProvinceShow } from '../models/Province-admin/province/show_sylius/admin/province/show';
import { shopGetCountryCollection$Html } from '../fn/country/shop-get-country-collection-html';
import { ShopGetCountryCollection$Html$Params } from '../fn/country/shop-get-country-collection-html';
import { shopGetCountryCollection$Json } from '../fn/country/shop-get-country-collection-json';
import { ShopGetCountryCollection$Json$Params } from '../fn/country/shop-get-country-collection-json';
import { shopGetCountryItem$Html } from '../fn/country/shop-get-country-item-html';
import { ShopGetCountryItem$Html$Params } from '../fn/country/shop-get-country-item-html';
import { shopGetCountryItem$Json } from '../fn/country/shop-get-country-item-json';
import { ShopGetCountryItem$Json$Params } from '../fn/country/shop-get-country-item-json';

@Injectable({ providedIn: 'root' })
export class CountryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetCountryCollection()` */
  static readonly AdminGetCountryCollectionPath = '/api/v2/admin/countries';

  /**
   * Retrieves the collection of Country resources.
   *
   * Retrieves the collection of Country resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCountryCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCountryCollection$Json$Response(params?: AdminGetCountryCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CountryAdminCountryIndexSyliusAdminCountryIndex>>> {
    return adminGetCountryCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Country resources.
   *
   * Retrieves the collection of Country resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCountryCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCountryCollection$Json(params?: AdminGetCountryCollection$Json$Params, context?: HttpContext): Observable<Array<CountryAdminCountryIndexSyliusAdminCountryIndex>> {
    return this.adminGetCountryCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CountryAdminCountryIndexSyliusAdminCountryIndex>>): Array<CountryAdminCountryIndexSyliusAdminCountryIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Country resources.
   *
   * Retrieves the collection of Country resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCountryCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCountryCollection$Html$Response(params?: AdminGetCountryCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CountryAdminCountryIndexSyliusAdminCountryIndex>>> {
    return adminGetCountryCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Country resources.
   *
   * Retrieves the collection of Country resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCountryCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCountryCollection$Html(params?: AdminGetCountryCollection$Html$Params, context?: HttpContext): Observable<Array<CountryAdminCountryIndexSyliusAdminCountryIndex>> {
    return this.adminGetCountryCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CountryAdminCountryIndexSyliusAdminCountryIndex>>): Array<CountryAdminCountryIndexSyliusAdminCountryIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostCountryCollection()` */
  static readonly AdminPostCountryCollectionPath = '/api/v2/admin/countries';

  /**
   * Creates a Country resource.
   *
   * Creates a Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCountryCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCountryCollection$Json$Json$Response(params: AdminPostCountryCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>> {
    return adminPostCountryCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Country resource.
   *
   * Creates a Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCountryCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCountryCollection$Json$Json(params: AdminPostCountryCollection$Json$Json$Params, context?: HttpContext): Observable<CountryAdminCountryShowSyliusAdminCountryShow> {
    return this.adminPostCountryCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>): CountryAdminCountryShowSyliusAdminCountryShow => r.body)
    );
  }

  /**
   * Creates a Country resource.
   *
   * Creates a Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCountryCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCountryCollection$Json$Html$Response(params: AdminPostCountryCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>> {
    return adminPostCountryCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Country resource.
   *
   * Creates a Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCountryCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCountryCollection$Json$Html(params: AdminPostCountryCollection$Json$Html$Params, context?: HttpContext): Observable<CountryAdminCountryShowSyliusAdminCountryShow> {
    return this.adminPostCountryCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>): CountryAdminCountryShowSyliusAdminCountryShow => r.body)
    );
  }

  /**
   * Creates a Country resource.
   *
   * Creates a Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCountryCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCountryCollection$Html$Json$Response(params: AdminPostCountryCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>> {
    return adminPostCountryCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Country resource.
   *
   * Creates a Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCountryCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCountryCollection$Html$Json(params: AdminPostCountryCollection$Html$Json$Params, context?: HttpContext): Observable<CountryAdminCountryShowSyliusAdminCountryShow> {
    return this.adminPostCountryCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>): CountryAdminCountryShowSyliusAdminCountryShow => r.body)
    );
  }

  /**
   * Creates a Country resource.
   *
   * Creates a Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCountryCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCountryCollection$Html$Html$Response(params: AdminPostCountryCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>> {
    return adminPostCountryCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Country resource.
   *
   * Creates a Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCountryCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCountryCollection$Html$Html(params: AdminPostCountryCollection$Html$Html$Params, context?: HttpContext): Observable<CountryAdminCountryShowSyliusAdminCountryShow> {
    return this.adminPostCountryCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>): CountryAdminCountryShowSyliusAdminCountryShow => r.body)
    );
  }

  /** Path part for operation `adminGetCountryItem()` */
  static readonly AdminGetCountryItemPath = '/api/v2/admin/countries/{code}';

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCountryItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCountryItem$Json$Response(params: AdminGetCountryItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>> {
    return adminGetCountryItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCountryItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCountryItem$Json(params: AdminGetCountryItem$Json$Params, context?: HttpContext): Observable<CountryAdminCountryShowSyliusAdminCountryShow> {
    return this.adminGetCountryItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>): CountryAdminCountryShowSyliusAdminCountryShow => r.body)
    );
  }

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCountryItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCountryItem$Html$Response(params: AdminGetCountryItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>> {
    return adminGetCountryItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCountryItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCountryItem$Html(params: AdminGetCountryItem$Html$Params, context?: HttpContext): Observable<CountryAdminCountryShowSyliusAdminCountryShow> {
    return this.adminGetCountryItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>): CountryAdminCountryShowSyliusAdminCountryShow => r.body)
    );
  }

  /** Path part for operation `adminPutCountryItem()` */
  static readonly AdminPutCountryItemPath = '/api/v2/admin/countries/{code}';

  /**
   * Replaces the Country resource.
   *
   * Replaces the Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCountryItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCountryItem$Json$Json$Response(params: AdminPutCountryItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>> {
    return adminPutCountryItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Country resource.
   *
   * Replaces the Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCountryItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCountryItem$Json$Json(params: AdminPutCountryItem$Json$Json$Params, context?: HttpContext): Observable<CountryAdminCountryShowSyliusAdminCountryShow> {
    return this.adminPutCountryItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>): CountryAdminCountryShowSyliusAdminCountryShow => r.body)
    );
  }

  /**
   * Replaces the Country resource.
   *
   * Replaces the Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCountryItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCountryItem$Json$Html$Response(params: AdminPutCountryItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>> {
    return adminPutCountryItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Country resource.
   *
   * Replaces the Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCountryItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCountryItem$Json$Html(params: AdminPutCountryItem$Json$Html$Params, context?: HttpContext): Observable<CountryAdminCountryShowSyliusAdminCountryShow> {
    return this.adminPutCountryItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>): CountryAdminCountryShowSyliusAdminCountryShow => r.body)
    );
  }

  /**
   * Replaces the Country resource.
   *
   * Replaces the Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCountryItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCountryItem$Html$Json$Response(params: AdminPutCountryItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>> {
    return adminPutCountryItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Country resource.
   *
   * Replaces the Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCountryItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCountryItem$Html$Json(params: AdminPutCountryItem$Html$Json$Params, context?: HttpContext): Observable<CountryAdminCountryShowSyliusAdminCountryShow> {
    return this.adminPutCountryItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>): CountryAdminCountryShowSyliusAdminCountryShow => r.body)
    );
  }

  /**
   * Replaces the Country resource.
   *
   * Replaces the Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCountryItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCountryItem$Html$Html$Response(params: AdminPutCountryItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>> {
    return adminPutCountryItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Country resource.
   *
   * Replaces the Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCountryItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCountryItem$Html$Html(params: AdminPutCountryItem$Html$Html$Params, context?: HttpContext): Observable<CountryAdminCountryShowSyliusAdminCountryShow> {
    return this.adminPutCountryItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountryAdminCountryShowSyliusAdminCountryShow>): CountryAdminCountryShowSyliusAdminCountryShow => r.body)
    );
  }

  /** Path part for operation `apiCountriesProvincesGetSubresourceCountrySubresource()` */
  static readonly ApiCountriesProvincesGetSubresourceCountrySubresourcePath = '/api/v2/admin/countries/{code}/provinces';

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCountriesProvincesGetSubresourceCountrySubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCountriesProvincesGetSubresourceCountrySubresource$Json$Response(params: ApiCountriesProvincesGetSubresourceCountrySubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow>>> {
    return apiCountriesProvincesGetSubresourceCountrySubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCountriesProvincesGetSubresourceCountrySubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCountriesProvincesGetSubresourceCountrySubresource$Json(params: ApiCountriesProvincesGetSubresourceCountrySubresource$Json$Params, context?: HttpContext): Observable<Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow>> {
    return this.apiCountriesProvincesGetSubresourceCountrySubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow>>): Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow> => r.body)
    );
  }

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCountriesProvincesGetSubresourceCountrySubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCountriesProvincesGetSubresourceCountrySubresource$Html$Response(params: ApiCountriesProvincesGetSubresourceCountrySubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow>>> {
    return apiCountriesProvincesGetSubresourceCountrySubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCountriesProvincesGetSubresourceCountrySubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCountriesProvincesGetSubresourceCountrySubresource$Html(params: ApiCountriesProvincesGetSubresourceCountrySubresource$Html$Params, context?: HttpContext): Observable<Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow>> {
    return this.apiCountriesProvincesGetSubresourceCountrySubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow>>): Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow> => r.body)
    );
  }

  /** Path part for operation `shopGetCountryCollection()` */
  static readonly ShopGetCountryCollectionPath = '/api/v2/shop/countries';

  /**
   * Retrieves the collection of Country resources.
   *
   * Retrieves the collection of Country resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetCountryCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCountryCollection$Json$Response(params?: ShopGetCountryCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CountryShopCountryIndexSyliusShopCountryIndex>>> {
    return shopGetCountryCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Country resources.
   *
   * Retrieves the collection of Country resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetCountryCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCountryCollection$Json(params?: ShopGetCountryCollection$Json$Params, context?: HttpContext): Observable<Array<CountryShopCountryIndexSyliusShopCountryIndex>> {
    return this.shopGetCountryCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CountryShopCountryIndexSyliusShopCountryIndex>>): Array<CountryShopCountryIndexSyliusShopCountryIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Country resources.
   *
   * Retrieves the collection of Country resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetCountryCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCountryCollection$Html$Response(params?: ShopGetCountryCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CountryShopCountryIndexSyliusShopCountryIndex>>> {
    return shopGetCountryCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Country resources.
   *
   * Retrieves the collection of Country resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetCountryCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCountryCollection$Html(params?: ShopGetCountryCollection$Html$Params, context?: HttpContext): Observable<Array<CountryShopCountryIndexSyliusShopCountryIndex>> {
    return this.shopGetCountryCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CountryShopCountryIndexSyliusShopCountryIndex>>): Array<CountryShopCountryIndexSyliusShopCountryIndex> => r.body)
    );
  }

  /** Path part for operation `shopGetCountryItem()` */
  static readonly ShopGetCountryItemPath = '/api/v2/shop/countries/{code}';

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetCountryItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCountryItem$Json$Response(params: ShopGetCountryItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CountryShopCountryShowSyliusShopCountryShow>> {
    return shopGetCountryItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetCountryItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCountryItem$Json(params: ShopGetCountryItem$Json$Params, context?: HttpContext): Observable<CountryShopCountryShowSyliusShopCountryShow> {
    return this.shopGetCountryItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountryShopCountryShowSyliusShopCountryShow>): CountryShopCountryShowSyliusShopCountryShow => r.body)
    );
  }

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetCountryItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCountryItem$Html$Response(params: ShopGetCountryItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CountryShopCountryShowSyliusShopCountryShow>> {
    return shopGetCountryItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetCountryItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCountryItem$Html(params: ShopGetCountryItem$Html$Params, context?: HttpContext): Observable<CountryShopCountryShowSyliusShopCountryShow> {
    return this.shopGetCountryItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountryShopCountryShowSyliusShopCountryShow>): CountryShopCountryShowSyliusShopCountryShow => r.body)
    );
  }

}

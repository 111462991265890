/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteTaxRateItem } from '../fn/tax-rate/admin-delete-tax-rate-item';
import { AdminDeleteTaxRateItem$Params } from '../fn/tax-rate/admin-delete-tax-rate-item';
import { adminGetTaxRateCollection$Html } from '../fn/tax-rate/admin-get-tax-rate-collection-html';
import { AdminGetTaxRateCollection$Html$Params } from '../fn/tax-rate/admin-get-tax-rate-collection-html';
import { adminGetTaxRateCollection$Json } from '../fn/tax-rate/admin-get-tax-rate-collection-json';
import { AdminGetTaxRateCollection$Json$Params } from '../fn/tax-rate/admin-get-tax-rate-collection-json';
import { adminGetTaxRateItem$Html } from '../fn/tax-rate/admin-get-tax-rate-item-html';
import { AdminGetTaxRateItem$Html$Params } from '../fn/tax-rate/admin-get-tax-rate-item-html';
import { adminGetTaxRateItem$Json } from '../fn/tax-rate/admin-get-tax-rate-item-json';
import { AdminGetTaxRateItem$Json$Params } from '../fn/tax-rate/admin-get-tax-rate-item-json';
import { adminPostTaxRateCollection$Html$Html } from '../fn/tax-rate/admin-post-tax-rate-collection-html-html';
import { AdminPostTaxRateCollection$Html$Html$Params } from '../fn/tax-rate/admin-post-tax-rate-collection-html-html';
import { adminPostTaxRateCollection$Html$Json } from '../fn/tax-rate/admin-post-tax-rate-collection-html-json';
import { AdminPostTaxRateCollection$Html$Json$Params } from '../fn/tax-rate/admin-post-tax-rate-collection-html-json';
import { adminPostTaxRateCollection$Json$Html } from '../fn/tax-rate/admin-post-tax-rate-collection-json-html';
import { AdminPostTaxRateCollection$Json$Html$Params } from '../fn/tax-rate/admin-post-tax-rate-collection-json-html';
import { adminPostTaxRateCollection$Json$Json } from '../fn/tax-rate/admin-post-tax-rate-collection-json-json';
import { AdminPostTaxRateCollection$Json$Json$Params } from '../fn/tax-rate/admin-post-tax-rate-collection-json-json';
import { adminPutTaxRateItem$Html$Html } from '../fn/tax-rate/admin-put-tax-rate-item-html-html';
import { AdminPutTaxRateItem$Html$Html$Params } from '../fn/tax-rate/admin-put-tax-rate-item-html-html';
import { adminPutTaxRateItem$Html$Json } from '../fn/tax-rate/admin-put-tax-rate-item-html-json';
import { AdminPutTaxRateItem$Html$Json$Params } from '../fn/tax-rate/admin-put-tax-rate-item-html-json';
import { adminPutTaxRateItem$Json$Html } from '../fn/tax-rate/admin-put-tax-rate-item-json-html';
import { AdminPutTaxRateItem$Json$Html$Params } from '../fn/tax-rate/admin-put-tax-rate-item-json-html';
import { adminPutTaxRateItem$Json$Json } from '../fn/tax-rate/admin-put-tax-rate-item-json-json';
import { AdminPutTaxRateItem$Json$Json$Params } from '../fn/tax-rate/admin-put-tax-rate-item-json-json';
import { Index as TaxRateAdminTaxRateIndexSyliusAdminTaxRateIndex } from '../models/TaxRate-admin/tax_rate/index_sylius/admin/tax_rate/index';
import { Show as TaxRateAdminTaxRateShowSyliusAdminTaxRateShow } from '../models/TaxRate-admin/tax_rate/show_sylius/admin/tax_rate/show';

@Injectable({ providedIn: 'root' })
export class TaxRateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetTaxRateCollection()` */
  static readonly AdminGetTaxRateCollectionPath = '/api/v2/admin/tax-rates';

  /**
   * Retrieves the collection of TaxRate resources.
   *
   * Retrieves the collection of TaxRate resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxRateCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxRateCollection$Json$Response(params?: AdminGetTaxRateCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxRateAdminTaxRateIndexSyliusAdminTaxRateIndex>>> {
    return adminGetTaxRateCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of TaxRate resources.
   *
   * Retrieves the collection of TaxRate resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxRateCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxRateCollection$Json(params?: AdminGetTaxRateCollection$Json$Params, context?: HttpContext): Observable<Array<TaxRateAdminTaxRateIndexSyliusAdminTaxRateIndex>> {
    return this.adminGetTaxRateCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxRateAdminTaxRateIndexSyliusAdminTaxRateIndex>>): Array<TaxRateAdminTaxRateIndexSyliusAdminTaxRateIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of TaxRate resources.
   *
   * Retrieves the collection of TaxRate resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxRateCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxRateCollection$Html$Response(params?: AdminGetTaxRateCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxRateAdminTaxRateIndexSyliusAdminTaxRateIndex>>> {
    return adminGetTaxRateCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of TaxRate resources.
   *
   * Retrieves the collection of TaxRate resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxRateCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxRateCollection$Html(params?: AdminGetTaxRateCollection$Html$Params, context?: HttpContext): Observable<Array<TaxRateAdminTaxRateIndexSyliusAdminTaxRateIndex>> {
    return this.adminGetTaxRateCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxRateAdminTaxRateIndexSyliusAdminTaxRateIndex>>): Array<TaxRateAdminTaxRateIndexSyliusAdminTaxRateIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostTaxRateCollection()` */
  static readonly AdminPostTaxRateCollectionPath = '/api/v2/admin/tax-rates';

  /**
   * Creates a TaxRate resource.
   *
   * Creates a TaxRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostTaxRateCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostTaxRateCollection$Json$Json$Response(params: AdminPostTaxRateCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>> {
    return adminPostTaxRateCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a TaxRate resource.
   *
   * Creates a TaxRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostTaxRateCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostTaxRateCollection$Json$Json(params: AdminPostTaxRateCollection$Json$Json$Params, context?: HttpContext): Observable<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow> {
    return this.adminPostTaxRateCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>): TaxRateAdminTaxRateShowSyliusAdminTaxRateShow => r.body)
    );
  }

  /**
   * Creates a TaxRate resource.
   *
   * Creates a TaxRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostTaxRateCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostTaxRateCollection$Json$Html$Response(params: AdminPostTaxRateCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>> {
    return adminPostTaxRateCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a TaxRate resource.
   *
   * Creates a TaxRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostTaxRateCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostTaxRateCollection$Json$Html(params: AdminPostTaxRateCollection$Json$Html$Params, context?: HttpContext): Observable<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow> {
    return this.adminPostTaxRateCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>): TaxRateAdminTaxRateShowSyliusAdminTaxRateShow => r.body)
    );
  }

  /**
   * Creates a TaxRate resource.
   *
   * Creates a TaxRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostTaxRateCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostTaxRateCollection$Html$Json$Response(params: AdminPostTaxRateCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>> {
    return adminPostTaxRateCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a TaxRate resource.
   *
   * Creates a TaxRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostTaxRateCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostTaxRateCollection$Html$Json(params: AdminPostTaxRateCollection$Html$Json$Params, context?: HttpContext): Observable<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow> {
    return this.adminPostTaxRateCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>): TaxRateAdminTaxRateShowSyliusAdminTaxRateShow => r.body)
    );
  }

  /**
   * Creates a TaxRate resource.
   *
   * Creates a TaxRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostTaxRateCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostTaxRateCollection$Html$Html$Response(params: AdminPostTaxRateCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>> {
    return adminPostTaxRateCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a TaxRate resource.
   *
   * Creates a TaxRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostTaxRateCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostTaxRateCollection$Html$Html(params: AdminPostTaxRateCollection$Html$Html$Params, context?: HttpContext): Observable<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow> {
    return this.adminPostTaxRateCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>): TaxRateAdminTaxRateShowSyliusAdminTaxRateShow => r.body)
    );
  }

  /** Path part for operation `adminGetTaxRateItem()` */
  static readonly AdminGetTaxRateItemPath = '/api/v2/admin/tax-rates/{code}';

  /**
   * Retrieves a TaxRate resource.
   *
   * Retrieves a TaxRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxRateItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxRateItem$Json$Response(params: AdminGetTaxRateItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>> {
    return adminGetTaxRateItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a TaxRate resource.
   *
   * Retrieves a TaxRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxRateItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxRateItem$Json(params: AdminGetTaxRateItem$Json$Params, context?: HttpContext): Observable<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow> {
    return this.adminGetTaxRateItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>): TaxRateAdminTaxRateShowSyliusAdminTaxRateShow => r.body)
    );
  }

  /**
   * Retrieves a TaxRate resource.
   *
   * Retrieves a TaxRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxRateItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxRateItem$Html$Response(params: AdminGetTaxRateItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>> {
    return adminGetTaxRateItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a TaxRate resource.
   *
   * Retrieves a TaxRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxRateItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxRateItem$Html(params: AdminGetTaxRateItem$Html$Params, context?: HttpContext): Observable<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow> {
    return this.adminGetTaxRateItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>): TaxRateAdminTaxRateShowSyliusAdminTaxRateShow => r.body)
    );
  }

  /** Path part for operation `adminPutTaxRateItem()` */
  static readonly AdminPutTaxRateItemPath = '/api/v2/admin/tax-rates/{code}';

  /**
   * Replaces the TaxRate resource.
   *
   * Replaces the TaxRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxRateItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxRateItem$Json$Json$Response(params: AdminPutTaxRateItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>> {
    return adminPutTaxRateItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the TaxRate resource.
   *
   * Replaces the TaxRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxRateItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxRateItem$Json$Json(params: AdminPutTaxRateItem$Json$Json$Params, context?: HttpContext): Observable<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow> {
    return this.adminPutTaxRateItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>): TaxRateAdminTaxRateShowSyliusAdminTaxRateShow => r.body)
    );
  }

  /**
   * Replaces the TaxRate resource.
   *
   * Replaces the TaxRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxRateItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxRateItem$Json$Html$Response(params: AdminPutTaxRateItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>> {
    return adminPutTaxRateItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the TaxRate resource.
   *
   * Replaces the TaxRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxRateItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxRateItem$Json$Html(params: AdminPutTaxRateItem$Json$Html$Params, context?: HttpContext): Observable<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow> {
    return this.adminPutTaxRateItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>): TaxRateAdminTaxRateShowSyliusAdminTaxRateShow => r.body)
    );
  }

  /**
   * Replaces the TaxRate resource.
   *
   * Replaces the TaxRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxRateItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxRateItem$Html$Json$Response(params: AdminPutTaxRateItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>> {
    return adminPutTaxRateItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the TaxRate resource.
   *
   * Replaces the TaxRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxRateItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxRateItem$Html$Json(params: AdminPutTaxRateItem$Html$Json$Params, context?: HttpContext): Observable<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow> {
    return this.adminPutTaxRateItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>): TaxRateAdminTaxRateShowSyliusAdminTaxRateShow => r.body)
    );
  }

  /**
   * Replaces the TaxRate resource.
   *
   * Replaces the TaxRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxRateItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxRateItem$Html$Html$Response(params: AdminPutTaxRateItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>> {
    return adminPutTaxRateItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the TaxRate resource.
   *
   * Replaces the TaxRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxRateItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxRateItem$Html$Html(params: AdminPutTaxRateItem$Html$Html$Params, context?: HttpContext): Observable<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow> {
    return this.adminPutTaxRateItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxRateAdminTaxRateShowSyliusAdminTaxRateShow>): TaxRateAdminTaxRateShowSyliusAdminTaxRateShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteTaxRateItem()` */
  static readonly AdminDeleteTaxRateItemPath = '/api/v2/admin/tax-rates/{code}';

  /**
   * Removes the TaxRate resource.
   *
   * Removes the TaxRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteTaxRateItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteTaxRateItem$Response(params: AdminDeleteTaxRateItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteTaxRateItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the TaxRate resource.
   *
   * Removes the TaxRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteTaxRateItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteTaxRateItem(params: AdminDeleteTaxRateItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteTaxRateItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

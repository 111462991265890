/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminCompletePaymentItem$Html } from '../fn/payment/admin-complete-payment-item-html';
import { AdminCompletePaymentItem$Html$Params } from '../fn/payment/admin-complete-payment-item-html';
import { adminCompletePaymentItem$Json } from '../fn/payment/admin-complete-payment-item-json';
import { AdminCompletePaymentItem$Json$Params } from '../fn/payment/admin-complete-payment-item-json';
import { adminGetPaymentCollection$Html } from '../fn/payment/admin-get-payment-collection-html';
import { AdminGetPaymentCollection$Html$Params } from '../fn/payment/admin-get-payment-collection-html';
import { adminGetPaymentCollection$Json } from '../fn/payment/admin-get-payment-collection-json';
import { AdminGetPaymentCollection$Json$Params } from '../fn/payment/admin-get-payment-collection-json';
import { adminGetPaymentItem$Html } from '../fn/payment/admin-get-payment-item-html';
import { AdminGetPaymentItem$Html$Params } from '../fn/payment/admin-get-payment-item-html';
import { adminGetPaymentItem$Json } from '../fn/payment/admin-get-payment-item-json';
import { AdminGetPaymentItem$Json$Params } from '../fn/payment/admin-get-payment-item-json';
import { apiOrdersPaymentsGetSubresourceOrderSubresource$Html } from '../fn/payment/api-orders-payments-get-subresource-order-subresource-html';
import { ApiOrdersPaymentsGetSubresourceOrderSubresource$Html$Params } from '../fn/payment/api-orders-payments-get-subresource-order-subresource-html';
import { apiOrdersPaymentsGetSubresourceOrderSubresource$Json } from '../fn/payment/api-orders-payments-get-subresource-order-subresource-json';
import { ApiOrdersPaymentsGetSubresourceOrderSubresource$Json$Params } from '../fn/payment/api-orders-payments-get-subresource-order-subresource-json';
import { Payment } from '../models/payment';
import { Index as PaymentAdminPaymentIndexSyliusAdminPaymentIndex } from '../models/Payment-admin/payment/index_sylius/admin/payment/index';
import { Show as PaymentAdminPaymentShowSyliusAdminPaymentShow } from '../models/Payment-admin/payment/show_sylius/admin/payment/show';
import { Show as PaymentShopPaymentShowSyliusShopPaymentShow } from '../models/Payment-shop/payment/show_sylius/shop/payment/show';
import { shopGetPaymentItem$Html } from '../fn/payment/shop-get-payment-item-html';
import { ShopGetPaymentItem$Html$Params } from '../fn/payment/shop-get-payment-item-html';
import { shopGetPaymentItem$Json } from '../fn/payment/shop-get-payment-item-json';
import { ShopGetPaymentItem$Json$Params } from '../fn/payment/shop-get-payment-item-json';

@Injectable({ providedIn: 'root' })
export class PaymentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiOrdersPaymentsGetSubresourceOrderSubresource()` */
  static readonly ApiOrdersPaymentsGetSubresourceOrderSubresourcePath = '/api/v2/admin/orders/{tokenValue}/payments';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersPaymentsGetSubresourceOrderSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersPaymentsGetSubresourceOrderSubresource$Json$Response(params: ApiOrdersPaymentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PaymentAdminPaymentShowSyliusAdminPaymentShow>>> {
    return apiOrdersPaymentsGetSubresourceOrderSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersPaymentsGetSubresourceOrderSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersPaymentsGetSubresourceOrderSubresource$Json(params: ApiOrdersPaymentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<Array<PaymentAdminPaymentShowSyliusAdminPaymentShow>> {
    return this.apiOrdersPaymentsGetSubresourceOrderSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentAdminPaymentShowSyliusAdminPaymentShow>>): Array<PaymentAdminPaymentShowSyliusAdminPaymentShow> => r.body)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersPaymentsGetSubresourceOrderSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersPaymentsGetSubresourceOrderSubresource$Html$Response(params: ApiOrdersPaymentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PaymentAdminPaymentShowSyliusAdminPaymentShow>>> {
    return apiOrdersPaymentsGetSubresourceOrderSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersPaymentsGetSubresourceOrderSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersPaymentsGetSubresourceOrderSubresource$Html(params: ApiOrdersPaymentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<Array<PaymentAdminPaymentShowSyliusAdminPaymentShow>> {
    return this.apiOrdersPaymentsGetSubresourceOrderSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentAdminPaymentShowSyliusAdminPaymentShow>>): Array<PaymentAdminPaymentShowSyliusAdminPaymentShow> => r.body)
    );
  }

  /** Path part for operation `adminGetPaymentCollection()` */
  static readonly AdminGetPaymentCollectionPath = '/api/v2/admin/payments';

  /**
   * Retrieves the collection of Payment resources.
   *
   * Retrieves the collection of Payment resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPaymentCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentCollection$Json$Response(params?: AdminGetPaymentCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PaymentAdminPaymentIndexSyliusAdminPaymentIndex>>> {
    return adminGetPaymentCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Payment resources.
   *
   * Retrieves the collection of Payment resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPaymentCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentCollection$Json(params?: AdminGetPaymentCollection$Json$Params, context?: HttpContext): Observable<Array<PaymentAdminPaymentIndexSyliusAdminPaymentIndex>> {
    return this.adminGetPaymentCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentAdminPaymentIndexSyliusAdminPaymentIndex>>): Array<PaymentAdminPaymentIndexSyliusAdminPaymentIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Payment resources.
   *
   * Retrieves the collection of Payment resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPaymentCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentCollection$Html$Response(params?: AdminGetPaymentCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PaymentAdminPaymentIndexSyliusAdminPaymentIndex>>> {
    return adminGetPaymentCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Payment resources.
   *
   * Retrieves the collection of Payment resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPaymentCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentCollection$Html(params?: AdminGetPaymentCollection$Html$Params, context?: HttpContext): Observable<Array<PaymentAdminPaymentIndexSyliusAdminPaymentIndex>> {
    return this.adminGetPaymentCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentAdminPaymentIndexSyliusAdminPaymentIndex>>): Array<PaymentAdminPaymentIndexSyliusAdminPaymentIndex> => r.body)
    );
  }

  /** Path part for operation `adminGetPaymentItem()` */
  static readonly AdminGetPaymentItemPath = '/api/v2/admin/payments/{id}';

  /**
   * Retrieves a Payment resource.
   *
   * Retrieves a Payment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPaymentItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentItem$Json$Response(params: AdminGetPaymentItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentAdminPaymentShowSyliusAdminPaymentShow>> {
    return adminGetPaymentItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Payment resource.
   *
   * Retrieves a Payment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPaymentItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentItem$Json(params: AdminGetPaymentItem$Json$Params, context?: HttpContext): Observable<PaymentAdminPaymentShowSyliusAdminPaymentShow> {
    return this.adminGetPaymentItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentAdminPaymentShowSyliusAdminPaymentShow>): PaymentAdminPaymentShowSyliusAdminPaymentShow => r.body)
    );
  }

  /**
   * Retrieves a Payment resource.
   *
   * Retrieves a Payment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPaymentItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentItem$Html$Response(params: AdminGetPaymentItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentAdminPaymentShowSyliusAdminPaymentShow>> {
    return adminGetPaymentItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Payment resource.
   *
   * Retrieves a Payment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPaymentItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentItem$Html(params: AdminGetPaymentItem$Html$Params, context?: HttpContext): Observable<PaymentAdminPaymentShowSyliusAdminPaymentShow> {
    return this.adminGetPaymentItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentAdminPaymentShowSyliusAdminPaymentShow>): PaymentAdminPaymentShowSyliusAdminPaymentShow => r.body)
    );
  }

  /** Path part for operation `adminCompletePaymentItem()` */
  static readonly AdminCompletePaymentItemPath = '/api/v2/admin/payments/{id}/complete';

  /**
   * Completes Payment.
   *
   * Updates the Payment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCompletePaymentItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminCompletePaymentItem$Json$Response(params: AdminCompletePaymentItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Payment>> {
    return adminCompletePaymentItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Completes Payment.
   *
   * Updates the Payment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCompletePaymentItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminCompletePaymentItem$Json(params: AdminCompletePaymentItem$Json$Params, context?: HttpContext): Observable<Payment> {
    return this.adminCompletePaymentItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Payment>): Payment => r.body)
    );
  }

  /**
   * Completes Payment.
   *
   * Updates the Payment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCompletePaymentItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminCompletePaymentItem$Html$Response(params: AdminCompletePaymentItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Payment>> {
    return adminCompletePaymentItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Completes Payment.
   *
   * Updates the Payment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCompletePaymentItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminCompletePaymentItem$Html(params: AdminCompletePaymentItem$Html$Params, context?: HttpContext): Observable<Payment> {
    return this.adminCompletePaymentItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Payment>): Payment => r.body)
    );
  }

  /** Path part for operation `shopGetPaymentItem()` */
  static readonly ShopGetPaymentItemPath = '/api/v2/shop/payments/{id}';

  /**
   * Retrieves a Payment resource.
   *
   * Retrieves a Payment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetPaymentItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetPaymentItem$Json$Response(params: ShopGetPaymentItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentShopPaymentShowSyliusShopPaymentShow>> {
    return shopGetPaymentItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Payment resource.
   *
   * Retrieves a Payment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetPaymentItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetPaymentItem$Json(params: ShopGetPaymentItem$Json$Params, context?: HttpContext): Observable<PaymentShopPaymentShowSyliusShopPaymentShow> {
    return this.shopGetPaymentItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentShopPaymentShowSyliusShopPaymentShow>): PaymentShopPaymentShowSyliusShopPaymentShow => r.body)
    );
  }

  /**
   * Retrieves a Payment resource.
   *
   * Retrieves a Payment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetPaymentItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetPaymentItem$Html$Response(params: ShopGetPaymentItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentShopPaymentShowSyliusShopPaymentShow>> {
    return shopGetPaymentItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Payment resource.
   *
   * Retrieves a Payment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetPaymentItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetPaymentItem$Html(params: ShopGetPaymentItem$Html$Params, context?: HttpContext): Observable<PaymentShopPaymentShowSyliusShopPaymentShow> {
    return this.shopGetPaymentItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentShopPaymentShowSyliusShopPaymentShow>): PaymentShopPaymentShowSyliusShopPaymentShow => r.body)
    );
  }

}

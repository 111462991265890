/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Update as CustomerChangeShopUserPasswordShopCustomerPasswordUpdateSyliusShopCustomerPasswordUpdate } from '../../models/Customer/ChangeShopUserPassword-shop/customer/password/update_sylius/shop/customer/password/update';

export interface ShopPasswordUpdateCustomerItem$Json$Json$Params {

/**
 * Resource identifier
 */
  id: string;

/**
 * Locales in this enum are all locales defined in the shop and only enabled ones will work in the given channel in the shop.
 */
  'Accept-Language'?: 'hu_HU';
  
    /**
     * The updated Customer resource
     */
    body: CustomerChangeShopUserPasswordShopCustomerPasswordUpdateSyliusShopCustomerPasswordUpdate
}

export function shopPasswordUpdateCustomerItem$Json$Json(http: HttpClient, rootUrl: string, params: ShopPasswordUpdateCustomerItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
  const rb = new RequestBuilder(rootUrl, shopPasswordUpdateCustomerItem$Json$Json.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {"style":"simple","explode":false});
    rb.header('Accept-Language', params['Accept-Language'], {"style":"simple","explode":false});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<any>;
    })
  );
}

shopPasswordUpdateCustomerItem$Json$Json.PATH = '/api/v2/shop/customers/{id}/password';

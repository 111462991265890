/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteCustomerGroupItem } from '../fn/customer-group/admin-delete-customer-group-item';
import { AdminDeleteCustomerGroupItem$Params } from '../fn/customer-group/admin-delete-customer-group-item';
import { adminGetCustomerGroupCollection$Html } from '../fn/customer-group/admin-get-customer-group-collection-html';
import { AdminGetCustomerGroupCollection$Html$Params } from '../fn/customer-group/admin-get-customer-group-collection-html';
import { adminGetCustomerGroupCollection$Json } from '../fn/customer-group/admin-get-customer-group-collection-json';
import { AdminGetCustomerGroupCollection$Json$Params } from '../fn/customer-group/admin-get-customer-group-collection-json';
import { adminGetCustomerGroupItem$Html } from '../fn/customer-group/admin-get-customer-group-item-html';
import { AdminGetCustomerGroupItem$Html$Params } from '../fn/customer-group/admin-get-customer-group-item-html';
import { adminGetCustomerGroupItem$Json } from '../fn/customer-group/admin-get-customer-group-item-json';
import { AdminGetCustomerGroupItem$Json$Params } from '../fn/customer-group/admin-get-customer-group-item-json';
import { adminPostCustomerGroupCollection$Html$Html } from '../fn/customer-group/admin-post-customer-group-collection-html-html';
import { AdminPostCustomerGroupCollection$Html$Html$Params } from '../fn/customer-group/admin-post-customer-group-collection-html-html';
import { adminPostCustomerGroupCollection$Html$Json } from '../fn/customer-group/admin-post-customer-group-collection-html-json';
import { AdminPostCustomerGroupCollection$Html$Json$Params } from '../fn/customer-group/admin-post-customer-group-collection-html-json';
import { adminPostCustomerGroupCollection$Json$Html } from '../fn/customer-group/admin-post-customer-group-collection-json-html';
import { AdminPostCustomerGroupCollection$Json$Html$Params } from '../fn/customer-group/admin-post-customer-group-collection-json-html';
import { adminPostCustomerGroupCollection$Json$Json } from '../fn/customer-group/admin-post-customer-group-collection-json-json';
import { AdminPostCustomerGroupCollection$Json$Json$Params } from '../fn/customer-group/admin-post-customer-group-collection-json-json';
import { adminPutCustomerGroupItem$Html$Html } from '../fn/customer-group/admin-put-customer-group-item-html-html';
import { AdminPutCustomerGroupItem$Html$Html$Params } from '../fn/customer-group/admin-put-customer-group-item-html-html';
import { adminPutCustomerGroupItem$Html$Json } from '../fn/customer-group/admin-put-customer-group-item-html-json';
import { AdminPutCustomerGroupItem$Html$Json$Params } from '../fn/customer-group/admin-put-customer-group-item-html-json';
import { adminPutCustomerGroupItem$Json$Html } from '../fn/customer-group/admin-put-customer-group-item-json-html';
import { AdminPutCustomerGroupItem$Json$Html$Params } from '../fn/customer-group/admin-put-customer-group-item-json-html';
import { adminPutCustomerGroupItem$Json$Json } from '../fn/customer-group/admin-put-customer-group-item-json-json';
import { AdminPutCustomerGroupItem$Json$Json$Params } from '../fn/customer-group/admin-put-customer-group-item-json-json';
import { Index as CustomerGroupAdminCustomerGroupIndexSyliusAdminCustomerGroupIndex } from '../models/CustomerGroup-admin/customer_group/index_sylius/admin/customer_group/index';
import { Show as CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow } from '../models/CustomerGroup-admin/customer_group/show_sylius/admin/customer_group/show';

@Injectable({ providedIn: 'root' })
export class CustomerGroupService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetCustomerGroupCollection()` */
  static readonly AdminGetCustomerGroupCollectionPath = '/api/v2/admin/customer-groups';

  /**
   * Retrieves the collection of CustomerGroup resources.
   *
   * Retrieves the collection of CustomerGroup resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCustomerGroupCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerGroupCollection$Json$Response(params?: AdminGetCustomerGroupCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerGroupAdminCustomerGroupIndexSyliusAdminCustomerGroupIndex>>> {
    return adminGetCustomerGroupCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of CustomerGroup resources.
   *
   * Retrieves the collection of CustomerGroup resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCustomerGroupCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerGroupCollection$Json(params?: AdminGetCustomerGroupCollection$Json$Params, context?: HttpContext): Observable<Array<CustomerGroupAdminCustomerGroupIndexSyliusAdminCustomerGroupIndex>> {
    return this.adminGetCustomerGroupCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerGroupAdminCustomerGroupIndexSyliusAdminCustomerGroupIndex>>): Array<CustomerGroupAdminCustomerGroupIndexSyliusAdminCustomerGroupIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of CustomerGroup resources.
   *
   * Retrieves the collection of CustomerGroup resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCustomerGroupCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerGroupCollection$Html$Response(params?: AdminGetCustomerGroupCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerGroupAdminCustomerGroupIndexSyliusAdminCustomerGroupIndex>>> {
    return adminGetCustomerGroupCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of CustomerGroup resources.
   *
   * Retrieves the collection of CustomerGroup resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCustomerGroupCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerGroupCollection$Html(params?: AdminGetCustomerGroupCollection$Html$Params, context?: HttpContext): Observable<Array<CustomerGroupAdminCustomerGroupIndexSyliusAdminCustomerGroupIndex>> {
    return this.adminGetCustomerGroupCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerGroupAdminCustomerGroupIndexSyliusAdminCustomerGroupIndex>>): Array<CustomerGroupAdminCustomerGroupIndexSyliusAdminCustomerGroupIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostCustomerGroupCollection()` */
  static readonly AdminPostCustomerGroupCollectionPath = '/api/v2/admin/customer-groups';

  /**
   * Creates a CustomerGroup resource.
   *
   * Creates a CustomerGroup resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCustomerGroupCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCustomerGroupCollection$Json$Json$Response(params: AdminPostCustomerGroupCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>> {
    return adminPostCustomerGroupCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a CustomerGroup resource.
   *
   * Creates a CustomerGroup resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCustomerGroupCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCustomerGroupCollection$Json$Json(params: AdminPostCustomerGroupCollection$Json$Json$Params, context?: HttpContext): Observable<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow> {
    return this.adminPostCustomerGroupCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>): CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow => r.body)
    );
  }

  /**
   * Creates a CustomerGroup resource.
   *
   * Creates a CustomerGroup resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCustomerGroupCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCustomerGroupCollection$Json$Html$Response(params: AdminPostCustomerGroupCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>> {
    return adminPostCustomerGroupCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a CustomerGroup resource.
   *
   * Creates a CustomerGroup resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCustomerGroupCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCustomerGroupCollection$Json$Html(params: AdminPostCustomerGroupCollection$Json$Html$Params, context?: HttpContext): Observable<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow> {
    return this.adminPostCustomerGroupCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>): CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow => r.body)
    );
  }

  /**
   * Creates a CustomerGroup resource.
   *
   * Creates a CustomerGroup resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCustomerGroupCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCustomerGroupCollection$Html$Json$Response(params: AdminPostCustomerGroupCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>> {
    return adminPostCustomerGroupCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a CustomerGroup resource.
   *
   * Creates a CustomerGroup resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCustomerGroupCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCustomerGroupCollection$Html$Json(params: AdminPostCustomerGroupCollection$Html$Json$Params, context?: HttpContext): Observable<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow> {
    return this.adminPostCustomerGroupCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>): CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow => r.body)
    );
  }

  /**
   * Creates a CustomerGroup resource.
   *
   * Creates a CustomerGroup resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCustomerGroupCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCustomerGroupCollection$Html$Html$Response(params: AdminPostCustomerGroupCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>> {
    return adminPostCustomerGroupCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a CustomerGroup resource.
   *
   * Creates a CustomerGroup resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCustomerGroupCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCustomerGroupCollection$Html$Html(params: AdminPostCustomerGroupCollection$Html$Html$Params, context?: HttpContext): Observable<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow> {
    return this.adminPostCustomerGroupCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>): CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow => r.body)
    );
  }

  /** Path part for operation `adminGetCustomerGroupItem()` */
  static readonly AdminGetCustomerGroupItemPath = '/api/v2/admin/customer-groups/{code}';

  /**
   * Retrieves a CustomerGroup resource.
   *
   * Retrieves a CustomerGroup resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCustomerGroupItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerGroupItem$Json$Response(params: AdminGetCustomerGroupItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>> {
    return adminGetCustomerGroupItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a CustomerGroup resource.
   *
   * Retrieves a CustomerGroup resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCustomerGroupItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerGroupItem$Json(params: AdminGetCustomerGroupItem$Json$Params, context?: HttpContext): Observable<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow> {
    return this.adminGetCustomerGroupItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>): CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow => r.body)
    );
  }

  /**
   * Retrieves a CustomerGroup resource.
   *
   * Retrieves a CustomerGroup resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCustomerGroupItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerGroupItem$Html$Response(params: AdminGetCustomerGroupItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>> {
    return adminGetCustomerGroupItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a CustomerGroup resource.
   *
   * Retrieves a CustomerGroup resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCustomerGroupItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerGroupItem$Html(params: AdminGetCustomerGroupItem$Html$Params, context?: HttpContext): Observable<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow> {
    return this.adminGetCustomerGroupItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>): CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow => r.body)
    );
  }

  /** Path part for operation `adminPutCustomerGroupItem()` */
  static readonly AdminPutCustomerGroupItemPath = '/api/v2/admin/customer-groups/{code}';

  /**
   * Replaces the CustomerGroup resource.
   *
   * Replaces the CustomerGroup resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCustomerGroupItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCustomerGroupItem$Json$Json$Response(params: AdminPutCustomerGroupItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>> {
    return adminPutCustomerGroupItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the CustomerGroup resource.
   *
   * Replaces the CustomerGroup resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCustomerGroupItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCustomerGroupItem$Json$Json(params: AdminPutCustomerGroupItem$Json$Json$Params, context?: HttpContext): Observable<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow> {
    return this.adminPutCustomerGroupItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>): CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow => r.body)
    );
  }

  /**
   * Replaces the CustomerGroup resource.
   *
   * Replaces the CustomerGroup resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCustomerGroupItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCustomerGroupItem$Json$Html$Response(params: AdminPutCustomerGroupItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>> {
    return adminPutCustomerGroupItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the CustomerGroup resource.
   *
   * Replaces the CustomerGroup resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCustomerGroupItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCustomerGroupItem$Json$Html(params: AdminPutCustomerGroupItem$Json$Html$Params, context?: HttpContext): Observable<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow> {
    return this.adminPutCustomerGroupItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>): CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow => r.body)
    );
  }

  /**
   * Replaces the CustomerGroup resource.
   *
   * Replaces the CustomerGroup resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCustomerGroupItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCustomerGroupItem$Html$Json$Response(params: AdminPutCustomerGroupItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>> {
    return adminPutCustomerGroupItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the CustomerGroup resource.
   *
   * Replaces the CustomerGroup resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCustomerGroupItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCustomerGroupItem$Html$Json(params: AdminPutCustomerGroupItem$Html$Json$Params, context?: HttpContext): Observable<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow> {
    return this.adminPutCustomerGroupItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>): CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow => r.body)
    );
  }

  /**
   * Replaces the CustomerGroup resource.
   *
   * Replaces the CustomerGroup resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCustomerGroupItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCustomerGroupItem$Html$Html$Response(params: AdminPutCustomerGroupItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>> {
    return adminPutCustomerGroupItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the CustomerGroup resource.
   *
   * Replaces the CustomerGroup resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCustomerGroupItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCustomerGroupItem$Html$Html(params: AdminPutCustomerGroupItem$Html$Html$Params, context?: HttpContext): Observable<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow> {
    return this.adminPutCustomerGroupItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow>): CustomerGroupAdminCustomerGroupShowSyliusAdminCustomerGroupShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteCustomerGroupItem()` */
  static readonly AdminDeleteCustomerGroupItemPath = '/api/v2/admin/customer-groups/{code}';

  /**
   * Removes the CustomerGroup resource.
   *
   * Removes the CustomerGroup resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteCustomerGroupItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteCustomerGroupItem$Response(params: AdminDeleteCustomerGroupItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteCustomerGroupItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the CustomerGroup resource.
   *
   * Removes the CustomerGroup resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteCustomerGroupItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteCustomerGroupItem(params: AdminDeleteCustomerGroupItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteCustomerGroupItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Index as CurrencyAdminCurrencyIndexSyliusAdminCurrencyIndex } from '../../models/Currency-admin/currency/index_sylius/admin/currency/index';

export interface AdminGetCurrencyCollection$Html$Params {

/**
 * The collection page number
 */
  page?: number;

/**
 * The number of items per page
 */
  itemsPerPage?: number;
}

export function adminGetCurrencyCollection$Html(http: HttpClient, rootUrl: string, params?: AdminGetCurrencyCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CurrencyAdminCurrencyIndexSyliusAdminCurrencyIndex>>> {
  const rb = new RequestBuilder(rootUrl, adminGetCurrencyCollection$Html.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/html', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<CurrencyAdminCurrencyIndexSyliusAdminCurrencyIndex>>;
    })
  );
}

adminGetCurrencyCollection$Html.PATH = '/api/v2/admin/currencies';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteProductAttributeItem } from '../fn/product-attribute/admin-delete-product-attribute-item';
import { AdminDeleteProductAttributeItem$Params } from '../fn/product-attribute/admin-delete-product-attribute-item';
import { adminGetProductAttributeCollection$Html } from '../fn/product-attribute/admin-get-product-attribute-collection-html';
import { AdminGetProductAttributeCollection$Html$Params } from '../fn/product-attribute/admin-get-product-attribute-collection-html';
import { adminGetProductAttributeCollection$Json } from '../fn/product-attribute/admin-get-product-attribute-collection-json';
import { AdminGetProductAttributeCollection$Json$Params } from '../fn/product-attribute/admin-get-product-attribute-collection-json';
import { adminGetProductAttributeItem$Html } from '../fn/product-attribute/admin-get-product-attribute-item-html';
import { AdminGetProductAttributeItem$Html$Params } from '../fn/product-attribute/admin-get-product-attribute-item-html';
import { adminGetProductAttributeItem$Json } from '../fn/product-attribute/admin-get-product-attribute-item-json';
import { AdminGetProductAttributeItem$Json$Params } from '../fn/product-attribute/admin-get-product-attribute-item-json';
import { adminPostProductAttributeCollection$Html$Html } from '../fn/product-attribute/admin-post-product-attribute-collection-html-html';
import { AdminPostProductAttributeCollection$Html$Html$Params } from '../fn/product-attribute/admin-post-product-attribute-collection-html-html';
import { adminPostProductAttributeCollection$Html$Json } from '../fn/product-attribute/admin-post-product-attribute-collection-html-json';
import { AdminPostProductAttributeCollection$Html$Json$Params } from '../fn/product-attribute/admin-post-product-attribute-collection-html-json';
import { adminPostProductAttributeCollection$Json$Html } from '../fn/product-attribute/admin-post-product-attribute-collection-json-html';
import { AdminPostProductAttributeCollection$Json$Html$Params } from '../fn/product-attribute/admin-post-product-attribute-collection-json-html';
import { adminPostProductAttributeCollection$Json$Json } from '../fn/product-attribute/admin-post-product-attribute-collection-json-json';
import { AdminPostProductAttributeCollection$Json$Json$Params } from '../fn/product-attribute/admin-post-product-attribute-collection-json-json';
import { adminPutProductAttributeItem$Html$Html } from '../fn/product-attribute/admin-put-product-attribute-item-html-html';
import { AdminPutProductAttributeItem$Html$Html$Params } from '../fn/product-attribute/admin-put-product-attribute-item-html-html';
import { adminPutProductAttributeItem$Html$Json } from '../fn/product-attribute/admin-put-product-attribute-item-html-json';
import { AdminPutProductAttributeItem$Html$Json$Params } from '../fn/product-attribute/admin-put-product-attribute-item-html-json';
import { adminPutProductAttributeItem$Json$Html } from '../fn/product-attribute/admin-put-product-attribute-item-json-html';
import { AdminPutProductAttributeItem$Json$Html$Params } from '../fn/product-attribute/admin-put-product-attribute-item-json-html';
import { adminPutProductAttributeItem$Json$Json } from '../fn/product-attribute/admin-put-product-attribute-item-json-json';
import { AdminPutProductAttributeItem$Json$Json$Params } from '../fn/product-attribute/admin-put-product-attribute-item-json-json';
import { Index as ProductAttributeAdminProductAttributeIndexSyliusAdminProductAttributeIndex } from '../models/ProductAttribute-admin/product_attribute/index_sylius/admin/product_attribute/index';
import { Show as ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow } from '../models/ProductAttribute-admin/product_attribute/show_sylius/admin/product_attribute/show';
import { Show as ProductAttributeShopProductAttributeShowSyliusShopProductAttributeShow } from '../models/ProductAttribute-shop/product_attribute/show_sylius/shop/product_attribute/show';
import { shopGetProductAttributeItem$Html } from '../fn/product-attribute/shop-get-product-attribute-item-html';
import { ShopGetProductAttributeItem$Html$Params } from '../fn/product-attribute/shop-get-product-attribute-item-html';
import { shopGetProductAttributeItem$Json } from '../fn/product-attribute/shop-get-product-attribute-item-json';
import { ShopGetProductAttributeItem$Json$Params } from '../fn/product-attribute/shop-get-product-attribute-item-json';

@Injectable({ providedIn: 'root' })
export class ProductAttributeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetProductAttributeCollection()` */
  static readonly AdminGetProductAttributeCollectionPath = '/api/v2/admin/product-attributes';

  /**
   * Retrieves the collection of ProductAttribute resources.
   *
   * Retrieves the collection of ProductAttribute resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAttributeCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeCollection$Json$Response(params?: AdminGetProductAttributeCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductAttributeAdminProductAttributeIndexSyliusAdminProductAttributeIndex>>> {
    return adminGetProductAttributeCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductAttribute resources.
   *
   * Retrieves the collection of ProductAttribute resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAttributeCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeCollection$Json(params?: AdminGetProductAttributeCollection$Json$Params, context?: HttpContext): Observable<Array<ProductAttributeAdminProductAttributeIndexSyliusAdminProductAttributeIndex>> {
    return this.adminGetProductAttributeCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductAttributeAdminProductAttributeIndexSyliusAdminProductAttributeIndex>>): Array<ProductAttributeAdminProductAttributeIndexSyliusAdminProductAttributeIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ProductAttribute resources.
   *
   * Retrieves the collection of ProductAttribute resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAttributeCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeCollection$Html$Response(params?: AdminGetProductAttributeCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductAttributeAdminProductAttributeIndexSyliusAdminProductAttributeIndex>>> {
    return adminGetProductAttributeCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductAttribute resources.
   *
   * Retrieves the collection of ProductAttribute resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAttributeCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeCollection$Html(params?: AdminGetProductAttributeCollection$Html$Params, context?: HttpContext): Observable<Array<ProductAttributeAdminProductAttributeIndexSyliusAdminProductAttributeIndex>> {
    return this.adminGetProductAttributeCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductAttributeAdminProductAttributeIndexSyliusAdminProductAttributeIndex>>): Array<ProductAttributeAdminProductAttributeIndexSyliusAdminProductAttributeIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostProductAttributeCollection()` */
  static readonly AdminPostProductAttributeCollectionPath = '/api/v2/admin/product-attributes';

  /**
   * Creates a ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductAttributeCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductAttributeCollection$Json$Json$Response(params: AdminPostProductAttributeCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>> {
    return adminPostProductAttributeCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductAttributeCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductAttributeCollection$Json$Json(params: AdminPostProductAttributeCollection$Json$Json$Params, context?: HttpContext): Observable<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow> {
    return this.adminPostProductAttributeCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>): ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow => r.body)
    );
  }

  /**
   * Creates a ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductAttributeCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductAttributeCollection$Json$Html$Response(params: AdminPostProductAttributeCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>> {
    return adminPostProductAttributeCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductAttributeCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductAttributeCollection$Json$Html(params: AdminPostProductAttributeCollection$Json$Html$Params, context?: HttpContext): Observable<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow> {
    return this.adminPostProductAttributeCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>): ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow => r.body)
    );
  }

  /**
   * Creates a ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductAttributeCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductAttributeCollection$Html$Json$Response(params: AdminPostProductAttributeCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>> {
    return adminPostProductAttributeCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductAttributeCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductAttributeCollection$Html$Json(params: AdminPostProductAttributeCollection$Html$Json$Params, context?: HttpContext): Observable<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow> {
    return this.adminPostProductAttributeCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>): ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow => r.body)
    );
  }

  /**
   * Creates a ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductAttributeCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductAttributeCollection$Html$Html$Response(params: AdminPostProductAttributeCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>> {
    return adminPostProductAttributeCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductAttributeCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductAttributeCollection$Html$Html(params: AdminPostProductAttributeCollection$Html$Html$Params, context?: HttpContext): Observable<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow> {
    return this.adminPostProductAttributeCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>): ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow => r.body)
    );
  }

  /** Path part for operation `adminGetProductAttributeItem()` */
  static readonly AdminGetProductAttributeItemPath = '/api/v2/admin/product-attributes/{code}';

  /**
   * Retrieves a ProductAttribute resource.
   *
   * Retrieves a ProductAttribute resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAttributeItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeItem$Json$Response(params: AdminGetProductAttributeItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>> {
    return adminGetProductAttributeItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAttribute resource.
   *
   * Retrieves a ProductAttribute resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAttributeItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeItem$Json(params: AdminGetProductAttributeItem$Json$Params, context?: HttpContext): Observable<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow> {
    return this.adminGetProductAttributeItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>): ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow => r.body)
    );
  }

  /**
   * Retrieves a ProductAttribute resource.
   *
   * Retrieves a ProductAttribute resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAttributeItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeItem$Html$Response(params: AdminGetProductAttributeItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>> {
    return adminGetProductAttributeItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAttribute resource.
   *
   * Retrieves a ProductAttribute resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAttributeItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAttributeItem$Html(params: AdminGetProductAttributeItem$Html$Params, context?: HttpContext): Observable<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow> {
    return this.adminGetProductAttributeItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>): ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow => r.body)
    );
  }

  /** Path part for operation `adminPutProductAttributeItem()` */
  static readonly AdminPutProductAttributeItemPath = '/api/v2/admin/product-attributes/{code}';

  /**
   * Replaces the ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductAttributeItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductAttributeItem$Json$Json$Response(params: AdminPutProductAttributeItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>> {
    return adminPutProductAttributeItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductAttributeItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductAttributeItem$Json$Json(params: AdminPutProductAttributeItem$Json$Json$Params, context?: HttpContext): Observable<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow> {
    return this.adminPutProductAttributeItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>): ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow => r.body)
    );
  }

  /**
   * Replaces the ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductAttributeItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductAttributeItem$Json$Html$Response(params: AdminPutProductAttributeItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>> {
    return adminPutProductAttributeItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductAttributeItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductAttributeItem$Json$Html(params: AdminPutProductAttributeItem$Json$Html$Params, context?: HttpContext): Observable<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow> {
    return this.adminPutProductAttributeItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>): ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow => r.body)
    );
  }

  /**
   * Replaces the ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductAttributeItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductAttributeItem$Html$Json$Response(params: AdminPutProductAttributeItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>> {
    return adminPutProductAttributeItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductAttributeItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductAttributeItem$Html$Json(params: AdminPutProductAttributeItem$Html$Json$Params, context?: HttpContext): Observable<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow> {
    return this.adminPutProductAttributeItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>): ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow => r.body)
    );
  }

  /**
   * Replaces the ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductAttributeItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductAttributeItem$Html$Html$Response(params: AdminPutProductAttributeItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>> {
    return adminPutProductAttributeItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductAttribute resource.
   *
   * Example configuration for a `text` type attribute:
   *
   * ```
   * {
   *     "type": "text",
   *     "configuration": {
   *         "min": 5,
   *         "max": 255
   *     }
   * }
   * ```
   *
   * Example configuration for a `datetime` type attribute:
   *
   * ```
   * {
   *     "type": "datetime",
   *     "configuration": {
   *         "format": "Y-m-d H:i:s"
   *     }
   * }
   * ```
   *
   * Example configuration for a `date` type attribute:
   *
   * ```
   * {
   *     "type": "date",
   *     "configuration": {
   *         "format": "Y-m-d"
   *     }
   * }
   * ```
   *
   * Example configuration for a `select` type attribute:
   *
   * ```
   * {
   *     "type": "select",
   *     "configuration": {
   *         "choices": {
   *             "0afb212e-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Plastic",
   *                 "fr_FR": "Plastique"
   *             },
   *             "3bfb211f-cd08-11ec-871e-0242ac120005": {
   *                 "en_US": "Cotton",
   *                 "fr_FR": "Coton"
   *             }
   *         },
   *         "multiple": true,
   *         "min": 1,
   *         "max": 3
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductAttributeItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductAttributeItem$Html$Html(params: AdminPutProductAttributeItem$Html$Html$Params, context?: HttpContext): Observable<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow> {
    return this.adminPutProductAttributeItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow>): ProductAttributeAdminProductAttributeShowSyliusAdminProductAttributeShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteProductAttributeItem()` */
  static readonly AdminDeleteProductAttributeItemPath = '/api/v2/admin/product-attributes/{code}';

  /**
   * Removes the ProductAttribute resource.
   *
   * Removes the ProductAttribute resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteProductAttributeItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductAttributeItem$Response(params: AdminDeleteProductAttributeItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteProductAttributeItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the ProductAttribute resource.
   *
   * Removes the ProductAttribute resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteProductAttributeItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductAttributeItem(params: AdminDeleteProductAttributeItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteProductAttributeItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `shopGetProductAttributeItem()` */
  static readonly ShopGetProductAttributeItemPath = '/api/v2/shop/product-attributes/{code}';

  /**
   * Retrieves a ProductAttribute resource.
   *
   * Retrieves a ProductAttribute resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductAttributeItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAttributeItem$Json$Response(params: ShopGetProductAttributeItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeShopProductAttributeShowSyliusShopProductAttributeShow>> {
    return shopGetProductAttributeItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAttribute resource.
   *
   * Retrieves a ProductAttribute resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductAttributeItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAttributeItem$Json(params: ShopGetProductAttributeItem$Json$Params, context?: HttpContext): Observable<ProductAttributeShopProductAttributeShowSyliusShopProductAttributeShow> {
    return this.shopGetProductAttributeItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeShopProductAttributeShowSyliusShopProductAttributeShow>): ProductAttributeShopProductAttributeShowSyliusShopProductAttributeShow => r.body)
    );
  }

  /**
   * Retrieves a ProductAttribute resource.
   *
   * Retrieves a ProductAttribute resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductAttributeItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAttributeItem$Html$Response(params: ShopGetProductAttributeItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAttributeShopProductAttributeShowSyliusShopProductAttributeShow>> {
    return shopGetProductAttributeItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAttribute resource.
   *
   * Retrieves a ProductAttribute resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductAttributeItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductAttributeItem$Html(params: ShopGetProductAttributeItem$Html$Params, context?: HttpContext): Observable<ProductAttributeShopProductAttributeShowSyliusShopProductAttributeShow> {
    return this.shopGetProductAttributeItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAttributeShopProductAttributeShowSyliusShopProductAttributeShow>): ProductAttributeShopProductAttributeShowSyliusShopProductAttributeShow => r.body)
    );
  }

}

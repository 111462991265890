/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Show as AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow } from '../../models/AvatarImage-admin/avatar_image/show_sylius/admin/avatar_image/show';

export interface AdminGetAvatarImageItem$Html$Params {

/**
 * Provide one of supported image liip imagine filters
 */
  imageFilter?: 'sylius_original' | 'sylius_small' | 'sylius_medium' | 'sylius_large' | 'sylius_admin_product_original' | 'sylius_admin_admin_user_avatar_thumbnail' | 'sylius_admin_product_tiny_thumbnail' | 'sylius_admin_product_thumbnail' | 'sylius_admin_product_small_thumbnail' | 'sylius_admin_product_large_thumbnail' | 'sylius_shop_product_original' | 'sylius_shop_product_tiny_thumbnail' | 'sylius_shop_product_small_thumbnail' | 'sylius_shop_product_thumbnail' | 'sylius_shop_product_large_thumbnail';

/**
 * Resource identifier
 */
  id: string;
}

export function adminGetAvatarImageItem$Html(http: HttpClient, rootUrl: string, params: AdminGetAvatarImageItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow>> {
  const rb = new RequestBuilder(rootUrl, adminGetAvatarImageItem$Html.PATH, 'get');
  if (params) {
    rb.query('imageFilter', params.imageFilter, {"style":"form","explode":false});
    rb.path('id', params.id, {"style":"simple","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/html', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow>;
    })
  );
}

adminGetAvatarImageItem$Html.PATH = '/api/v2/admin/avatar-images/{id}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetProvinceItem$Html } from '../fn/province/admin-get-province-item-html';
import { AdminGetProvinceItem$Html$Params } from '../fn/province/admin-get-province-item-html';
import { adminGetProvinceItem$Json } from '../fn/province/admin-get-province-item-json';
import { AdminGetProvinceItem$Json$Params } from '../fn/province/admin-get-province-item-json';
import { adminPutProvinceItem$Html$Html } from '../fn/province/admin-put-province-item-html-html';
import { AdminPutProvinceItem$Html$Html$Params } from '../fn/province/admin-put-province-item-html-html';
import { adminPutProvinceItem$Html$Json } from '../fn/province/admin-put-province-item-html-json';
import { AdminPutProvinceItem$Html$Json$Params } from '../fn/province/admin-put-province-item-html-json';
import { adminPutProvinceItem$Json$Html } from '../fn/province/admin-put-province-item-json-html';
import { AdminPutProvinceItem$Json$Html$Params } from '../fn/province/admin-put-province-item-json-html';
import { adminPutProvinceItem$Json$Json } from '../fn/province/admin-put-province-item-json-json';
import { AdminPutProvinceItem$Json$Json$Params } from '../fn/province/admin-put-province-item-json-json';
import { apiCountriesProvincesGetSubresourceCountrySubresource$Html } from '../fn/province/api-countries-provinces-get-subresource-country-subresource-html';
import { ApiCountriesProvincesGetSubresourceCountrySubresource$Html$Params } from '../fn/province/api-countries-provinces-get-subresource-country-subresource-html';
import { apiCountriesProvincesGetSubresourceCountrySubresource$Json } from '../fn/province/api-countries-provinces-get-subresource-country-subresource-json';
import { ApiCountriesProvincesGetSubresourceCountrySubresource$Json$Params } from '../fn/province/api-countries-provinces-get-subresource-country-subresource-json';
import { Show as ProvinceAdminProvinceShowSyliusAdminProvinceShow } from '../models/Province-admin/province/show_sylius/admin/province/show';
import { Show as ProvinceShopProvinceShowSyliusShopProvinceShow } from '../models/Province-shop/province/show_sylius/shop/province/show';
import { shopGetProvinceItem$Html } from '../fn/province/shop-get-province-item-html';
import { ShopGetProvinceItem$Html$Params } from '../fn/province/shop-get-province-item-html';
import { shopGetProvinceItem$Json } from '../fn/province/shop-get-province-item-json';
import { ShopGetProvinceItem$Json$Params } from '../fn/province/shop-get-province-item-json';

@Injectable({ providedIn: 'root' })
export class ProvinceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiCountriesProvincesGetSubresourceCountrySubresource()` */
  static readonly ApiCountriesProvincesGetSubresourceCountrySubresourcePath = '/api/v2/admin/countries/{code}/provinces';

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCountriesProvincesGetSubresourceCountrySubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCountriesProvincesGetSubresourceCountrySubresource$Json$Response(params: ApiCountriesProvincesGetSubresourceCountrySubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow>>> {
    return apiCountriesProvincesGetSubresourceCountrySubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCountriesProvincesGetSubresourceCountrySubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCountriesProvincesGetSubresourceCountrySubresource$Json(params: ApiCountriesProvincesGetSubresourceCountrySubresource$Json$Params, context?: HttpContext): Observable<Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow>> {
    return this.apiCountriesProvincesGetSubresourceCountrySubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow>>): Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow> => r.body)
    );
  }

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCountriesProvincesGetSubresourceCountrySubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCountriesProvincesGetSubresourceCountrySubresource$Html$Response(params: ApiCountriesProvincesGetSubresourceCountrySubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow>>> {
    return apiCountriesProvincesGetSubresourceCountrySubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCountriesProvincesGetSubresourceCountrySubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCountriesProvincesGetSubresourceCountrySubresource$Html(params: ApiCountriesProvincesGetSubresourceCountrySubresource$Html$Params, context?: HttpContext): Observable<Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow>> {
    return this.apiCountriesProvincesGetSubresourceCountrySubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow>>): Array<ProvinceAdminProvinceShowSyliusAdminProvinceShow> => r.body)
    );
  }

  /** Path part for operation `adminGetProvinceItem()` */
  static readonly AdminGetProvinceItemPath = '/api/v2/admin/provinces/{code}';

  /**
   * Retrieves a Province resource.
   *
   * Retrieves a Province resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProvinceItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProvinceItem$Json$Response(params: AdminGetProvinceItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProvinceAdminProvinceShowSyliusAdminProvinceShow>> {
    return adminGetProvinceItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Province resource.
   *
   * Retrieves a Province resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProvinceItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProvinceItem$Json(params: AdminGetProvinceItem$Json$Params, context?: HttpContext): Observable<ProvinceAdminProvinceShowSyliusAdminProvinceShow> {
    return this.adminGetProvinceItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProvinceAdminProvinceShowSyliusAdminProvinceShow>): ProvinceAdminProvinceShowSyliusAdminProvinceShow => r.body)
    );
  }

  /**
   * Retrieves a Province resource.
   *
   * Retrieves a Province resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProvinceItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProvinceItem$Html$Response(params: AdminGetProvinceItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProvinceAdminProvinceShowSyliusAdminProvinceShow>> {
    return adminGetProvinceItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Province resource.
   *
   * Retrieves a Province resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProvinceItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProvinceItem$Html(params: AdminGetProvinceItem$Html$Params, context?: HttpContext): Observable<ProvinceAdminProvinceShowSyliusAdminProvinceShow> {
    return this.adminGetProvinceItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProvinceAdminProvinceShowSyliusAdminProvinceShow>): ProvinceAdminProvinceShowSyliusAdminProvinceShow => r.body)
    );
  }

  /** Path part for operation `adminPutProvinceItem()` */
  static readonly AdminPutProvinceItemPath = '/api/v2/admin/provinces/{code}';

  /**
   * Replaces the Province resource.
   *
   * Replaces the Province resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProvinceItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProvinceItem$Json$Json$Response(params: AdminPutProvinceItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProvinceAdminProvinceShowSyliusAdminProvinceShow>> {
    return adminPutProvinceItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Province resource.
   *
   * Replaces the Province resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProvinceItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProvinceItem$Json$Json(params: AdminPutProvinceItem$Json$Json$Params, context?: HttpContext): Observable<ProvinceAdminProvinceShowSyliusAdminProvinceShow> {
    return this.adminPutProvinceItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProvinceAdminProvinceShowSyliusAdminProvinceShow>): ProvinceAdminProvinceShowSyliusAdminProvinceShow => r.body)
    );
  }

  /**
   * Replaces the Province resource.
   *
   * Replaces the Province resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProvinceItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProvinceItem$Json$Html$Response(params: AdminPutProvinceItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProvinceAdminProvinceShowSyliusAdminProvinceShow>> {
    return adminPutProvinceItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Province resource.
   *
   * Replaces the Province resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProvinceItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProvinceItem$Json$Html(params: AdminPutProvinceItem$Json$Html$Params, context?: HttpContext): Observable<ProvinceAdminProvinceShowSyliusAdminProvinceShow> {
    return this.adminPutProvinceItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProvinceAdminProvinceShowSyliusAdminProvinceShow>): ProvinceAdminProvinceShowSyliusAdminProvinceShow => r.body)
    );
  }

  /**
   * Replaces the Province resource.
   *
   * Replaces the Province resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProvinceItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProvinceItem$Html$Json$Response(params: AdminPutProvinceItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProvinceAdminProvinceShowSyliusAdminProvinceShow>> {
    return adminPutProvinceItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Province resource.
   *
   * Replaces the Province resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProvinceItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProvinceItem$Html$Json(params: AdminPutProvinceItem$Html$Json$Params, context?: HttpContext): Observable<ProvinceAdminProvinceShowSyliusAdminProvinceShow> {
    return this.adminPutProvinceItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProvinceAdminProvinceShowSyliusAdminProvinceShow>): ProvinceAdminProvinceShowSyliusAdminProvinceShow => r.body)
    );
  }

  /**
   * Replaces the Province resource.
   *
   * Replaces the Province resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProvinceItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProvinceItem$Html$Html$Response(params: AdminPutProvinceItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProvinceAdminProvinceShowSyliusAdminProvinceShow>> {
    return adminPutProvinceItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Province resource.
   *
   * Replaces the Province resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProvinceItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProvinceItem$Html$Html(params: AdminPutProvinceItem$Html$Html$Params, context?: HttpContext): Observable<ProvinceAdminProvinceShowSyliusAdminProvinceShow> {
    return this.adminPutProvinceItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProvinceAdminProvinceShowSyliusAdminProvinceShow>): ProvinceAdminProvinceShowSyliusAdminProvinceShow => r.body)
    );
  }

  /** Path part for operation `shopGetProvinceItem()` */
  static readonly ShopGetProvinceItemPath = '/api/v2/shop/provinces/{code}';

  /**
   * Retrieves a Province resource.
   *
   * Retrieves a Province resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProvinceItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProvinceItem$Json$Response(params: ShopGetProvinceItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProvinceShopProvinceShowSyliusShopProvinceShow>> {
    return shopGetProvinceItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Province resource.
   *
   * Retrieves a Province resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProvinceItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProvinceItem$Json(params: ShopGetProvinceItem$Json$Params, context?: HttpContext): Observable<ProvinceShopProvinceShowSyliusShopProvinceShow> {
    return this.shopGetProvinceItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProvinceShopProvinceShowSyliusShopProvinceShow>): ProvinceShopProvinceShowSyliusShopProvinceShow => r.body)
    );
  }

  /**
   * Retrieves a Province resource.
   *
   * Retrieves a Province resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProvinceItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProvinceItem$Html$Response(params: ShopGetProvinceItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProvinceShopProvinceShowSyliusShopProvinceShow>> {
    return shopGetProvinceItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Province resource.
   *
   * Retrieves a Province resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProvinceItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProvinceItem$Html(params: ShopGetProvinceItem$Html$Params, context?: HttpContext): Observable<ProvinceShopProvinceShowSyliusShopProvinceShow> {
    return this.shopGetProvinceItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProvinceShopProvinceShowSyliusShopProvinceShow>): ProvinceShopProvinceShowSyliusShopProvinceShow => r.body)
    );
  }

}

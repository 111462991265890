/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteTaxonItem } from '../fn/taxon/admin-delete-taxon-item';
import { AdminDeleteTaxonItem$Params } from '../fn/taxon/admin-delete-taxon-item';
import { adminGetTaxonCollection$Html } from '../fn/taxon/admin-get-taxon-collection-html';
import { AdminGetTaxonCollection$Html$Params } from '../fn/taxon/admin-get-taxon-collection-html';
import { adminGetTaxonCollection$Json } from '../fn/taxon/admin-get-taxon-collection-json';
import { AdminGetTaxonCollection$Json$Params } from '../fn/taxon/admin-get-taxon-collection-json';
import { adminGetTaxonItem$Html } from '../fn/taxon/admin-get-taxon-item-html';
import { AdminGetTaxonItem$Html$Params } from '../fn/taxon/admin-get-taxon-item-html';
import { adminGetTaxonItem$Json } from '../fn/taxon/admin-get-taxon-item-json';
import { AdminGetTaxonItem$Json$Params } from '../fn/taxon/admin-get-taxon-item-json';
import { adminPostTaxonCollection$Html$Html } from '../fn/taxon/admin-post-taxon-collection-html-html';
import { AdminPostTaxonCollection$Html$Html$Params } from '../fn/taxon/admin-post-taxon-collection-html-html';
import { adminPostTaxonCollection$Html$Json } from '../fn/taxon/admin-post-taxon-collection-html-json';
import { AdminPostTaxonCollection$Html$Json$Params } from '../fn/taxon/admin-post-taxon-collection-html-json';
import { adminPostTaxonCollection$Json$Html } from '../fn/taxon/admin-post-taxon-collection-json-html';
import { AdminPostTaxonCollection$Json$Html$Params } from '../fn/taxon/admin-post-taxon-collection-json-html';
import { adminPostTaxonCollection$Json$Json } from '../fn/taxon/admin-post-taxon-collection-json-json';
import { AdminPostTaxonCollection$Json$Json$Params } from '../fn/taxon/admin-post-taxon-collection-json-json';
import { adminPutTaxonItem$Html$Html } from '../fn/taxon/admin-put-taxon-item-html-html';
import { AdminPutTaxonItem$Html$Html$Params } from '../fn/taxon/admin-put-taxon-item-html-html';
import { adminPutTaxonItem$Html$Json } from '../fn/taxon/admin-put-taxon-item-html-json';
import { AdminPutTaxonItem$Html$Json$Params } from '../fn/taxon/admin-put-taxon-item-html-json';
import { adminPutTaxonItem$Json$Html } from '../fn/taxon/admin-put-taxon-item-json-html';
import { AdminPutTaxonItem$Json$Html$Params } from '../fn/taxon/admin-put-taxon-item-json-html';
import { adminPutTaxonItem$Json$Json } from '../fn/taxon/admin-put-taxon-item-json-json';
import { AdminPutTaxonItem$Json$Json$Params } from '../fn/taxon/admin-put-taxon-item-json-json';
import { apiTaxaImagesGetSubresourceTaxonSubresource$Html } from '../fn/taxon-image/api-taxa-images-get-subresource-taxon-subresource-html';
import { ApiTaxaImagesGetSubresourceTaxonSubresource$Html$Params } from '../fn/taxon-image/api-taxa-images-get-subresource-taxon-subresource-html';
import { apiTaxaImagesGetSubresourceTaxonSubresource$Json } from '../fn/taxon-image/api-taxa-images-get-subresource-taxon-subresource-json';
import { ApiTaxaImagesGetSubresourceTaxonSubresource$Json$Params } from '../fn/taxon-image/api-taxa-images-get-subresource-taxon-subresource-json';
import { shopGetTaxonCollection$Html } from '../fn/taxon/shop-get-taxon-collection-html';
import { ShopGetTaxonCollection$Html$Params } from '../fn/taxon/shop-get-taxon-collection-html';
import { shopGetTaxonCollection$Json } from '../fn/taxon/shop-get-taxon-collection-json';
import { ShopGetTaxonCollection$Json$Params } from '../fn/taxon/shop-get-taxon-collection-json';
import { shopGetTaxonItem$Html } from '../fn/taxon/shop-get-taxon-item-html';
import { ShopGetTaxonItem$Html$Params } from '../fn/taxon/shop-get-taxon-item-html';
import { shopGetTaxonItem$Json } from '../fn/taxon/shop-get-taxon-item-json';
import { ShopGetTaxonItem$Json$Params } from '../fn/taxon/shop-get-taxon-item-json';
import { Index as TaxonAdminTaxonIndexSyliusAdminTaxonIndex } from '../models/Taxon-admin/taxon/index_sylius/admin/taxon/index';
import { Show as TaxonAdminTaxonShowSyliusAdminTaxonShow } from '../models/Taxon-admin/taxon/show_sylius/admin/taxon/show';
import { Index as TaxonShopTaxonIndexSyliusShopTaxonIndex } from '../models/Taxon-shop/taxon/index_sylius/shop/taxon/index';
import { Show as TaxonShopTaxonShowSyliusShopTaxonShow } from '../models/Taxon-shop/taxon/show_sylius/shop/taxon/show';
import { Show as TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow } from '../models/TaxonImage-admin/taxon_image/show_sylius/admin/taxon_image/show';

@Injectable({ providedIn: 'root' })
export class TaxonService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetTaxonCollection()` */
  static readonly AdminGetTaxonCollectionPath = '/api/v2/admin/taxons';

  /**
   * Retrieves the collection of Taxon resources.
   *
   * Retrieves the collection of Taxon resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxonCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonCollection$Json$Response(params?: AdminGetTaxonCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxonAdminTaxonIndexSyliusAdminTaxonIndex>>> {
    return adminGetTaxonCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Taxon resources.
   *
   * Retrieves the collection of Taxon resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxonCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonCollection$Json(params?: AdminGetTaxonCollection$Json$Params, context?: HttpContext): Observable<Array<TaxonAdminTaxonIndexSyliusAdminTaxonIndex>> {
    return this.adminGetTaxonCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxonAdminTaxonIndexSyliusAdminTaxonIndex>>): Array<TaxonAdminTaxonIndexSyliusAdminTaxonIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Taxon resources.
   *
   * Retrieves the collection of Taxon resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxonCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonCollection$Html$Response(params?: AdminGetTaxonCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxonAdminTaxonIndexSyliusAdminTaxonIndex>>> {
    return adminGetTaxonCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Taxon resources.
   *
   * Retrieves the collection of Taxon resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxonCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonCollection$Html(params?: AdminGetTaxonCollection$Html$Params, context?: HttpContext): Observable<Array<TaxonAdminTaxonIndexSyliusAdminTaxonIndex>> {
    return this.adminGetTaxonCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxonAdminTaxonIndexSyliusAdminTaxonIndex>>): Array<TaxonAdminTaxonIndexSyliusAdminTaxonIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostTaxonCollection()` */
  static readonly AdminPostTaxonCollectionPath = '/api/v2/admin/taxons';

  /**
   * Creates a Taxon resource.
   *
   * Creates a Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostTaxonCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostTaxonCollection$Json$Json$Response(params: AdminPostTaxonCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>> {
    return adminPostTaxonCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Taxon resource.
   *
   * Creates a Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostTaxonCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostTaxonCollection$Json$Json(params: AdminPostTaxonCollection$Json$Json$Params, context?: HttpContext): Observable<TaxonAdminTaxonShowSyliusAdminTaxonShow> {
    return this.adminPostTaxonCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>): TaxonAdminTaxonShowSyliusAdminTaxonShow => r.body)
    );
  }

  /**
   * Creates a Taxon resource.
   *
   * Creates a Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostTaxonCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostTaxonCollection$Json$Html$Response(params: AdminPostTaxonCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>> {
    return adminPostTaxonCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Taxon resource.
   *
   * Creates a Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostTaxonCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostTaxonCollection$Json$Html(params: AdminPostTaxonCollection$Json$Html$Params, context?: HttpContext): Observable<TaxonAdminTaxonShowSyliusAdminTaxonShow> {
    return this.adminPostTaxonCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>): TaxonAdminTaxonShowSyliusAdminTaxonShow => r.body)
    );
  }

  /**
   * Creates a Taxon resource.
   *
   * Creates a Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostTaxonCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostTaxonCollection$Html$Json$Response(params: AdminPostTaxonCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>> {
    return adminPostTaxonCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Taxon resource.
   *
   * Creates a Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostTaxonCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostTaxonCollection$Html$Json(params: AdminPostTaxonCollection$Html$Json$Params, context?: HttpContext): Observable<TaxonAdminTaxonShowSyliusAdminTaxonShow> {
    return this.adminPostTaxonCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>): TaxonAdminTaxonShowSyliusAdminTaxonShow => r.body)
    );
  }

  /**
   * Creates a Taxon resource.
   *
   * Creates a Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostTaxonCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostTaxonCollection$Html$Html$Response(params: AdminPostTaxonCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>> {
    return adminPostTaxonCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Taxon resource.
   *
   * Creates a Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostTaxonCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostTaxonCollection$Html$Html(params: AdminPostTaxonCollection$Html$Html$Params, context?: HttpContext): Observable<TaxonAdminTaxonShowSyliusAdminTaxonShow> {
    return this.adminPostTaxonCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>): TaxonAdminTaxonShowSyliusAdminTaxonShow => r.body)
    );
  }

  /** Path part for operation `adminGetTaxonItem()` */
  static readonly AdminGetTaxonItemPath = '/api/v2/admin/taxons/{code}';

  /**
   * Retrieves a Taxon resource.
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxonItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonItem$Json$Response(params: AdminGetTaxonItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>> {
    return adminGetTaxonItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Taxon resource.
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxonItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonItem$Json(params: AdminGetTaxonItem$Json$Params, context?: HttpContext): Observable<TaxonAdminTaxonShowSyliusAdminTaxonShow> {
    return this.adminGetTaxonItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>): TaxonAdminTaxonShowSyliusAdminTaxonShow => r.body)
    );
  }

  /**
   * Retrieves a Taxon resource.
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxonItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonItem$Html$Response(params: AdminGetTaxonItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>> {
    return adminGetTaxonItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Taxon resource.
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxonItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonItem$Html(params: AdminGetTaxonItem$Html$Params, context?: HttpContext): Observable<TaxonAdminTaxonShowSyliusAdminTaxonShow> {
    return this.adminGetTaxonItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>): TaxonAdminTaxonShowSyliusAdminTaxonShow => r.body)
    );
  }

  /** Path part for operation `adminPutTaxonItem()` */
  static readonly AdminPutTaxonItemPath = '/api/v2/admin/taxons/{code}';

  /**
   * Replaces the Taxon resource.
   *
   * Replaces the Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxonItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxonItem$Json$Json$Response(params: AdminPutTaxonItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>> {
    return adminPutTaxonItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Taxon resource.
   *
   * Replaces the Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxonItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxonItem$Json$Json(params: AdminPutTaxonItem$Json$Json$Params, context?: HttpContext): Observable<TaxonAdminTaxonShowSyliusAdminTaxonShow> {
    return this.adminPutTaxonItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>): TaxonAdminTaxonShowSyliusAdminTaxonShow => r.body)
    );
  }

  /**
   * Replaces the Taxon resource.
   *
   * Replaces the Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxonItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxonItem$Json$Html$Response(params: AdminPutTaxonItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>> {
    return adminPutTaxonItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Taxon resource.
   *
   * Replaces the Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxonItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxonItem$Json$Html(params: AdminPutTaxonItem$Json$Html$Params, context?: HttpContext): Observable<TaxonAdminTaxonShowSyliusAdminTaxonShow> {
    return this.adminPutTaxonItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>): TaxonAdminTaxonShowSyliusAdminTaxonShow => r.body)
    );
  }

  /**
   * Replaces the Taxon resource.
   *
   * Replaces the Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxonItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxonItem$Html$Json$Response(params: AdminPutTaxonItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>> {
    return adminPutTaxonItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Taxon resource.
   *
   * Replaces the Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxonItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxonItem$Html$Json(params: AdminPutTaxonItem$Html$Json$Params, context?: HttpContext): Observable<TaxonAdminTaxonShowSyliusAdminTaxonShow> {
    return this.adminPutTaxonItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>): TaxonAdminTaxonShowSyliusAdminTaxonShow => r.body)
    );
  }

  /**
   * Replaces the Taxon resource.
   *
   * Replaces the Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxonItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxonItem$Html$Html$Response(params: AdminPutTaxonItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>> {
    return adminPutTaxonItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Taxon resource.
   *
   * Replaces the Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxonItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxonItem$Html$Html(params: AdminPutTaxonItem$Html$Html$Params, context?: HttpContext): Observable<TaxonAdminTaxonShowSyliusAdminTaxonShow> {
    return this.adminPutTaxonItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonAdminTaxonShowSyliusAdminTaxonShow>): TaxonAdminTaxonShowSyliusAdminTaxonShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteTaxonItem()` */
  static readonly AdminDeleteTaxonItemPath = '/api/v2/admin/taxons/{code}';

  /**
   * Removes the Taxon resource.
   *
   * Removes the Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteTaxonItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteTaxonItem$Response(params: AdminDeleteTaxonItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteTaxonItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Taxon resource.
   *
   * Removes the Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteTaxonItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteTaxonItem(params: AdminDeleteTaxonItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteTaxonItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiTaxaImagesGetSubresourceTaxonSubresource()` */
  static readonly ApiTaxaImagesGetSubresourceTaxonSubresourcePath = '/api/v2/admin/taxons/{code}/images';

  /**
   * Retrieves the collection of TaxonImage resources for a given Taxon.
   *                     .
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaxaImagesGetSubresourceTaxonSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaxaImagesGetSubresourceTaxonSubresource$Json$Response(params: ApiTaxaImagesGetSubresourceTaxonSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>>> {
    return apiTaxaImagesGetSubresourceTaxonSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of TaxonImage resources for a given Taxon.
   *                     .
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaxaImagesGetSubresourceTaxonSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaxaImagesGetSubresourceTaxonSubresource$Json(params: ApiTaxaImagesGetSubresourceTaxonSubresource$Json$Params, context?: HttpContext): Observable<Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>> {
    return this.apiTaxaImagesGetSubresourceTaxonSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>>): Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow> => r.body)
    );
  }

  /**
   * Retrieves the collection of TaxonImage resources for a given Taxon.
   *                     .
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaxaImagesGetSubresourceTaxonSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaxaImagesGetSubresourceTaxonSubresource$Html$Response(params: ApiTaxaImagesGetSubresourceTaxonSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>>> {
    return apiTaxaImagesGetSubresourceTaxonSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of TaxonImage resources for a given Taxon.
   *                     .
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaxaImagesGetSubresourceTaxonSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaxaImagesGetSubresourceTaxonSubresource$Html(params: ApiTaxaImagesGetSubresourceTaxonSubresource$Html$Params, context?: HttpContext): Observable<Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>> {
    return this.apiTaxaImagesGetSubresourceTaxonSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>>): Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow> => r.body)
    );
  }

  /** Path part for operation `shopGetTaxonCollection()` */
  static readonly ShopGetTaxonCollectionPath = '/api/v2/shop/taxons';

  /**
   * Retrieves the collection of Taxon resources.
   *
   * Retrieves the collection of Taxon resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetTaxonCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetTaxonCollection$Json$Response(params?: ShopGetTaxonCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxonShopTaxonIndexSyliusShopTaxonIndex>>> {
    return shopGetTaxonCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Taxon resources.
   *
   * Retrieves the collection of Taxon resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetTaxonCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetTaxonCollection$Json(params?: ShopGetTaxonCollection$Json$Params, context?: HttpContext): Observable<Array<TaxonShopTaxonIndexSyliusShopTaxonIndex>> {
    return this.shopGetTaxonCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxonShopTaxonIndexSyliusShopTaxonIndex>>): Array<TaxonShopTaxonIndexSyliusShopTaxonIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Taxon resources.
   *
   * Retrieves the collection of Taxon resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetTaxonCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetTaxonCollection$Html$Response(params?: ShopGetTaxonCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxonShopTaxonIndexSyliusShopTaxonIndex>>> {
    return shopGetTaxonCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Taxon resources.
   *
   * Retrieves the collection of Taxon resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetTaxonCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetTaxonCollection$Html(params?: ShopGetTaxonCollection$Html$Params, context?: HttpContext): Observable<Array<TaxonShopTaxonIndexSyliusShopTaxonIndex>> {
    return this.shopGetTaxonCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxonShopTaxonIndexSyliusShopTaxonIndex>>): Array<TaxonShopTaxonIndexSyliusShopTaxonIndex> => r.body)
    );
  }

  /** Path part for operation `shopGetTaxonItem()` */
  static readonly ShopGetTaxonItemPath = '/api/v2/shop/taxons/{code}';

  /**
   * Retrieves a Taxon resource.
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetTaxonItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetTaxonItem$Json$Response(params: ShopGetTaxonItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonShopTaxonShowSyliusShopTaxonShow>> {
    return shopGetTaxonItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Taxon resource.
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetTaxonItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetTaxonItem$Json(params: ShopGetTaxonItem$Json$Params, context?: HttpContext): Observable<TaxonShopTaxonShowSyliusShopTaxonShow> {
    return this.shopGetTaxonItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonShopTaxonShowSyliusShopTaxonShow>): TaxonShopTaxonShowSyliusShopTaxonShow => r.body)
    );
  }

  /**
   * Retrieves a Taxon resource.
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetTaxonItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetTaxonItem$Html$Response(params: ShopGetTaxonItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonShopTaxonShowSyliusShopTaxonShow>> {
    return shopGetTaxonItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Taxon resource.
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetTaxonItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetTaxonItem$Html(params: ShopGetTaxonItem$Html$Params, context?: HttpContext): Observable<TaxonShopTaxonShowSyliusShopTaxonShow> {
    return this.shopGetTaxonItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonShopTaxonShowSyliusShopTaxonShow>): TaxonShopTaxonShowSyliusShopTaxonShow => r.body)
    );
  }

}

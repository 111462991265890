/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetProductTranslationItem$Html } from '../fn/product-translation/admin-get-product-translation-item-html';
import { AdminGetProductTranslationItem$Html$Params } from '../fn/product-translation/admin-get-product-translation-item-html';
import { adminGetProductTranslationItem$Json } from '../fn/product-translation/admin-get-product-translation-item-json';
import { AdminGetProductTranslationItem$Json$Params } from '../fn/product-translation/admin-get-product-translation-item-json';
import { ProductTranslation } from '../models/product-translation';

@Injectable({ providedIn: 'root' })
export class ProductTranslationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetProductTranslationItem()` */
  static readonly AdminGetProductTranslationItemPath = '/api/v2/admin/product-translations/{id}';

  /**
   * Retrieves a ProductTranslation resource.
   *
   * Retrieves a ProductTranslation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductTranslationItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductTranslationItem$Json$Response(params: AdminGetProductTranslationItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductTranslation>> {
    return adminGetProductTranslationItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductTranslation resource.
   *
   * Retrieves a ProductTranslation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductTranslationItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductTranslationItem$Json(params: AdminGetProductTranslationItem$Json$Params, context?: HttpContext): Observable<ProductTranslation> {
    return this.adminGetProductTranslationItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductTranslation>): ProductTranslation => r.body)
    );
  }

  /**
   * Retrieves a ProductTranslation resource.
   *
   * Retrieves a ProductTranslation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductTranslationItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductTranslationItem$Html$Response(params: AdminGetProductTranslationItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductTranslation>> {
    return adminGetProductTranslationItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductTranslation resource.
   *
   * Retrieves a ProductTranslation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductTranslationItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductTranslationItem$Html(params: AdminGetProductTranslationItem$Html$Params, context?: HttpContext): Observable<ProductTranslation> {
    return this.adminGetProductTranslationItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductTranslation>): ProductTranslation => r.body)
    );
  }

}

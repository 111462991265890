/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminArchiveShippingMethodItem$Html } from '../fn/shipping-method/admin-archive-shipping-method-item-html';
import { AdminArchiveShippingMethodItem$Html$Params } from '../fn/shipping-method/admin-archive-shipping-method-item-html';
import { adminArchiveShippingMethodItem$Json } from '../fn/shipping-method/admin-archive-shipping-method-item-json';
import { AdminArchiveShippingMethodItem$Json$Params } from '../fn/shipping-method/admin-archive-shipping-method-item-json';
import { adminDeleteShippingMethodItem } from '../fn/shipping-method/admin-delete-shipping-method-item';
import { AdminDeleteShippingMethodItem$Params } from '../fn/shipping-method/admin-delete-shipping-method-item';
import { adminGetShippingMethodCollection$Html } from '../fn/shipping-method/admin-get-shipping-method-collection-html';
import { AdminGetShippingMethodCollection$Html$Params } from '../fn/shipping-method/admin-get-shipping-method-collection-html';
import { adminGetShippingMethodCollection$Json } from '../fn/shipping-method/admin-get-shipping-method-collection-json';
import { AdminGetShippingMethodCollection$Json$Params } from '../fn/shipping-method/admin-get-shipping-method-collection-json';
import { adminGetShippingMethodItem$Html } from '../fn/shipping-method/admin-get-shipping-method-item-html';
import { AdminGetShippingMethodItem$Html$Params } from '../fn/shipping-method/admin-get-shipping-method-item-html';
import { adminGetShippingMethodItem$Json } from '../fn/shipping-method/admin-get-shipping-method-item-json';
import { AdminGetShippingMethodItem$Json$Params } from '../fn/shipping-method/admin-get-shipping-method-item-json';
import { adminPostShippingMethodCollection$Html$Html } from '../fn/shipping-method/admin-post-shipping-method-collection-html-html';
import { AdminPostShippingMethodCollection$Html$Html$Params } from '../fn/shipping-method/admin-post-shipping-method-collection-html-html';
import { adminPostShippingMethodCollection$Html$Json } from '../fn/shipping-method/admin-post-shipping-method-collection-html-json';
import { AdminPostShippingMethodCollection$Html$Json$Params } from '../fn/shipping-method/admin-post-shipping-method-collection-html-json';
import { adminPostShippingMethodCollection$Json$Html } from '../fn/shipping-method/admin-post-shipping-method-collection-json-html';
import { AdminPostShippingMethodCollection$Json$Html$Params } from '../fn/shipping-method/admin-post-shipping-method-collection-json-html';
import { adminPostShippingMethodCollection$Json$Json } from '../fn/shipping-method/admin-post-shipping-method-collection-json-json';
import { AdminPostShippingMethodCollection$Json$Json$Params } from '../fn/shipping-method/admin-post-shipping-method-collection-json-json';
import { adminPutShippingMethodItem$Html$Html } from '../fn/shipping-method/admin-put-shipping-method-item-html-html';
import { AdminPutShippingMethodItem$Html$Html$Params } from '../fn/shipping-method/admin-put-shipping-method-item-html-html';
import { adminPutShippingMethodItem$Html$Json } from '../fn/shipping-method/admin-put-shipping-method-item-html-json';
import { AdminPutShippingMethodItem$Html$Json$Params } from '../fn/shipping-method/admin-put-shipping-method-item-html-json';
import { adminPutShippingMethodItem$Json$Html } from '../fn/shipping-method/admin-put-shipping-method-item-json-html';
import { AdminPutShippingMethodItem$Json$Html$Params } from '../fn/shipping-method/admin-put-shipping-method-item-json-html';
import { adminPutShippingMethodItem$Json$Json } from '../fn/shipping-method/admin-put-shipping-method-item-json-json';
import { AdminPutShippingMethodItem$Json$Json$Params } from '../fn/shipping-method/admin-put-shipping-method-item-json-json';
import { adminRestoreShippingMethodItem$Html } from '../fn/shipping-method/admin-restore-shipping-method-item-html';
import { AdminRestoreShippingMethodItem$Html$Params } from '../fn/shipping-method/admin-restore-shipping-method-item-html';
import { adminRestoreShippingMethodItem$Json } from '../fn/shipping-method/admin-restore-shipping-method-item-json';
import { AdminRestoreShippingMethodItem$Json$Params } from '../fn/shipping-method/admin-restore-shipping-method-item-json';
import { Index as ShippingMethodAdminShippingMethodIndexSyliusAdminShippingMethodIndex } from '../models/ShippingMethod-admin/shipping_method/index_sylius/admin/shipping_method/index';
import { Show as ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow } from '../models/ShippingMethod-admin/shipping_method/show_sylius/admin/shipping_method/show';
import { Index as ShippingMethodShopShippingMethodIndexSyliusShopShippingMethodIndex } from '../models/ShippingMethod-shop/shipping_method/index_sylius/shop/shipping_method/index';
import { Show as ShippingMethodShopShippingMethodShowSyliusShopShippingMethodShow } from '../models/ShippingMethod-shop/shipping_method/show_sylius/shop/shipping_method/show';
import { shopGetShippingMethodCollection$Html } from '../fn/shipping-method/shop-get-shipping-method-collection-html';
import { ShopGetShippingMethodCollection$Html$Params } from '../fn/shipping-method/shop-get-shipping-method-collection-html';
import { shopGetShippingMethodCollection$Json } from '../fn/shipping-method/shop-get-shipping-method-collection-json';
import { ShopGetShippingMethodCollection$Json$Params } from '../fn/shipping-method/shop-get-shipping-method-collection-json';
import { shopGetShippingMethodItem$Html } from '../fn/shipping-method/shop-get-shipping-method-item-html';
import { ShopGetShippingMethodItem$Html$Params } from '../fn/shipping-method/shop-get-shipping-method-item-html';
import { shopGetShippingMethodItem$Json } from '../fn/shipping-method/shop-get-shipping-method-item-json';
import { ShopGetShippingMethodItem$Json$Params } from '../fn/shipping-method/shop-get-shipping-method-item-json';

@Injectable({ providedIn: 'root' })
export class ShippingMethodService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetShippingMethodCollection()` */
  static readonly AdminGetShippingMethodCollectionPath = '/api/v2/admin/shipping-methods';

  /**
   * Retrieves the collection of ShippingMethod resources.
   *
   * Retrieves the collection of ShippingMethod resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShippingMethodCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingMethodCollection$Json$Response(params?: AdminGetShippingMethodCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShippingMethodAdminShippingMethodIndexSyliusAdminShippingMethodIndex>>> {
    return adminGetShippingMethodCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ShippingMethod resources.
   *
   * Retrieves the collection of ShippingMethod resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShippingMethodCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingMethodCollection$Json(params?: AdminGetShippingMethodCollection$Json$Params, context?: HttpContext): Observable<Array<ShippingMethodAdminShippingMethodIndexSyliusAdminShippingMethodIndex>> {
    return this.adminGetShippingMethodCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShippingMethodAdminShippingMethodIndexSyliusAdminShippingMethodIndex>>): Array<ShippingMethodAdminShippingMethodIndexSyliusAdminShippingMethodIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ShippingMethod resources.
   *
   * Retrieves the collection of ShippingMethod resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShippingMethodCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingMethodCollection$Html$Response(params?: AdminGetShippingMethodCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShippingMethodAdminShippingMethodIndexSyliusAdminShippingMethodIndex>>> {
    return adminGetShippingMethodCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ShippingMethod resources.
   *
   * Retrieves the collection of ShippingMethod resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShippingMethodCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingMethodCollection$Html(params?: AdminGetShippingMethodCollection$Html$Params, context?: HttpContext): Observable<Array<ShippingMethodAdminShippingMethodIndexSyliusAdminShippingMethodIndex>> {
    return this.adminGetShippingMethodCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShippingMethodAdminShippingMethodIndexSyliusAdminShippingMethodIndex>>): Array<ShippingMethodAdminShippingMethodIndexSyliusAdminShippingMethodIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostShippingMethodCollection()` */
  static readonly AdminPostShippingMethodCollectionPath = '/api/v2/admin/shipping-methods';

  /**
   * Creates a ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostShippingMethodCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostShippingMethodCollection$Json$Json$Response(params: AdminPostShippingMethodCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>> {
    return adminPostShippingMethodCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostShippingMethodCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostShippingMethodCollection$Json$Json(params: AdminPostShippingMethodCollection$Json$Json$Params, context?: HttpContext): Observable<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow> {
    return this.adminPostShippingMethodCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>): ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow => r.body)
    );
  }

  /**
   * Creates a ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostShippingMethodCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostShippingMethodCollection$Json$Html$Response(params: AdminPostShippingMethodCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>> {
    return adminPostShippingMethodCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostShippingMethodCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostShippingMethodCollection$Json$Html(params: AdminPostShippingMethodCollection$Json$Html$Params, context?: HttpContext): Observable<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow> {
    return this.adminPostShippingMethodCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>): ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow => r.body)
    );
  }

  /**
   * Creates a ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostShippingMethodCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostShippingMethodCollection$Html$Json$Response(params: AdminPostShippingMethodCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>> {
    return adminPostShippingMethodCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostShippingMethodCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostShippingMethodCollection$Html$Json(params: AdminPostShippingMethodCollection$Html$Json$Params, context?: HttpContext): Observable<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow> {
    return this.adminPostShippingMethodCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>): ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow => r.body)
    );
  }

  /**
   * Creates a ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostShippingMethodCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostShippingMethodCollection$Html$Html$Response(params: AdminPostShippingMethodCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>> {
    return adminPostShippingMethodCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostShippingMethodCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostShippingMethodCollection$Html$Html(params: AdminPostShippingMethodCollection$Html$Html$Params, context?: HttpContext): Observable<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow> {
    return this.adminPostShippingMethodCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>): ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow => r.body)
    );
  }

  /** Path part for operation `adminGetShippingMethodItem()` */
  static readonly AdminGetShippingMethodItemPath = '/api/v2/admin/shipping-methods/{code}';

  /**
   * Retrieves a ShippingMethod resource.
   *
   * Retrieves a ShippingMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShippingMethodItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingMethodItem$Json$Response(params: AdminGetShippingMethodItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>> {
    return adminGetShippingMethodItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ShippingMethod resource.
   *
   * Retrieves a ShippingMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShippingMethodItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingMethodItem$Json(params: AdminGetShippingMethodItem$Json$Params, context?: HttpContext): Observable<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow> {
    return this.adminGetShippingMethodItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>): ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow => r.body)
    );
  }

  /**
   * Retrieves a ShippingMethod resource.
   *
   * Retrieves a ShippingMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShippingMethodItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingMethodItem$Html$Response(params: AdminGetShippingMethodItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>> {
    return adminGetShippingMethodItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ShippingMethod resource.
   *
   * Retrieves a ShippingMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShippingMethodItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingMethodItem$Html(params: AdminGetShippingMethodItem$Html$Params, context?: HttpContext): Observable<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow> {
    return this.adminGetShippingMethodItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>): ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow => r.body)
    );
  }

  /** Path part for operation `adminPutShippingMethodItem()` */
  static readonly AdminPutShippingMethodItemPath = '/api/v2/admin/shipping-methods/{code}';

  /**
   * Replaces the ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutShippingMethodItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutShippingMethodItem$Json$Json$Response(params: AdminPutShippingMethodItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>> {
    return adminPutShippingMethodItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutShippingMethodItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutShippingMethodItem$Json$Json(params: AdminPutShippingMethodItem$Json$Json$Params, context?: HttpContext): Observable<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow> {
    return this.adminPutShippingMethodItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>): ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow => r.body)
    );
  }

  /**
   * Replaces the ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutShippingMethodItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutShippingMethodItem$Json$Html$Response(params: AdminPutShippingMethodItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>> {
    return adminPutShippingMethodItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutShippingMethodItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutShippingMethodItem$Json$Html(params: AdminPutShippingMethodItem$Json$Html$Params, context?: HttpContext): Observable<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow> {
    return this.adminPutShippingMethodItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>): ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow => r.body)
    );
  }

  /**
   * Replaces the ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutShippingMethodItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutShippingMethodItem$Html$Json$Response(params: AdminPutShippingMethodItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>> {
    return adminPutShippingMethodItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutShippingMethodItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutShippingMethodItem$Html$Json(params: AdminPutShippingMethodItem$Html$Json$Params, context?: HttpContext): Observable<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow> {
    return this.adminPutShippingMethodItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>): ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow => r.body)
    );
  }

  /**
   * Replaces the ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutShippingMethodItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutShippingMethodItem$Html$Html$Response(params: AdminPutShippingMethodItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>> {
    return adminPutShippingMethodItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ShippingMethod resource.
   *
   * Example configuration for `total_weight_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "total_weight_greater_than_or_equal",
   *     "configuration": {
   *         "weight": int
   *     }
   * }
   * ```
   *
   * Example configuration for `order_total_greater_than_or_equal` rule type:
   *
   * ```
   * {
   *     "type": "order_total_greater_than_or_equal",
   *     "configuration": {
   *         "channel-code": [
   *             "amount": int,
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `flat_rate` shipping charges calculator:
   *
   * ```
   * "shippingChargesCalculator": "flat_rate",
   * "shippingChargesCalculatorConfiguration": {
   *     "channel-code": [
   *         "amount": int,
   *     ]
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `total_weight_greater_than_or_equal`, `total_weight_less_than_or_equal`, `order_total_greater_than_or_equal`, `order_total_less_than_or_equal` 
   *
   *  Allowed calculators: `flat_rate`, `per_unit_rate`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutShippingMethodItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutShippingMethodItem$Html$Html(params: AdminPutShippingMethodItem$Html$Html$Params, context?: HttpContext): Observable<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow> {
    return this.adminPutShippingMethodItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>): ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteShippingMethodItem()` */
  static readonly AdminDeleteShippingMethodItemPath = '/api/v2/admin/shipping-methods/{code}';

  /**
   * Removes the ShippingMethod resource.
   *
   * Removes the ShippingMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteShippingMethodItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteShippingMethodItem$Response(params: AdminDeleteShippingMethodItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteShippingMethodItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the ShippingMethod resource.
   *
   * Removes the ShippingMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteShippingMethodItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteShippingMethodItem(params: AdminDeleteShippingMethodItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteShippingMethodItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminArchiveShippingMethodItem()` */
  static readonly AdminArchiveShippingMethodItemPath = '/api/v2/admin/shipping-methods/{code}/archive';

  /**
   * Archives Shipping Method.
   *
   * Updates the ShippingMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminArchiveShippingMethodItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminArchiveShippingMethodItem$Json$Response(params: AdminArchiveShippingMethodItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>> {
    return adminArchiveShippingMethodItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Archives Shipping Method.
   *
   * Updates the ShippingMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminArchiveShippingMethodItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminArchiveShippingMethodItem$Json(params: AdminArchiveShippingMethodItem$Json$Params, context?: HttpContext): Observable<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow> {
    return this.adminArchiveShippingMethodItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>): ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow => r.body)
    );
  }

  /**
   * Archives Shipping Method.
   *
   * Updates the ShippingMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminArchiveShippingMethodItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminArchiveShippingMethodItem$Html$Response(params: AdminArchiveShippingMethodItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>> {
    return adminArchiveShippingMethodItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Archives Shipping Method.
   *
   * Updates the ShippingMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminArchiveShippingMethodItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminArchiveShippingMethodItem$Html(params: AdminArchiveShippingMethodItem$Html$Params, context?: HttpContext): Observable<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow> {
    return this.adminArchiveShippingMethodItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>): ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow => r.body)
    );
  }

  /** Path part for operation `adminRestoreShippingMethodItem()` */
  static readonly AdminRestoreShippingMethodItemPath = '/api/v2/admin/shipping-methods/{code}/restore';

  /**
   * Restores archived Shipping Method.
   *
   * Updates the ShippingMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminRestoreShippingMethodItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminRestoreShippingMethodItem$Json$Response(params: AdminRestoreShippingMethodItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>> {
    return adminRestoreShippingMethodItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Restores archived Shipping Method.
   *
   * Updates the ShippingMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminRestoreShippingMethodItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminRestoreShippingMethodItem$Json(params: AdminRestoreShippingMethodItem$Json$Params, context?: HttpContext): Observable<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow> {
    return this.adminRestoreShippingMethodItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>): ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow => r.body)
    );
  }

  /**
   * Restores archived Shipping Method.
   *
   * Updates the ShippingMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminRestoreShippingMethodItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminRestoreShippingMethodItem$Html$Response(params: AdminRestoreShippingMethodItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>> {
    return adminRestoreShippingMethodItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Restores archived Shipping Method.
   *
   * Updates the ShippingMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminRestoreShippingMethodItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminRestoreShippingMethodItem$Html(params: AdminRestoreShippingMethodItem$Html$Params, context?: HttpContext): Observable<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow> {
    return this.adminRestoreShippingMethodItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow>): ShippingMethodAdminShippingMethodShowSyliusAdminShippingMethodShow => r.body)
    );
  }

  /** Path part for operation `shopGetShippingMethodCollection()` */
  static readonly ShopGetShippingMethodCollectionPath = '/api/v2/shop/shipping-methods';

  /**
   * Retrieves the collection of ShippingMethod resources.
   *
   * Retrieves the collection of ShippingMethod resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetShippingMethodCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetShippingMethodCollection$Json$Response(params?: ShopGetShippingMethodCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShippingMethodShopShippingMethodIndexSyliusShopShippingMethodIndex>>> {
    return shopGetShippingMethodCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ShippingMethod resources.
   *
   * Retrieves the collection of ShippingMethod resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetShippingMethodCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetShippingMethodCollection$Json(params?: ShopGetShippingMethodCollection$Json$Params, context?: HttpContext): Observable<Array<ShippingMethodShopShippingMethodIndexSyliusShopShippingMethodIndex>> {
    return this.shopGetShippingMethodCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShippingMethodShopShippingMethodIndexSyliusShopShippingMethodIndex>>): Array<ShippingMethodShopShippingMethodIndexSyliusShopShippingMethodIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ShippingMethod resources.
   *
   * Retrieves the collection of ShippingMethod resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetShippingMethodCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetShippingMethodCollection$Html$Response(params?: ShopGetShippingMethodCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShippingMethodShopShippingMethodIndexSyliusShopShippingMethodIndex>>> {
    return shopGetShippingMethodCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ShippingMethod resources.
   *
   * Retrieves the collection of ShippingMethod resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetShippingMethodCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetShippingMethodCollection$Html(params?: ShopGetShippingMethodCollection$Html$Params, context?: HttpContext): Observable<Array<ShippingMethodShopShippingMethodIndexSyliusShopShippingMethodIndex>> {
    return this.shopGetShippingMethodCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShippingMethodShopShippingMethodIndexSyliusShopShippingMethodIndex>>): Array<ShippingMethodShopShippingMethodIndexSyliusShopShippingMethodIndex> => r.body)
    );
  }

  /** Path part for operation `shopGetShippingMethodItem()` */
  static readonly ShopGetShippingMethodItemPath = '/api/v2/shop/shipping-methods/{code}';

  /**
   * Retrieves a ShippingMethod resource.
   *
   * Retrieves a ShippingMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetShippingMethodItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetShippingMethodItem$Json$Response(params: ShopGetShippingMethodItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodShopShippingMethodShowSyliusShopShippingMethodShow>> {
    return shopGetShippingMethodItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ShippingMethod resource.
   *
   * Retrieves a ShippingMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetShippingMethodItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetShippingMethodItem$Json(params: ShopGetShippingMethodItem$Json$Params, context?: HttpContext): Observable<ShippingMethodShopShippingMethodShowSyliusShopShippingMethodShow> {
    return this.shopGetShippingMethodItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodShopShippingMethodShowSyliusShopShippingMethodShow>): ShippingMethodShopShippingMethodShowSyliusShopShippingMethodShow => r.body)
    );
  }

  /**
   * Retrieves a ShippingMethod resource.
   *
   * Retrieves a ShippingMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetShippingMethodItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetShippingMethodItem$Html$Response(params: ShopGetShippingMethodItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodShopShippingMethodShowSyliusShopShippingMethodShow>> {
    return shopGetShippingMethodItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ShippingMethod resource.
   *
   * Retrieves a ShippingMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetShippingMethodItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetShippingMethodItem$Html(params: ShopGetShippingMethodItem$Html$Params, context?: HttpContext): Observable<ShippingMethodShopShippingMethodShowSyliusShopShippingMethodShow> {
    return this.shopGetShippingMethodItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodShopShippingMethodShowSyliusShopShippingMethodShow>): ShippingMethodShopShippingMethodShowSyliusShopShippingMethodShow => r.body)
    );
  }

}

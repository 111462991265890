/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeletePromotionCouponItem } from '../fn/promotion-coupon/admin-delete-promotion-coupon-item';
import { AdminDeletePromotionCouponItem$Params } from '../fn/promotion-coupon/admin-delete-promotion-coupon-item';
import { adminGeneratePromotionCouponCollection$Html$Html } from '../fn/promotion-coupon/admin-generate-promotion-coupon-collection-html-html';
import { AdminGeneratePromotionCouponCollection$Html$Html$Params } from '../fn/promotion-coupon/admin-generate-promotion-coupon-collection-html-html';
import { adminGeneratePromotionCouponCollection$Html$Json } from '../fn/promotion-coupon/admin-generate-promotion-coupon-collection-html-json';
import { AdminGeneratePromotionCouponCollection$Html$Json$Params } from '../fn/promotion-coupon/admin-generate-promotion-coupon-collection-html-json';
import { adminGeneratePromotionCouponCollection$Json$Html } from '../fn/promotion-coupon/admin-generate-promotion-coupon-collection-json-html';
import { AdminGeneratePromotionCouponCollection$Json$Html$Params } from '../fn/promotion-coupon/admin-generate-promotion-coupon-collection-json-html';
import { adminGeneratePromotionCouponCollection$Json$Json } from '../fn/promotion-coupon/admin-generate-promotion-coupon-collection-json-json';
import { AdminGeneratePromotionCouponCollection$Json$Json$Params } from '../fn/promotion-coupon/admin-generate-promotion-coupon-collection-json-json';
import { adminGetPromotionCouponCollection$Html } from '../fn/promotion-coupon/admin-get-promotion-coupon-collection-html';
import { AdminGetPromotionCouponCollection$Html$Params } from '../fn/promotion-coupon/admin-get-promotion-coupon-collection-html';
import { adminGetPromotionCouponCollection$Json } from '../fn/promotion-coupon/admin-get-promotion-coupon-collection-json';
import { AdminGetPromotionCouponCollection$Json$Params } from '../fn/promotion-coupon/admin-get-promotion-coupon-collection-json';
import { adminGetPromotionCouponItem$Html } from '../fn/promotion-coupon/admin-get-promotion-coupon-item-html';
import { AdminGetPromotionCouponItem$Html$Params } from '../fn/promotion-coupon/admin-get-promotion-coupon-item-html';
import { adminGetPromotionCouponItem$Json } from '../fn/promotion-coupon/admin-get-promotion-coupon-item-json';
import { AdminGetPromotionCouponItem$Json$Params } from '../fn/promotion-coupon/admin-get-promotion-coupon-item-json';
import { adminPostPromotionCouponCollection$Html$Html } from '../fn/promotion-coupon/admin-post-promotion-coupon-collection-html-html';
import { AdminPostPromotionCouponCollection$Html$Html$Params } from '../fn/promotion-coupon/admin-post-promotion-coupon-collection-html-html';
import { adminPostPromotionCouponCollection$Html$Json } from '../fn/promotion-coupon/admin-post-promotion-coupon-collection-html-json';
import { AdminPostPromotionCouponCollection$Html$Json$Params } from '../fn/promotion-coupon/admin-post-promotion-coupon-collection-html-json';
import { adminPostPromotionCouponCollection$Json$Html } from '../fn/promotion-coupon/admin-post-promotion-coupon-collection-json-html';
import { AdminPostPromotionCouponCollection$Json$Html$Params } from '../fn/promotion-coupon/admin-post-promotion-coupon-collection-json-html';
import { adminPostPromotionCouponCollection$Json$Json } from '../fn/promotion-coupon/admin-post-promotion-coupon-collection-json-json';
import { AdminPostPromotionCouponCollection$Json$Json$Params } from '../fn/promotion-coupon/admin-post-promotion-coupon-collection-json-json';
import { adminPutPromotionCouponItem$Html$Html } from '../fn/promotion-coupon/admin-put-promotion-coupon-item-html-html';
import { AdminPutPromotionCouponItem$Html$Html$Params } from '../fn/promotion-coupon/admin-put-promotion-coupon-item-html-html';
import { adminPutPromotionCouponItem$Html$Json } from '../fn/promotion-coupon/admin-put-promotion-coupon-item-html-json';
import { AdminPutPromotionCouponItem$Html$Json$Params } from '../fn/promotion-coupon/admin-put-promotion-coupon-item-html-json';
import { adminPutPromotionCouponItem$Json$Html } from '../fn/promotion-coupon/admin-put-promotion-coupon-item-json-html';
import { AdminPutPromotionCouponItem$Json$Html$Params } from '../fn/promotion-coupon/admin-put-promotion-coupon-item-json-html';
import { adminPutPromotionCouponItem$Json$Json } from '../fn/promotion-coupon/admin-put-promotion-coupon-item-json-json';
import { AdminPutPromotionCouponItem$Json$Json$Params } from '../fn/promotion-coupon/admin-put-promotion-coupon-item-json-json';
import { apiPromotionsCouponsGetSubresourcePromotionSubresource$Html } from '../fn/promotion-coupon/api-promotions-coupons-get-subresource-promotion-subresource-html';
import { ApiPromotionsCouponsGetSubresourcePromotionSubresource$Html$Params } from '../fn/promotion-coupon/api-promotions-coupons-get-subresource-promotion-subresource-html';
import { apiPromotionsCouponsGetSubresourcePromotionSubresource$Json } from '../fn/promotion-coupon/api-promotions-coupons-get-subresource-promotion-subresource-json';
import { ApiPromotionsCouponsGetSubresourcePromotionSubresource$Json$Params } from '../fn/promotion-coupon/api-promotions-coupons-get-subresource-promotion-subresource-json';
import { Index as PromotionCouponAdminPromotionCouponIndexSyliusAdminPromotionCouponIndex } from '../models/PromotionCoupon-admin/promotion_coupon/index_sylius/admin/promotion_coupon/index';
import { Show as PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow } from '../models/PromotionCoupon-admin/promotion_coupon/show_sylius/admin/promotion_coupon/show';

@Injectable({ providedIn: 'root' })
export class PromotionCouponService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetPromotionCouponCollection()` */
  static readonly AdminGetPromotionCouponCollectionPath = '/api/v2/admin/promotion-coupons';

  /**
   * Retrieves the collection of PromotionCoupon resources.
   *
   * Retrieves the collection of PromotionCoupon resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPromotionCouponCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionCouponCollection$Json$Response(params?: AdminGetPromotionCouponCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponIndexSyliusAdminPromotionCouponIndex>>> {
    return adminGetPromotionCouponCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of PromotionCoupon resources.
   *
   * Retrieves the collection of PromotionCoupon resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPromotionCouponCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionCouponCollection$Json(params?: AdminGetPromotionCouponCollection$Json$Params, context?: HttpContext): Observable<Array<PromotionCouponAdminPromotionCouponIndexSyliusAdminPromotionCouponIndex>> {
    return this.adminGetPromotionCouponCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponIndexSyliusAdminPromotionCouponIndex>>): Array<PromotionCouponAdminPromotionCouponIndexSyliusAdminPromotionCouponIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of PromotionCoupon resources.
   *
   * Retrieves the collection of PromotionCoupon resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPromotionCouponCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionCouponCollection$Html$Response(params?: AdminGetPromotionCouponCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponIndexSyliusAdminPromotionCouponIndex>>> {
    return adminGetPromotionCouponCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of PromotionCoupon resources.
   *
   * Retrieves the collection of PromotionCoupon resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPromotionCouponCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionCouponCollection$Html(params?: AdminGetPromotionCouponCollection$Html$Params, context?: HttpContext): Observable<Array<PromotionCouponAdminPromotionCouponIndexSyliusAdminPromotionCouponIndex>> {
    return this.adminGetPromotionCouponCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponIndexSyliusAdminPromotionCouponIndex>>): Array<PromotionCouponAdminPromotionCouponIndexSyliusAdminPromotionCouponIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostPromotionCouponCollection()` */
  static readonly AdminPostPromotionCouponCollectionPath = '/api/v2/admin/promotion-coupons';

  /**
   * Creates a PromotionCoupon resource.
   *
   * Creates a PromotionCoupon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostPromotionCouponCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostPromotionCouponCollection$Json$Json$Response(params: AdminPostPromotionCouponCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return adminPostPromotionCouponCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a PromotionCoupon resource.
   *
   * Creates a PromotionCoupon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostPromotionCouponCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostPromotionCouponCollection$Json$Json(params: AdminPostPromotionCouponCollection$Json$Json$Params, context?: HttpContext): Observable<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> {
    return this.adminPostPromotionCouponCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>): PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow => r.body)
    );
  }

  /**
   * Creates a PromotionCoupon resource.
   *
   * Creates a PromotionCoupon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostPromotionCouponCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostPromotionCouponCollection$Json$Html$Response(params: AdminPostPromotionCouponCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return adminPostPromotionCouponCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a PromotionCoupon resource.
   *
   * Creates a PromotionCoupon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostPromotionCouponCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostPromotionCouponCollection$Json$Html(params: AdminPostPromotionCouponCollection$Json$Html$Params, context?: HttpContext): Observable<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> {
    return this.adminPostPromotionCouponCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>): PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow => r.body)
    );
  }

  /**
   * Creates a PromotionCoupon resource.
   *
   * Creates a PromotionCoupon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostPromotionCouponCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostPromotionCouponCollection$Html$Json$Response(params: AdminPostPromotionCouponCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return adminPostPromotionCouponCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a PromotionCoupon resource.
   *
   * Creates a PromotionCoupon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostPromotionCouponCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostPromotionCouponCollection$Html$Json(params: AdminPostPromotionCouponCollection$Html$Json$Params, context?: HttpContext): Observable<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> {
    return this.adminPostPromotionCouponCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>): PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow => r.body)
    );
  }

  /**
   * Creates a PromotionCoupon resource.
   *
   * Creates a PromotionCoupon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostPromotionCouponCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostPromotionCouponCollection$Html$Html$Response(params: AdminPostPromotionCouponCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return adminPostPromotionCouponCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a PromotionCoupon resource.
   *
   * Creates a PromotionCoupon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostPromotionCouponCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostPromotionCouponCollection$Html$Html(params: AdminPostPromotionCouponCollection$Html$Html$Params, context?: HttpContext): Observable<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> {
    return this.adminPostPromotionCouponCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>): PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow => r.body)
    );
  }

  /** Path part for operation `adminGeneratePromotionCouponCollection()` */
  static readonly AdminGeneratePromotionCouponCollectionPath = '/api/v2/admin/promotion-coupons/generate';

  /**
   * Generates promotion coupons.
   *
   * Generates promotion coupons
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGeneratePromotionCouponCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminGeneratePromotionCouponCollection$Json$Json$Response(params: AdminGeneratePromotionCouponCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return adminGeneratePromotionCouponCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Generates promotion coupons.
   *
   * Generates promotion coupons
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGeneratePromotionCouponCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminGeneratePromotionCouponCollection$Json$Json(params: AdminGeneratePromotionCouponCollection$Json$Json$Params, context?: HttpContext): Observable<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> {
    return this.adminGeneratePromotionCouponCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>): PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow => r.body)
    );
  }

  /**
   * Generates promotion coupons.
   *
   * Generates promotion coupons
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGeneratePromotionCouponCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminGeneratePromotionCouponCollection$Json$Html$Response(params: AdminGeneratePromotionCouponCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return adminGeneratePromotionCouponCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Generates promotion coupons.
   *
   * Generates promotion coupons
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGeneratePromotionCouponCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminGeneratePromotionCouponCollection$Json$Html(params: AdminGeneratePromotionCouponCollection$Json$Html$Params, context?: HttpContext): Observable<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> {
    return this.adminGeneratePromotionCouponCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>): PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow => r.body)
    );
  }

  /**
   * Generates promotion coupons.
   *
   * Generates promotion coupons
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGeneratePromotionCouponCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminGeneratePromotionCouponCollection$Html$Json$Response(params: AdminGeneratePromotionCouponCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return adminGeneratePromotionCouponCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Generates promotion coupons.
   *
   * Generates promotion coupons
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGeneratePromotionCouponCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminGeneratePromotionCouponCollection$Html$Json(params: AdminGeneratePromotionCouponCollection$Html$Json$Params, context?: HttpContext): Observable<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> {
    return this.adminGeneratePromotionCouponCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>): PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow => r.body)
    );
  }

  /**
   * Generates promotion coupons.
   *
   * Generates promotion coupons
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGeneratePromotionCouponCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminGeneratePromotionCouponCollection$Html$Html$Response(params: AdminGeneratePromotionCouponCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return adminGeneratePromotionCouponCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Generates promotion coupons.
   *
   * Generates promotion coupons
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGeneratePromotionCouponCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminGeneratePromotionCouponCollection$Html$Html(params: AdminGeneratePromotionCouponCollection$Html$Html$Params, context?: HttpContext): Observable<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> {
    return this.adminGeneratePromotionCouponCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>): PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow => r.body)
    );
  }

  /** Path part for operation `adminGetPromotionCouponItem()` */
  static readonly AdminGetPromotionCouponItemPath = '/api/v2/admin/promotion-coupons/{code}';

  /**
   * Retrieves a PromotionCoupon resource.
   *
   * Retrieves a PromotionCoupon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPromotionCouponItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionCouponItem$Json$Response(params: AdminGetPromotionCouponItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return adminGetPromotionCouponItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a PromotionCoupon resource.
   *
   * Retrieves a PromotionCoupon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPromotionCouponItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionCouponItem$Json(params: AdminGetPromotionCouponItem$Json$Params, context?: HttpContext): Observable<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> {
    return this.adminGetPromotionCouponItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>): PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow => r.body)
    );
  }

  /**
   * Retrieves a PromotionCoupon resource.
   *
   * Retrieves a PromotionCoupon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPromotionCouponItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionCouponItem$Html$Response(params: AdminGetPromotionCouponItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return adminGetPromotionCouponItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a PromotionCoupon resource.
   *
   * Retrieves a PromotionCoupon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPromotionCouponItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionCouponItem$Html(params: AdminGetPromotionCouponItem$Html$Params, context?: HttpContext): Observable<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> {
    return this.adminGetPromotionCouponItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>): PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow => r.body)
    );
  }

  /** Path part for operation `adminPutPromotionCouponItem()` */
  static readonly AdminPutPromotionCouponItemPath = '/api/v2/admin/promotion-coupons/{code}';

  /**
   * Replaces the PromotionCoupon resource.
   *
   * Replaces the PromotionCoupon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutPromotionCouponItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutPromotionCouponItem$Json$Json$Response(params: AdminPutPromotionCouponItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return adminPutPromotionCouponItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the PromotionCoupon resource.
   *
   * Replaces the PromotionCoupon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutPromotionCouponItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutPromotionCouponItem$Json$Json(params: AdminPutPromotionCouponItem$Json$Json$Params, context?: HttpContext): Observable<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> {
    return this.adminPutPromotionCouponItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>): PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow => r.body)
    );
  }

  /**
   * Replaces the PromotionCoupon resource.
   *
   * Replaces the PromotionCoupon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutPromotionCouponItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutPromotionCouponItem$Json$Html$Response(params: AdminPutPromotionCouponItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return adminPutPromotionCouponItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the PromotionCoupon resource.
   *
   * Replaces the PromotionCoupon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutPromotionCouponItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutPromotionCouponItem$Json$Html(params: AdminPutPromotionCouponItem$Json$Html$Params, context?: HttpContext): Observable<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> {
    return this.adminPutPromotionCouponItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>): PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow => r.body)
    );
  }

  /**
   * Replaces the PromotionCoupon resource.
   *
   * Replaces the PromotionCoupon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutPromotionCouponItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutPromotionCouponItem$Html$Json$Response(params: AdminPutPromotionCouponItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return adminPutPromotionCouponItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the PromotionCoupon resource.
   *
   * Replaces the PromotionCoupon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutPromotionCouponItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutPromotionCouponItem$Html$Json(params: AdminPutPromotionCouponItem$Html$Json$Params, context?: HttpContext): Observable<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> {
    return this.adminPutPromotionCouponItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>): PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow => r.body)
    );
  }

  /**
   * Replaces the PromotionCoupon resource.
   *
   * Replaces the PromotionCoupon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutPromotionCouponItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutPromotionCouponItem$Html$Html$Response(params: AdminPutPromotionCouponItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return adminPutPromotionCouponItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the PromotionCoupon resource.
   *
   * Replaces the PromotionCoupon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutPromotionCouponItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutPromotionCouponItem$Html$Html(params: AdminPutPromotionCouponItem$Html$Html$Params, context?: HttpContext): Observable<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> {
    return this.adminPutPromotionCouponItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>): PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow => r.body)
    );
  }

  /** Path part for operation `adminDeletePromotionCouponItem()` */
  static readonly AdminDeletePromotionCouponItemPath = '/api/v2/admin/promotion-coupons/{code}';

  /**
   * Removes the PromotionCoupon resource.
   *
   * Removes the PromotionCoupon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeletePromotionCouponItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeletePromotionCouponItem$Response(params: AdminDeletePromotionCouponItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeletePromotionCouponItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the PromotionCoupon resource.
   *
   * Removes the PromotionCoupon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeletePromotionCouponItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeletePromotionCouponItem(params: AdminDeletePromotionCouponItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeletePromotionCouponItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiPromotionsCouponsGetSubresourcePromotionSubresource()` */
  static readonly ApiPromotionsCouponsGetSubresourcePromotionSubresourcePath = '/api/v2/admin/promotions/{code}/coupons';

  /**
   * Retrieves a Promotion resource.
   *
   * Retrieves a Promotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPromotionsCouponsGetSubresourcePromotionSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPromotionsCouponsGetSubresourcePromotionSubresource$Json$Response(params: ApiPromotionsCouponsGetSubresourcePromotionSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>>> {
    return apiPromotionsCouponsGetSubresourcePromotionSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Promotion resource.
   *
   * Retrieves a Promotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPromotionsCouponsGetSubresourcePromotionSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPromotionsCouponsGetSubresourcePromotionSubresource$Json(params: ApiPromotionsCouponsGetSubresourcePromotionSubresource$Json$Params, context?: HttpContext): Observable<Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return this.apiPromotionsCouponsGetSubresourcePromotionSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>>): Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> => r.body)
    );
  }

  /**
   * Retrieves a Promotion resource.
   *
   * Retrieves a Promotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPromotionsCouponsGetSubresourcePromotionSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPromotionsCouponsGetSubresourcePromotionSubresource$Html$Response(params: ApiPromotionsCouponsGetSubresourcePromotionSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>>> {
    return apiPromotionsCouponsGetSubresourcePromotionSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Promotion resource.
   *
   * Retrieves a Promotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPromotionsCouponsGetSubresourcePromotionSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPromotionsCouponsGetSubresourcePromotionSubresource$Html(params: ApiPromotionsCouponsGetSubresourcePromotionSubresource$Html$Params, context?: HttpContext): Observable<Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return this.apiPromotionsCouponsGetSubresourcePromotionSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>>): Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetShippingMethodTranslationItem$Html } from '../fn/shipping-method-translation/admin-get-shipping-method-translation-item-html';
import { AdminGetShippingMethodTranslationItem$Html$Params } from '../fn/shipping-method-translation/admin-get-shipping-method-translation-item-html';
import { adminGetShippingMethodTranslationItem$Json } from '../fn/shipping-method-translation/admin-get-shipping-method-translation-item-json';
import { AdminGetShippingMethodTranslationItem$Json$Params } from '../fn/shipping-method-translation/admin-get-shipping-method-translation-item-json';
import { ShippingMethodTranslation } from '../models/shipping-method-translation';

@Injectable({ providedIn: 'root' })
export class ShippingMethodTranslationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetShippingMethodTranslationItem()` */
  static readonly AdminGetShippingMethodTranslationItemPath = '/api/v2/admin/shipping-method-translations/{id}';

  /**
   * Retrieves a ShippingMethodTranslation resource.
   *
   * Retrieves a ShippingMethodTranslation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShippingMethodTranslationItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingMethodTranslationItem$Json$Response(params: AdminGetShippingMethodTranslationItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodTranslation>> {
    return adminGetShippingMethodTranslationItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ShippingMethodTranslation resource.
   *
   * Retrieves a ShippingMethodTranslation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShippingMethodTranslationItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingMethodTranslationItem$Json(params: AdminGetShippingMethodTranslationItem$Json$Params, context?: HttpContext): Observable<ShippingMethodTranslation> {
    return this.adminGetShippingMethodTranslationItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodTranslation>): ShippingMethodTranslation => r.body)
    );
  }

  /**
   * Retrieves a ShippingMethodTranslation resource.
   *
   * Retrieves a ShippingMethodTranslation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShippingMethodTranslationItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingMethodTranslationItem$Html$Response(params: AdminGetShippingMethodTranslationItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingMethodTranslation>> {
    return adminGetShippingMethodTranslationItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ShippingMethodTranslation resource.
   *
   * Retrieves a ShippingMethodTranslation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShippingMethodTranslationItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingMethodTranslationItem$Html(params: AdminGetShippingMethodTranslationItem$Html$Params, context?: HttpContext): Observable<ShippingMethodTranslation> {
    return this.adminGetShippingMethodTranslationItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingMethodTranslation>): ShippingMethodTranslation => r.body)
    );
  }

}

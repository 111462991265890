/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Index as CustomerGroupAdminCustomerGroupIndexSyliusAdminCustomerGroupIndex } from '../../models/CustomerGroup-admin/customer_group/index_sylius/admin/customer_group/index';

export interface AdminGetCustomerGroupCollection$Json$Params {

/**
 * The collection page number
 */
  page?: number;

/**
 * The number of items per page
 */
  itemsPerPage?: number;
}

export function adminGetCustomerGroupCollection$Json(http: HttpClient, rootUrl: string, params?: AdminGetCustomerGroupCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerGroupAdminCustomerGroupIndexSyliusAdminCustomerGroupIndex>>> {
  const rb = new RequestBuilder(rootUrl, adminGetCustomerGroupCollection$Json.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<CustomerGroupAdminCustomerGroupIndexSyliusAdminCustomerGroupIndex>>;
    })
  );
}

adminGetCustomerGroupCollection$Json.PATH = '/api/v2/admin/customer-groups';

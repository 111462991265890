/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteProductImageItem } from '../fn/product-image/admin-delete-product-image-item';
import { AdminDeleteProductImageItem$Params } from '../fn/product-image/admin-delete-product-image-item';
import { adminGetProductImageCollection$Html } from '../fn/product-image/admin-get-product-image-collection-html';
import { AdminGetProductImageCollection$Html$Params } from '../fn/product-image/admin-get-product-image-collection-html';
import { adminGetProductImageCollection$Json } from '../fn/product-image/admin-get-product-image-collection-json';
import { AdminGetProductImageCollection$Json$Params } from '../fn/product-image/admin-get-product-image-collection-json';
import { adminGetProductImageItem$Html } from '../fn/product-image/admin-get-product-image-item-html';
import { AdminGetProductImageItem$Html$Params } from '../fn/product-image/admin-get-product-image-item-html';
import { adminGetProductImageItem$Json } from '../fn/product-image/admin-get-product-image-item-json';
import { AdminGetProductImageItem$Json$Params } from '../fn/product-image/admin-get-product-image-item-json';
import { adminPostProductImageCollection$Html } from '../fn/product-image/admin-post-product-image-collection-html';
import { AdminPostProductImageCollection$Html$Params } from '../fn/product-image/admin-post-product-image-collection-html';
import { adminPostProductImageCollection$Json } from '../fn/product-image/admin-post-product-image-collection-json';
import { AdminPostProductImageCollection$Json$Params } from '../fn/product-image/admin-post-product-image-collection-json';
import { adminPutProductImageItem$Html$Html } from '../fn/product-image/admin-put-product-image-item-html-html';
import { AdminPutProductImageItem$Html$Html$Params } from '../fn/product-image/admin-put-product-image-item-html-html';
import { adminPutProductImageItem$Html$Json } from '../fn/product-image/admin-put-product-image-item-html-json';
import { AdminPutProductImageItem$Html$Json$Params } from '../fn/product-image/admin-put-product-image-item-html-json';
import { adminPutProductImageItem$Json$Html } from '../fn/product-image/admin-put-product-image-item-json-html';
import { AdminPutProductImageItem$Json$Html$Params } from '../fn/product-image/admin-put-product-image-item-json-html';
import { adminPutProductImageItem$Json$Json } from '../fn/product-image/admin-put-product-image-item-json-json';
import { AdminPutProductImageItem$Json$Json$Params } from '../fn/product-image/admin-put-product-image-item-json-json';
import { apiProductsImagesGetSubresourceProductSubresource$Html } from '../fn/product-image/api-products-images-get-subresource-product-subresource-html';
import { ApiProductsImagesGetSubresourceProductSubresource$Html$Params } from '../fn/product-image/api-products-images-get-subresource-product-subresource-html';
import { apiProductsImagesGetSubresourceProductSubresource$Json } from '../fn/product-image/api-products-images-get-subresource-product-subresource-json';
import { ApiProductsImagesGetSubresourceProductSubresource$Json$Params } from '../fn/product-image/api-products-images-get-subresource-product-subresource-json';
import { Index as ProductImageAdminProductImageIndexSyliusAdminProductImageIndex } from '../models/ProductImage-admin/product_image/index_sylius/admin/product_image/index';
import { Show as ProductImageAdminProductImageShowSyliusAdminProductImageShow } from '../models/ProductImage-admin/product_image/show_sylius/admin/product_image/show';
import { Show as ProductImageShopProductImageShowSyliusShopProductImageShow } from '../models/ProductImage-shop/product_image/show_sylius/shop/product_image/show';
import { shopGetProductImageItem$Html } from '../fn/product-image/shop-get-product-image-item-html';
import { ShopGetProductImageItem$Html$Params } from '../fn/product-image/shop-get-product-image-item-html';
import { shopGetProductImageItem$Json } from '../fn/product-image/shop-get-product-image-item-json';
import { ShopGetProductImageItem$Json$Params } from '../fn/product-image/shop-get-product-image-item-json';

@Injectable({ providedIn: 'root' })
export class ProductImageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetProductImageCollection()` */
  static readonly AdminGetProductImageCollectionPath = '/api/v2/admin/product-images';

  /**
   * Retrieves the collection of ProductImage resources.
   *
   * Retrieves the collection of ProductImage resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductImageCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductImageCollection$Json$Response(params?: AdminGetProductImageCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductImageAdminProductImageIndexSyliusAdminProductImageIndex>>> {
    return adminGetProductImageCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductImage resources.
   *
   * Retrieves the collection of ProductImage resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductImageCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductImageCollection$Json(params?: AdminGetProductImageCollection$Json$Params, context?: HttpContext): Observable<Array<ProductImageAdminProductImageIndexSyliusAdminProductImageIndex>> {
    return this.adminGetProductImageCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductImageAdminProductImageIndexSyliusAdminProductImageIndex>>): Array<ProductImageAdminProductImageIndexSyliusAdminProductImageIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ProductImage resources.
   *
   * Retrieves the collection of ProductImage resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductImageCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductImageCollection$Html$Response(params?: AdminGetProductImageCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductImageAdminProductImageIndexSyliusAdminProductImageIndex>>> {
    return adminGetProductImageCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductImage resources.
   *
   * Retrieves the collection of ProductImage resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductImageCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductImageCollection$Html(params?: AdminGetProductImageCollection$Html$Params, context?: HttpContext): Observable<Array<ProductImageAdminProductImageIndexSyliusAdminProductImageIndex>> {
    return this.adminGetProductImageCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductImageAdminProductImageIndexSyliusAdminProductImageIndex>>): Array<ProductImageAdminProductImageIndexSyliusAdminProductImageIndex> => r.body)
    );
  }

  /** Path part for operation `adminGetProductImageItem()` */
  static readonly AdminGetProductImageItemPath = '/api/v2/admin/product-images/{id}';

  /**
   * Retrieves a ProductImage resource.
   *
   * Retrieves a ProductImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductImageItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductImageItem$Json$Response(params: AdminGetProductImageItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>> {
    return adminGetProductImageItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductImage resource.
   *
   * Retrieves a ProductImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductImageItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductImageItem$Json(params: AdminGetProductImageItem$Json$Params, context?: HttpContext): Observable<ProductImageAdminProductImageShowSyliusAdminProductImageShow> {
    return this.adminGetProductImageItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>): ProductImageAdminProductImageShowSyliusAdminProductImageShow => r.body)
    );
  }

  /**
   * Retrieves a ProductImage resource.
   *
   * Retrieves a ProductImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductImageItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductImageItem$Html$Response(params: AdminGetProductImageItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>> {
    return adminGetProductImageItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductImage resource.
   *
   * Retrieves a ProductImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductImageItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductImageItem$Html(params: AdminGetProductImageItem$Html$Params, context?: HttpContext): Observable<ProductImageAdminProductImageShowSyliusAdminProductImageShow> {
    return this.adminGetProductImageItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>): ProductImageAdminProductImageShowSyliusAdminProductImageShow => r.body)
    );
  }

  /** Path part for operation `adminPutProductImageItem()` */
  static readonly AdminPutProductImageItemPath = '/api/v2/admin/product-images/{id}';

  /**
   * Replaces the ProductImage resource.
   *
   * Replaces the ProductImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductImageItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductImageItem$Json$Json$Response(params: AdminPutProductImageItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>> {
    return adminPutProductImageItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductImage resource.
   *
   * Replaces the ProductImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductImageItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductImageItem$Json$Json(params: AdminPutProductImageItem$Json$Json$Params, context?: HttpContext): Observable<ProductImageAdminProductImageShowSyliusAdminProductImageShow> {
    return this.adminPutProductImageItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>): ProductImageAdminProductImageShowSyliusAdminProductImageShow => r.body)
    );
  }

  /**
   * Replaces the ProductImage resource.
   *
   * Replaces the ProductImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductImageItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductImageItem$Json$Html$Response(params: AdminPutProductImageItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>> {
    return adminPutProductImageItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductImage resource.
   *
   * Replaces the ProductImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductImageItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductImageItem$Json$Html(params: AdminPutProductImageItem$Json$Html$Params, context?: HttpContext): Observable<ProductImageAdminProductImageShowSyliusAdminProductImageShow> {
    return this.adminPutProductImageItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>): ProductImageAdminProductImageShowSyliusAdminProductImageShow => r.body)
    );
  }

  /**
   * Replaces the ProductImage resource.
   *
   * Replaces the ProductImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductImageItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductImageItem$Html$Json$Response(params: AdminPutProductImageItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>> {
    return adminPutProductImageItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductImage resource.
   *
   * Replaces the ProductImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductImageItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductImageItem$Html$Json(params: AdminPutProductImageItem$Html$Json$Params, context?: HttpContext): Observable<ProductImageAdminProductImageShowSyliusAdminProductImageShow> {
    return this.adminPutProductImageItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>): ProductImageAdminProductImageShowSyliusAdminProductImageShow => r.body)
    );
  }

  /**
   * Replaces the ProductImage resource.
   *
   * Replaces the ProductImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductImageItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductImageItem$Html$Html$Response(params: AdminPutProductImageItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>> {
    return adminPutProductImageItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductImage resource.
   *
   * Replaces the ProductImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductImageItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductImageItem$Html$Html(params: AdminPutProductImageItem$Html$Html$Params, context?: HttpContext): Observable<ProductImageAdminProductImageShowSyliusAdminProductImageShow> {
    return this.adminPutProductImageItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>): ProductImageAdminProductImageShowSyliusAdminProductImageShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteProductImageItem()` */
  static readonly AdminDeleteProductImageItemPath = '/api/v2/admin/product-images/{id}';

  /**
   * Removes the ProductImage resource.
   *
   * Removes the ProductImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteProductImageItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductImageItem$Response(params: AdminDeleteProductImageItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteProductImageItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the ProductImage resource.
   *
   * Removes the ProductImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteProductImageItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductImageItem(params: AdminDeleteProductImageItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteProductImageItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiProductsImagesGetSubresourceProductSubresource()` */
  static readonly ApiProductsImagesGetSubresourceProductSubresourcePath = '/api/v2/admin/products/{code}/images';

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductsImagesGetSubresourceProductSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsImagesGetSubresourceProductSubresource$Json$Response(params: ApiProductsImagesGetSubresourceProductSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow>>> {
    return apiProductsImagesGetSubresourceProductSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductsImagesGetSubresourceProductSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsImagesGetSubresourceProductSubresource$Json(params: ApiProductsImagesGetSubresourceProductSubresource$Json$Params, context?: HttpContext): Observable<Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow>> {
    return this.apiProductsImagesGetSubresourceProductSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow>>): Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow> => r.body)
    );
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductsImagesGetSubresourceProductSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsImagesGetSubresourceProductSubresource$Html$Response(params: ApiProductsImagesGetSubresourceProductSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow>>> {
    return apiProductsImagesGetSubresourceProductSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductsImagesGetSubresourceProductSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsImagesGetSubresourceProductSubresource$Html(params: ApiProductsImagesGetSubresourceProductSubresource$Html$Params, context?: HttpContext): Observable<Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow>> {
    return this.apiProductsImagesGetSubresourceProductSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow>>): Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow> => r.body)
    );
  }

  /** Path part for operation `adminPostProductImageCollection()` */
  static readonly AdminPostProductImageCollectionPath = '/api/v2/admin/products/{code}/images';

  /**
   * Creates a ProductImage resource.
   *
   * Creates a ProductImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductImageCollection$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminPostProductImageCollection$Json$Response(params: AdminPostProductImageCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>> {
    return adminPostProductImageCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductImage resource.
   *
   * Creates a ProductImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductImageCollection$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminPostProductImageCollection$Json(params: AdminPostProductImageCollection$Json$Params, context?: HttpContext): Observable<ProductImageAdminProductImageShowSyliusAdminProductImageShow> {
    return this.adminPostProductImageCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>): ProductImageAdminProductImageShowSyliusAdminProductImageShow => r.body)
    );
  }

  /**
   * Creates a ProductImage resource.
   *
   * Creates a ProductImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductImageCollection$Html()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminPostProductImageCollection$Html$Response(params: AdminPostProductImageCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>> {
    return adminPostProductImageCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductImage resource.
   *
   * Creates a ProductImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductImageCollection$Html$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminPostProductImageCollection$Html(params: AdminPostProductImageCollection$Html$Params, context?: HttpContext): Observable<ProductImageAdminProductImageShowSyliusAdminProductImageShow> {
    return this.adminPostProductImageCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductImageAdminProductImageShowSyliusAdminProductImageShow>): ProductImageAdminProductImageShowSyliusAdminProductImageShow => r.body)
    );
  }

  /** Path part for operation `shopGetProductImageItem()` */
  static readonly ShopGetProductImageItemPath = '/api/v2/shop/product-images/{id}';

  /**
   * Retrieves a ProductImage resource.
   *
   * Retrieves a ProductImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductImageItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductImageItem$Json$Response(params: ShopGetProductImageItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductImageShopProductImageShowSyliusShopProductImageShow>> {
    return shopGetProductImageItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductImage resource.
   *
   * Retrieves a ProductImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductImageItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductImageItem$Json(params: ShopGetProductImageItem$Json$Params, context?: HttpContext): Observable<ProductImageShopProductImageShowSyliusShopProductImageShow> {
    return this.shopGetProductImageItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductImageShopProductImageShowSyliusShopProductImageShow>): ProductImageShopProductImageShowSyliusShopProductImageShow => r.body)
    );
  }

  /**
   * Retrieves a ProductImage resource.
   *
   * Retrieves a ProductImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductImageItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductImageItem$Html$Response(params: ShopGetProductImageItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductImageShopProductImageShowSyliusShopProductImageShow>> {
    return shopGetProductImageItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductImage resource.
   *
   * Retrieves a ProductImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductImageItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductImageItem$Html(params: ShopGetProductImageItem$Html$Params, context?: HttpContext): Observable<ProductImageShopProductImageShowSyliusShopProductImageShow> {
    return this.shopGetProductImageItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductImageShopProductImageShowSyliusShopProductImageShow>): ProductImageShopProductImageShowSyliusShopProductImageShow => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteAvatarImageItem } from '../fn/avatar-image/admin-delete-avatar-image-item';
import { AdminDeleteAvatarImageItem$Params } from '../fn/avatar-image/admin-delete-avatar-image-item';
import { adminGetAvatarImageItem$Html } from '../fn/avatar-image/admin-get-avatar-image-item-html';
import { AdminGetAvatarImageItem$Html$Params } from '../fn/avatar-image/admin-get-avatar-image-item-html';
import { adminGetAvatarImageItem$Json } from '../fn/avatar-image/admin-get-avatar-image-item-json';
import { AdminGetAvatarImageItem$Json$Params } from '../fn/avatar-image/admin-get-avatar-image-item-json';
import { adminPostAvatarImageCollection$Html } from '../fn/avatar-image/admin-post-avatar-image-collection-html';
import { AdminPostAvatarImageCollection$Html$Params } from '../fn/avatar-image/admin-post-avatar-image-collection-html';
import { adminPostAvatarImageCollection$Json } from '../fn/avatar-image/admin-post-avatar-image-collection-json';
import { AdminPostAvatarImageCollection$Json$Params } from '../fn/avatar-image/admin-post-avatar-image-collection-json';
import { Show as AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow } from '../models/AvatarImage-admin/avatar_image/show_sylius/admin/avatar_image/show';

@Injectable({ providedIn: 'root' })
export class AvatarImageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminPostAvatarImageCollection()` */
  static readonly AdminPostAvatarImageCollectionPath = '/api/v2/admin/avatar-images';

  /**
   * Creates a AvatarImage resource.
   *
   * Creates a AvatarImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostAvatarImageCollection$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminPostAvatarImageCollection$Json$Response(params?: AdminPostAvatarImageCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow>> {
    return adminPostAvatarImageCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a AvatarImage resource.
   *
   * Creates a AvatarImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostAvatarImageCollection$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminPostAvatarImageCollection$Json(params?: AdminPostAvatarImageCollection$Json$Params, context?: HttpContext): Observable<AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow> {
    return this.adminPostAvatarImageCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow>): AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow => r.body)
    );
  }

  /**
   * Creates a AvatarImage resource.
   *
   * Creates a AvatarImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostAvatarImageCollection$Html()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminPostAvatarImageCollection$Html$Response(params?: AdminPostAvatarImageCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow>> {
    return adminPostAvatarImageCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a AvatarImage resource.
   *
   * Creates a AvatarImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostAvatarImageCollection$Html$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminPostAvatarImageCollection$Html(params?: AdminPostAvatarImageCollection$Html$Params, context?: HttpContext): Observable<AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow> {
    return this.adminPostAvatarImageCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow>): AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow => r.body)
    );
  }

  /** Path part for operation `adminGetAvatarImageItem()` */
  static readonly AdminGetAvatarImageItemPath = '/api/v2/admin/avatar-images/{id}';

  /**
   * Retrieves a AvatarImage resource.
   *
   * Retrieves a AvatarImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetAvatarImageItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAvatarImageItem$Json$Response(params: AdminGetAvatarImageItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow>> {
    return adminGetAvatarImageItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a AvatarImage resource.
   *
   * Retrieves a AvatarImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetAvatarImageItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAvatarImageItem$Json(params: AdminGetAvatarImageItem$Json$Params, context?: HttpContext): Observable<AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow> {
    return this.adminGetAvatarImageItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow>): AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow => r.body)
    );
  }

  /**
   * Retrieves a AvatarImage resource.
   *
   * Retrieves a AvatarImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetAvatarImageItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAvatarImageItem$Html$Response(params: AdminGetAvatarImageItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow>> {
    return adminGetAvatarImageItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a AvatarImage resource.
   *
   * Retrieves a AvatarImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetAvatarImageItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAvatarImageItem$Html(params: AdminGetAvatarImageItem$Html$Params, context?: HttpContext): Observable<AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow> {
    return this.adminGetAvatarImageItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow>): AvatarImageAdminAvatarImageShowSyliusAdminAvatarImageShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteAvatarImageItem()` */
  static readonly AdminDeleteAvatarImageItemPath = '/api/v2/admin/avatar-images/{id}';

  /**
   * Removes the AvatarImage resource.
   *
   * Removes the AvatarImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteAvatarImageItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteAvatarImageItem$Response(params: AdminDeleteAvatarImageItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteAvatarImageItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the AvatarImage resource.
   *
   * Removes the AvatarImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteAvatarImageItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteAvatarImageItem(params: AdminDeleteAvatarImageItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteAvatarImageItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteChannelItem } from '../fn/channel/admin-delete-channel-item';
import { AdminDeleteChannelItem$Params } from '../fn/channel/admin-delete-channel-item';
import { adminGetChannelCollection$Html } from '../fn/channel/admin-get-channel-collection-html';
import { AdminGetChannelCollection$Html$Params } from '../fn/channel/admin-get-channel-collection-html';
import { adminGetChannelCollection$Json } from '../fn/channel/admin-get-channel-collection-json';
import { AdminGetChannelCollection$Json$Params } from '../fn/channel/admin-get-channel-collection-json';
import { adminGetChannelItem$Html } from '../fn/channel/admin-get-channel-item-html';
import { AdminGetChannelItem$Html$Params } from '../fn/channel/admin-get-channel-item-html';
import { adminGetChannelItem$Json } from '../fn/channel/admin-get-channel-item-json';
import { AdminGetChannelItem$Json$Params } from '../fn/channel/admin-get-channel-item-json';
import { adminPostChannelCollection$Html$Html } from '../fn/channel/admin-post-channel-collection-html-html';
import { AdminPostChannelCollection$Html$Html$Params } from '../fn/channel/admin-post-channel-collection-html-html';
import { adminPostChannelCollection$Html$Json } from '../fn/channel/admin-post-channel-collection-html-json';
import { AdminPostChannelCollection$Html$Json$Params } from '../fn/channel/admin-post-channel-collection-html-json';
import { adminPostChannelCollection$Json$Html } from '../fn/channel/admin-post-channel-collection-json-html';
import { AdminPostChannelCollection$Json$Html$Params } from '../fn/channel/admin-post-channel-collection-json-html';
import { adminPostChannelCollection$Json$Json } from '../fn/channel/admin-post-channel-collection-json-json';
import { AdminPostChannelCollection$Json$Json$Params } from '../fn/channel/admin-post-channel-collection-json-json';
import { adminPutChannelItem$Html$Html } from '../fn/channel/admin-put-channel-item-html-html';
import { AdminPutChannelItem$Html$Html$Params } from '../fn/channel/admin-put-channel-item-html-html';
import { adminPutChannelItem$Html$Json } from '../fn/channel/admin-put-channel-item-html-json';
import { AdminPutChannelItem$Html$Json$Params } from '../fn/channel/admin-put-channel-item-html-json';
import { adminPutChannelItem$Json$Html } from '../fn/channel/admin-put-channel-item-json-html';
import { AdminPutChannelItem$Json$Html$Params } from '../fn/channel/admin-put-channel-item-json-html';
import { adminPutChannelItem$Json$Json } from '../fn/channel/admin-put-channel-item-json-json';
import { AdminPutChannelItem$Json$Json$Params } from '../fn/channel/admin-put-channel-item-json-json';
import { apiChannelsShopBillingDataGetSubresourceChannelSubresource$Html } from '../fn/shop-billing-data/api-channels-shop-billing-data-get-subresource-channel-subresource-html';
import { ApiChannelsShopBillingDataGetSubresourceChannelSubresource$Html$Params } from '../fn/shop-billing-data/api-channels-shop-billing-data-get-subresource-channel-subresource-html';
import { apiChannelsShopBillingDataGetSubresourceChannelSubresource$Json } from '../fn/shop-billing-data/api-channels-shop-billing-data-get-subresource-channel-subresource-json';
import { ApiChannelsShopBillingDataGetSubresourceChannelSubresource$Json$Params } from '../fn/shop-billing-data/api-channels-shop-billing-data-get-subresource-channel-subresource-json';
import { Index as ChannelAdminChannelIndexSyliusAdminChannelIndex } from '../models/Channel-admin/channel/index_sylius/admin/channel/index';
import { Show as ChannelAdminChannelShowSyliusAdminChannelShow } from '../models/Channel-admin/channel/show_sylius/admin/channel/show';
import { Index as ChannelShopChannelIndexSyliusShopChannelIndex } from '../models/Channel-shop/channel/index_sylius/shop/channel/index';
import { Show as ChannelShopChannelShowSyliusShopChannelShow } from '../models/Channel-shop/channel/show_sylius/shop/channel/show';
import { Show as ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow } from '../models/ShopBillingData-admin/shop_billing_data/read_sylius/admin/shop_billing_data/show';
import { shopGetChannelCollection$Html } from '../fn/channel/shop-get-channel-collection-html';
import { ShopGetChannelCollection$Html$Params } from '../fn/channel/shop-get-channel-collection-html';
import { shopGetChannelCollection$Json } from '../fn/channel/shop-get-channel-collection-json';
import { ShopGetChannelCollection$Json$Params } from '../fn/channel/shop-get-channel-collection-json';
import { shopGetChannelItem$Html } from '../fn/channel/shop-get-channel-item-html';
import { ShopGetChannelItem$Html$Params } from '../fn/channel/shop-get-channel-item-html';
import { shopGetChannelItem$Json } from '../fn/channel/shop-get-channel-item-json';
import { ShopGetChannelItem$Json$Params } from '../fn/channel/shop-get-channel-item-json';

@Injectable({ providedIn: 'root' })
export class ChannelService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetChannelCollection()` */
  static readonly AdminGetChannelCollectionPath = '/api/v2/admin/channels';

  /**
   * Retrieves the collection of Channel resources.
   *
   * Retrieves the collection of Channel resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetChannelCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelCollection$Json$Response(params?: AdminGetChannelCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChannelAdminChannelIndexSyliusAdminChannelIndex>>> {
    return adminGetChannelCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Channel resources.
   *
   * Retrieves the collection of Channel resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetChannelCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelCollection$Json(params?: AdminGetChannelCollection$Json$Params, context?: HttpContext): Observable<Array<ChannelAdminChannelIndexSyliusAdminChannelIndex>> {
    return this.adminGetChannelCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChannelAdminChannelIndexSyliusAdminChannelIndex>>): Array<ChannelAdminChannelIndexSyliusAdminChannelIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Channel resources.
   *
   * Retrieves the collection of Channel resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetChannelCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelCollection$Html$Response(params?: AdminGetChannelCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChannelAdminChannelIndexSyliusAdminChannelIndex>>> {
    return adminGetChannelCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Channel resources.
   *
   * Retrieves the collection of Channel resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetChannelCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelCollection$Html(params?: AdminGetChannelCollection$Html$Params, context?: HttpContext): Observable<Array<ChannelAdminChannelIndexSyliusAdminChannelIndex>> {
    return this.adminGetChannelCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChannelAdminChannelIndexSyliusAdminChannelIndex>>): Array<ChannelAdminChannelIndexSyliusAdminChannelIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostChannelCollection()` */
  static readonly AdminPostChannelCollectionPath = '/api/v2/admin/channels';

  /**
   * Creates a Channel resource.
   *
   * Creates a Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostChannelCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostChannelCollection$Json$Json$Response(params: AdminPostChannelCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>> {
    return adminPostChannelCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Channel resource.
   *
   * Creates a Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostChannelCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostChannelCollection$Json$Json(params: AdminPostChannelCollection$Json$Json$Params, context?: HttpContext): Observable<ChannelAdminChannelShowSyliusAdminChannelShow> {
    return this.adminPostChannelCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>): ChannelAdminChannelShowSyliusAdminChannelShow => r.body)
    );
  }

  /**
   * Creates a Channel resource.
   *
   * Creates a Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostChannelCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostChannelCollection$Json$Html$Response(params: AdminPostChannelCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>> {
    return adminPostChannelCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Channel resource.
   *
   * Creates a Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostChannelCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostChannelCollection$Json$Html(params: AdminPostChannelCollection$Json$Html$Params, context?: HttpContext): Observable<ChannelAdminChannelShowSyliusAdminChannelShow> {
    return this.adminPostChannelCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>): ChannelAdminChannelShowSyliusAdminChannelShow => r.body)
    );
  }

  /**
   * Creates a Channel resource.
   *
   * Creates a Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostChannelCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostChannelCollection$Html$Json$Response(params: AdminPostChannelCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>> {
    return adminPostChannelCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Channel resource.
   *
   * Creates a Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostChannelCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostChannelCollection$Html$Json(params: AdminPostChannelCollection$Html$Json$Params, context?: HttpContext): Observable<ChannelAdminChannelShowSyliusAdminChannelShow> {
    return this.adminPostChannelCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>): ChannelAdminChannelShowSyliusAdminChannelShow => r.body)
    );
  }

  /**
   * Creates a Channel resource.
   *
   * Creates a Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostChannelCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostChannelCollection$Html$Html$Response(params: AdminPostChannelCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>> {
    return adminPostChannelCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Channel resource.
   *
   * Creates a Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostChannelCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostChannelCollection$Html$Html(params: AdminPostChannelCollection$Html$Html$Params, context?: HttpContext): Observable<ChannelAdminChannelShowSyliusAdminChannelShow> {
    return this.adminPostChannelCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>): ChannelAdminChannelShowSyliusAdminChannelShow => r.body)
    );
  }

  /** Path part for operation `adminGetChannelItem()` */
  static readonly AdminGetChannelItemPath = '/api/v2/admin/channels/{code}';

  /**
   * Use $code to retrieve a channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetChannelItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelItem$Json$Response(params: AdminGetChannelItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>> {
    return adminGetChannelItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Use $code to retrieve a channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetChannelItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelItem$Json(params: AdminGetChannelItem$Json$Params, context?: HttpContext): Observable<ChannelAdminChannelShowSyliusAdminChannelShow> {
    return this.adminGetChannelItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>): ChannelAdminChannelShowSyliusAdminChannelShow => r.body)
    );
  }

  /**
   * Use $code to retrieve a channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetChannelItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelItem$Html$Response(params: AdminGetChannelItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>> {
    return adminGetChannelItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Use $code to retrieve a channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetChannelItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelItem$Html(params: AdminGetChannelItem$Html$Params, context?: HttpContext): Observable<ChannelAdminChannelShowSyliusAdminChannelShow> {
    return this.adminGetChannelItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>): ChannelAdminChannelShowSyliusAdminChannelShow => r.body)
    );
  }

  /** Path part for operation `adminPutChannelItem()` */
  static readonly AdminPutChannelItemPath = '/api/v2/admin/channels/{code}';

  /**
   * Replaces the Channel resource.
   *
   * Replaces the Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutChannelItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutChannelItem$Json$Json$Response(params: AdminPutChannelItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>> {
    return adminPutChannelItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Channel resource.
   *
   * Replaces the Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutChannelItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutChannelItem$Json$Json(params: AdminPutChannelItem$Json$Json$Params, context?: HttpContext): Observable<ChannelAdminChannelShowSyliusAdminChannelShow> {
    return this.adminPutChannelItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>): ChannelAdminChannelShowSyliusAdminChannelShow => r.body)
    );
  }

  /**
   * Replaces the Channel resource.
   *
   * Replaces the Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutChannelItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutChannelItem$Json$Html$Response(params: AdminPutChannelItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>> {
    return adminPutChannelItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Channel resource.
   *
   * Replaces the Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutChannelItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutChannelItem$Json$Html(params: AdminPutChannelItem$Json$Html$Params, context?: HttpContext): Observable<ChannelAdminChannelShowSyliusAdminChannelShow> {
    return this.adminPutChannelItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>): ChannelAdminChannelShowSyliusAdminChannelShow => r.body)
    );
  }

  /**
   * Replaces the Channel resource.
   *
   * Replaces the Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutChannelItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutChannelItem$Html$Json$Response(params: AdminPutChannelItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>> {
    return adminPutChannelItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Channel resource.
   *
   * Replaces the Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutChannelItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutChannelItem$Html$Json(params: AdminPutChannelItem$Html$Json$Params, context?: HttpContext): Observable<ChannelAdminChannelShowSyliusAdminChannelShow> {
    return this.adminPutChannelItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>): ChannelAdminChannelShowSyliusAdminChannelShow => r.body)
    );
  }

  /**
   * Replaces the Channel resource.
   *
   * Replaces the Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutChannelItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutChannelItem$Html$Html$Response(params: AdminPutChannelItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>> {
    return adminPutChannelItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Channel resource.
   *
   * Replaces the Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutChannelItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutChannelItem$Html$Html(params: AdminPutChannelItem$Html$Html$Params, context?: HttpContext): Observable<ChannelAdminChannelShowSyliusAdminChannelShow> {
    return this.adminPutChannelItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>): ChannelAdminChannelShowSyliusAdminChannelShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteChannelItem()` */
  static readonly AdminDeleteChannelItemPath = '/api/v2/admin/channels/{code}';

  /**
   * Removes the Channel resource.
   *
   * Removes the Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteChannelItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteChannelItem$Response(params: AdminDeleteChannelItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteChannelItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Channel resource.
   *
   * Removes the Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteChannelItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteChannelItem(params: AdminDeleteChannelItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteChannelItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiChannelsShopBillingDataGetSubresourceChannelSubresource()` */
  static readonly ApiChannelsShopBillingDataGetSubresourceChannelSubresourcePath = '/api/v2/admin/channels/{code}/shop-billing-data';

  /**
   * Retrieves a Channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChannelsShopBillingDataGetSubresourceChannelSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChannelsShopBillingDataGetSubresourceChannelSubresource$Json$Response(params: ApiChannelsShopBillingDataGetSubresourceChannelSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow>> {
    return apiChannelsShopBillingDataGetSubresourceChannelSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiChannelsShopBillingDataGetSubresourceChannelSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChannelsShopBillingDataGetSubresourceChannelSubresource$Json(params: ApiChannelsShopBillingDataGetSubresourceChannelSubresource$Json$Params, context?: HttpContext): Observable<ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow> {
    return this.apiChannelsShopBillingDataGetSubresourceChannelSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow>): ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow => r.body)
    );
  }

  /**
   * Retrieves a Channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChannelsShopBillingDataGetSubresourceChannelSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChannelsShopBillingDataGetSubresourceChannelSubresource$Html$Response(params: ApiChannelsShopBillingDataGetSubresourceChannelSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow>> {
    return apiChannelsShopBillingDataGetSubresourceChannelSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiChannelsShopBillingDataGetSubresourceChannelSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChannelsShopBillingDataGetSubresourceChannelSubresource$Html(params: ApiChannelsShopBillingDataGetSubresourceChannelSubresource$Html$Params, context?: HttpContext): Observable<ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow> {
    return this.apiChannelsShopBillingDataGetSubresourceChannelSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow>): ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow => r.body)
    );
  }

  /** Path part for operation `shopGetChannelCollection()` */
  static readonly ShopGetChannelCollectionPath = '/api/v2/shop/channels';

  /**
   * Retrieves the collection of Channel resources.
   *
   * Retrieves the collection of Channel resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetChannelCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetChannelCollection$Json$Response(params?: ShopGetChannelCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChannelShopChannelIndexSyliusShopChannelIndex>>> {
    return shopGetChannelCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Channel resources.
   *
   * Retrieves the collection of Channel resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetChannelCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetChannelCollection$Json(params?: ShopGetChannelCollection$Json$Params, context?: HttpContext): Observable<Array<ChannelShopChannelIndexSyliusShopChannelIndex>> {
    return this.shopGetChannelCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChannelShopChannelIndexSyliusShopChannelIndex>>): Array<ChannelShopChannelIndexSyliusShopChannelIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Channel resources.
   *
   * Retrieves the collection of Channel resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetChannelCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetChannelCollection$Html$Response(params?: ShopGetChannelCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChannelShopChannelIndexSyliusShopChannelIndex>>> {
    return shopGetChannelCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Channel resources.
   *
   * Retrieves the collection of Channel resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetChannelCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetChannelCollection$Html(params?: ShopGetChannelCollection$Html$Params, context?: HttpContext): Observable<Array<ChannelShopChannelIndexSyliusShopChannelIndex>> {
    return this.shopGetChannelCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChannelShopChannelIndexSyliusShopChannelIndex>>): Array<ChannelShopChannelIndexSyliusShopChannelIndex> => r.body)
    );
  }

  /** Path part for operation `shopGetChannelItem()` */
  static readonly ShopGetChannelItemPath = '/api/v2/shop/channels/{code}';

  /**
   * Use $code to retrieve a channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetChannelItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetChannelItem$Json$Response(params: ShopGetChannelItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelShopChannelShowSyliusShopChannelShow>> {
    return shopGetChannelItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Use $code to retrieve a channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetChannelItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetChannelItem$Json(params: ShopGetChannelItem$Json$Params, context?: HttpContext): Observable<ChannelShopChannelShowSyliusShopChannelShow> {
    return this.shopGetChannelItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelShopChannelShowSyliusShopChannelShow>): ChannelShopChannelShowSyliusShopChannelShow => r.body)
    );
  }

  /**
   * Use $code to retrieve a channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetChannelItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetChannelItem$Html$Response(params: ShopGetChannelItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelShopChannelShowSyliusShopChannelShow>> {
    return shopGetChannelItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Use $code to retrieve a channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetChannelItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetChannelItem$Html(params: ShopGetChannelItem$Html$Params, context?: HttpContext): Observable<ChannelShopChannelShowSyliusShopChannelShow> {
    return this.shopGetChannelItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelShopChannelShowSyliusShopChannelShow>): ChannelShopChannelShowSyliusShopChannelShow => r.body)
    );
  }

}

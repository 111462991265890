/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetProductOptionValueItem$Html } from '../fn/product-option-value/admin-get-product-option-value-item-html';
import { AdminGetProductOptionValueItem$Html$Params } from '../fn/product-option-value/admin-get-product-option-value-item-html';
import { adminGetProductOptionValueItem$Json } from '../fn/product-option-value/admin-get-product-option-value-item-json';
import { AdminGetProductOptionValueItem$Json$Params } from '../fn/product-option-value/admin-get-product-option-value-item-json';
import { apiProductOptionsValuesGetSubresourceProductOptionSubresource$Html } from '../fn/product-option-value/api-product-options-values-get-subresource-product-option-subresource-html';
import { ApiProductOptionsValuesGetSubresourceProductOptionSubresource$Html$Params } from '../fn/product-option-value/api-product-options-values-get-subresource-product-option-subresource-html';
import { apiProductOptionsValuesGetSubresourceProductOptionSubresource$Json } from '../fn/product-option-value/api-product-options-values-get-subresource-product-option-subresource-json';
import { ApiProductOptionsValuesGetSubresourceProductOptionSubresource$Json$Params } from '../fn/product-option-value/api-product-options-values-get-subresource-product-option-subresource-json';
import { Show as ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow } from '../models/ProductOptionValue-admin/product_option_value/show_sylius/admin/product_option_value/show';
import { Show as ProductOptionValueShopProductOptionValueShowSyliusShopProductOptionValueShow } from '../models/ProductOptionValue-shop/product_option_value/show_sylius/shop/product_option_value/show';
import { shopGetProductOptionValueItem$Html } from '../fn/product-option-value/shop-get-product-option-value-item-html';
import { ShopGetProductOptionValueItem$Html$Params } from '../fn/product-option-value/shop-get-product-option-value-item-html';
import { shopGetProductOptionValueItem$Json } from '../fn/product-option-value/shop-get-product-option-value-item-json';
import { ShopGetProductOptionValueItem$Json$Params } from '../fn/product-option-value/shop-get-product-option-value-item-json';

@Injectable({ providedIn: 'root' })
export class ProductOptionValueService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetProductOptionValueItem()` */
  static readonly AdminGetProductOptionValueItemPath = '/api/v2/admin/product-option-values/{code}';

  /**
   * Retrieves a ProductOptionValue resource.
   *
   * Retrieves a ProductOptionValue resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductOptionValueItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductOptionValueItem$Json$Response(params: AdminGetProductOptionValueItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>> {
    return adminGetProductOptionValueItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductOptionValue resource.
   *
   * Retrieves a ProductOptionValue resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductOptionValueItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductOptionValueItem$Json(params: AdminGetProductOptionValueItem$Json$Params, context?: HttpContext): Observable<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow> {
    return this.adminGetProductOptionValueItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>): ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow => r.body)
    );
  }

  /**
   * Retrieves a ProductOptionValue resource.
   *
   * Retrieves a ProductOptionValue resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductOptionValueItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductOptionValueItem$Html$Response(params: AdminGetProductOptionValueItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>> {
    return adminGetProductOptionValueItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductOptionValue resource.
   *
   * Retrieves a ProductOptionValue resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductOptionValueItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductOptionValueItem$Html(params: AdminGetProductOptionValueItem$Html$Params, context?: HttpContext): Observable<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow> {
    return this.adminGetProductOptionValueItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>): ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow => r.body)
    );
  }

  /** Path part for operation `apiProductOptionsValuesGetSubresourceProductOptionSubresource()` */
  static readonly ApiProductOptionsValuesGetSubresourceProductOptionSubresourcePath = '/api/v2/admin/product-options/{code}/values';

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductOptionsValuesGetSubresourceProductOptionSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductOptionsValuesGetSubresourceProductOptionSubresource$Json$Response(params: ApiProductOptionsValuesGetSubresourceProductOptionSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>>> {
    return apiProductOptionsValuesGetSubresourceProductOptionSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductOptionsValuesGetSubresourceProductOptionSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductOptionsValuesGetSubresourceProductOptionSubresource$Json(params: ApiProductOptionsValuesGetSubresourceProductOptionSubresource$Json$Params, context?: HttpContext): Observable<Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>> {
    return this.apiProductOptionsValuesGetSubresourceProductOptionSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>>): Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow> => r.body)
    );
  }

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductOptionsValuesGetSubresourceProductOptionSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductOptionsValuesGetSubresourceProductOptionSubresource$Html$Response(params: ApiProductOptionsValuesGetSubresourceProductOptionSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>>> {
    return apiProductOptionsValuesGetSubresourceProductOptionSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductOption resource.
   *
   * Retrieves a ProductOption resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductOptionsValuesGetSubresourceProductOptionSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductOptionsValuesGetSubresourceProductOptionSubresource$Html(params: ApiProductOptionsValuesGetSubresourceProductOptionSubresource$Html$Params, context?: HttpContext): Observable<Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>> {
    return this.apiProductOptionsValuesGetSubresourceProductOptionSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow>>): Array<ProductOptionValueAdminProductOptionValueShowSyliusAdminProductOptionValueShow> => r.body)
    );
  }

  /** Path part for operation `shopGetProductOptionValueItem()` */
  static readonly ShopGetProductOptionValueItemPath = '/api/v2/shop/product-option-values/{code}';

  /**
   * Retrieves a ProductOptionValue resource.
   *
   * Retrieves a ProductOptionValue resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductOptionValueItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductOptionValueItem$Json$Response(params: ShopGetProductOptionValueItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionValueShopProductOptionValueShowSyliusShopProductOptionValueShow>> {
    return shopGetProductOptionValueItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductOptionValue resource.
   *
   * Retrieves a ProductOptionValue resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductOptionValueItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductOptionValueItem$Json(params: ShopGetProductOptionValueItem$Json$Params, context?: HttpContext): Observable<ProductOptionValueShopProductOptionValueShowSyliusShopProductOptionValueShow> {
    return this.shopGetProductOptionValueItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionValueShopProductOptionValueShowSyliusShopProductOptionValueShow>): ProductOptionValueShopProductOptionValueShowSyliusShopProductOptionValueShow => r.body)
    );
  }

  /**
   * Retrieves a ProductOptionValue resource.
   *
   * Retrieves a ProductOptionValue resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductOptionValueItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductOptionValueItem$Html$Response(params: ShopGetProductOptionValueItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductOptionValueShopProductOptionValueShowSyliusShopProductOptionValueShow>> {
    return shopGetProductOptionValueItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductOptionValue resource.
   *
   * Retrieves a ProductOptionValue resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductOptionValueItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductOptionValueItem$Html(params: ShopGetProductOptionValueItem$Html$Params, context?: HttpContext): Observable<ProductOptionValueShopProductOptionValueShowSyliusShopProductOptionValueShow> {
    return this.shopGetProductOptionValueItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductOptionValueShopProductOptionValueShowSyliusShopProductOptionValueShow>): ProductOptionValueShopProductOptionValueShowSyliusShopProductOptionValueShow => r.body)
    );
  }

}

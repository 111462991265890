/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteShippingCategoryItem } from '../fn/shipping-category/admin-delete-shipping-category-item';
import { AdminDeleteShippingCategoryItem$Params } from '../fn/shipping-category/admin-delete-shipping-category-item';
import { adminGetShippingCategoryCollection$Html } from '../fn/shipping-category/admin-get-shipping-category-collection-html';
import { AdminGetShippingCategoryCollection$Html$Params } from '../fn/shipping-category/admin-get-shipping-category-collection-html';
import { adminGetShippingCategoryCollection$Json } from '../fn/shipping-category/admin-get-shipping-category-collection-json';
import { AdminGetShippingCategoryCollection$Json$Params } from '../fn/shipping-category/admin-get-shipping-category-collection-json';
import { adminGetShippingCategoryItem$Html } from '../fn/shipping-category/admin-get-shipping-category-item-html';
import { AdminGetShippingCategoryItem$Html$Params } from '../fn/shipping-category/admin-get-shipping-category-item-html';
import { adminGetShippingCategoryItem$Json } from '../fn/shipping-category/admin-get-shipping-category-item-json';
import { AdminGetShippingCategoryItem$Json$Params } from '../fn/shipping-category/admin-get-shipping-category-item-json';
import { adminPostShippingCategoryCollection$Html$Html } from '../fn/shipping-category/admin-post-shipping-category-collection-html-html';
import { AdminPostShippingCategoryCollection$Html$Html$Params } from '../fn/shipping-category/admin-post-shipping-category-collection-html-html';
import { adminPostShippingCategoryCollection$Html$Json } from '../fn/shipping-category/admin-post-shipping-category-collection-html-json';
import { AdminPostShippingCategoryCollection$Html$Json$Params } from '../fn/shipping-category/admin-post-shipping-category-collection-html-json';
import { adminPostShippingCategoryCollection$Json$Html } from '../fn/shipping-category/admin-post-shipping-category-collection-json-html';
import { AdminPostShippingCategoryCollection$Json$Html$Params } from '../fn/shipping-category/admin-post-shipping-category-collection-json-html';
import { adminPostShippingCategoryCollection$Json$Json } from '../fn/shipping-category/admin-post-shipping-category-collection-json-json';
import { AdminPostShippingCategoryCollection$Json$Json$Params } from '../fn/shipping-category/admin-post-shipping-category-collection-json-json';
import { adminPutShippingCategoryItem$Html$Html } from '../fn/shipping-category/admin-put-shipping-category-item-html-html';
import { AdminPutShippingCategoryItem$Html$Html$Params } from '../fn/shipping-category/admin-put-shipping-category-item-html-html';
import { adminPutShippingCategoryItem$Html$Json } from '../fn/shipping-category/admin-put-shipping-category-item-html-json';
import { AdminPutShippingCategoryItem$Html$Json$Params } from '../fn/shipping-category/admin-put-shipping-category-item-html-json';
import { adminPutShippingCategoryItem$Json$Html } from '../fn/shipping-category/admin-put-shipping-category-item-json-html';
import { AdminPutShippingCategoryItem$Json$Html$Params } from '../fn/shipping-category/admin-put-shipping-category-item-json-html';
import { adminPutShippingCategoryItem$Json$Json } from '../fn/shipping-category/admin-put-shipping-category-item-json-json';
import { AdminPutShippingCategoryItem$Json$Json$Params } from '../fn/shipping-category/admin-put-shipping-category-item-json-json';
import { Index as ShippingCategoryAdminShippingCategoryIndexSyliusAdminShippingCategoryIndex } from '../models/ShippingCategory-admin/shipping_category/index_sylius/admin/shipping_category/index';
import { Show as ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow } from '../models/ShippingCategory-admin/shipping_category/show_sylius/admin/shipping_category/show';

@Injectable({ providedIn: 'root' })
export class ShippingCategoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetShippingCategoryCollection()` */
  static readonly AdminGetShippingCategoryCollectionPath = '/api/v2/admin/shipping-categories';

  /**
   * Retrieves the collection of ShippingCategory resources.
   *
   * Retrieves the collection of ShippingCategory resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShippingCategoryCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingCategoryCollection$Json$Response(params?: AdminGetShippingCategoryCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShippingCategoryAdminShippingCategoryIndexSyliusAdminShippingCategoryIndex>>> {
    return adminGetShippingCategoryCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ShippingCategory resources.
   *
   * Retrieves the collection of ShippingCategory resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShippingCategoryCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingCategoryCollection$Json(params?: AdminGetShippingCategoryCollection$Json$Params, context?: HttpContext): Observable<Array<ShippingCategoryAdminShippingCategoryIndexSyliusAdminShippingCategoryIndex>> {
    return this.adminGetShippingCategoryCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShippingCategoryAdminShippingCategoryIndexSyliusAdminShippingCategoryIndex>>): Array<ShippingCategoryAdminShippingCategoryIndexSyliusAdminShippingCategoryIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ShippingCategory resources.
   *
   * Retrieves the collection of ShippingCategory resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShippingCategoryCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingCategoryCollection$Html$Response(params?: AdminGetShippingCategoryCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShippingCategoryAdminShippingCategoryIndexSyliusAdminShippingCategoryIndex>>> {
    return adminGetShippingCategoryCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ShippingCategory resources.
   *
   * Retrieves the collection of ShippingCategory resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShippingCategoryCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingCategoryCollection$Html(params?: AdminGetShippingCategoryCollection$Html$Params, context?: HttpContext): Observable<Array<ShippingCategoryAdminShippingCategoryIndexSyliusAdminShippingCategoryIndex>> {
    return this.adminGetShippingCategoryCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShippingCategoryAdminShippingCategoryIndexSyliusAdminShippingCategoryIndex>>): Array<ShippingCategoryAdminShippingCategoryIndexSyliusAdminShippingCategoryIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostShippingCategoryCollection()` */
  static readonly AdminPostShippingCategoryCollectionPath = '/api/v2/admin/shipping-categories';

  /**
   * Creates a ShippingCategory resource.
   *
   * Creates a ShippingCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostShippingCategoryCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostShippingCategoryCollection$Json$Json$Response(params: AdminPostShippingCategoryCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>> {
    return adminPostShippingCategoryCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ShippingCategory resource.
   *
   * Creates a ShippingCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostShippingCategoryCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostShippingCategoryCollection$Json$Json(params: AdminPostShippingCategoryCollection$Json$Json$Params, context?: HttpContext): Observable<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow> {
    return this.adminPostShippingCategoryCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>): ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow => r.body)
    );
  }

  /**
   * Creates a ShippingCategory resource.
   *
   * Creates a ShippingCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostShippingCategoryCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostShippingCategoryCollection$Json$Html$Response(params: AdminPostShippingCategoryCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>> {
    return adminPostShippingCategoryCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ShippingCategory resource.
   *
   * Creates a ShippingCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostShippingCategoryCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostShippingCategoryCollection$Json$Html(params: AdminPostShippingCategoryCollection$Json$Html$Params, context?: HttpContext): Observable<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow> {
    return this.adminPostShippingCategoryCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>): ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow => r.body)
    );
  }

  /**
   * Creates a ShippingCategory resource.
   *
   * Creates a ShippingCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostShippingCategoryCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostShippingCategoryCollection$Html$Json$Response(params: AdminPostShippingCategoryCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>> {
    return adminPostShippingCategoryCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ShippingCategory resource.
   *
   * Creates a ShippingCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostShippingCategoryCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostShippingCategoryCollection$Html$Json(params: AdminPostShippingCategoryCollection$Html$Json$Params, context?: HttpContext): Observable<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow> {
    return this.adminPostShippingCategoryCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>): ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow => r.body)
    );
  }

  /**
   * Creates a ShippingCategory resource.
   *
   * Creates a ShippingCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostShippingCategoryCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostShippingCategoryCollection$Html$Html$Response(params: AdminPostShippingCategoryCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>> {
    return adminPostShippingCategoryCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ShippingCategory resource.
   *
   * Creates a ShippingCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostShippingCategoryCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostShippingCategoryCollection$Html$Html(params: AdminPostShippingCategoryCollection$Html$Html$Params, context?: HttpContext): Observable<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow> {
    return this.adminPostShippingCategoryCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>): ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow => r.body)
    );
  }

  /** Path part for operation `adminGetShippingCategoryItem()` */
  static readonly AdminGetShippingCategoryItemPath = '/api/v2/admin/shipping-categories/{code}';

  /**
   * Retrieves a ShippingCategory resource.
   *
   * Retrieves a ShippingCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShippingCategoryItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingCategoryItem$Json$Response(params: AdminGetShippingCategoryItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>> {
    return adminGetShippingCategoryItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ShippingCategory resource.
   *
   * Retrieves a ShippingCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShippingCategoryItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingCategoryItem$Json(params: AdminGetShippingCategoryItem$Json$Params, context?: HttpContext): Observable<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow> {
    return this.adminGetShippingCategoryItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>): ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow => r.body)
    );
  }

  /**
   * Retrieves a ShippingCategory resource.
   *
   * Retrieves a ShippingCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShippingCategoryItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingCategoryItem$Html$Response(params: AdminGetShippingCategoryItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>> {
    return adminGetShippingCategoryItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ShippingCategory resource.
   *
   * Retrieves a ShippingCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShippingCategoryItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShippingCategoryItem$Html(params: AdminGetShippingCategoryItem$Html$Params, context?: HttpContext): Observable<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow> {
    return this.adminGetShippingCategoryItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>): ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow => r.body)
    );
  }

  /** Path part for operation `adminPutShippingCategoryItem()` */
  static readonly AdminPutShippingCategoryItemPath = '/api/v2/admin/shipping-categories/{code}';

  /**
   * Replaces the ShippingCategory resource.
   *
   * Replaces the ShippingCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutShippingCategoryItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutShippingCategoryItem$Json$Json$Response(params: AdminPutShippingCategoryItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>> {
    return adminPutShippingCategoryItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ShippingCategory resource.
   *
   * Replaces the ShippingCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutShippingCategoryItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutShippingCategoryItem$Json$Json(params: AdminPutShippingCategoryItem$Json$Json$Params, context?: HttpContext): Observable<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow> {
    return this.adminPutShippingCategoryItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>): ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow => r.body)
    );
  }

  /**
   * Replaces the ShippingCategory resource.
   *
   * Replaces the ShippingCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutShippingCategoryItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutShippingCategoryItem$Json$Html$Response(params: AdminPutShippingCategoryItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>> {
    return adminPutShippingCategoryItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ShippingCategory resource.
   *
   * Replaces the ShippingCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutShippingCategoryItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutShippingCategoryItem$Json$Html(params: AdminPutShippingCategoryItem$Json$Html$Params, context?: HttpContext): Observable<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow> {
    return this.adminPutShippingCategoryItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>): ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow => r.body)
    );
  }

  /**
   * Replaces the ShippingCategory resource.
   *
   * Replaces the ShippingCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutShippingCategoryItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutShippingCategoryItem$Html$Json$Response(params: AdminPutShippingCategoryItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>> {
    return adminPutShippingCategoryItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ShippingCategory resource.
   *
   * Replaces the ShippingCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutShippingCategoryItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutShippingCategoryItem$Html$Json(params: AdminPutShippingCategoryItem$Html$Json$Params, context?: HttpContext): Observable<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow> {
    return this.adminPutShippingCategoryItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>): ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow => r.body)
    );
  }

  /**
   * Replaces the ShippingCategory resource.
   *
   * Replaces the ShippingCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutShippingCategoryItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutShippingCategoryItem$Html$Html$Response(params: AdminPutShippingCategoryItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>> {
    return adminPutShippingCategoryItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ShippingCategory resource.
   *
   * Replaces the ShippingCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutShippingCategoryItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutShippingCategoryItem$Html$Html(params: AdminPutShippingCategoryItem$Html$Html$Params, context?: HttpContext): Observable<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow> {
    return this.adminPutShippingCategoryItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow>): ShippingCategoryAdminShippingCategoryShowSyliusAdminShippingCategoryShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteShippingCategoryItem()` */
  static readonly AdminDeleteShippingCategoryItemPath = '/api/v2/admin/shipping-categories/{code}';

  /**
   * Removes the ShippingCategory resource.
   *
   * Removes the ShippingCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteShippingCategoryItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteShippingCategoryItem$Response(params: AdminDeleteShippingCategoryItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteShippingCategoryItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the ShippingCategory resource.
   *
   * Removes the ShippingCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteShippingCategoryItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteShippingCategoryItem(params: AdminDeleteShippingCategoryItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteShippingCategoryItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

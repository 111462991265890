/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteTaxCategoryItem } from '../fn/tax-category/admin-delete-tax-category-item';
import { AdminDeleteTaxCategoryItem$Params } from '../fn/tax-category/admin-delete-tax-category-item';
import { adminGetTaxCategoryCollection$Html } from '../fn/tax-category/admin-get-tax-category-collection-html';
import { AdminGetTaxCategoryCollection$Html$Params } from '../fn/tax-category/admin-get-tax-category-collection-html';
import { adminGetTaxCategoryCollection$Json } from '../fn/tax-category/admin-get-tax-category-collection-json';
import { AdminGetTaxCategoryCollection$Json$Params } from '../fn/tax-category/admin-get-tax-category-collection-json';
import { adminGetTaxCategoryItem$Html } from '../fn/tax-category/admin-get-tax-category-item-html';
import { AdminGetTaxCategoryItem$Html$Params } from '../fn/tax-category/admin-get-tax-category-item-html';
import { adminGetTaxCategoryItem$Json } from '../fn/tax-category/admin-get-tax-category-item-json';
import { AdminGetTaxCategoryItem$Json$Params } from '../fn/tax-category/admin-get-tax-category-item-json';
import { adminPostTaxCategoryCollection$Html$Html } from '../fn/tax-category/admin-post-tax-category-collection-html-html';
import { AdminPostTaxCategoryCollection$Html$Html$Params } from '../fn/tax-category/admin-post-tax-category-collection-html-html';
import { adminPostTaxCategoryCollection$Html$Json } from '../fn/tax-category/admin-post-tax-category-collection-html-json';
import { AdminPostTaxCategoryCollection$Html$Json$Params } from '../fn/tax-category/admin-post-tax-category-collection-html-json';
import { adminPostTaxCategoryCollection$Json$Html } from '../fn/tax-category/admin-post-tax-category-collection-json-html';
import { AdminPostTaxCategoryCollection$Json$Html$Params } from '../fn/tax-category/admin-post-tax-category-collection-json-html';
import { adminPostTaxCategoryCollection$Json$Json } from '../fn/tax-category/admin-post-tax-category-collection-json-json';
import { AdminPostTaxCategoryCollection$Json$Json$Params } from '../fn/tax-category/admin-post-tax-category-collection-json-json';
import { adminPutTaxCategoryItem$Html$Html } from '../fn/tax-category/admin-put-tax-category-item-html-html';
import { AdminPutTaxCategoryItem$Html$Html$Params } from '../fn/tax-category/admin-put-tax-category-item-html-html';
import { adminPutTaxCategoryItem$Html$Json } from '../fn/tax-category/admin-put-tax-category-item-html-json';
import { AdminPutTaxCategoryItem$Html$Json$Params } from '../fn/tax-category/admin-put-tax-category-item-html-json';
import { adminPutTaxCategoryItem$Json$Html } from '../fn/tax-category/admin-put-tax-category-item-json-html';
import { AdminPutTaxCategoryItem$Json$Html$Params } from '../fn/tax-category/admin-put-tax-category-item-json-html';
import { adminPutTaxCategoryItem$Json$Json } from '../fn/tax-category/admin-put-tax-category-item-json-json';
import { AdminPutTaxCategoryItem$Json$Json$Params } from '../fn/tax-category/admin-put-tax-category-item-json-json';
import { Index as TaxCategoryAdminTaxCategoryIndexSyliusAdminTaxCategoryIndex } from '../models/TaxCategory-admin/tax_category/index_sylius/admin/tax_category/index';
import { Show as TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow } from '../models/TaxCategory-admin/tax_category/show_sylius/admin/tax_category/show';

@Injectable({ providedIn: 'root' })
export class TaxCategoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetTaxCategoryCollection()` */
  static readonly AdminGetTaxCategoryCollectionPath = '/api/v2/admin/tax-categories';

  /**
   * Retrieves the collection of TaxCategory resources.
   *
   * Retrieves the collection of TaxCategory resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxCategoryCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxCategoryCollection$Json$Response(params?: AdminGetTaxCategoryCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxCategoryAdminTaxCategoryIndexSyliusAdminTaxCategoryIndex>>> {
    return adminGetTaxCategoryCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of TaxCategory resources.
   *
   * Retrieves the collection of TaxCategory resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxCategoryCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxCategoryCollection$Json(params?: AdminGetTaxCategoryCollection$Json$Params, context?: HttpContext): Observable<Array<TaxCategoryAdminTaxCategoryIndexSyliusAdminTaxCategoryIndex>> {
    return this.adminGetTaxCategoryCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxCategoryAdminTaxCategoryIndexSyliusAdminTaxCategoryIndex>>): Array<TaxCategoryAdminTaxCategoryIndexSyliusAdminTaxCategoryIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of TaxCategory resources.
   *
   * Retrieves the collection of TaxCategory resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxCategoryCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxCategoryCollection$Html$Response(params?: AdminGetTaxCategoryCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxCategoryAdminTaxCategoryIndexSyliusAdminTaxCategoryIndex>>> {
    return adminGetTaxCategoryCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of TaxCategory resources.
   *
   * Retrieves the collection of TaxCategory resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxCategoryCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxCategoryCollection$Html(params?: AdminGetTaxCategoryCollection$Html$Params, context?: HttpContext): Observable<Array<TaxCategoryAdminTaxCategoryIndexSyliusAdminTaxCategoryIndex>> {
    return this.adminGetTaxCategoryCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxCategoryAdminTaxCategoryIndexSyliusAdminTaxCategoryIndex>>): Array<TaxCategoryAdminTaxCategoryIndexSyliusAdminTaxCategoryIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostTaxCategoryCollection()` */
  static readonly AdminPostTaxCategoryCollectionPath = '/api/v2/admin/tax-categories';

  /**
   * Creates a TaxCategory resource.
   *
   * Creates a TaxCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostTaxCategoryCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostTaxCategoryCollection$Json$Json$Response(params: AdminPostTaxCategoryCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>> {
    return adminPostTaxCategoryCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a TaxCategory resource.
   *
   * Creates a TaxCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostTaxCategoryCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostTaxCategoryCollection$Json$Json(params: AdminPostTaxCategoryCollection$Json$Json$Params, context?: HttpContext): Observable<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow> {
    return this.adminPostTaxCategoryCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>): TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow => r.body)
    );
  }

  /**
   * Creates a TaxCategory resource.
   *
   * Creates a TaxCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostTaxCategoryCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostTaxCategoryCollection$Json$Html$Response(params: AdminPostTaxCategoryCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>> {
    return adminPostTaxCategoryCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a TaxCategory resource.
   *
   * Creates a TaxCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostTaxCategoryCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostTaxCategoryCollection$Json$Html(params: AdminPostTaxCategoryCollection$Json$Html$Params, context?: HttpContext): Observable<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow> {
    return this.adminPostTaxCategoryCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>): TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow => r.body)
    );
  }

  /**
   * Creates a TaxCategory resource.
   *
   * Creates a TaxCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostTaxCategoryCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostTaxCategoryCollection$Html$Json$Response(params: AdminPostTaxCategoryCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>> {
    return adminPostTaxCategoryCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a TaxCategory resource.
   *
   * Creates a TaxCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostTaxCategoryCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostTaxCategoryCollection$Html$Json(params: AdminPostTaxCategoryCollection$Html$Json$Params, context?: HttpContext): Observable<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow> {
    return this.adminPostTaxCategoryCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>): TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow => r.body)
    );
  }

  /**
   * Creates a TaxCategory resource.
   *
   * Creates a TaxCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostTaxCategoryCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostTaxCategoryCollection$Html$Html$Response(params: AdminPostTaxCategoryCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>> {
    return adminPostTaxCategoryCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a TaxCategory resource.
   *
   * Creates a TaxCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostTaxCategoryCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostTaxCategoryCollection$Html$Html(params: AdminPostTaxCategoryCollection$Html$Html$Params, context?: HttpContext): Observable<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow> {
    return this.adminPostTaxCategoryCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>): TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow => r.body)
    );
  }

  /** Path part for operation `adminGetTaxCategoryItem()` */
  static readonly AdminGetTaxCategoryItemPath = '/api/v2/admin/tax-categories/{code}';

  /**
   * Retrieves a TaxCategory resource.
   *
   * Retrieves a TaxCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxCategoryItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxCategoryItem$Json$Response(params: AdminGetTaxCategoryItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>> {
    return adminGetTaxCategoryItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a TaxCategory resource.
   *
   * Retrieves a TaxCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxCategoryItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxCategoryItem$Json(params: AdminGetTaxCategoryItem$Json$Params, context?: HttpContext): Observable<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow> {
    return this.adminGetTaxCategoryItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>): TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow => r.body)
    );
  }

  /**
   * Retrieves a TaxCategory resource.
   *
   * Retrieves a TaxCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxCategoryItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxCategoryItem$Html$Response(params: AdminGetTaxCategoryItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>> {
    return adminGetTaxCategoryItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a TaxCategory resource.
   *
   * Retrieves a TaxCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxCategoryItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxCategoryItem$Html(params: AdminGetTaxCategoryItem$Html$Params, context?: HttpContext): Observable<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow> {
    return this.adminGetTaxCategoryItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>): TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow => r.body)
    );
  }

  /** Path part for operation `adminPutTaxCategoryItem()` */
  static readonly AdminPutTaxCategoryItemPath = '/api/v2/admin/tax-categories/{code}';

  /**
   * Replaces the TaxCategory resource.
   *
   * Replaces the TaxCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxCategoryItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxCategoryItem$Json$Json$Response(params: AdminPutTaxCategoryItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>> {
    return adminPutTaxCategoryItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the TaxCategory resource.
   *
   * Replaces the TaxCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxCategoryItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxCategoryItem$Json$Json(params: AdminPutTaxCategoryItem$Json$Json$Params, context?: HttpContext): Observable<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow> {
    return this.adminPutTaxCategoryItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>): TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow => r.body)
    );
  }

  /**
   * Replaces the TaxCategory resource.
   *
   * Replaces the TaxCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxCategoryItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxCategoryItem$Json$Html$Response(params: AdminPutTaxCategoryItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>> {
    return adminPutTaxCategoryItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the TaxCategory resource.
   *
   * Replaces the TaxCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxCategoryItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxCategoryItem$Json$Html(params: AdminPutTaxCategoryItem$Json$Html$Params, context?: HttpContext): Observable<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow> {
    return this.adminPutTaxCategoryItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>): TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow => r.body)
    );
  }

  /**
   * Replaces the TaxCategory resource.
   *
   * Replaces the TaxCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxCategoryItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxCategoryItem$Html$Json$Response(params: AdminPutTaxCategoryItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>> {
    return adminPutTaxCategoryItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the TaxCategory resource.
   *
   * Replaces the TaxCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxCategoryItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxCategoryItem$Html$Json(params: AdminPutTaxCategoryItem$Html$Json$Params, context?: HttpContext): Observable<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow> {
    return this.adminPutTaxCategoryItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>): TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow => r.body)
    );
  }

  /**
   * Replaces the TaxCategory resource.
   *
   * Replaces the TaxCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxCategoryItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxCategoryItem$Html$Html$Response(params: AdminPutTaxCategoryItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>> {
    return adminPutTaxCategoryItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the TaxCategory resource.
   *
   * Replaces the TaxCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxCategoryItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxCategoryItem$Html$Html(params: AdminPutTaxCategoryItem$Html$Html$Params, context?: HttpContext): Observable<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow> {
    return this.adminPutTaxCategoryItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow>): TaxCategoryAdminTaxCategoryShowSyliusAdminTaxCategoryShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteTaxCategoryItem()` */
  static readonly AdminDeleteTaxCategoryItemPath = '/api/v2/admin/tax-categories/{code}';

  /**
   * Removes the TaxCategory resource.
   *
   * Removes the TaxCategory resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteTaxCategoryItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteTaxCategoryItem$Response(params: AdminDeleteTaxCategoryItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteTaxCategoryItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the TaxCategory resource.
   *
   * Removes the TaxCategory resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteTaxCategoryItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteTaxCategoryItem(params: AdminDeleteTaxCategoryItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteTaxCategoryItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetZoneMemberItem$Html } from '../fn/zone-member/admin-get-zone-member-item-html';
import { AdminGetZoneMemberItem$Html$Params } from '../fn/zone-member/admin-get-zone-member-item-html';
import { adminGetZoneMemberItem$Json } from '../fn/zone-member/admin-get-zone-member-item-json';
import { AdminGetZoneMemberItem$Json$Params } from '../fn/zone-member/admin-get-zone-member-item-json';
import { apiZonesMembersGetSubresourceZoneSubresource$Html } from '../fn/zone-member/api-zones-members-get-subresource-zone-subresource-html';
import { ApiZonesMembersGetSubresourceZoneSubresource$Html$Params } from '../fn/zone-member/api-zones-members-get-subresource-zone-subresource-html';
import { apiZonesMembersGetSubresourceZoneSubresource$Json } from '../fn/zone-member/api-zones-members-get-subresource-zone-subresource-json';
import { ApiZonesMembersGetSubresourceZoneSubresource$Json$Params } from '../fn/zone-member/api-zones-members-get-subresource-zone-subresource-json';
import { Show as ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow } from '../models/ZoneMember-admin/zone_member/show_sylius/admin/zone_member/show';

@Injectable({ providedIn: 'root' })
export class ZoneMemberService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetZoneMemberItem()` */
  static readonly AdminGetZoneMemberItemPath = '/api/v2/admin/zone-members/{id}';

  /**
   * Retrieves a ZoneMember resource.
   *
   * Retrieves a ZoneMember resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetZoneMemberItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetZoneMemberItem$Json$Response(params: AdminGetZoneMemberItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>> {
    return adminGetZoneMemberItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ZoneMember resource.
   *
   * Retrieves a ZoneMember resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetZoneMemberItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetZoneMemberItem$Json(params: AdminGetZoneMemberItem$Json$Params, context?: HttpContext): Observable<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow> {
    return this.adminGetZoneMemberItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>): ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow => r.body)
    );
  }

  /**
   * Retrieves a ZoneMember resource.
   *
   * Retrieves a ZoneMember resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetZoneMemberItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetZoneMemberItem$Html$Response(params: AdminGetZoneMemberItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>> {
    return adminGetZoneMemberItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ZoneMember resource.
   *
   * Retrieves a ZoneMember resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetZoneMemberItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetZoneMemberItem$Html(params: AdminGetZoneMemberItem$Html$Params, context?: HttpContext): Observable<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow> {
    return this.adminGetZoneMemberItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>): ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow => r.body)
    );
  }

  /** Path part for operation `apiZonesMembersGetSubresourceZoneSubresource()` */
  static readonly ApiZonesMembersGetSubresourceZoneSubresourcePath = '/api/v2/admin/zones/{code}/members';

  /**
   * Retrieves a Zone resource.
   *
   * Retrieves a Zone resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiZonesMembersGetSubresourceZoneSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiZonesMembersGetSubresourceZoneSubresource$Json$Response(params: ApiZonesMembersGetSubresourceZoneSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>>> {
    return apiZonesMembersGetSubresourceZoneSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Zone resource.
   *
   * Retrieves a Zone resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiZonesMembersGetSubresourceZoneSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiZonesMembersGetSubresourceZoneSubresource$Json(params: ApiZonesMembersGetSubresourceZoneSubresource$Json$Params, context?: HttpContext): Observable<Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>> {
    return this.apiZonesMembersGetSubresourceZoneSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>>): Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow> => r.body)
    );
  }

  /**
   * Retrieves a Zone resource.
   *
   * Retrieves a Zone resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiZonesMembersGetSubresourceZoneSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiZonesMembersGetSubresourceZoneSubresource$Html$Response(params: ApiZonesMembersGetSubresourceZoneSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>>> {
    return apiZonesMembersGetSubresourceZoneSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Zone resource.
   *
   * Retrieves a Zone resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiZonesMembersGetSubresourceZoneSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiZonesMembersGetSubresourceZoneSubresource$Html(params: ApiZonesMembersGetSubresourceZoneSubresource$Html$Params, context?: HttpContext): Observable<Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>> {
    return this.apiZonesMembersGetSubresourceZoneSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow>>): Array<ZoneMemberAdminZoneMemberShowSyliusAdminZoneMemberShow> => r.body)
    );
  }

}

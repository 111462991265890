/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Index as AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex } from '../models/Adjustment-admin/order_item_unit/index_sylius/admin/order_item_unit/index';
import { adminGetOrderItemUnitItem$Html } from '../fn/order-item-unit/admin-get-order-item-unit-item-html';
import { AdminGetOrderItemUnitItem$Html$Params } from '../fn/order-item-unit/admin-get-order-item-unit-item-html';
import { adminGetOrderItemUnitItem$Json } from '../fn/order-item-unit/admin-get-order-item-unit-item-json';
import { AdminGetOrderItemUnitItem$Json$Params } from '../fn/order-item-unit/admin-get-order-item-unit-item-json';
import { apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html } from '../fn/adjustment/api-order-item-units-adjustments-get-subresource-order-item-unit-subresource-html';
import { ApiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html$Params } from '../fn/adjustment/api-order-item-units-adjustments-get-subresource-order-item-unit-subresource-html';
import { apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json } from '../fn/adjustment/api-order-item-units-adjustments-get-subresource-order-item-unit-subresource-json';
import { ApiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json$Params } from '../fn/adjustment/api-order-item-units-adjustments-get-subresource-order-item-unit-subresource-json';
import { Show as OrderItemUnitAdminOrderItemUnitShowSyliusAdminOrderItemUnitShow } from '../models/OrderItemUnit-admin/order_item_unit/show_sylius/admin/order_item_unit/show';
import { Show as OrderItemUnitShopOrderItemUnitShowSyliusShopOrderItemUnitShow } from '../models/OrderItemUnit-shop/order_item_unit/show_sylius/shop/order_item_unit/show';
import { shopGetOrderItemUnitItem$Html } from '../fn/order-item-unit/shop-get-order-item-unit-item-html';
import { ShopGetOrderItemUnitItem$Html$Params } from '../fn/order-item-unit/shop-get-order-item-unit-item-html';
import { shopGetOrderItemUnitItem$Json } from '../fn/order-item-unit/shop-get-order-item-unit-item-json';
import { ShopGetOrderItemUnitItem$Json$Params } from '../fn/order-item-unit/shop-get-order-item-unit-item-json';

@Injectable({ providedIn: 'root' })
export class OrderItemUnitService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetOrderItemUnitItem()` */
  static readonly AdminGetOrderItemUnitItemPath = '/api/v2/admin/order-item-units/{id}';

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetOrderItemUnitItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderItemUnitItem$Json$Response(params: AdminGetOrderItemUnitItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderItemUnitAdminOrderItemUnitShowSyliusAdminOrderItemUnitShow>> {
    return adminGetOrderItemUnitItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetOrderItemUnitItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderItemUnitItem$Json(params: AdminGetOrderItemUnitItem$Json$Params, context?: HttpContext): Observable<OrderItemUnitAdminOrderItemUnitShowSyliusAdminOrderItemUnitShow> {
    return this.adminGetOrderItemUnitItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderItemUnitAdminOrderItemUnitShowSyliusAdminOrderItemUnitShow>): OrderItemUnitAdminOrderItemUnitShowSyliusAdminOrderItemUnitShow => r.body)
    );
  }

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetOrderItemUnitItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderItemUnitItem$Html$Response(params: AdminGetOrderItemUnitItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderItemUnitAdminOrderItemUnitShowSyliusAdminOrderItemUnitShow>> {
    return adminGetOrderItemUnitItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetOrderItemUnitItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderItemUnitItem$Html(params: AdminGetOrderItemUnitItem$Html$Params, context?: HttpContext): Observable<OrderItemUnitAdminOrderItemUnitShowSyliusAdminOrderItemUnitShow> {
    return this.adminGetOrderItemUnitItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderItemUnitAdminOrderItemUnitShowSyliusAdminOrderItemUnitShow>): OrderItemUnitAdminOrderItemUnitShowSyliusAdminOrderItemUnitShow => r.body)
    );
  }

  /** Path part for operation `apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource()` */
  static readonly ApiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresourcePath = '/api/v2/admin/order-item-units/{id}/adjustments';

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json$Response(params: ApiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex>>> {
    return apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json(params: ApiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json$Params, context?: HttpContext): Observable<Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex>> {
    return this.apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex>>): Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex> => r.body)
    );
  }

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html$Response(params: ApiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex>>> {
    return apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html(params: ApiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html$Params, context?: HttpContext): Observable<Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex>> {
    return this.apiOrderItemUnitsAdjustmentsGetSubresourceOrderItemUnitSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex>>): Array<AdjustmentAdminOrderItemUnitIndexSyliusAdminOrderItemUnitIndex> => r.body)
    );
  }

  /** Path part for operation `shopGetOrderItemUnitItem()` */
  static readonly ShopGetOrderItemUnitItemPath = '/api/v2/shop/order-item-units/{id}';

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetOrderItemUnitItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderItemUnitItem$Json$Response(params: ShopGetOrderItemUnitItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderItemUnitShopOrderItemUnitShowSyliusShopOrderItemUnitShow>> {
    return shopGetOrderItemUnitItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetOrderItemUnitItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderItemUnitItem$Json(params: ShopGetOrderItemUnitItem$Json$Params, context?: HttpContext): Observable<OrderItemUnitShopOrderItemUnitShowSyliusShopOrderItemUnitShow> {
    return this.shopGetOrderItemUnitItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderItemUnitShopOrderItemUnitShowSyliusShopOrderItemUnitShow>): OrderItemUnitShopOrderItemUnitShowSyliusShopOrderItemUnitShow => r.body)
    );
  }

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetOrderItemUnitItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderItemUnitItem$Html$Response(params: ShopGetOrderItemUnitItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderItemUnitShopOrderItemUnitShowSyliusShopOrderItemUnitShow>> {
    return shopGetOrderItemUnitItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a OrderItemUnit resource.
   *
   * Retrieves a OrderItemUnit resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetOrderItemUnitItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderItemUnitItem$Html(params: ShopGetOrderItemUnitItem$Html$Params, context?: HttpContext): Observable<OrderItemUnitShopOrderItemUnitShowSyliusShopOrderItemUnitShow> {
    return this.shopGetOrderItemUnitItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderItemUnitShopOrderItemUnitShowSyliusShopOrderItemUnitShow>): OrderItemUnitShopOrderItemUnitShowSyliusShopOrderItemUnitShow => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteProductItem } from '../fn/product/admin-delete-product-item';
import { AdminDeleteProductItem$Params } from '../fn/product/admin-delete-product-item';
import { adminGetProductCollection$Html } from '../fn/product/admin-get-product-collection-html';
import { AdminGetProductCollection$Html$Params } from '../fn/product/admin-get-product-collection-html';
import { adminGetProductCollection$Json } from '../fn/product/admin-get-product-collection-json';
import { AdminGetProductCollection$Json$Params } from '../fn/product/admin-get-product-collection-json';
import { adminGetProductItem$Html } from '../fn/product/admin-get-product-item-html';
import { AdminGetProductItem$Html$Params } from '../fn/product/admin-get-product-item-html';
import { adminGetProductItem$Json } from '../fn/product/admin-get-product-item-json';
import { AdminGetProductItem$Json$Params } from '../fn/product/admin-get-product-item-json';
import { adminPostProductCollection$Html$Html } from '../fn/product/admin-post-product-collection-html-html';
import { AdminPostProductCollection$Html$Html$Params } from '../fn/product/admin-post-product-collection-html-html';
import { adminPostProductCollection$Html$Json } from '../fn/product/admin-post-product-collection-html-json';
import { AdminPostProductCollection$Html$Json$Params } from '../fn/product/admin-post-product-collection-html-json';
import { adminPostProductCollection$Json$Html } from '../fn/product/admin-post-product-collection-json-html';
import { AdminPostProductCollection$Json$Html$Params } from '../fn/product/admin-post-product-collection-json-html';
import { adminPostProductCollection$Json$Json } from '../fn/product/admin-post-product-collection-json-json';
import { AdminPostProductCollection$Json$Json$Params } from '../fn/product/admin-post-product-collection-json-json';
import { adminPutProductItem$Html$Html } from '../fn/product/admin-put-product-item-html-html';
import { AdminPutProductItem$Html$Html$Params } from '../fn/product/admin-put-product-item-html-html';
import { adminPutProductItem$Html$Json } from '../fn/product/admin-put-product-item-html-json';
import { AdminPutProductItem$Html$Json$Params } from '../fn/product/admin-put-product-item-html-json';
import { adminPutProductItem$Json$Html } from '../fn/product/admin-put-product-item-json-html';
import { AdminPutProductItem$Json$Html$Params } from '../fn/product/admin-put-product-item-json-html';
import { adminPutProductItem$Json$Json } from '../fn/product/admin-put-product-item-json-json';
import { AdminPutProductItem$Json$Json$Params } from '../fn/product/admin-put-product-item-json-json';
import { apiProductsAttributesGetSubresourceProductSubresource$Html } from '../fn/product-attribute-value/api-products-attributes-get-subresource-product-subresource-html';
import { ApiProductsAttributesGetSubresourceProductSubresource$Html$Params } from '../fn/product-attribute-value/api-products-attributes-get-subresource-product-subresource-html';
import { apiProductsAttributesGetSubresourceProductSubresource$Json } from '../fn/product-attribute-value/api-products-attributes-get-subresource-product-subresource-json';
import { ApiProductsAttributesGetSubresourceProductSubresource$Json$Params } from '../fn/product-attribute-value/api-products-attributes-get-subresource-product-subresource-json';
import { apiProductsImagesGetSubresourceProductSubresource$Html } from '../fn/product-image/api-products-images-get-subresource-product-subresource-html';
import { ApiProductsImagesGetSubresourceProductSubresource$Html$Params } from '../fn/product-image/api-products-images-get-subresource-product-subresource-html';
import { apiProductsImagesGetSubresourceProductSubresource$Json } from '../fn/product-image/api-products-images-get-subresource-product-subresource-json';
import { ApiProductsImagesGetSubresourceProductSubresource$Json$Params } from '../fn/product-image/api-products-images-get-subresource-product-subresource-json';
import { Index as ProductAdminProductIndexSyliusAdminProductIndex } from '../models/Product-admin/product/index_sylius/admin/product/index';
import { Show as ProductAdminProductShowSyliusAdminProductShow } from '../models/Product-admin/product/show_sylius/admin/product/show';
import { Index as ProductShopProductIndexSyliusShopProductIndex } from '../models/Product-shop/product/index_sylius/shop/product/index';
import { Show as ProductShopProductShowSyliusShopProductShow } from '../models/Product-shop/product/show_sylius/shop/product/show';
import { Show as ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow } from '../models/ProductAttributeValue-shop/product_attribute_value/show_sylius/shop/product_attribute_value/show';
import { Show as ProductImageAdminProductImageShowSyliusAdminProductImageShow } from '../models/ProductImage-admin/product_image/show_sylius/admin/product_image/show';
import { shopGetBySlugProductItem$Html } from '../fn/product/shop-get-by-slug-product-item-html';
import { ShopGetBySlugProductItem$Html$Params } from '../fn/product/shop-get-by-slug-product-item-html';
import { shopGetBySlugProductItem$Json } from '../fn/product/shop-get-by-slug-product-item-json';
import { ShopGetBySlugProductItem$Json$Params } from '../fn/product/shop-get-by-slug-product-item-json';
import { shopGetProductCollection$Html } from '../fn/product/shop-get-product-collection-html';
import { ShopGetProductCollection$Html$Params } from '../fn/product/shop-get-product-collection-html';
import { shopGetProductCollection$Json } from '../fn/product/shop-get-product-collection-json';
import { ShopGetProductCollection$Json$Params } from '../fn/product/shop-get-product-collection-json';
import { shopGetProductItem$Html } from '../fn/product/shop-get-product-item-html';
import { ShopGetProductItem$Html$Params } from '../fn/product/shop-get-product-item-html';
import { shopGetProductItem$Json } from '../fn/product/shop-get-product-item-json';
import { ShopGetProductItem$Json$Params } from '../fn/product/shop-get-product-item-json';

@Injectable({ providedIn: 'root' })
export class ProductService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetProductCollection()` */
  static readonly AdminGetProductCollectionPath = '/api/v2/admin/products';

  /**
   * Retrieves the collection of Product resources.
   *
   * Retrieves the collection of Product resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductCollection$Json$Response(params?: AdminGetProductCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductAdminProductIndexSyliusAdminProductIndex>>> {
    return adminGetProductCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Product resources.
   *
   * Retrieves the collection of Product resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductCollection$Json(params?: AdminGetProductCollection$Json$Params, context?: HttpContext): Observable<Array<ProductAdminProductIndexSyliusAdminProductIndex>> {
    return this.adminGetProductCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductAdminProductIndexSyliusAdminProductIndex>>): Array<ProductAdminProductIndexSyliusAdminProductIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Product resources.
   *
   * Retrieves the collection of Product resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductCollection$Html$Response(params?: AdminGetProductCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductAdminProductIndexSyliusAdminProductIndex>>> {
    return adminGetProductCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Product resources.
   *
   * Retrieves the collection of Product resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductCollection$Html(params?: AdminGetProductCollection$Html$Params, context?: HttpContext): Observable<Array<ProductAdminProductIndexSyliusAdminProductIndex>> {
    return this.adminGetProductCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductAdminProductIndexSyliusAdminProductIndex>>): Array<ProductAdminProductIndexSyliusAdminProductIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostProductCollection()` */
  static readonly AdminPostProductCollectionPath = '/api/v2/admin/products';

  /**
   * Creates a Product resource.
   *
   * Creates a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductCollection$Json$Json$Response(params: AdminPostProductCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>> {
    return adminPostProductCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Product resource.
   *
   * Creates a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductCollection$Json$Json(params: AdminPostProductCollection$Json$Json$Params, context?: HttpContext): Observable<ProductAdminProductShowSyliusAdminProductShow> {
    return this.adminPostProductCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>): ProductAdminProductShowSyliusAdminProductShow => r.body)
    );
  }

  /**
   * Creates a Product resource.
   *
   * Creates a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductCollection$Json$Html$Response(params: AdminPostProductCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>> {
    return adminPostProductCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Product resource.
   *
   * Creates a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductCollection$Json$Html(params: AdminPostProductCollection$Json$Html$Params, context?: HttpContext): Observable<ProductAdminProductShowSyliusAdminProductShow> {
    return this.adminPostProductCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>): ProductAdminProductShowSyliusAdminProductShow => r.body)
    );
  }

  /**
   * Creates a Product resource.
   *
   * Creates a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductCollection$Html$Json$Response(params: AdminPostProductCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>> {
    return adminPostProductCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Product resource.
   *
   * Creates a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductCollection$Html$Json(params: AdminPostProductCollection$Html$Json$Params, context?: HttpContext): Observable<ProductAdminProductShowSyliusAdminProductShow> {
    return this.adminPostProductCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>): ProductAdminProductShowSyliusAdminProductShow => r.body)
    );
  }

  /**
   * Creates a Product resource.
   *
   * Creates a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductCollection$Html$Html$Response(params: AdminPostProductCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>> {
    return adminPostProductCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Product resource.
   *
   * Creates a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductCollection$Html$Html(params: AdminPostProductCollection$Html$Html$Params, context?: HttpContext): Observable<ProductAdminProductShowSyliusAdminProductShow> {
    return this.adminPostProductCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>): ProductAdminProductShowSyliusAdminProductShow => r.body)
    );
  }

  /** Path part for operation `adminGetProductItem()` */
  static readonly AdminGetProductItemPath = '/api/v2/admin/products/{code}';

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductItem$Json$Response(params: AdminGetProductItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>> {
    return adminGetProductItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductItem$Json(params: AdminGetProductItem$Json$Params, context?: HttpContext): Observable<ProductAdminProductShowSyliusAdminProductShow> {
    return this.adminGetProductItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>): ProductAdminProductShowSyliusAdminProductShow => r.body)
    );
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductItem$Html$Response(params: AdminGetProductItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>> {
    return adminGetProductItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductItem$Html(params: AdminGetProductItem$Html$Params, context?: HttpContext): Observable<ProductAdminProductShowSyliusAdminProductShow> {
    return this.adminGetProductItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>): ProductAdminProductShowSyliusAdminProductShow => r.body)
    );
  }

  /** Path part for operation `adminPutProductItem()` */
  static readonly AdminPutProductItemPath = '/api/v2/admin/products/{code}';

  /**
   * Replaces the Product resource.
   *
   * Replaces the Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductItem$Json$Json$Response(params: AdminPutProductItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>> {
    return adminPutProductItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Product resource.
   *
   * Replaces the Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductItem$Json$Json(params: AdminPutProductItem$Json$Json$Params, context?: HttpContext): Observable<ProductAdminProductShowSyliusAdminProductShow> {
    return this.adminPutProductItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>): ProductAdminProductShowSyliusAdminProductShow => r.body)
    );
  }

  /**
   * Replaces the Product resource.
   *
   * Replaces the Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductItem$Json$Html$Response(params: AdminPutProductItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>> {
    return adminPutProductItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Product resource.
   *
   * Replaces the Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductItem$Json$Html(params: AdminPutProductItem$Json$Html$Params, context?: HttpContext): Observable<ProductAdminProductShowSyliusAdminProductShow> {
    return this.adminPutProductItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>): ProductAdminProductShowSyliusAdminProductShow => r.body)
    );
  }

  /**
   * Replaces the Product resource.
   *
   * Replaces the Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductItem$Html$Json$Response(params: AdminPutProductItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>> {
    return adminPutProductItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Product resource.
   *
   * Replaces the Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductItem$Html$Json(params: AdminPutProductItem$Html$Json$Params, context?: HttpContext): Observable<ProductAdminProductShowSyliusAdminProductShow> {
    return this.adminPutProductItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>): ProductAdminProductShowSyliusAdminProductShow => r.body)
    );
  }

  /**
   * Replaces the Product resource.
   *
   * Replaces the Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductItem$Html$Html$Response(params: AdminPutProductItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>> {
    return adminPutProductItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Product resource.
   *
   * Replaces the Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductItem$Html$Html(params: AdminPutProductItem$Html$Html$Params, context?: HttpContext): Observable<ProductAdminProductShowSyliusAdminProductShow> {
    return this.adminPutProductItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAdminProductShowSyliusAdminProductShow>): ProductAdminProductShowSyliusAdminProductShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteProductItem()` */
  static readonly AdminDeleteProductItemPath = '/api/v2/admin/products/{code}';

  /**
   * Removes the Product resource.
   *
   * Removes the Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteProductItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductItem$Response(params: AdminDeleteProductItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteProductItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Product resource.
   *
   * Removes the Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteProductItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductItem(params: AdminDeleteProductItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteProductItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiProductsImagesGetSubresourceProductSubresource()` */
  static readonly ApiProductsImagesGetSubresourceProductSubresourcePath = '/api/v2/admin/products/{code}/images';

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductsImagesGetSubresourceProductSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsImagesGetSubresourceProductSubresource$Json$Response(params: ApiProductsImagesGetSubresourceProductSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow>>> {
    return apiProductsImagesGetSubresourceProductSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductsImagesGetSubresourceProductSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsImagesGetSubresourceProductSubresource$Json(params: ApiProductsImagesGetSubresourceProductSubresource$Json$Params, context?: HttpContext): Observable<Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow>> {
    return this.apiProductsImagesGetSubresourceProductSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow>>): Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow> => r.body)
    );
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductsImagesGetSubresourceProductSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsImagesGetSubresourceProductSubresource$Html$Response(params: ApiProductsImagesGetSubresourceProductSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow>>> {
    return apiProductsImagesGetSubresourceProductSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductsImagesGetSubresourceProductSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsImagesGetSubresourceProductSubresource$Html(params: ApiProductsImagesGetSubresourceProductSubresource$Html$Params, context?: HttpContext): Observable<Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow>> {
    return this.apiProductsImagesGetSubresourceProductSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow>>): Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow> => r.body)
    );
  }

  /** Path part for operation `shopGetProductCollection()` */
  static readonly ShopGetProductCollectionPath = '/api/v2/shop/products';

  /**
   * Retrieves the collection of Product resources.
   *
   * Retrieves the collection of Product resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductCollection$Json$Response(params?: ShopGetProductCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductShopProductIndexSyliusShopProductIndex>>> {
    return shopGetProductCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Product resources.
   *
   * Retrieves the collection of Product resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductCollection$Json(params?: ShopGetProductCollection$Json$Params, context?: HttpContext): Observable<Array<ProductShopProductIndexSyliusShopProductIndex>> {
    return this.shopGetProductCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductShopProductIndexSyliusShopProductIndex>>): Array<ProductShopProductIndexSyliusShopProductIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Product resources.
   *
   * Retrieves the collection of Product resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductCollection$Html$Response(params?: ShopGetProductCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductShopProductIndexSyliusShopProductIndex>>> {
    return shopGetProductCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Product resources.
   *
   * Retrieves the collection of Product resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductCollection$Html(params?: ShopGetProductCollection$Html$Params, context?: HttpContext): Observable<Array<ProductShopProductIndexSyliusShopProductIndex>> {
    return this.shopGetProductCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductShopProductIndexSyliusShopProductIndex>>): Array<ProductShopProductIndexSyliusShopProductIndex> => r.body)
    );
  }

  /** Path part for operation `shopGetBySlugProductItem()` */
  static readonly ShopGetBySlugProductItemPath = '/api/v2/shop/products-by-slug/{slug}';

  /**
   * Use slug to retrieve a product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetBySlugProductItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetBySlugProductItem$Json$Response(params: ShopGetBySlugProductItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductShopProductShowSyliusShopProductShow>> {
    return shopGetBySlugProductItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Use slug to retrieve a product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetBySlugProductItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetBySlugProductItem$Json(params: ShopGetBySlugProductItem$Json$Params, context?: HttpContext): Observable<ProductShopProductShowSyliusShopProductShow> {
    return this.shopGetBySlugProductItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductShopProductShowSyliusShopProductShow>): ProductShopProductShowSyliusShopProductShow => r.body)
    );
  }

  /**
   * Use slug to retrieve a product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetBySlugProductItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetBySlugProductItem$Html$Response(params: ShopGetBySlugProductItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductShopProductShowSyliusShopProductShow>> {
    return shopGetBySlugProductItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Use slug to retrieve a product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetBySlugProductItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetBySlugProductItem$Html(params: ShopGetBySlugProductItem$Html$Params, context?: HttpContext): Observable<ProductShopProductShowSyliusShopProductShow> {
    return this.shopGetBySlugProductItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductShopProductShowSyliusShopProductShow>): ProductShopProductShowSyliusShopProductShow => r.body)
    );
  }

  /** Path part for operation `shopGetProductItem()` */
  static readonly ShopGetProductItemPath = '/api/v2/shop/products/{code}';

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductItem$Json$Response(params: ShopGetProductItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductShopProductShowSyliusShopProductShow>> {
    return shopGetProductItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductItem$Json(params: ShopGetProductItem$Json$Params, context?: HttpContext): Observable<ProductShopProductShowSyliusShopProductShow> {
    return this.shopGetProductItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductShopProductShowSyliusShopProductShow>): ProductShopProductShowSyliusShopProductShow => r.body)
    );
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductItem$Html$Response(params: ShopGetProductItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductShopProductShowSyliusShopProductShow>> {
    return shopGetProductItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductItem$Html(params: ShopGetProductItem$Html$Params, context?: HttpContext): Observable<ProductShopProductShowSyliusShopProductShow> {
    return this.shopGetProductItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductShopProductShowSyliusShopProductShow>): ProductShopProductShowSyliusShopProductShow => r.body)
    );
  }

  /** Path part for operation `apiProductsAttributesGetSubresourceProductSubresource()` */
  static readonly ApiProductsAttributesGetSubresourceProductSubresourcePath = '/api/v2/shop/products/{code}/attributes';

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductsAttributesGetSubresourceProductSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsAttributesGetSubresourceProductSubresource$Json$Response(params: ApiProductsAttributesGetSubresourceProductSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>>> {
    return apiProductsAttributesGetSubresourceProductSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductsAttributesGetSubresourceProductSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsAttributesGetSubresourceProductSubresource$Json(params: ApiProductsAttributesGetSubresourceProductSubresource$Json$Params, context?: HttpContext): Observable<Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>> {
    return this.apiProductsAttributesGetSubresourceProductSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>>): Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow> => r.body)
    );
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductsAttributesGetSubresourceProductSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsAttributesGetSubresourceProductSubresource$Html$Response(params: ApiProductsAttributesGetSubresourceProductSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>>> {
    return apiProductsAttributesGetSubresourceProductSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Product resource.
   *
   * Retrieves a Product resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductsAttributesGetSubresourceProductSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductsAttributesGetSubresourceProductSubresource$Html(params: ApiProductsAttributesGetSubresourceProductSubresource$Html$Params, context?: HttpContext): Observable<Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>> {
    return this.apiProductsAttributesGetSubresourceProductSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow>>): Array<ProductAttributeValueShopProductAttributeValueShowSyliusShopProductAttributeValueShow> => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Show as OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow } from '../../models/Order-shop/order/show_sylius/shop/order/show_shop/cart/show_sylius/shop/cart/show';
import { Complete as OrderCompleteOrderShopCartCompleteSyliusShopCartComplete } from '../../models/Order/CompleteOrder-shop/cart/complete_sylius/shop/cart/complete';

export interface ShopCompleteOrderItem$Json$Params {

/**
 * Resource identifier
 */
  tokenValue: string;

/**
 * Locales in this enum are all locales defined in the shop and only enabled ones will work in the given channel in the shop.
 */
  'Accept-Language'?: 'hu_HU';
  
    /**
     * The updated Order resource
     */
    body: OrderCompleteOrderShopCartCompleteSyliusShopCartComplete
}

export function shopCompleteOrderItem$Json(http: HttpClient, rootUrl: string, params: ShopCompleteOrderItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>> {
  const rb = new RequestBuilder(rootUrl, shopCompleteOrderItem$Json.PATH, 'patch');
  if (params) {
    rb.path('tokenValue', params.tokenValue, {"style":"simple","explode":false});
    rb.header('Accept-Language', params['Accept-Language'], {"style":"simple","explode":false});
    rb.body(params.body, 'application/merge-patch+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>;
    })
  );
}

shopCompleteOrderItem$Json.PATH = '/api/v2/shop/orders/{tokenValue}/complete';

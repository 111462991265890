/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Create as ExchangeRateAdminExchangeRateCreateSyliusAdminExchangeRateCreate } from '../../models/ExchangeRate-admin/exchange_rate/create_sylius/admin/exchange_rate/create';
import { Show as ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow } from '../../models/ExchangeRate-admin/exchange_rate/show_sylius/admin/exchange_rate/show';

export interface AdminPostExchangeRateCollection$Html$Html$Params {

/**
 * Locales in this enum are all locales defined in the shop and only enabled ones will work in the given channel in the shop.
 */
  'Accept-Language'?: 'hu_HU';
  
    /**
     * The new ExchangeRate resource
     */
    body: ExchangeRateAdminExchangeRateCreateSyliusAdminExchangeRateCreate
}

export function adminPostExchangeRateCollection$Html$Html(http: HttpClient, rootUrl: string, params: AdminPostExchangeRateCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>> {
  const rb = new RequestBuilder(rootUrl, adminPostExchangeRateCollection$Html$Html.PATH, 'post');
  if (params) {
    rb.header('Accept-Language', params['Accept-Language'], {"style":"simple","explode":false});
    rb.body(params.body, 'text/html');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/html', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>;
    })
  );
}

adminPostExchangeRateCollection$Html$Html.PATH = '/api/v2/admin/exchange-rates';

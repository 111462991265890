/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeletePaymentMethodItem } from '../fn/payment-method/admin-delete-payment-method-item';
import { AdminDeletePaymentMethodItem$Params } from '../fn/payment-method/admin-delete-payment-method-item';
import { adminGetPaymentMethodCollection$Html } from '../fn/payment-method/admin-get-payment-method-collection-html';
import { AdminGetPaymentMethodCollection$Html$Params } from '../fn/payment-method/admin-get-payment-method-collection-html';
import { adminGetPaymentMethodCollection$Json } from '../fn/payment-method/admin-get-payment-method-collection-json';
import { AdminGetPaymentMethodCollection$Json$Params } from '../fn/payment-method/admin-get-payment-method-collection-json';
import { adminGetPaymentMethodItem$Html } from '../fn/payment-method/admin-get-payment-method-item-html';
import { AdminGetPaymentMethodItem$Html$Params } from '../fn/payment-method/admin-get-payment-method-item-html';
import { adminGetPaymentMethodItem$Json } from '../fn/payment-method/admin-get-payment-method-item-json';
import { AdminGetPaymentMethodItem$Json$Params } from '../fn/payment-method/admin-get-payment-method-item-json';
import { adminPostPaymentMethodCollection$Html$Html } from '../fn/payment-method/admin-post-payment-method-collection-html-html';
import { AdminPostPaymentMethodCollection$Html$Html$Params } from '../fn/payment-method/admin-post-payment-method-collection-html-html';
import { adminPostPaymentMethodCollection$Html$Json } from '../fn/payment-method/admin-post-payment-method-collection-html-json';
import { AdminPostPaymentMethodCollection$Html$Json$Params } from '../fn/payment-method/admin-post-payment-method-collection-html-json';
import { adminPostPaymentMethodCollection$Json$Html } from '../fn/payment-method/admin-post-payment-method-collection-json-html';
import { AdminPostPaymentMethodCollection$Json$Html$Params } from '../fn/payment-method/admin-post-payment-method-collection-json-html';
import { adminPostPaymentMethodCollection$Json$Json } from '../fn/payment-method/admin-post-payment-method-collection-json-json';
import { AdminPostPaymentMethodCollection$Json$Json$Params } from '../fn/payment-method/admin-post-payment-method-collection-json-json';
import { adminPutPaymentMethodItem$Html$Html } from '../fn/payment-method/admin-put-payment-method-item-html-html';
import { AdminPutPaymentMethodItem$Html$Html$Params } from '../fn/payment-method/admin-put-payment-method-item-html-html';
import { adminPutPaymentMethodItem$Html$Json } from '../fn/payment-method/admin-put-payment-method-item-html-json';
import { AdminPutPaymentMethodItem$Html$Json$Params } from '../fn/payment-method/admin-put-payment-method-item-html-json';
import { adminPutPaymentMethodItem$Json$Html } from '../fn/payment-method/admin-put-payment-method-item-json-html';
import { AdminPutPaymentMethodItem$Json$Html$Params } from '../fn/payment-method/admin-put-payment-method-item-json-html';
import { adminPutPaymentMethodItem$Json$Json } from '../fn/payment-method/admin-put-payment-method-item-json-json';
import { AdminPutPaymentMethodItem$Json$Json$Params } from '../fn/payment-method/admin-put-payment-method-item-json-json';
import { apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html } from '../fn/gateway-config/api-payment-methods-gateway-config-get-subresource-payment-method-subresource-html';
import { ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html$Params } from '../fn/gateway-config/api-payment-methods-gateway-config-get-subresource-payment-method-subresource-html';
import { apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json } from '../fn/gateway-config/api-payment-methods-gateway-config-get-subresource-payment-method-subresource-json';
import { ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json$Params } from '../fn/gateway-config/api-payment-methods-gateway-config-get-subresource-payment-method-subresource-json';
import { Show as GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow } from '../models/GatewayConfig-admin/gateway_config/show_sylius/admin/gateway_config/show';
import { Index as PaymentMethodAdminPaymentMethodIndexSyliusAdminPaymentMethodIndex } from '../models/PaymentMethod-admin/payment_method/index_sylius/admin/payment_method/index';
import { Show as PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow } from '../models/PaymentMethod-admin/payment_method/show_sylius/admin/payment_method/show';
import { Index as PaymentMethodShopPaymentMethodIndexSyliusShopPaymentMethodIndex } from '../models/PaymentMethod-shop/payment_method/index_sylius/shop/payment_method/index';
import { Show as PaymentMethodShopPaymentMethodShowSyliusShopPaymentMethodShow } from '../models/PaymentMethod-shop/payment_method/show_sylius/shop/payment_method/show';
import { shopGetPaymentMethodCollection$Html } from '../fn/payment-method/shop-get-payment-method-collection-html';
import { ShopGetPaymentMethodCollection$Html$Params } from '../fn/payment-method/shop-get-payment-method-collection-html';
import { shopGetPaymentMethodCollection$Json } from '../fn/payment-method/shop-get-payment-method-collection-json';
import { ShopGetPaymentMethodCollection$Json$Params } from '../fn/payment-method/shop-get-payment-method-collection-json';
import { shopGetPaymentMethodItem$Html } from '../fn/payment-method/shop-get-payment-method-item-html';
import { ShopGetPaymentMethodItem$Html$Params } from '../fn/payment-method/shop-get-payment-method-item-html';
import { shopGetPaymentMethodItem$Json } from '../fn/payment-method/shop-get-payment-method-item-json';
import { ShopGetPaymentMethodItem$Json$Params } from '../fn/payment-method/shop-get-payment-method-item-json';

@Injectable({ providedIn: 'root' })
export class PaymentMethodService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetPaymentMethodCollection()` */
  static readonly AdminGetPaymentMethodCollectionPath = '/api/v2/admin/payment-methods';

  /**
   * Retrieves the collection of PaymentMethod resources.
   *
   * Retrieves the collection of PaymentMethod resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPaymentMethodCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentMethodCollection$Json$Response(params?: AdminGetPaymentMethodCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PaymentMethodAdminPaymentMethodIndexSyliusAdminPaymentMethodIndex>>> {
    return adminGetPaymentMethodCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of PaymentMethod resources.
   *
   * Retrieves the collection of PaymentMethod resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPaymentMethodCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentMethodCollection$Json(params?: AdminGetPaymentMethodCollection$Json$Params, context?: HttpContext): Observable<Array<PaymentMethodAdminPaymentMethodIndexSyliusAdminPaymentMethodIndex>> {
    return this.adminGetPaymentMethodCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentMethodAdminPaymentMethodIndexSyliusAdminPaymentMethodIndex>>): Array<PaymentMethodAdminPaymentMethodIndexSyliusAdminPaymentMethodIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of PaymentMethod resources.
   *
   * Retrieves the collection of PaymentMethod resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPaymentMethodCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentMethodCollection$Html$Response(params?: AdminGetPaymentMethodCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PaymentMethodAdminPaymentMethodIndexSyliusAdminPaymentMethodIndex>>> {
    return adminGetPaymentMethodCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of PaymentMethod resources.
   *
   * Retrieves the collection of PaymentMethod resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPaymentMethodCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentMethodCollection$Html(params?: AdminGetPaymentMethodCollection$Html$Params, context?: HttpContext): Observable<Array<PaymentMethodAdminPaymentMethodIndexSyliusAdminPaymentMethodIndex>> {
    return this.adminGetPaymentMethodCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentMethodAdminPaymentMethodIndexSyliusAdminPaymentMethodIndex>>): Array<PaymentMethodAdminPaymentMethodIndexSyliusAdminPaymentMethodIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostPaymentMethodCollection()` */
  static readonly AdminPostPaymentMethodCollectionPath = '/api/v2/admin/payment-methods';

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostPaymentMethodCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostPaymentMethodCollection$Json$Json$Response(params: AdminPostPaymentMethodCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>> {
    return adminPostPaymentMethodCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostPaymentMethodCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostPaymentMethodCollection$Json$Json(params: AdminPostPaymentMethodCollection$Json$Json$Params, context?: HttpContext): Observable<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow> {
    return this.adminPostPaymentMethodCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>): PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow => r.body)
    );
  }

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostPaymentMethodCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostPaymentMethodCollection$Json$Html$Response(params: AdminPostPaymentMethodCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>> {
    return adminPostPaymentMethodCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostPaymentMethodCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostPaymentMethodCollection$Json$Html(params: AdminPostPaymentMethodCollection$Json$Html$Params, context?: HttpContext): Observable<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow> {
    return this.adminPostPaymentMethodCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>): PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow => r.body)
    );
  }

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostPaymentMethodCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostPaymentMethodCollection$Html$Json$Response(params: AdminPostPaymentMethodCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>> {
    return adminPostPaymentMethodCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostPaymentMethodCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostPaymentMethodCollection$Html$Json(params: AdminPostPaymentMethodCollection$Html$Json$Params, context?: HttpContext): Observable<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow> {
    return this.adminPostPaymentMethodCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>): PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow => r.body)
    );
  }

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostPaymentMethodCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostPaymentMethodCollection$Html$Html$Response(params: AdminPostPaymentMethodCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>> {
    return adminPostPaymentMethodCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a PaymentMethod resource.
   *
   * Creates a PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostPaymentMethodCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostPaymentMethodCollection$Html$Html(params: AdminPostPaymentMethodCollection$Html$Html$Params, context?: HttpContext): Observable<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow> {
    return this.adminPostPaymentMethodCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>): PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow => r.body)
    );
  }

  /** Path part for operation `adminGetPaymentMethodItem()` */
  static readonly AdminGetPaymentMethodItemPath = '/api/v2/admin/payment-methods/{code}';

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPaymentMethodItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentMethodItem$Json$Response(params: AdminGetPaymentMethodItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>> {
    return adminGetPaymentMethodItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPaymentMethodItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentMethodItem$Json(params: AdminGetPaymentMethodItem$Json$Params, context?: HttpContext): Observable<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow> {
    return this.adminGetPaymentMethodItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>): PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow => r.body)
    );
  }

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPaymentMethodItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentMethodItem$Html$Response(params: AdminGetPaymentMethodItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>> {
    return adminGetPaymentMethodItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPaymentMethodItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentMethodItem$Html(params: AdminGetPaymentMethodItem$Html$Params, context?: HttpContext): Observable<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow> {
    return this.adminGetPaymentMethodItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>): PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow => r.body)
    );
  }

  /** Path part for operation `adminPutPaymentMethodItem()` */
  static readonly AdminPutPaymentMethodItemPath = '/api/v2/admin/payment-methods/{code}';

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutPaymentMethodItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutPaymentMethodItem$Json$Json$Response(params: AdminPutPaymentMethodItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>> {
    return adminPutPaymentMethodItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutPaymentMethodItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutPaymentMethodItem$Json$Json(params: AdminPutPaymentMethodItem$Json$Json$Params, context?: HttpContext): Observable<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow> {
    return this.adminPutPaymentMethodItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>): PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow => r.body)
    );
  }

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutPaymentMethodItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutPaymentMethodItem$Json$Html$Response(params: AdminPutPaymentMethodItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>> {
    return adminPutPaymentMethodItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutPaymentMethodItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutPaymentMethodItem$Json$Html(params: AdminPutPaymentMethodItem$Json$Html$Params, context?: HttpContext): Observable<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow> {
    return this.adminPutPaymentMethodItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>): PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow => r.body)
    );
  }

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutPaymentMethodItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutPaymentMethodItem$Html$Json$Response(params: AdminPutPaymentMethodItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>> {
    return adminPutPaymentMethodItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutPaymentMethodItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutPaymentMethodItem$Html$Json(params: AdminPutPaymentMethodItem$Html$Json$Params, context?: HttpContext): Observable<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow> {
    return this.adminPutPaymentMethodItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>): PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow => r.body)
    );
  }

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutPaymentMethodItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutPaymentMethodItem$Html$Html$Response(params: AdminPutPaymentMethodItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>> {
    return adminPutPaymentMethodItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the PaymentMethod resource.
   *
   * Replaces the PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutPaymentMethodItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutPaymentMethodItem$Html$Html(params: AdminPutPaymentMethodItem$Html$Html$Params, context?: HttpContext): Observable<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow> {
    return this.adminPutPaymentMethodItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow>): PaymentMethodAdminPaymentMethodShowSyliusAdminPaymentMethodShow => r.body)
    );
  }

  /** Path part for operation `adminDeletePaymentMethodItem()` */
  static readonly AdminDeletePaymentMethodItemPath = '/api/v2/admin/payment-methods/{code}';

  /**
   * Removes the PaymentMethod resource.
   *
   * Removes the PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeletePaymentMethodItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeletePaymentMethodItem$Response(params: AdminDeletePaymentMethodItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeletePaymentMethodItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the PaymentMethod resource.
   *
   * Removes the PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeletePaymentMethodItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeletePaymentMethodItem(params: AdminDeletePaymentMethodItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeletePaymentMethodItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource()` */
  static readonly ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresourcePath = '/api/v2/admin/payment-methods/{code}/gateway-config';

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json$Response(params: ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow>> {
    return apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json(params: ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json$Params, context?: HttpContext): Observable<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow> {
    return this.apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow>): GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow => r.body)
    );
  }

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html$Response(params: ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow>> {
    return apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html(params: ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html$Params, context?: HttpContext): Observable<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow> {
    return this.apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow>): GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow => r.body)
    );
  }

  /** Path part for operation `shopGetPaymentMethodCollection()` */
  static readonly ShopGetPaymentMethodCollectionPath = '/api/v2/shop/payment-methods';

  /**
   * Retrieves the collection of PaymentMethod resources.
   *
   * Retrieves the collection of PaymentMethod resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetPaymentMethodCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetPaymentMethodCollection$Json$Response(params?: ShopGetPaymentMethodCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PaymentMethodShopPaymentMethodIndexSyliusShopPaymentMethodIndex>>> {
    return shopGetPaymentMethodCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of PaymentMethod resources.
   *
   * Retrieves the collection of PaymentMethod resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetPaymentMethodCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetPaymentMethodCollection$Json(params?: ShopGetPaymentMethodCollection$Json$Params, context?: HttpContext): Observable<Array<PaymentMethodShopPaymentMethodIndexSyliusShopPaymentMethodIndex>> {
    return this.shopGetPaymentMethodCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentMethodShopPaymentMethodIndexSyliusShopPaymentMethodIndex>>): Array<PaymentMethodShopPaymentMethodIndexSyliusShopPaymentMethodIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of PaymentMethod resources.
   *
   * Retrieves the collection of PaymentMethod resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetPaymentMethodCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetPaymentMethodCollection$Html$Response(params?: ShopGetPaymentMethodCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PaymentMethodShopPaymentMethodIndexSyliusShopPaymentMethodIndex>>> {
    return shopGetPaymentMethodCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of PaymentMethod resources.
   *
   * Retrieves the collection of PaymentMethod resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetPaymentMethodCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetPaymentMethodCollection$Html(params?: ShopGetPaymentMethodCollection$Html$Params, context?: HttpContext): Observable<Array<PaymentMethodShopPaymentMethodIndexSyliusShopPaymentMethodIndex>> {
    return this.shopGetPaymentMethodCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentMethodShopPaymentMethodIndexSyliusShopPaymentMethodIndex>>): Array<PaymentMethodShopPaymentMethodIndexSyliusShopPaymentMethodIndex> => r.body)
    );
  }

  /** Path part for operation `shopGetPaymentMethodItem()` */
  static readonly ShopGetPaymentMethodItemPath = '/api/v2/shop/payment-methods/{code}';

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetPaymentMethodItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetPaymentMethodItem$Json$Response(params: ShopGetPaymentMethodItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentMethodShopPaymentMethodShowSyliusShopPaymentMethodShow>> {
    return shopGetPaymentMethodItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetPaymentMethodItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetPaymentMethodItem$Json(params: ShopGetPaymentMethodItem$Json$Params, context?: HttpContext): Observable<PaymentMethodShopPaymentMethodShowSyliusShopPaymentMethodShow> {
    return this.shopGetPaymentMethodItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodShopPaymentMethodShowSyliusShopPaymentMethodShow>): PaymentMethodShopPaymentMethodShowSyliusShopPaymentMethodShow => r.body)
    );
  }

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetPaymentMethodItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetPaymentMethodItem$Html$Response(params: ShopGetPaymentMethodItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentMethodShopPaymentMethodShowSyliusShopPaymentMethodShow>> {
    return shopGetPaymentMethodItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetPaymentMethodItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetPaymentMethodItem$Html(params: ShopGetPaymentMethodItem$Html$Params, context?: HttpContext): Observable<PaymentMethodShopPaymentMethodShowSyliusShopPaymentMethodShow> {
    return this.shopGetPaymentMethodItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodShopPaymentMethodShowSyliusShopPaymentMethodShow>): PaymentMethodShopPaymentMethodShowSyliusShopPaymentMethodShow => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetChannelPricingLogEntryCollection$Html } from '../fn/channel-pricing-log-entry/admin-get-channel-pricing-log-entry-collection-html';
import { AdminGetChannelPricingLogEntryCollection$Html$Params } from '../fn/channel-pricing-log-entry/admin-get-channel-pricing-log-entry-collection-html';
import { adminGetChannelPricingLogEntryCollection$Json } from '../fn/channel-pricing-log-entry/admin-get-channel-pricing-log-entry-collection-json';
import { AdminGetChannelPricingLogEntryCollection$Json$Params } from '../fn/channel-pricing-log-entry/admin-get-channel-pricing-log-entry-collection-json';
import { adminGetChannelPricingLogEntryItem$Html } from '../fn/channel-pricing-log-entry/admin-get-channel-pricing-log-entry-item-html';
import { AdminGetChannelPricingLogEntryItem$Html$Params } from '../fn/channel-pricing-log-entry/admin-get-channel-pricing-log-entry-item-html';
import { adminGetChannelPricingLogEntryItem$Json } from '../fn/channel-pricing-log-entry/admin-get-channel-pricing-log-entry-item-json';
import { AdminGetChannelPricingLogEntryItem$Json$Params } from '../fn/channel-pricing-log-entry/admin-get-channel-pricing-log-entry-item-json';
import { Index as ChannelPricingLogEntryAdminChannelPricingLogEntryIndexSyliusAdminChannelPricingLogEntryIndex } from '../models/ChannelPricingLogEntry-admin/channel_pricing_log_entry/index_sylius/admin/channel_pricing_log_entry/index';
import { Show as ChannelPricingLogEntryAdminChannelPricingLogEntryShowSyliusAdminChannelPricingLogEntryShow } from '../models/ChannelPricingLogEntry-admin/channel_pricing_log_entry/show_sylius/admin/channel_pricing_log_entry/show';

@Injectable({ providedIn: 'root' })
export class ChannelPricingLogEntryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetChannelPricingLogEntryCollection()` */
  static readonly AdminGetChannelPricingLogEntryCollectionPath = '/api/v2/admin/channel-pricing-log-entries';

  /**
   * Retrieves the collection of ChannelPricingLogEntry resources.
   *
   * Retrieves the collection of ChannelPricingLogEntry resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetChannelPricingLogEntryCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelPricingLogEntryCollection$Json$Response(params?: AdminGetChannelPricingLogEntryCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChannelPricingLogEntryAdminChannelPricingLogEntryIndexSyliusAdminChannelPricingLogEntryIndex>>> {
    return adminGetChannelPricingLogEntryCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ChannelPricingLogEntry resources.
   *
   * Retrieves the collection of ChannelPricingLogEntry resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetChannelPricingLogEntryCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelPricingLogEntryCollection$Json(params?: AdminGetChannelPricingLogEntryCollection$Json$Params, context?: HttpContext): Observable<Array<ChannelPricingLogEntryAdminChannelPricingLogEntryIndexSyliusAdminChannelPricingLogEntryIndex>> {
    return this.adminGetChannelPricingLogEntryCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChannelPricingLogEntryAdminChannelPricingLogEntryIndexSyliusAdminChannelPricingLogEntryIndex>>): Array<ChannelPricingLogEntryAdminChannelPricingLogEntryIndexSyliusAdminChannelPricingLogEntryIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ChannelPricingLogEntry resources.
   *
   * Retrieves the collection of ChannelPricingLogEntry resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetChannelPricingLogEntryCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelPricingLogEntryCollection$Html$Response(params?: AdminGetChannelPricingLogEntryCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChannelPricingLogEntryAdminChannelPricingLogEntryIndexSyliusAdminChannelPricingLogEntryIndex>>> {
    return adminGetChannelPricingLogEntryCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ChannelPricingLogEntry resources.
   *
   * Retrieves the collection of ChannelPricingLogEntry resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetChannelPricingLogEntryCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelPricingLogEntryCollection$Html(params?: AdminGetChannelPricingLogEntryCollection$Html$Params, context?: HttpContext): Observable<Array<ChannelPricingLogEntryAdminChannelPricingLogEntryIndexSyliusAdminChannelPricingLogEntryIndex>> {
    return this.adminGetChannelPricingLogEntryCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChannelPricingLogEntryAdminChannelPricingLogEntryIndexSyliusAdminChannelPricingLogEntryIndex>>): Array<ChannelPricingLogEntryAdminChannelPricingLogEntryIndexSyliusAdminChannelPricingLogEntryIndex> => r.body)
    );
  }

  /** Path part for operation `adminGetChannelPricingLogEntryItem()` */
  static readonly AdminGetChannelPricingLogEntryItemPath = '/api/v2/admin/channel-pricing-log-entries/{id}';

  /**
   * Retrieves a ChannelPricingLogEntry resource.
   *
   * Retrieves a ChannelPricingLogEntry resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetChannelPricingLogEntryItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelPricingLogEntryItem$Json$Response(params: AdminGetChannelPricingLogEntryItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelPricingLogEntryAdminChannelPricingLogEntryShowSyliusAdminChannelPricingLogEntryShow>> {
    return adminGetChannelPricingLogEntryItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ChannelPricingLogEntry resource.
   *
   * Retrieves a ChannelPricingLogEntry resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetChannelPricingLogEntryItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelPricingLogEntryItem$Json(params: AdminGetChannelPricingLogEntryItem$Json$Params, context?: HttpContext): Observable<ChannelPricingLogEntryAdminChannelPricingLogEntryShowSyliusAdminChannelPricingLogEntryShow> {
    return this.adminGetChannelPricingLogEntryItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelPricingLogEntryAdminChannelPricingLogEntryShowSyliusAdminChannelPricingLogEntryShow>): ChannelPricingLogEntryAdminChannelPricingLogEntryShowSyliusAdminChannelPricingLogEntryShow => r.body)
    );
  }

  /**
   * Retrieves a ChannelPricingLogEntry resource.
   *
   * Retrieves a ChannelPricingLogEntry resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetChannelPricingLogEntryItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelPricingLogEntryItem$Html$Response(params: AdminGetChannelPricingLogEntryItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelPricingLogEntryAdminChannelPricingLogEntryShowSyliusAdminChannelPricingLogEntryShow>> {
    return adminGetChannelPricingLogEntryItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ChannelPricingLogEntry resource.
   *
   * Retrieves a ChannelPricingLogEntry resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetChannelPricingLogEntryItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelPricingLogEntryItem$Html(params: AdminGetChannelPricingLogEntryItem$Html$Params, context?: HttpContext): Observable<ChannelPricingLogEntryAdminChannelPricingLogEntryShowSyliusAdminChannelPricingLogEntryShow> {
    return this.adminGetChannelPricingLogEntryItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelPricingLogEntryAdminChannelPricingLogEntryShowSyliusAdminChannelPricingLogEntryShow>): ChannelPricingLogEntryAdminChannelPricingLogEntryShowSyliusAdminChannelPricingLogEntryShow => r.body)
    );
  }

}

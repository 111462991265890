/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Show as ProductImageAdminProductImageShowSyliusAdminProductImageShow } from '../../models/ProductImage-admin/product_image/show_sylius/admin/product_image/show';

export interface ApiProductsImagesGetSubresourceProductSubresource$Json$Params {

/**
 * Provide one of supported image liip imagine filters
 */
  imageFilter?: 'sylius_original' | 'sylius_small' | 'sylius_medium' | 'sylius_large' | 'sylius_admin_product_original' | 'sylius_admin_admin_user_avatar_thumbnail' | 'sylius_admin_product_tiny_thumbnail' | 'sylius_admin_product_thumbnail' | 'sylius_admin_product_small_thumbnail' | 'sylius_admin_product_large_thumbnail' | 'sylius_shop_product_original' | 'sylius_shop_product_tiny_thumbnail' | 'sylius_shop_product_small_thumbnail' | 'sylius_shop_product_thumbnail' | 'sylius_shop_product_large_thumbnail';

/**
 * Product identifier
 */
  code: string;

/**
 * The collection page number
 */
  page?: number;

/**
 * The number of items per page
 */
  itemsPerPage?: number;
}

export function apiProductsImagesGetSubresourceProductSubresource$Json(http: HttpClient, rootUrl: string, params: ApiProductsImagesGetSubresourceProductSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow>>> {
  const rb = new RequestBuilder(rootUrl, apiProductsImagesGetSubresourceProductSubresource$Json.PATH, 'get');
  if (params) {
    rb.query('imageFilter', params.imageFilter, {"style":"form","explode":false});
    rb.path('code', params.code, {"style":"simple","explode":false});
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ProductImageAdminProductImageShowSyliusAdminProductImageShow>>;
    })
  );
}

apiProductsImagesGetSubresourceProductSubresource$Json.PATH = '/api/v2/admin/products/{code}/images';

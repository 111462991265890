/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Update as ShipmentShipShipmentAdminShipmentUpdateSyliusAdminShipmentUpdate } from '../../models/Shipment/ShipShipment-admin/shipment/update_sylius/admin/shipment/update';

export interface AdminShipShipmentItem$Html$Params {

/**
 * Resource identifier
 */
  id: string;

/**
 * Locales in this enum are all locales defined in the shop and only enabled ones will work in the given channel in the shop.
 */
  'Accept-Language'?: 'hu_HU';
  
    /**
     * The updated Shipment resource
     */
    body: ShipmentShipShipmentAdminShipmentUpdateSyliusAdminShipmentUpdate
}

export function adminShipShipmentItem$Html(http: HttpClient, rootUrl: string, params: AdminShipShipmentItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
  const rb = new RequestBuilder(rootUrl, adminShipShipmentItem$Html.PATH, 'patch');
  if (params) {
    rb.path('id', params.id, {"style":"simple","explode":false});
    rb.header('Accept-Language', params['Accept-Language'], {"style":"simple","explode":false});
    rb.body(params.body, 'application/merge-patch+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/html', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<any>;
    })
  );
}

adminShipShipmentItem$Html.PATH = '/api/v2/admin/shipments/{id}/ship';

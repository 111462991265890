/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteTaxonImageItem } from '../fn/taxon-image/admin-delete-taxon-image-item';
import { AdminDeleteTaxonImageItem$Params } from '../fn/taxon-image/admin-delete-taxon-image-item';
import { adminGetTaxonImageCollection$Html } from '../fn/taxon-image/admin-get-taxon-image-collection-html';
import { AdminGetTaxonImageCollection$Html$Params } from '../fn/taxon-image/admin-get-taxon-image-collection-html';
import { adminGetTaxonImageCollection$Json } from '../fn/taxon-image/admin-get-taxon-image-collection-json';
import { AdminGetTaxonImageCollection$Json$Params } from '../fn/taxon-image/admin-get-taxon-image-collection-json';
import { adminGetTaxonImageItem$Html } from '../fn/taxon-image/admin-get-taxon-image-item-html';
import { AdminGetTaxonImageItem$Html$Params } from '../fn/taxon-image/admin-get-taxon-image-item-html';
import { adminGetTaxonImageItem$Json } from '../fn/taxon-image/admin-get-taxon-image-item-json';
import { AdminGetTaxonImageItem$Json$Params } from '../fn/taxon-image/admin-get-taxon-image-item-json';
import { adminPostTaxonImageCollection$Html } from '../fn/taxon-image/admin-post-taxon-image-collection-html';
import { AdminPostTaxonImageCollection$Html$Params } from '../fn/taxon-image/admin-post-taxon-image-collection-html';
import { adminPostTaxonImageCollection$Json } from '../fn/taxon-image/admin-post-taxon-image-collection-json';
import { AdminPostTaxonImageCollection$Json$Params } from '../fn/taxon-image/admin-post-taxon-image-collection-json';
import { adminPutTaxonImageItem$Html$Html } from '../fn/taxon-image/admin-put-taxon-image-item-html-html';
import { AdminPutTaxonImageItem$Html$Html$Params } from '../fn/taxon-image/admin-put-taxon-image-item-html-html';
import { adminPutTaxonImageItem$Html$Json } from '../fn/taxon-image/admin-put-taxon-image-item-html-json';
import { AdminPutTaxonImageItem$Html$Json$Params } from '../fn/taxon-image/admin-put-taxon-image-item-html-json';
import { adminPutTaxonImageItem$Json$Html } from '../fn/taxon-image/admin-put-taxon-image-item-json-html';
import { AdminPutTaxonImageItem$Json$Html$Params } from '../fn/taxon-image/admin-put-taxon-image-item-json-html';
import { adminPutTaxonImageItem$Json$Json } from '../fn/taxon-image/admin-put-taxon-image-item-json-json';
import { AdminPutTaxonImageItem$Json$Json$Params } from '../fn/taxon-image/admin-put-taxon-image-item-json-json';
import { apiTaxaImagesGetSubresourceTaxonSubresource$Html } from '../fn/taxon-image/api-taxa-images-get-subresource-taxon-subresource-html';
import { ApiTaxaImagesGetSubresourceTaxonSubresource$Html$Params } from '../fn/taxon-image/api-taxa-images-get-subresource-taxon-subresource-html';
import { apiTaxaImagesGetSubresourceTaxonSubresource$Json } from '../fn/taxon-image/api-taxa-images-get-subresource-taxon-subresource-json';
import { ApiTaxaImagesGetSubresourceTaxonSubresource$Json$Params } from '../fn/taxon-image/api-taxa-images-get-subresource-taxon-subresource-json';
import { shopGetTaxonImageItem$Html } from '../fn/taxon-image/shop-get-taxon-image-item-html';
import { ShopGetTaxonImageItem$Html$Params } from '../fn/taxon-image/shop-get-taxon-image-item-html';
import { shopGetTaxonImageItem$Json } from '../fn/taxon-image/shop-get-taxon-image-item-json';
import { ShopGetTaxonImageItem$Json$Params } from '../fn/taxon-image/shop-get-taxon-image-item-json';
import { Index as TaxonImageAdminTaxonImageIndexSyliusAdminTaxonImageIndex } from '../models/TaxonImage-admin/taxon_image/index_sylius/admin/taxon_image/index';
import { Show as TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow } from '../models/TaxonImage-admin/taxon_image/show_sylius/admin/taxon_image/show';
import { Show as TaxonImageShopTaxonImageShowSyliusShopTaxonImageShow } from '../models/TaxonImage-shop/taxon_image/show_sylius/shop/taxon_image/show';

@Injectable({ providedIn: 'root' })
export class TaxonImageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetTaxonImageCollection()` */
  static readonly AdminGetTaxonImageCollectionPath = '/api/v2/admin/taxon-images';

  /**
   * Retrieves the collection of TaxonImage resources.
   *
   * Retrieves the collection of TaxonImage resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxonImageCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonImageCollection$Json$Response(params?: AdminGetTaxonImageCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxonImageAdminTaxonImageIndexSyliusAdminTaxonImageIndex>>> {
    return adminGetTaxonImageCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of TaxonImage resources.
   *
   * Retrieves the collection of TaxonImage resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxonImageCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonImageCollection$Json(params?: AdminGetTaxonImageCollection$Json$Params, context?: HttpContext): Observable<Array<TaxonImageAdminTaxonImageIndexSyliusAdminTaxonImageIndex>> {
    return this.adminGetTaxonImageCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxonImageAdminTaxonImageIndexSyliusAdminTaxonImageIndex>>): Array<TaxonImageAdminTaxonImageIndexSyliusAdminTaxonImageIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of TaxonImage resources.
   *
   * Retrieves the collection of TaxonImage resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxonImageCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonImageCollection$Html$Response(params?: AdminGetTaxonImageCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxonImageAdminTaxonImageIndexSyliusAdminTaxonImageIndex>>> {
    return adminGetTaxonImageCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of TaxonImage resources.
   *
   * Retrieves the collection of TaxonImage resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxonImageCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonImageCollection$Html(params?: AdminGetTaxonImageCollection$Html$Params, context?: HttpContext): Observable<Array<TaxonImageAdminTaxonImageIndexSyliusAdminTaxonImageIndex>> {
    return this.adminGetTaxonImageCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxonImageAdminTaxonImageIndexSyliusAdminTaxonImageIndex>>): Array<TaxonImageAdminTaxonImageIndexSyliusAdminTaxonImageIndex> => r.body)
    );
  }

  /** Path part for operation `adminGetTaxonImageItem()` */
  static readonly AdminGetTaxonImageItemPath = '/api/v2/admin/taxon-images/{id}';

  /**
   * Retrieves a TaxonImage resource.
   *
   * Retrieves a TaxonImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxonImageItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonImageItem$Json$Response(params: AdminGetTaxonImageItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>> {
    return adminGetTaxonImageItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a TaxonImage resource.
   *
   * Retrieves a TaxonImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxonImageItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonImageItem$Json(params: AdminGetTaxonImageItem$Json$Params, context?: HttpContext): Observable<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow> {
    return this.adminGetTaxonImageItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>): TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow => r.body)
    );
  }

  /**
   * Retrieves a TaxonImage resource.
   *
   * Retrieves a TaxonImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxonImageItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonImageItem$Html$Response(params: AdminGetTaxonImageItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>> {
    return adminGetTaxonImageItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a TaxonImage resource.
   *
   * Retrieves a TaxonImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxonImageItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonImageItem$Html(params: AdminGetTaxonImageItem$Html$Params, context?: HttpContext): Observable<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow> {
    return this.adminGetTaxonImageItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>): TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow => r.body)
    );
  }

  /** Path part for operation `adminPutTaxonImageItem()` */
  static readonly AdminPutTaxonImageItemPath = '/api/v2/admin/taxon-images/{id}';

  /**
   * Replaces the TaxonImage resource.
   *
   * Replaces the TaxonImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxonImageItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxonImageItem$Json$Json$Response(params: AdminPutTaxonImageItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>> {
    return adminPutTaxonImageItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the TaxonImage resource.
   *
   * Replaces the TaxonImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxonImageItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxonImageItem$Json$Json(params: AdminPutTaxonImageItem$Json$Json$Params, context?: HttpContext): Observable<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow> {
    return this.adminPutTaxonImageItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>): TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow => r.body)
    );
  }

  /**
   * Replaces the TaxonImage resource.
   *
   * Replaces the TaxonImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxonImageItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxonImageItem$Json$Html$Response(params: AdminPutTaxonImageItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>> {
    return adminPutTaxonImageItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the TaxonImage resource.
   *
   * Replaces the TaxonImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxonImageItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutTaxonImageItem$Json$Html(params: AdminPutTaxonImageItem$Json$Html$Params, context?: HttpContext): Observable<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow> {
    return this.adminPutTaxonImageItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>): TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow => r.body)
    );
  }

  /**
   * Replaces the TaxonImage resource.
   *
   * Replaces the TaxonImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxonImageItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxonImageItem$Html$Json$Response(params: AdminPutTaxonImageItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>> {
    return adminPutTaxonImageItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the TaxonImage resource.
   *
   * Replaces the TaxonImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxonImageItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxonImageItem$Html$Json(params: AdminPutTaxonImageItem$Html$Json$Params, context?: HttpContext): Observable<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow> {
    return this.adminPutTaxonImageItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>): TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow => r.body)
    );
  }

  /**
   * Replaces the TaxonImage resource.
   *
   * Replaces the TaxonImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutTaxonImageItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxonImageItem$Html$Html$Response(params: AdminPutTaxonImageItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>> {
    return adminPutTaxonImageItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the TaxonImage resource.
   *
   * Replaces the TaxonImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutTaxonImageItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutTaxonImageItem$Html$Html(params: AdminPutTaxonImageItem$Html$Html$Params, context?: HttpContext): Observable<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow> {
    return this.adminPutTaxonImageItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>): TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteTaxonImageItem()` */
  static readonly AdminDeleteTaxonImageItemPath = '/api/v2/admin/taxon-images/{id}';

  /**
   * Removes the TaxonImage resource.
   *
   * Removes the TaxonImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteTaxonImageItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteTaxonImageItem$Response(params: AdminDeleteTaxonImageItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteTaxonImageItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the TaxonImage resource.
   *
   * Removes the TaxonImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteTaxonImageItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteTaxonImageItem(params: AdminDeleteTaxonImageItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteTaxonImageItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiTaxaImagesGetSubresourceTaxonSubresource()` */
  static readonly ApiTaxaImagesGetSubresourceTaxonSubresourcePath = '/api/v2/admin/taxons/{code}/images';

  /**
   * Retrieves the collection of TaxonImage resources for a given Taxon.
   *                     .
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaxaImagesGetSubresourceTaxonSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaxaImagesGetSubresourceTaxonSubresource$Json$Response(params: ApiTaxaImagesGetSubresourceTaxonSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>>> {
    return apiTaxaImagesGetSubresourceTaxonSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of TaxonImage resources for a given Taxon.
   *                     .
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaxaImagesGetSubresourceTaxonSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaxaImagesGetSubresourceTaxonSubresource$Json(params: ApiTaxaImagesGetSubresourceTaxonSubresource$Json$Params, context?: HttpContext): Observable<Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>> {
    return this.apiTaxaImagesGetSubresourceTaxonSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>>): Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow> => r.body)
    );
  }

  /**
   * Retrieves the collection of TaxonImage resources for a given Taxon.
   *                     .
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTaxaImagesGetSubresourceTaxonSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaxaImagesGetSubresourceTaxonSubresource$Html$Response(params: ApiTaxaImagesGetSubresourceTaxonSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>>> {
    return apiTaxaImagesGetSubresourceTaxonSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of TaxonImage resources for a given Taxon.
   *                     .
   *
   * Retrieves a Taxon resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTaxaImagesGetSubresourceTaxonSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTaxaImagesGetSubresourceTaxonSubresource$Html(params: ApiTaxaImagesGetSubresourceTaxonSubresource$Html$Params, context?: HttpContext): Observable<Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>> {
    return this.apiTaxaImagesGetSubresourceTaxonSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>>): Array<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow> => r.body)
    );
  }

  /** Path part for operation `adminPostTaxonImageCollection()` */
  static readonly AdminPostTaxonImageCollectionPath = '/api/v2/admin/taxons/{code}/images';

  /**
   * Creates a TaxonImage resource.
   *
   * Creates a TaxonImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostTaxonImageCollection$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminPostTaxonImageCollection$Json$Response(params: AdminPostTaxonImageCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>> {
    return adminPostTaxonImageCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a TaxonImage resource.
   *
   * Creates a TaxonImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostTaxonImageCollection$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminPostTaxonImageCollection$Json(params: AdminPostTaxonImageCollection$Json$Params, context?: HttpContext): Observable<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow> {
    return this.adminPostTaxonImageCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>): TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow => r.body)
    );
  }

  /**
   * Creates a TaxonImage resource.
   *
   * Creates a TaxonImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostTaxonImageCollection$Html()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminPostTaxonImageCollection$Html$Response(params: AdminPostTaxonImageCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>> {
    return adminPostTaxonImageCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a TaxonImage resource.
   *
   * Creates a TaxonImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostTaxonImageCollection$Html$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminPostTaxonImageCollection$Html(params: AdminPostTaxonImageCollection$Html$Params, context?: HttpContext): Observable<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow> {
    return this.adminPostTaxonImageCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow>): TaxonImageAdminTaxonImageShowSyliusAdminTaxonImageShow => r.body)
    );
  }

  /** Path part for operation `shopGetTaxonImageItem()` */
  static readonly ShopGetTaxonImageItemPath = '/api/v2/shop/taxon-images/{id}';

  /**
   * Retrieves a TaxonImage resource.
   *
   * Retrieves a TaxonImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetTaxonImageItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetTaxonImageItem$Json$Response(params: ShopGetTaxonImageItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonImageShopTaxonImageShowSyliusShopTaxonImageShow>> {
    return shopGetTaxonImageItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a TaxonImage resource.
   *
   * Retrieves a TaxonImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetTaxonImageItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetTaxonImageItem$Json(params: ShopGetTaxonImageItem$Json$Params, context?: HttpContext): Observable<TaxonImageShopTaxonImageShowSyliusShopTaxonImageShow> {
    return this.shopGetTaxonImageItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonImageShopTaxonImageShowSyliusShopTaxonImageShow>): TaxonImageShopTaxonImageShowSyliusShopTaxonImageShow => r.body)
    );
  }

  /**
   * Retrieves a TaxonImage resource.
   *
   * Retrieves a TaxonImage resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetTaxonImageItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetTaxonImageItem$Html$Response(params: ShopGetTaxonImageItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonImageShopTaxonImageShowSyliusShopTaxonImageShow>> {
    return shopGetTaxonImageItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a TaxonImage resource.
   *
   * Retrieves a TaxonImage resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetTaxonImageItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetTaxonImageItem$Html(params: ShopGetTaxonImageItem$Html$Params, context?: HttpContext): Observable<TaxonImageShopTaxonImageShowSyliusShopTaxonImageShow> {
    return this.shopGetTaxonImageItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonImageShopTaxonImageShowSyliusShopTaxonImageShow>): TaxonImageShopTaxonImageShowSyliusShopTaxonImageShow => r.body)
    );
  }

}

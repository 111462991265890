/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminCreateResetPasswordRequestAdministratorCollection$Html$Html } from '../fn/administrator/admin-create-reset-password-request-administrator-collection-html-html';
import { AdminCreateResetPasswordRequestAdministratorCollection$Html$Html$Params } from '../fn/administrator/admin-create-reset-password-request-administrator-collection-html-html';
import { adminCreateResetPasswordRequestAdministratorCollection$Html$Json } from '../fn/administrator/admin-create-reset-password-request-administrator-collection-html-json';
import { AdminCreateResetPasswordRequestAdministratorCollection$Html$Json$Params } from '../fn/administrator/admin-create-reset-password-request-administrator-collection-html-json';
import { adminCreateResetPasswordRequestAdministratorCollection$Json$Html } from '../fn/administrator/admin-create-reset-password-request-administrator-collection-json-html';
import { AdminCreateResetPasswordRequestAdministratorCollection$Json$Html$Params } from '../fn/administrator/admin-create-reset-password-request-administrator-collection-json-html';
import { adminCreateResetPasswordRequestAdministratorCollection$Json$Json } from '../fn/administrator/admin-create-reset-password-request-administrator-collection-json-json';
import { AdminCreateResetPasswordRequestAdministratorCollection$Json$Json$Params } from '../fn/administrator/admin-create-reset-password-request-administrator-collection-json-json';
import { adminDeleteAdministratorItem } from '../fn/administrator/admin-delete-administrator-item';
import { AdminDeleteAdministratorItem$Params } from '../fn/administrator/admin-delete-administrator-item';
import { adminGetAdministratorCollection$Html } from '../fn/administrator/admin-get-administrator-collection-html';
import { AdminGetAdministratorCollection$Html$Params } from '../fn/administrator/admin-get-administrator-collection-html';
import { adminGetAdministratorCollection$Json } from '../fn/administrator/admin-get-administrator-collection-json';
import { AdminGetAdministratorCollection$Json$Params } from '../fn/administrator/admin-get-administrator-collection-json';
import { adminGetAdministratorItem$Html } from '../fn/administrator/admin-get-administrator-item-html';
import { AdminGetAdministratorItem$Html$Params } from '../fn/administrator/admin-get-administrator-item-html';
import { adminGetAdministratorItem$Json } from '../fn/administrator/admin-get-administrator-item-json';
import { AdminGetAdministratorItem$Json$Params } from '../fn/administrator/admin-get-administrator-item-json';
import { Index as AdministratorAdminAdminUserIndexSyliusAdminAdminUserIndex } from '../models/Administrator-admin/admin_user/index_sylius/admin/admin_user/index';
import { Show as AdministratorAdminAdminUserShowSyliusAdminAdminUserShow } from '../models/Administrator-admin/admin_user/show_sylius/admin/admin_user/show';
import { Read as AdministratorAdminAdministratorTokenRead } from '../models/Administrator-admin/administrator/token/read';
import { adminPostAdministratorCollection$Html$Html } from '../fn/administrator/admin-post-administrator-collection-html-html';
import { AdminPostAdministratorCollection$Html$Html$Params } from '../fn/administrator/admin-post-administrator-collection-html-html';
import { adminPostAdministratorCollection$Html$Json } from '../fn/administrator/admin-post-administrator-collection-html-json';
import { AdminPostAdministratorCollection$Html$Json$Params } from '../fn/administrator/admin-post-administrator-collection-html-json';
import { adminPostAdministratorCollection$Json$Html } from '../fn/administrator/admin-post-administrator-collection-json-html';
import { AdminPostAdministratorCollection$Json$Html$Params } from '../fn/administrator/admin-post-administrator-collection-json-html';
import { adminPostAdministratorCollection$Json$Json } from '../fn/administrator/admin-post-administrator-collection-json-json';
import { AdminPostAdministratorCollection$Json$Json$Params } from '../fn/administrator/admin-post-administrator-collection-json-json';
import { adminPutAdministratorItem$Html$Html } from '../fn/administrator/admin-put-administrator-item-html-html';
import { AdminPutAdministratorItem$Html$Html$Params } from '../fn/administrator/admin-put-administrator-item-html-html';
import { adminPutAdministratorItem$Html$Json } from '../fn/administrator/admin-put-administrator-item-html-json';
import { AdminPutAdministratorItem$Html$Json$Params } from '../fn/administrator/admin-put-administrator-item-html-json';
import { adminPutAdministratorItem$Json$Html } from '../fn/administrator/admin-put-administrator-item-json-html';
import { AdminPutAdministratorItem$Json$Html$Params } from '../fn/administrator/admin-put-administrator-item-json-html';
import { adminPutAdministratorItem$Json$Json } from '../fn/administrator/admin-put-administrator-item-json-json';
import { AdminPutAdministratorItem$Json$Json$Params } from '../fn/administrator/admin-put-administrator-item-json-json';
import { adminUpdateResetPasswordRequestAdministratorItem$Html } from '../fn/administrator/admin-update-reset-password-request-administrator-item-html';
import { AdminUpdateResetPasswordRequestAdministratorItem$Html$Params } from '../fn/administrator/admin-update-reset-password-request-administrator-item-html';
import { adminUpdateResetPasswordRequestAdministratorItem$Json } from '../fn/administrator/admin-update-reset-password-request-administrator-item-json';
import { AdminUpdateResetPasswordRequestAdministratorItem$Json$Params } from '../fn/administrator/admin-update-reset-password-request-administrator-item-json';
import { postAdministratorCredentialsItem } from '../fn/administrator/post-administrator-credentials-item';
import { PostAdministratorCredentialsItem$Params } from '../fn/administrator/post-administrator-credentials-item';

@Injectable({ providedIn: 'root' })
export class AdministratorService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetAdministratorCollection()` */
  static readonly AdminGetAdministratorCollectionPath = '/api/v2/admin/administrators';

  /**
   * Retrieves the collection of Administrator resources.
   *
   * Retrieves the collection of Administrator resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetAdministratorCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAdministratorCollection$Json$Response(params?: AdminGetAdministratorCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdministratorAdminAdminUserIndexSyliusAdminAdminUserIndex>>> {
    return adminGetAdministratorCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Administrator resources.
   *
   * Retrieves the collection of Administrator resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetAdministratorCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAdministratorCollection$Json(params?: AdminGetAdministratorCollection$Json$Params, context?: HttpContext): Observable<Array<AdministratorAdminAdminUserIndexSyliusAdminAdminUserIndex>> {
    return this.adminGetAdministratorCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdministratorAdminAdminUserIndexSyliusAdminAdminUserIndex>>): Array<AdministratorAdminAdminUserIndexSyliusAdminAdminUserIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Administrator resources.
   *
   * Retrieves the collection of Administrator resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetAdministratorCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAdministratorCollection$Html$Response(params?: AdminGetAdministratorCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdministratorAdminAdminUserIndexSyliusAdminAdminUserIndex>>> {
    return adminGetAdministratorCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Administrator resources.
   *
   * Retrieves the collection of Administrator resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetAdministratorCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAdministratorCollection$Html(params?: AdminGetAdministratorCollection$Html$Params, context?: HttpContext): Observable<Array<AdministratorAdminAdminUserIndexSyliusAdminAdminUserIndex>> {
    return this.adminGetAdministratorCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdministratorAdminAdminUserIndexSyliusAdminAdminUserIndex>>): Array<AdministratorAdminAdminUserIndexSyliusAdminAdminUserIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostAdministratorCollection()` */
  static readonly AdminPostAdministratorCollectionPath = '/api/v2/admin/administrators';

  /**
   * Creates a Administrator resource.
   *
   * Creates a Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostAdministratorCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostAdministratorCollection$Json$Json$Response(params: AdminPostAdministratorCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>> {
    return adminPostAdministratorCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Administrator resource.
   *
   * Creates a Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostAdministratorCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostAdministratorCollection$Json$Json(params: AdminPostAdministratorCollection$Json$Json$Params, context?: HttpContext): Observable<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow> {
    return this.adminPostAdministratorCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>): AdministratorAdminAdminUserShowSyliusAdminAdminUserShow => r.body)
    );
  }

  /**
   * Creates a Administrator resource.
   *
   * Creates a Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostAdministratorCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostAdministratorCollection$Json$Html$Response(params: AdminPostAdministratorCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>> {
    return adminPostAdministratorCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Administrator resource.
   *
   * Creates a Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostAdministratorCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostAdministratorCollection$Json$Html(params: AdminPostAdministratorCollection$Json$Html$Params, context?: HttpContext): Observable<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow> {
    return this.adminPostAdministratorCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>): AdministratorAdminAdminUserShowSyliusAdminAdminUserShow => r.body)
    );
  }

  /**
   * Creates a Administrator resource.
   *
   * Creates a Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostAdministratorCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostAdministratorCollection$Html$Json$Response(params: AdminPostAdministratorCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>> {
    return adminPostAdministratorCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Administrator resource.
   *
   * Creates a Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostAdministratorCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostAdministratorCollection$Html$Json(params: AdminPostAdministratorCollection$Html$Json$Params, context?: HttpContext): Observable<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow> {
    return this.adminPostAdministratorCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>): AdministratorAdminAdminUserShowSyliusAdminAdminUserShow => r.body)
    );
  }

  /**
   * Creates a Administrator resource.
   *
   * Creates a Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostAdministratorCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostAdministratorCollection$Html$Html$Response(params: AdminPostAdministratorCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>> {
    return adminPostAdministratorCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Administrator resource.
   *
   * Creates a Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostAdministratorCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostAdministratorCollection$Html$Html(params: AdminPostAdministratorCollection$Html$Html$Params, context?: HttpContext): Observable<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow> {
    return this.adminPostAdministratorCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>): AdministratorAdminAdminUserShowSyliusAdminAdminUserShow => r.body)
    );
  }

  /** Path part for operation `adminCreateResetPasswordRequestAdministratorCollection()` */
  static readonly AdminCreateResetPasswordRequestAdministratorCollectionPath = '/api/v2/admin/administrators/reset-password';

  /**
   * Requests administrator's password reset.
   *
   * Creates a Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCreateResetPasswordRequestAdministratorCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminCreateResetPasswordRequestAdministratorCollection$Json$Json$Response(params: AdminCreateResetPasswordRequestAdministratorCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminCreateResetPasswordRequestAdministratorCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Requests administrator's password reset.
   *
   * Creates a Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCreateResetPasswordRequestAdministratorCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminCreateResetPasswordRequestAdministratorCollection$Json$Json(params: AdminCreateResetPasswordRequestAdministratorCollection$Json$Json$Params, context?: HttpContext): Observable<any> {
    return this.adminCreateResetPasswordRequestAdministratorCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Requests administrator's password reset.
   *
   * Creates a Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCreateResetPasswordRequestAdministratorCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminCreateResetPasswordRequestAdministratorCollection$Json$Html$Response(params: AdminCreateResetPasswordRequestAdministratorCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminCreateResetPasswordRequestAdministratorCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Requests administrator's password reset.
   *
   * Creates a Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCreateResetPasswordRequestAdministratorCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminCreateResetPasswordRequestAdministratorCollection$Json$Html(params: AdminCreateResetPasswordRequestAdministratorCollection$Json$Html$Params, context?: HttpContext): Observable<any> {
    return this.adminCreateResetPasswordRequestAdministratorCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Requests administrator's password reset.
   *
   * Creates a Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCreateResetPasswordRequestAdministratorCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminCreateResetPasswordRequestAdministratorCollection$Html$Json$Response(params: AdminCreateResetPasswordRequestAdministratorCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminCreateResetPasswordRequestAdministratorCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Requests administrator's password reset.
   *
   * Creates a Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCreateResetPasswordRequestAdministratorCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminCreateResetPasswordRequestAdministratorCollection$Html$Json(params: AdminCreateResetPasswordRequestAdministratorCollection$Html$Json$Params, context?: HttpContext): Observable<any> {
    return this.adminCreateResetPasswordRequestAdministratorCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Requests administrator's password reset.
   *
   * Creates a Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCreateResetPasswordRequestAdministratorCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminCreateResetPasswordRequestAdministratorCollection$Html$Html$Response(params: AdminCreateResetPasswordRequestAdministratorCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminCreateResetPasswordRequestAdministratorCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Requests administrator's password reset.
   *
   * Creates a Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCreateResetPasswordRequestAdministratorCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminCreateResetPasswordRequestAdministratorCollection$Html$Html(params: AdminCreateResetPasswordRequestAdministratorCollection$Html$Html$Params, context?: HttpContext): Observable<any> {
    return this.adminCreateResetPasswordRequestAdministratorCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `adminUpdateResetPasswordRequestAdministratorItem()` */
  static readonly AdminUpdateResetPasswordRequestAdministratorItemPath = '/api/v2/admin/administrators/reset-password/{token}';

  /**
   * Resets administrator's password.
   *
   * Updates the Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUpdateResetPasswordRequestAdministratorItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminUpdateResetPasswordRequestAdministratorItem$Json$Response(params: AdminUpdateResetPasswordRequestAdministratorItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminUpdateResetPasswordRequestAdministratorItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Resets administrator's password.
   *
   * Updates the Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUpdateResetPasswordRequestAdministratorItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminUpdateResetPasswordRequestAdministratorItem$Json(params: AdminUpdateResetPasswordRequestAdministratorItem$Json$Params, context?: HttpContext): Observable<any> {
    return this.adminUpdateResetPasswordRequestAdministratorItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Resets administrator's password.
   *
   * Updates the Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUpdateResetPasswordRequestAdministratorItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminUpdateResetPasswordRequestAdministratorItem$Html$Response(params: AdminUpdateResetPasswordRequestAdministratorItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminUpdateResetPasswordRequestAdministratorItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Resets administrator's password.
   *
   * Updates the Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUpdateResetPasswordRequestAdministratorItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminUpdateResetPasswordRequestAdministratorItem$Html(params: AdminUpdateResetPasswordRequestAdministratorItem$Html$Params, context?: HttpContext): Observable<any> {
    return this.adminUpdateResetPasswordRequestAdministratorItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `postAdministratorCredentialsItem()` */
  static readonly PostAdministratorCredentialsItemPath = '/api/v2/admin/administrators/token';

  /**
   * Retrieves the JWT token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAdministratorCredentialsItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAdministratorCredentialsItem$Response(params?: PostAdministratorCredentialsItem$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministratorAdminAdministratorTokenRead>> {
    return postAdministratorCredentialsItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the JWT token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postAdministratorCredentialsItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAdministratorCredentialsItem(params?: PostAdministratorCredentialsItem$Params, context?: HttpContext): Observable<AdministratorAdminAdministratorTokenRead> {
    return this.postAdministratorCredentialsItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministratorAdminAdministratorTokenRead>): AdministratorAdminAdministratorTokenRead => r.body)
    );
  }

  /** Path part for operation `adminGetAdministratorItem()` */
  static readonly AdminGetAdministratorItemPath = '/api/v2/admin/administrators/{id}';

  /**
   * Retrieves a Administrator resource.
   *
   * Retrieves a Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetAdministratorItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAdministratorItem$Json$Response(params: AdminGetAdministratorItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>> {
    return adminGetAdministratorItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Administrator resource.
   *
   * Retrieves a Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetAdministratorItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAdministratorItem$Json(params: AdminGetAdministratorItem$Json$Params, context?: HttpContext): Observable<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow> {
    return this.adminGetAdministratorItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>): AdministratorAdminAdminUserShowSyliusAdminAdminUserShow => r.body)
    );
  }

  /**
   * Retrieves a Administrator resource.
   *
   * Retrieves a Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetAdministratorItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAdministratorItem$Html$Response(params: AdminGetAdministratorItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>> {
    return adminGetAdministratorItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Administrator resource.
   *
   * Retrieves a Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetAdministratorItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAdministratorItem$Html(params: AdminGetAdministratorItem$Html$Params, context?: HttpContext): Observable<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow> {
    return this.adminGetAdministratorItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>): AdministratorAdminAdminUserShowSyliusAdminAdminUserShow => r.body)
    );
  }

  /** Path part for operation `adminPutAdministratorItem()` */
  static readonly AdminPutAdministratorItemPath = '/api/v2/admin/administrators/{id}';

  /**
   * Replaces the Administrator resource.
   *
   * Replaces the Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutAdministratorItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutAdministratorItem$Json$Json$Response(params: AdminPutAdministratorItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>> {
    return adminPutAdministratorItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Administrator resource.
   *
   * Replaces the Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutAdministratorItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutAdministratorItem$Json$Json(params: AdminPutAdministratorItem$Json$Json$Params, context?: HttpContext): Observable<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow> {
    return this.adminPutAdministratorItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>): AdministratorAdminAdminUserShowSyliusAdminAdminUserShow => r.body)
    );
  }

  /**
   * Replaces the Administrator resource.
   *
   * Replaces the Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutAdministratorItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutAdministratorItem$Json$Html$Response(params: AdminPutAdministratorItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>> {
    return adminPutAdministratorItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Administrator resource.
   *
   * Replaces the Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutAdministratorItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutAdministratorItem$Json$Html(params: AdminPutAdministratorItem$Json$Html$Params, context?: HttpContext): Observable<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow> {
    return this.adminPutAdministratorItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>): AdministratorAdminAdminUserShowSyliusAdminAdminUserShow => r.body)
    );
  }

  /**
   * Replaces the Administrator resource.
   *
   * Replaces the Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutAdministratorItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutAdministratorItem$Html$Json$Response(params: AdminPutAdministratorItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>> {
    return adminPutAdministratorItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Administrator resource.
   *
   * Replaces the Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutAdministratorItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutAdministratorItem$Html$Json(params: AdminPutAdministratorItem$Html$Json$Params, context?: HttpContext): Observable<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow> {
    return this.adminPutAdministratorItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>): AdministratorAdminAdminUserShowSyliusAdminAdminUserShow => r.body)
    );
  }

  /**
   * Replaces the Administrator resource.
   *
   * Replaces the Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutAdministratorItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutAdministratorItem$Html$Html$Response(params: AdminPutAdministratorItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>> {
    return adminPutAdministratorItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Administrator resource.
   *
   * Replaces the Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutAdministratorItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutAdministratorItem$Html$Html(params: AdminPutAdministratorItem$Html$Html$Params, context?: HttpContext): Observable<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow> {
    return this.adminPutAdministratorItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>): AdministratorAdminAdminUserShowSyliusAdminAdminUserShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteAdministratorItem()` */
  static readonly AdminDeleteAdministratorItemPath = '/api/v2/admin/administrators/{id}';

  /**
   * Removes the Administrator resource.
   *
   * Removes the Administrator resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteAdministratorItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteAdministratorItem$Response(params: AdminDeleteAdministratorItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteAdministratorItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Administrator resource.
   *
   * Removes the Administrator resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteAdministratorItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteAdministratorItem(params: AdminDeleteAdministratorItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteAdministratorItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteExchangeRateItem } from '../fn/exchange-rate/admin-delete-exchange-rate-item';
import { AdminDeleteExchangeRateItem$Params } from '../fn/exchange-rate/admin-delete-exchange-rate-item';
import { adminGetExchangeRateCollection$Html } from '../fn/exchange-rate/admin-get-exchange-rate-collection-html';
import { AdminGetExchangeRateCollection$Html$Params } from '../fn/exchange-rate/admin-get-exchange-rate-collection-html';
import { adminGetExchangeRateCollection$Json } from '../fn/exchange-rate/admin-get-exchange-rate-collection-json';
import { AdminGetExchangeRateCollection$Json$Params } from '../fn/exchange-rate/admin-get-exchange-rate-collection-json';
import { adminGetExchangeRateItem$Html } from '../fn/exchange-rate/admin-get-exchange-rate-item-html';
import { AdminGetExchangeRateItem$Html$Params } from '../fn/exchange-rate/admin-get-exchange-rate-item-html';
import { adminGetExchangeRateItem$Json } from '../fn/exchange-rate/admin-get-exchange-rate-item-json';
import { AdminGetExchangeRateItem$Json$Params } from '../fn/exchange-rate/admin-get-exchange-rate-item-json';
import { adminPostExchangeRateCollection$Html$Html } from '../fn/exchange-rate/admin-post-exchange-rate-collection-html-html';
import { AdminPostExchangeRateCollection$Html$Html$Params } from '../fn/exchange-rate/admin-post-exchange-rate-collection-html-html';
import { adminPostExchangeRateCollection$Html$Json } from '../fn/exchange-rate/admin-post-exchange-rate-collection-html-json';
import { AdminPostExchangeRateCollection$Html$Json$Params } from '../fn/exchange-rate/admin-post-exchange-rate-collection-html-json';
import { adminPostExchangeRateCollection$Json$Html } from '../fn/exchange-rate/admin-post-exchange-rate-collection-json-html';
import { AdminPostExchangeRateCollection$Json$Html$Params } from '../fn/exchange-rate/admin-post-exchange-rate-collection-json-html';
import { adminPostExchangeRateCollection$Json$Json } from '../fn/exchange-rate/admin-post-exchange-rate-collection-json-json';
import { AdminPostExchangeRateCollection$Json$Json$Params } from '../fn/exchange-rate/admin-post-exchange-rate-collection-json-json';
import { adminPutExchangeRateItem$Html$Html } from '../fn/exchange-rate/admin-put-exchange-rate-item-html-html';
import { AdminPutExchangeRateItem$Html$Html$Params } from '../fn/exchange-rate/admin-put-exchange-rate-item-html-html';
import { adminPutExchangeRateItem$Html$Json } from '../fn/exchange-rate/admin-put-exchange-rate-item-html-json';
import { AdminPutExchangeRateItem$Html$Json$Params } from '../fn/exchange-rate/admin-put-exchange-rate-item-html-json';
import { adminPutExchangeRateItem$Json$Html } from '../fn/exchange-rate/admin-put-exchange-rate-item-json-html';
import { AdminPutExchangeRateItem$Json$Html$Params } from '../fn/exchange-rate/admin-put-exchange-rate-item-json-html';
import { adminPutExchangeRateItem$Json$Json } from '../fn/exchange-rate/admin-put-exchange-rate-item-json-json';
import { AdminPutExchangeRateItem$Json$Json$Params } from '../fn/exchange-rate/admin-put-exchange-rate-item-json-json';
import { Index as ExchangeRateAdminExchangeRateIndexSyliusAdminExchangeRateIndex } from '../models/ExchangeRate-admin/exchange_rate/index_sylius/admin/exchange_rate/index';
import { Show as ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow } from '../models/ExchangeRate-admin/exchange_rate/show_sylius/admin/exchange_rate/show';
import { Index as ExchangeRateShopExchangeRateIndexSyliusShopExchangeRateIndex } from '../models/ExchangeRate-shop/exchange_rate/index_sylius/shop/exchange_rate/index';
import { Show as ExchangeRateShopExchangeRateShowSyliusShopExchangeRateShow } from '../models/ExchangeRate-shop/exchange_rate/show_sylius/shop/exchange_rate/show';
import { shopGetExchangeRateCollection$Html } from '../fn/exchange-rate/shop-get-exchange-rate-collection-html';
import { ShopGetExchangeRateCollection$Html$Params } from '../fn/exchange-rate/shop-get-exchange-rate-collection-html';
import { shopGetExchangeRateCollection$Json } from '../fn/exchange-rate/shop-get-exchange-rate-collection-json';
import { ShopGetExchangeRateCollection$Json$Params } from '../fn/exchange-rate/shop-get-exchange-rate-collection-json';
import { shopGetExchangeRateItem$Html } from '../fn/exchange-rate/shop-get-exchange-rate-item-html';
import { ShopGetExchangeRateItem$Html$Params } from '../fn/exchange-rate/shop-get-exchange-rate-item-html';
import { shopGetExchangeRateItem$Json } from '../fn/exchange-rate/shop-get-exchange-rate-item-json';
import { ShopGetExchangeRateItem$Json$Params } from '../fn/exchange-rate/shop-get-exchange-rate-item-json';

@Injectable({ providedIn: 'root' })
export class ExchangeRateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetExchangeRateCollection()` */
  static readonly AdminGetExchangeRateCollectionPath = '/api/v2/admin/exchange-rates';

  /**
   * Retrieves the collection of ExchangeRate resources.
   *
   * Retrieves the collection of ExchangeRate resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetExchangeRateCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetExchangeRateCollection$Json$Response(params?: AdminGetExchangeRateCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExchangeRateAdminExchangeRateIndexSyliusAdminExchangeRateIndex>>> {
    return adminGetExchangeRateCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ExchangeRate resources.
   *
   * Retrieves the collection of ExchangeRate resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetExchangeRateCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetExchangeRateCollection$Json(params?: AdminGetExchangeRateCollection$Json$Params, context?: HttpContext): Observable<Array<ExchangeRateAdminExchangeRateIndexSyliusAdminExchangeRateIndex>> {
    return this.adminGetExchangeRateCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExchangeRateAdminExchangeRateIndexSyliusAdminExchangeRateIndex>>): Array<ExchangeRateAdminExchangeRateIndexSyliusAdminExchangeRateIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ExchangeRate resources.
   *
   * Retrieves the collection of ExchangeRate resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetExchangeRateCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetExchangeRateCollection$Html$Response(params?: AdminGetExchangeRateCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExchangeRateAdminExchangeRateIndexSyliusAdminExchangeRateIndex>>> {
    return adminGetExchangeRateCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ExchangeRate resources.
   *
   * Retrieves the collection of ExchangeRate resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetExchangeRateCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetExchangeRateCollection$Html(params?: AdminGetExchangeRateCollection$Html$Params, context?: HttpContext): Observable<Array<ExchangeRateAdminExchangeRateIndexSyliusAdminExchangeRateIndex>> {
    return this.adminGetExchangeRateCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExchangeRateAdminExchangeRateIndexSyliusAdminExchangeRateIndex>>): Array<ExchangeRateAdminExchangeRateIndexSyliusAdminExchangeRateIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostExchangeRateCollection()` */
  static readonly AdminPostExchangeRateCollectionPath = '/api/v2/admin/exchange-rates';

  /**
   * Creates a ExchangeRate resource.
   *
   * Creates a ExchangeRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostExchangeRateCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostExchangeRateCollection$Json$Json$Response(params: AdminPostExchangeRateCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>> {
    return adminPostExchangeRateCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ExchangeRate resource.
   *
   * Creates a ExchangeRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostExchangeRateCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostExchangeRateCollection$Json$Json(params: AdminPostExchangeRateCollection$Json$Json$Params, context?: HttpContext): Observable<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow> {
    return this.adminPostExchangeRateCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>): ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow => r.body)
    );
  }

  /**
   * Creates a ExchangeRate resource.
   *
   * Creates a ExchangeRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostExchangeRateCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostExchangeRateCollection$Json$Html$Response(params: AdminPostExchangeRateCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>> {
    return adminPostExchangeRateCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ExchangeRate resource.
   *
   * Creates a ExchangeRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostExchangeRateCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostExchangeRateCollection$Json$Html(params: AdminPostExchangeRateCollection$Json$Html$Params, context?: HttpContext): Observable<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow> {
    return this.adminPostExchangeRateCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>): ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow => r.body)
    );
  }

  /**
   * Creates a ExchangeRate resource.
   *
   * Creates a ExchangeRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostExchangeRateCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostExchangeRateCollection$Html$Json$Response(params: AdminPostExchangeRateCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>> {
    return adminPostExchangeRateCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ExchangeRate resource.
   *
   * Creates a ExchangeRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostExchangeRateCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostExchangeRateCollection$Html$Json(params: AdminPostExchangeRateCollection$Html$Json$Params, context?: HttpContext): Observable<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow> {
    return this.adminPostExchangeRateCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>): ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow => r.body)
    );
  }

  /**
   * Creates a ExchangeRate resource.
   *
   * Creates a ExchangeRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostExchangeRateCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostExchangeRateCollection$Html$Html$Response(params: AdminPostExchangeRateCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>> {
    return adminPostExchangeRateCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ExchangeRate resource.
   *
   * Creates a ExchangeRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostExchangeRateCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostExchangeRateCollection$Html$Html(params: AdminPostExchangeRateCollection$Html$Html$Params, context?: HttpContext): Observable<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow> {
    return this.adminPostExchangeRateCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>): ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow => r.body)
    );
  }

  /** Path part for operation `adminGetExchangeRateItem()` */
  static readonly AdminGetExchangeRateItemPath = '/api/v2/admin/exchange-rates/{id}';

  /**
   * Retrieves a ExchangeRate resource.
   *
   * Retrieves a ExchangeRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetExchangeRateItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetExchangeRateItem$Json$Response(params: AdminGetExchangeRateItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>> {
    return adminGetExchangeRateItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ExchangeRate resource.
   *
   * Retrieves a ExchangeRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetExchangeRateItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetExchangeRateItem$Json(params: AdminGetExchangeRateItem$Json$Params, context?: HttpContext): Observable<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow> {
    return this.adminGetExchangeRateItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>): ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow => r.body)
    );
  }

  /**
   * Retrieves a ExchangeRate resource.
   *
   * Retrieves a ExchangeRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetExchangeRateItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetExchangeRateItem$Html$Response(params: AdminGetExchangeRateItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>> {
    return adminGetExchangeRateItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ExchangeRate resource.
   *
   * Retrieves a ExchangeRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetExchangeRateItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetExchangeRateItem$Html(params: AdminGetExchangeRateItem$Html$Params, context?: HttpContext): Observable<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow> {
    return this.adminGetExchangeRateItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>): ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow => r.body)
    );
  }

  /** Path part for operation `adminPutExchangeRateItem()` */
  static readonly AdminPutExchangeRateItemPath = '/api/v2/admin/exchange-rates/{id}';

  /**
   * Replaces the ExchangeRate resource.
   *
   * Replaces the ExchangeRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutExchangeRateItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutExchangeRateItem$Json$Json$Response(params: AdminPutExchangeRateItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>> {
    return adminPutExchangeRateItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ExchangeRate resource.
   *
   * Replaces the ExchangeRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutExchangeRateItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutExchangeRateItem$Json$Json(params: AdminPutExchangeRateItem$Json$Json$Params, context?: HttpContext): Observable<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow> {
    return this.adminPutExchangeRateItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>): ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow => r.body)
    );
  }

  /**
   * Replaces the ExchangeRate resource.
   *
   * Replaces the ExchangeRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutExchangeRateItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutExchangeRateItem$Json$Html$Response(params: AdminPutExchangeRateItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>> {
    return adminPutExchangeRateItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ExchangeRate resource.
   *
   * Replaces the ExchangeRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutExchangeRateItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutExchangeRateItem$Json$Html(params: AdminPutExchangeRateItem$Json$Html$Params, context?: HttpContext): Observable<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow> {
    return this.adminPutExchangeRateItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>): ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow => r.body)
    );
  }

  /**
   * Replaces the ExchangeRate resource.
   *
   * Replaces the ExchangeRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutExchangeRateItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutExchangeRateItem$Html$Json$Response(params: AdminPutExchangeRateItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>> {
    return adminPutExchangeRateItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ExchangeRate resource.
   *
   * Replaces the ExchangeRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutExchangeRateItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutExchangeRateItem$Html$Json(params: AdminPutExchangeRateItem$Html$Json$Params, context?: HttpContext): Observable<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow> {
    return this.adminPutExchangeRateItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>): ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow => r.body)
    );
  }

  /**
   * Replaces the ExchangeRate resource.
   *
   * Replaces the ExchangeRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutExchangeRateItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutExchangeRateItem$Html$Html$Response(params: AdminPutExchangeRateItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>> {
    return adminPutExchangeRateItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ExchangeRate resource.
   *
   * Replaces the ExchangeRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutExchangeRateItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutExchangeRateItem$Html$Html(params: AdminPutExchangeRateItem$Html$Html$Params, context?: HttpContext): Observable<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow> {
    return this.adminPutExchangeRateItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow>): ExchangeRateAdminExchangeRateShowSyliusAdminExchangeRateShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteExchangeRateItem()` */
  static readonly AdminDeleteExchangeRateItemPath = '/api/v2/admin/exchange-rates/{id}';

  /**
   * Removes the ExchangeRate resource.
   *
   * Removes the ExchangeRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteExchangeRateItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteExchangeRateItem$Response(params: AdminDeleteExchangeRateItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteExchangeRateItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the ExchangeRate resource.
   *
   * Removes the ExchangeRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteExchangeRateItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteExchangeRateItem(params: AdminDeleteExchangeRateItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteExchangeRateItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `shopGetExchangeRateCollection()` */
  static readonly ShopGetExchangeRateCollectionPath = '/api/v2/shop/exchange-rates';

  /**
   * Retrieves the collection of ExchangeRate resources.
   *
   * Retrieves the collection of ExchangeRate resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetExchangeRateCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetExchangeRateCollection$Json$Response(params?: ShopGetExchangeRateCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExchangeRateShopExchangeRateIndexSyliusShopExchangeRateIndex>>> {
    return shopGetExchangeRateCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ExchangeRate resources.
   *
   * Retrieves the collection of ExchangeRate resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetExchangeRateCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetExchangeRateCollection$Json(params?: ShopGetExchangeRateCollection$Json$Params, context?: HttpContext): Observable<Array<ExchangeRateShopExchangeRateIndexSyliusShopExchangeRateIndex>> {
    return this.shopGetExchangeRateCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExchangeRateShopExchangeRateIndexSyliusShopExchangeRateIndex>>): Array<ExchangeRateShopExchangeRateIndexSyliusShopExchangeRateIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ExchangeRate resources.
   *
   * Retrieves the collection of ExchangeRate resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetExchangeRateCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetExchangeRateCollection$Html$Response(params?: ShopGetExchangeRateCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExchangeRateShopExchangeRateIndexSyliusShopExchangeRateIndex>>> {
    return shopGetExchangeRateCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ExchangeRate resources.
   *
   * Retrieves the collection of ExchangeRate resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetExchangeRateCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetExchangeRateCollection$Html(params?: ShopGetExchangeRateCollection$Html$Params, context?: HttpContext): Observable<Array<ExchangeRateShopExchangeRateIndexSyliusShopExchangeRateIndex>> {
    return this.shopGetExchangeRateCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExchangeRateShopExchangeRateIndexSyliusShopExchangeRateIndex>>): Array<ExchangeRateShopExchangeRateIndexSyliusShopExchangeRateIndex> => r.body)
    );
  }

  /** Path part for operation `shopGetExchangeRateItem()` */
  static readonly ShopGetExchangeRateItemPath = '/api/v2/shop/exchange-rates/{id}';

  /**
   * Retrieves a ExchangeRate resource.
   *
   * Retrieves a ExchangeRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetExchangeRateItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetExchangeRateItem$Json$Response(params: ShopGetExchangeRateItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateShopExchangeRateShowSyliusShopExchangeRateShow>> {
    return shopGetExchangeRateItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ExchangeRate resource.
   *
   * Retrieves a ExchangeRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetExchangeRateItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetExchangeRateItem$Json(params: ShopGetExchangeRateItem$Json$Params, context?: HttpContext): Observable<ExchangeRateShopExchangeRateShowSyliusShopExchangeRateShow> {
    return this.shopGetExchangeRateItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateShopExchangeRateShowSyliusShopExchangeRateShow>): ExchangeRateShopExchangeRateShowSyliusShopExchangeRateShow => r.body)
    );
  }

  /**
   * Retrieves a ExchangeRate resource.
   *
   * Retrieves a ExchangeRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetExchangeRateItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetExchangeRateItem$Html$Response(params: ShopGetExchangeRateItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ExchangeRateShopExchangeRateShowSyliusShopExchangeRateShow>> {
    return shopGetExchangeRateItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ExchangeRate resource.
   *
   * Retrieves a ExchangeRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetExchangeRateItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetExchangeRateItem$Html(params: ShopGetExchangeRateItem$Html$Params, context?: HttpContext): Observable<ExchangeRateShopExchangeRateShowSyliusShopExchangeRateShow> {
    return this.shopGetExchangeRateItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExchangeRateShopExchangeRateShowSyliusShopExchangeRateShow>): ExchangeRateShopExchangeRateShowSyliusShopExchangeRateShow => r.body)
    );
  }

}

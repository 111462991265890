/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Show as ProvinceAdminProvinceShowSyliusAdminProvinceShow } from '../../models/Province-admin/province/show_sylius/admin/province/show';
import { Update as ProvinceAdminProvinceUpdateSyliusAdminProvinceUpdate } from '../../models/Province-admin/province/update_sylius/admin/province/update';

export interface AdminPutProvinceItem$Html$Html$Params {

/**
 * Resource identifier
 */
  code: string;

/**
 * Locales in this enum are all locales defined in the shop and only enabled ones will work in the given channel in the shop.
 */
  'Accept-Language'?: 'hu_HU';
  
    /**
     * The updated Province resource
     */
    body: ProvinceAdminProvinceUpdateSyliusAdminProvinceUpdate
}

export function adminPutProvinceItem$Html$Html(http: HttpClient, rootUrl: string, params: AdminPutProvinceItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProvinceAdminProvinceShowSyliusAdminProvinceShow>> {
  const rb = new RequestBuilder(rootUrl, adminPutProvinceItem$Html$Html.PATH, 'put');
  if (params) {
    rb.path('code', params.code, {"style":"simple","explode":false});
    rb.header('Accept-Language', params['Accept-Language'], {"style":"simple","explode":false});
    rb.body(params.body, 'text/html');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/html', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ProvinceAdminProvinceShowSyliusAdminProvinceShow>;
    })
  );
}

adminPutProvinceItem$Html$Html.PATH = '/api/v2/admin/provinces/{code}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Show as ChannelAdminChannelShowSyliusAdminChannelShow } from '../../models/Channel-admin/channel/show_sylius/admin/channel/show';
import { Update as ChannelAdminChannelUpdateSyliusAdminChannelUpdate } from '../../models/Channel-admin/channel/update_sylius/admin/channel/update';

export interface AdminPutChannelItem$Html$Json$Params {

/**
 * Resource identifier
 */
  code: string;
  
    /**
     * The updated Channel resource
     */
    body: ChannelAdminChannelUpdateSyliusAdminChannelUpdate
}

export function adminPutChannelItem$Html$Json(http: HttpClient, rootUrl: string, params: AdminPutChannelItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>> {
  const rb = new RequestBuilder(rootUrl, adminPutChannelItem$Html$Json.PATH, 'put');
  if (params) {
    rb.path('code', params.code, {"style":"simple","explode":false});
    rb.body(params.body, 'text/html');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ChannelAdminChannelShowSyliusAdminChannelShow>;
    })
  );
}

adminPutChannelItem$Html$Json.PATH = '/api/v2/admin/channels/{code}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteCatalogPromotionItem } from '../fn/catalog-promotion/admin-delete-catalog-promotion-item';
import { AdminDeleteCatalogPromotionItem$Params } from '../fn/catalog-promotion/admin-delete-catalog-promotion-item';
import { adminGetCatalogPromotionCollection$Html } from '../fn/catalog-promotion/admin-get-catalog-promotion-collection-html';
import { AdminGetCatalogPromotionCollection$Html$Params } from '../fn/catalog-promotion/admin-get-catalog-promotion-collection-html';
import { adminGetCatalogPromotionCollection$Json } from '../fn/catalog-promotion/admin-get-catalog-promotion-collection-json';
import { AdminGetCatalogPromotionCollection$Json$Params } from '../fn/catalog-promotion/admin-get-catalog-promotion-collection-json';
import { adminGetCatalogPromotionItem$Html } from '../fn/catalog-promotion/admin-get-catalog-promotion-item-html';
import { AdminGetCatalogPromotionItem$Html$Params } from '../fn/catalog-promotion/admin-get-catalog-promotion-item-html';
import { adminGetCatalogPromotionItem$Json } from '../fn/catalog-promotion/admin-get-catalog-promotion-item-json';
import { AdminGetCatalogPromotionItem$Json$Params } from '../fn/catalog-promotion/admin-get-catalog-promotion-item-json';
import { adminPostCatalogPromotionCollection$Html$Html } from '../fn/catalog-promotion/admin-post-catalog-promotion-collection-html-html';
import { AdminPostCatalogPromotionCollection$Html$Html$Params } from '../fn/catalog-promotion/admin-post-catalog-promotion-collection-html-html';
import { adminPostCatalogPromotionCollection$Html$Json } from '../fn/catalog-promotion/admin-post-catalog-promotion-collection-html-json';
import { AdminPostCatalogPromotionCollection$Html$Json$Params } from '../fn/catalog-promotion/admin-post-catalog-promotion-collection-html-json';
import { adminPostCatalogPromotionCollection$Json$Html } from '../fn/catalog-promotion/admin-post-catalog-promotion-collection-json-html';
import { AdminPostCatalogPromotionCollection$Json$Html$Params } from '../fn/catalog-promotion/admin-post-catalog-promotion-collection-json-html';
import { adminPostCatalogPromotionCollection$Json$Json } from '../fn/catalog-promotion/admin-post-catalog-promotion-collection-json-json';
import { AdminPostCatalogPromotionCollection$Json$Json$Params } from '../fn/catalog-promotion/admin-post-catalog-promotion-collection-json-json';
import { adminPutCatalogPromotionItem$Html$Html } from '../fn/catalog-promotion/admin-put-catalog-promotion-item-html-html';
import { AdminPutCatalogPromotionItem$Html$Html$Params } from '../fn/catalog-promotion/admin-put-catalog-promotion-item-html-html';
import { adminPutCatalogPromotionItem$Html$Json } from '../fn/catalog-promotion/admin-put-catalog-promotion-item-html-json';
import { AdminPutCatalogPromotionItem$Html$Json$Params } from '../fn/catalog-promotion/admin-put-catalog-promotion-item-html-json';
import { adminPutCatalogPromotionItem$Json$Html } from '../fn/catalog-promotion/admin-put-catalog-promotion-item-json-html';
import { AdminPutCatalogPromotionItem$Json$Html$Params } from '../fn/catalog-promotion/admin-put-catalog-promotion-item-json-html';
import { adminPutCatalogPromotionItem$Json$Json } from '../fn/catalog-promotion/admin-put-catalog-promotion-item-json-json';
import { AdminPutCatalogPromotionItem$Json$Json$Params } from '../fn/catalog-promotion/admin-put-catalog-promotion-item-json-json';
import { Index as CatalogPromotionAdminCatalogPromotionIndexSyliusAdminCatalogPromotionIndex } from '../models/CatalogPromotion-admin/catalog_promotion/index_sylius/admin/catalog_promotion/index';
import { Show as CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow } from '../models/CatalogPromotion-admin/catalog_promotion/show_sylius/admin/catalog_promotion/show';
import { Show as CatalogPromotionShopCatalogPromotionShowSyliusShopCatalogPromotionShow } from '../models/CatalogPromotion-shop/catalog_promotion/show_sylius/shop/catalog_promotion/show';
import { shopGetCatalogPromotionItem$Html } from '../fn/catalog-promotion/shop-get-catalog-promotion-item-html';
import { ShopGetCatalogPromotionItem$Html$Params } from '../fn/catalog-promotion/shop-get-catalog-promotion-item-html';
import { shopGetCatalogPromotionItem$Json } from '../fn/catalog-promotion/shop-get-catalog-promotion-item-json';
import { ShopGetCatalogPromotionItem$Json$Params } from '../fn/catalog-promotion/shop-get-catalog-promotion-item-json';

@Injectable({ providedIn: 'root' })
export class CatalogPromotionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetCatalogPromotionCollection()` */
  static readonly AdminGetCatalogPromotionCollectionPath = '/api/v2/admin/catalog-promotions';

  /**
   * Retrieves the collection of CatalogPromotion resources.
   *
   * Retrieves the collection of CatalogPromotion resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCatalogPromotionCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCatalogPromotionCollection$Json$Response(params?: AdminGetCatalogPromotionCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CatalogPromotionAdminCatalogPromotionIndexSyliusAdminCatalogPromotionIndex>>> {
    return adminGetCatalogPromotionCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of CatalogPromotion resources.
   *
   * Retrieves the collection of CatalogPromotion resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCatalogPromotionCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCatalogPromotionCollection$Json(params?: AdminGetCatalogPromotionCollection$Json$Params, context?: HttpContext): Observable<Array<CatalogPromotionAdminCatalogPromotionIndexSyliusAdminCatalogPromotionIndex>> {
    return this.adminGetCatalogPromotionCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CatalogPromotionAdminCatalogPromotionIndexSyliusAdminCatalogPromotionIndex>>): Array<CatalogPromotionAdminCatalogPromotionIndexSyliusAdminCatalogPromotionIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of CatalogPromotion resources.
   *
   * Retrieves the collection of CatalogPromotion resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCatalogPromotionCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCatalogPromotionCollection$Html$Response(params?: AdminGetCatalogPromotionCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CatalogPromotionAdminCatalogPromotionIndexSyliusAdminCatalogPromotionIndex>>> {
    return adminGetCatalogPromotionCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of CatalogPromotion resources.
   *
   * Retrieves the collection of CatalogPromotion resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCatalogPromotionCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCatalogPromotionCollection$Html(params?: AdminGetCatalogPromotionCollection$Html$Params, context?: HttpContext): Observable<Array<CatalogPromotionAdminCatalogPromotionIndexSyliusAdminCatalogPromotionIndex>> {
    return this.adminGetCatalogPromotionCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CatalogPromotionAdminCatalogPromotionIndexSyliusAdminCatalogPromotionIndex>>): Array<CatalogPromotionAdminCatalogPromotionIndexSyliusAdminCatalogPromotionIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostCatalogPromotionCollection()` */
  static readonly AdminPostCatalogPromotionCollectionPath = '/api/v2/admin/catalog-promotions';

  /**
   * Creates a CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCatalogPromotionCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCatalogPromotionCollection$Json$Json$Response(params: AdminPostCatalogPromotionCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>> {
    return adminPostCatalogPromotionCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCatalogPromotionCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCatalogPromotionCollection$Json$Json(params: AdminPostCatalogPromotionCollection$Json$Json$Params, context?: HttpContext): Observable<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow> {
    return this.adminPostCatalogPromotionCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>): CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow => r.body)
    );
  }

  /**
   * Creates a CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCatalogPromotionCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCatalogPromotionCollection$Json$Html$Response(params: AdminPostCatalogPromotionCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>> {
    return adminPostCatalogPromotionCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCatalogPromotionCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCatalogPromotionCollection$Json$Html(params: AdminPostCatalogPromotionCollection$Json$Html$Params, context?: HttpContext): Observable<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow> {
    return this.adminPostCatalogPromotionCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>): CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow => r.body)
    );
  }

  /**
   * Creates a CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCatalogPromotionCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCatalogPromotionCollection$Html$Json$Response(params: AdminPostCatalogPromotionCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>> {
    return adminPostCatalogPromotionCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCatalogPromotionCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCatalogPromotionCollection$Html$Json(params: AdminPostCatalogPromotionCollection$Html$Json$Params, context?: HttpContext): Observable<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow> {
    return this.adminPostCatalogPromotionCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>): CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow => r.body)
    );
  }

  /**
   * Creates a CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCatalogPromotionCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCatalogPromotionCollection$Html$Html$Response(params: AdminPostCatalogPromotionCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>> {
    return adminPostCatalogPromotionCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCatalogPromotionCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCatalogPromotionCollection$Html$Html(params: AdminPostCatalogPromotionCollection$Html$Html$Params, context?: HttpContext): Observable<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow> {
    return this.adminPostCatalogPromotionCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>): CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow => r.body)
    );
  }

  /** Path part for operation `adminGetCatalogPromotionItem()` */
  static readonly AdminGetCatalogPromotionItemPath = '/api/v2/admin/catalog-promotions/{code}';

  /**
   * Retrieves a CatalogPromotion resource.
   *
   * Retrieves a CatalogPromotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCatalogPromotionItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCatalogPromotionItem$Json$Response(params: AdminGetCatalogPromotionItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>> {
    return adminGetCatalogPromotionItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a CatalogPromotion resource.
   *
   * Retrieves a CatalogPromotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCatalogPromotionItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCatalogPromotionItem$Json(params: AdminGetCatalogPromotionItem$Json$Params, context?: HttpContext): Observable<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow> {
    return this.adminGetCatalogPromotionItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>): CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow => r.body)
    );
  }

  /**
   * Retrieves a CatalogPromotion resource.
   *
   * Retrieves a CatalogPromotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCatalogPromotionItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCatalogPromotionItem$Html$Response(params: AdminGetCatalogPromotionItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>> {
    return adminGetCatalogPromotionItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a CatalogPromotion resource.
   *
   * Retrieves a CatalogPromotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCatalogPromotionItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCatalogPromotionItem$Html(params: AdminGetCatalogPromotionItem$Html$Params, context?: HttpContext): Observable<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow> {
    return this.adminGetCatalogPromotionItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>): CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow => r.body)
    );
  }

  /** Path part for operation `adminPutCatalogPromotionItem()` */
  static readonly AdminPutCatalogPromotionItemPath = '/api/v2/admin/catalog-promotions/{code}';

  /**
   * Replaces the CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCatalogPromotionItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCatalogPromotionItem$Json$Json$Response(params: AdminPutCatalogPromotionItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>> {
    return adminPutCatalogPromotionItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCatalogPromotionItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCatalogPromotionItem$Json$Json(params: AdminPutCatalogPromotionItem$Json$Json$Params, context?: HttpContext): Observable<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow> {
    return this.adminPutCatalogPromotionItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>): CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow => r.body)
    );
  }

  /**
   * Replaces the CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCatalogPromotionItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCatalogPromotionItem$Json$Html$Response(params: AdminPutCatalogPromotionItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>> {
    return adminPutCatalogPromotionItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCatalogPromotionItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCatalogPromotionItem$Json$Html(params: AdminPutCatalogPromotionItem$Json$Html$Params, context?: HttpContext): Observable<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow> {
    return this.adminPutCatalogPromotionItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>): CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow => r.body)
    );
  }

  /**
   * Replaces the CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCatalogPromotionItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCatalogPromotionItem$Html$Json$Response(params: AdminPutCatalogPromotionItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>> {
    return adminPutCatalogPromotionItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCatalogPromotionItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCatalogPromotionItem$Html$Json(params: AdminPutCatalogPromotionItem$Html$Json$Params, context?: HttpContext): Observable<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow> {
    return this.adminPutCatalogPromotionItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>): CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow => r.body)
    );
  }

  /**
   * Replaces the CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCatalogPromotionItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCatalogPromotionItem$Html$Html$Response(params: AdminPutCatalogPromotionItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>> {
    return adminPutCatalogPromotionItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the CatalogPromotion resource.
   *
   * Example configuration for `for_variants` scope type:
   *
   * ```
   * {
   *     "type": "for_variants",
   *     "configuration": {
   *         "variants": [
   *             "string"
   *         ]
   *     }
   * }
   * ```
   *
   * Example configuration for `percentage_discount` action type:
   *
   * ```
   * {
   *     "type": "percentage_discount",
   *     "configuration": {
   *         "amount": float
   *     }
   * }
   * ```
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCatalogPromotionItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCatalogPromotionItem$Html$Html(params: AdminPutCatalogPromotionItem$Html$Html$Params, context?: HttpContext): Observable<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow> {
    return this.adminPutCatalogPromotionItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow>): CatalogPromotionAdminCatalogPromotionShowSyliusAdminCatalogPromotionShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteCatalogPromotionItem()` */
  static readonly AdminDeleteCatalogPromotionItemPath = '/api/v2/admin/catalog-promotions/{code}';

  /**
   * Removes the CatalogPromotion resource.
   *
   * Removes the CatalogPromotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteCatalogPromotionItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteCatalogPromotionItem$Response(params: AdminDeleteCatalogPromotionItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteCatalogPromotionItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the CatalogPromotion resource.
   *
   * Removes the CatalogPromotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteCatalogPromotionItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteCatalogPromotionItem(params: AdminDeleteCatalogPromotionItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteCatalogPromotionItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `shopGetCatalogPromotionItem()` */
  static readonly ShopGetCatalogPromotionItemPath = '/api/v2/shop/catalog-promotions/{code}';

  /**
   * Retrieves a CatalogPromotion resource.
   *
   * Retrieves a CatalogPromotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetCatalogPromotionItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCatalogPromotionItem$Json$Response(params: ShopGetCatalogPromotionItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CatalogPromotionShopCatalogPromotionShowSyliusShopCatalogPromotionShow>> {
    return shopGetCatalogPromotionItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a CatalogPromotion resource.
   *
   * Retrieves a CatalogPromotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetCatalogPromotionItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCatalogPromotionItem$Json(params: ShopGetCatalogPromotionItem$Json$Params, context?: HttpContext): Observable<CatalogPromotionShopCatalogPromotionShowSyliusShopCatalogPromotionShow> {
    return this.shopGetCatalogPromotionItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CatalogPromotionShopCatalogPromotionShowSyliusShopCatalogPromotionShow>): CatalogPromotionShopCatalogPromotionShowSyliusShopCatalogPromotionShow => r.body)
    );
  }

  /**
   * Retrieves a CatalogPromotion resource.
   *
   * Retrieves a CatalogPromotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetCatalogPromotionItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCatalogPromotionItem$Html$Response(params: ShopGetCatalogPromotionItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CatalogPromotionShopCatalogPromotionShowSyliusShopCatalogPromotionShow>> {
    return shopGetCatalogPromotionItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a CatalogPromotion resource.
   *
   * Retrieves a CatalogPromotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetCatalogPromotionItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCatalogPromotionItem$Html(params: ShopGetCatalogPromotionItem$Html$Params, context?: HttpContext): Observable<CatalogPromotionShopCatalogPromotionShowSyliusShopCatalogPromotionShow> {
    return this.shopGetCatalogPromotionItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CatalogPromotionShopCatalogPromotionShowSyliusShopCatalogPromotionShow>): CatalogPromotionShopCatalogPromotionShowSyliusShopCatalogPromotionShow => r.body)
    );
  }

}

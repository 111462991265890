/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteUserCustomerItem } from '../fn/customer/admin-delete-user-customer-item';
import { AdminDeleteUserCustomerItem$Params } from '../fn/customer/admin-delete-user-customer-item';
import { adminGetCustomerCollection$Html } from '../fn/customer/admin-get-customer-collection-html';
import { AdminGetCustomerCollection$Html$Params } from '../fn/customer/admin-get-customer-collection-html';
import { adminGetCustomerCollection$Json } from '../fn/customer/admin-get-customer-collection-json';
import { AdminGetCustomerCollection$Json$Params } from '../fn/customer/admin-get-customer-collection-json';
import { adminGetCustomerItem$Html } from '../fn/customer/admin-get-customer-item-html';
import { AdminGetCustomerItem$Html$Params } from '../fn/customer/admin-get-customer-item-html';
import { adminGetCustomerItem$Json } from '../fn/customer/admin-get-customer-item-json';
import { AdminGetCustomerItem$Json$Params } from '../fn/customer/admin-get-customer-item-json';
import { adminPostCustomerCollection$Html$Html } from '../fn/customer/admin-post-customer-collection-html-html';
import { AdminPostCustomerCollection$Html$Html$Params } from '../fn/customer/admin-post-customer-collection-html-html';
import { adminPostCustomerCollection$Html$Json } from '../fn/customer/admin-post-customer-collection-html-json';
import { AdminPostCustomerCollection$Html$Json$Params } from '../fn/customer/admin-post-customer-collection-html-json';
import { adminPostCustomerCollection$Json$Html } from '../fn/customer/admin-post-customer-collection-json-html';
import { AdminPostCustomerCollection$Json$Html$Params } from '../fn/customer/admin-post-customer-collection-json-html';
import { adminPostCustomerCollection$Json$Json } from '../fn/customer/admin-post-customer-collection-json-json';
import { AdminPostCustomerCollection$Json$Json$Params } from '../fn/customer/admin-post-customer-collection-json-json';
import { adminPutCustomerItem$Html$Html } from '../fn/customer/admin-put-customer-item-html-html';
import { AdminPutCustomerItem$Html$Html$Params } from '../fn/customer/admin-put-customer-item-html-html';
import { adminPutCustomerItem$Html$Json } from '../fn/customer/admin-put-customer-item-html-json';
import { AdminPutCustomerItem$Html$Json$Params } from '../fn/customer/admin-put-customer-item-html-json';
import { adminPutCustomerItem$Json$Html } from '../fn/customer/admin-put-customer-item-json-html';
import { AdminPutCustomerItem$Json$Html$Params } from '../fn/customer/admin-put-customer-item-json-html';
import { adminPutCustomerItem$Json$Json } from '../fn/customer/admin-put-customer-item-json-json';
import { AdminPutCustomerItem$Json$Json$Params } from '../fn/customer/admin-put-customer-item-json-json';
import { adminStatisticsGetCustomerItem$Html } from '../fn/customer/admin-statistics-get-customer-item-html';
import { AdminStatisticsGetCustomerItem$Html$Params } from '../fn/customer/admin-statistics-get-customer-item-html';
import { adminStatisticsGetCustomerItem$Json } from '../fn/customer/admin-statistics-get-customer-item-json';
import { AdminStatisticsGetCustomerItem$Json$Params } from '../fn/customer/admin-statistics-get-customer-item-json';
import { Index as CustomerAdminCustomerIndexSyliusAdminCustomerIndex } from '../models/Customer-admin/customer/index_sylius/admin/customer/index';
import { Show as CustomerAdminCustomerShowSyliusAdminCustomerShow } from '../models/Customer-admin/customer/show_sylius/admin/customer/show';
import { Show as CustomerAdminCustomerStatisticsShowSyliusAdminCustomerStatisticsShow } from '../models/Customer-admin/customer/statistics/show_sylius/admin/customer/statistics/show';
import { Show as CustomerShopCustomerShowSyliusShopCustomerShow } from '../models/Customer-shop/customer/show_sylius/shop/customer/show';
import { Read as CustomerShopCustomerTokenRead } from '../models/Customer-shop/customer/token/read';
import { postCustomerCredentialsItem } from '../fn/customer/post-customer-credentials-item';
import { PostCustomerCredentialsItem$Params } from '../fn/customer/post-customer-credentials-item';
import { shopGetCustomerItem$Html } from '../fn/customer/shop-get-customer-item-html';
import { ShopGetCustomerItem$Html$Params } from '../fn/customer/shop-get-customer-item-html';
import { shopGetCustomerItem$Json } from '../fn/customer/shop-get-customer-item-json';
import { ShopGetCustomerItem$Json$Params } from '../fn/customer/shop-get-customer-item-json';
import { shopPasswordUpdateCustomerItem$Html$Html } from '../fn/customer/shop-password-update-customer-item-html-html';
import { ShopPasswordUpdateCustomerItem$Html$Html$Params } from '../fn/customer/shop-password-update-customer-item-html-html';
import { shopPasswordUpdateCustomerItem$Html$Json } from '../fn/customer/shop-password-update-customer-item-html-json';
import { ShopPasswordUpdateCustomerItem$Html$Json$Params } from '../fn/customer/shop-password-update-customer-item-html-json';
import { shopPasswordUpdateCustomerItem$Json$Html } from '../fn/customer/shop-password-update-customer-item-json-html';
import { ShopPasswordUpdateCustomerItem$Json$Html$Params } from '../fn/customer/shop-password-update-customer-item-json-html';
import { shopPasswordUpdateCustomerItem$Json$Json } from '../fn/customer/shop-password-update-customer-item-json-json';
import { ShopPasswordUpdateCustomerItem$Json$Json$Params } from '../fn/customer/shop-password-update-customer-item-json-json';
import { shopPostCustomerCollection$Html$Html } from '../fn/customer/shop-post-customer-collection-html-html';
import { ShopPostCustomerCollection$Html$Html$Params } from '../fn/customer/shop-post-customer-collection-html-html';
import { shopPostCustomerCollection$Html$Json } from '../fn/customer/shop-post-customer-collection-html-json';
import { ShopPostCustomerCollection$Html$Json$Params } from '../fn/customer/shop-post-customer-collection-html-json';
import { shopPostCustomerCollection$Json$Html } from '../fn/customer/shop-post-customer-collection-json-html';
import { ShopPostCustomerCollection$Json$Html$Params } from '../fn/customer/shop-post-customer-collection-json-html';
import { shopPostCustomerCollection$Json$Json } from '../fn/customer/shop-post-customer-collection-json-json';
import { ShopPostCustomerCollection$Json$Json$Params } from '../fn/customer/shop-post-customer-collection-json-json';
import { shopPutCustomerItem$Html$Html } from '../fn/customer/shop-put-customer-item-html-html';
import { ShopPutCustomerItem$Html$Html$Params } from '../fn/customer/shop-put-customer-item-html-html';
import { shopPutCustomerItem$Html$Json } from '../fn/customer/shop-put-customer-item-html-json';
import { ShopPutCustomerItem$Html$Json$Params } from '../fn/customer/shop-put-customer-item-html-json';
import { shopPutCustomerItem$Json$Html } from '../fn/customer/shop-put-customer-item-json-html';
import { ShopPutCustomerItem$Json$Html$Params } from '../fn/customer/shop-put-customer-item-json-html';
import { shopPutCustomerItem$Json$Json } from '../fn/customer/shop-put-customer-item-json-json';
import { ShopPutCustomerItem$Json$Json$Params } from '../fn/customer/shop-put-customer-item-json-json';
import { shopRequestResetPasswordCustomerCollection$Html$Html } from '../fn/customer/shop-request-reset-password-customer-collection-html-html';
import { ShopRequestResetPasswordCustomerCollection$Html$Html$Params } from '../fn/customer/shop-request-reset-password-customer-collection-html-html';
import { shopRequestResetPasswordCustomerCollection$Html$Json } from '../fn/customer/shop-request-reset-password-customer-collection-html-json';
import { ShopRequestResetPasswordCustomerCollection$Html$Json$Params } from '../fn/customer/shop-request-reset-password-customer-collection-html-json';
import { shopRequestResetPasswordCustomerCollection$Json$Html } from '../fn/customer/shop-request-reset-password-customer-collection-json-html';
import { ShopRequestResetPasswordCustomerCollection$Json$Html$Params } from '../fn/customer/shop-request-reset-password-customer-collection-json-html';
import { shopRequestResetPasswordCustomerCollection$Json$Json } from '../fn/customer/shop-request-reset-password-customer-collection-json-json';
import { ShopRequestResetPasswordCustomerCollection$Json$Json$Params } from '../fn/customer/shop-request-reset-password-customer-collection-json-json';
import { shopResendVerificationEmailCustomerCollection$Html$Html } from '../fn/customer/shop-resend-verification-email-customer-collection-html-html';
import { ShopResendVerificationEmailCustomerCollection$Html$Html$Params } from '../fn/customer/shop-resend-verification-email-customer-collection-html-html';
import { shopResendVerificationEmailCustomerCollection$Html$Json } from '../fn/customer/shop-resend-verification-email-customer-collection-html-json';
import { ShopResendVerificationEmailCustomerCollection$Html$Json$Params } from '../fn/customer/shop-resend-verification-email-customer-collection-html-json';
import { shopResendVerificationEmailCustomerCollection$Json$Html } from '../fn/customer/shop-resend-verification-email-customer-collection-json-html';
import { ShopResendVerificationEmailCustomerCollection$Json$Html$Params } from '../fn/customer/shop-resend-verification-email-customer-collection-json-html';
import { shopResendVerificationEmailCustomerCollection$Json$Json } from '../fn/customer/shop-resend-verification-email-customer-collection-json-json';
import { ShopResendVerificationEmailCustomerCollection$Json$Json$Params } from '../fn/customer/shop-resend-verification-email-customer-collection-json-json';
import { shopUpdateResetPasswordCustomerItem$Html } from '../fn/customer/shop-update-reset-password-customer-item-html';
import { ShopUpdateResetPasswordCustomerItem$Html$Params } from '../fn/customer/shop-update-reset-password-customer-item-html';
import { shopUpdateResetPasswordCustomerItem$Json } from '../fn/customer/shop-update-reset-password-customer-item-json';
import { ShopUpdateResetPasswordCustomerItem$Json$Params } from '../fn/customer/shop-update-reset-password-customer-item-json';
import { shopVerifyCustomerAccountCustomerItem$Html } from '../fn/customer/shop-verify-customer-account-customer-item-html';
import { ShopVerifyCustomerAccountCustomerItem$Html$Params } from '../fn/customer/shop-verify-customer-account-customer-item-html';
import { shopVerifyCustomerAccountCustomerItem$Json } from '../fn/customer/shop-verify-customer-account-customer-item-json';
import { ShopVerifyCustomerAccountCustomerItem$Json$Params } from '../fn/customer/shop-verify-customer-account-customer-item-json';

@Injectable({ providedIn: 'root' })
export class CustomerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetCustomerCollection()` */
  static readonly AdminGetCustomerCollectionPath = '/api/v2/admin/customers';

  /**
   * Retrieves the collection of Customer resources.
   *
   * Retrieves the collection of Customer resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCustomerCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerCollection$Json$Response(params?: AdminGetCustomerCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerAdminCustomerIndexSyliusAdminCustomerIndex>>> {
    return adminGetCustomerCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Customer resources.
   *
   * Retrieves the collection of Customer resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCustomerCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerCollection$Json(params?: AdminGetCustomerCollection$Json$Params, context?: HttpContext): Observable<Array<CustomerAdminCustomerIndexSyliusAdminCustomerIndex>> {
    return this.adminGetCustomerCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerAdminCustomerIndexSyliusAdminCustomerIndex>>): Array<CustomerAdminCustomerIndexSyliusAdminCustomerIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Customer resources.
   *
   * Retrieves the collection of Customer resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCustomerCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerCollection$Html$Response(params?: AdminGetCustomerCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerAdminCustomerIndexSyliusAdminCustomerIndex>>> {
    return adminGetCustomerCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Customer resources.
   *
   * Retrieves the collection of Customer resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCustomerCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerCollection$Html(params?: AdminGetCustomerCollection$Html$Params, context?: HttpContext): Observable<Array<CustomerAdminCustomerIndexSyliusAdminCustomerIndex>> {
    return this.adminGetCustomerCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerAdminCustomerIndexSyliusAdminCustomerIndex>>): Array<CustomerAdminCustomerIndexSyliusAdminCustomerIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostCustomerCollection()` */
  static readonly AdminPostCustomerCollectionPath = '/api/v2/admin/customers';

  /**
   * Creates a Customer resource.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCustomerCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCustomerCollection$Json$Json$Response(params: AdminPostCustomerCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>> {
    return adminPostCustomerCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Customer resource.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCustomerCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCustomerCollection$Json$Json(params: AdminPostCustomerCollection$Json$Json$Params, context?: HttpContext): Observable<CustomerAdminCustomerShowSyliusAdminCustomerShow> {
    return this.adminPostCustomerCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>): CustomerAdminCustomerShowSyliusAdminCustomerShow => r.body)
    );
  }

  /**
   * Creates a Customer resource.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCustomerCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCustomerCollection$Json$Html$Response(params: AdminPostCustomerCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>> {
    return adminPostCustomerCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Customer resource.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCustomerCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCustomerCollection$Json$Html(params: AdminPostCustomerCollection$Json$Html$Params, context?: HttpContext): Observable<CustomerAdminCustomerShowSyliusAdminCustomerShow> {
    return this.adminPostCustomerCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>): CustomerAdminCustomerShowSyliusAdminCustomerShow => r.body)
    );
  }

  /**
   * Creates a Customer resource.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCustomerCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCustomerCollection$Html$Json$Response(params: AdminPostCustomerCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>> {
    return adminPostCustomerCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Customer resource.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCustomerCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCustomerCollection$Html$Json(params: AdminPostCustomerCollection$Html$Json$Params, context?: HttpContext): Observable<CustomerAdminCustomerShowSyliusAdminCustomerShow> {
    return this.adminPostCustomerCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>): CustomerAdminCustomerShowSyliusAdminCustomerShow => r.body)
    );
  }

  /**
   * Creates a Customer resource.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCustomerCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCustomerCollection$Html$Html$Response(params: AdminPostCustomerCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>> {
    return adminPostCustomerCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Customer resource.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCustomerCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCustomerCollection$Html$Html(params: AdminPostCustomerCollection$Html$Html$Params, context?: HttpContext): Observable<CustomerAdminCustomerShowSyliusAdminCustomerShow> {
    return this.adminPostCustomerCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>): CustomerAdminCustomerShowSyliusAdminCustomerShow => r.body)
    );
  }

  /** Path part for operation `adminGetCustomerItem()` */
  static readonly AdminGetCustomerItemPath = '/api/v2/admin/customers/{id}';

  /**
   * Retrieves a Customer resource.
   *
   * Retrieves a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCustomerItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerItem$Json$Response(params: AdminGetCustomerItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>> {
    return adminGetCustomerItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Customer resource.
   *
   * Retrieves a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCustomerItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerItem$Json(params: AdminGetCustomerItem$Json$Params, context?: HttpContext): Observable<CustomerAdminCustomerShowSyliusAdminCustomerShow> {
    return this.adminGetCustomerItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>): CustomerAdminCustomerShowSyliusAdminCustomerShow => r.body)
    );
  }

  /**
   * Retrieves a Customer resource.
   *
   * Retrieves a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCustomerItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerItem$Html$Response(params: AdminGetCustomerItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>> {
    return adminGetCustomerItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Customer resource.
   *
   * Retrieves a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCustomerItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCustomerItem$Html(params: AdminGetCustomerItem$Html$Params, context?: HttpContext): Observable<CustomerAdminCustomerShowSyliusAdminCustomerShow> {
    return this.adminGetCustomerItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>): CustomerAdminCustomerShowSyliusAdminCustomerShow => r.body)
    );
  }

  /** Path part for operation `adminPutCustomerItem()` */
  static readonly AdminPutCustomerItemPath = '/api/v2/admin/customers/{id}';

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCustomerItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCustomerItem$Json$Json$Response(params: AdminPutCustomerItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>> {
    return adminPutCustomerItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCustomerItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCustomerItem$Json$Json(params: AdminPutCustomerItem$Json$Json$Params, context?: HttpContext): Observable<CustomerAdminCustomerShowSyliusAdminCustomerShow> {
    return this.adminPutCustomerItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>): CustomerAdminCustomerShowSyliusAdminCustomerShow => r.body)
    );
  }

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCustomerItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCustomerItem$Json$Html$Response(params: AdminPutCustomerItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>> {
    return adminPutCustomerItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCustomerItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutCustomerItem$Json$Html(params: AdminPutCustomerItem$Json$Html$Params, context?: HttpContext): Observable<CustomerAdminCustomerShowSyliusAdminCustomerShow> {
    return this.adminPutCustomerItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>): CustomerAdminCustomerShowSyliusAdminCustomerShow => r.body)
    );
  }

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCustomerItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCustomerItem$Html$Json$Response(params: AdminPutCustomerItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>> {
    return adminPutCustomerItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCustomerItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCustomerItem$Html$Json(params: AdminPutCustomerItem$Html$Json$Params, context?: HttpContext): Observable<CustomerAdminCustomerShowSyliusAdminCustomerShow> {
    return this.adminPutCustomerItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>): CustomerAdminCustomerShowSyliusAdminCustomerShow => r.body)
    );
  }

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutCustomerItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCustomerItem$Html$Html$Response(params: AdminPutCustomerItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>> {
    return adminPutCustomerItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutCustomerItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutCustomerItem$Html$Html(params: AdminPutCustomerItem$Html$Html$Params, context?: HttpContext): Observable<CustomerAdminCustomerShowSyliusAdminCustomerShow> {
    return this.adminPutCustomerItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerAdminCustomerShowSyliusAdminCustomerShow>): CustomerAdminCustomerShowSyliusAdminCustomerShow => r.body)
    );
  }

  /** Path part for operation `adminStatisticsGetCustomerItem()` */
  static readonly AdminStatisticsGetCustomerItemPath = '/api/v2/admin/customers/{id}/statistics';

  /**
   * Retrieves a Customer resource.
   *
   * Retrieves a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatisticsGetCustomerItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatisticsGetCustomerItem$Json$Response(params: AdminStatisticsGetCustomerItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerAdminCustomerStatisticsShowSyliusAdminCustomerStatisticsShow>> {
    return adminStatisticsGetCustomerItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Customer resource.
   *
   * Retrieves a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatisticsGetCustomerItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatisticsGetCustomerItem$Json(params: AdminStatisticsGetCustomerItem$Json$Params, context?: HttpContext): Observable<CustomerAdminCustomerStatisticsShowSyliusAdminCustomerStatisticsShow> {
    return this.adminStatisticsGetCustomerItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerAdminCustomerStatisticsShowSyliusAdminCustomerStatisticsShow>): CustomerAdminCustomerStatisticsShowSyliusAdminCustomerStatisticsShow => r.body)
    );
  }

  /**
   * Retrieves a Customer resource.
   *
   * Retrieves a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatisticsGetCustomerItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatisticsGetCustomerItem$Html$Response(params: AdminStatisticsGetCustomerItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerAdminCustomerStatisticsShowSyliusAdminCustomerStatisticsShow>> {
    return adminStatisticsGetCustomerItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Customer resource.
   *
   * Retrieves a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatisticsGetCustomerItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatisticsGetCustomerItem$Html(params: AdminStatisticsGetCustomerItem$Html$Params, context?: HttpContext): Observable<CustomerAdminCustomerStatisticsShowSyliusAdminCustomerStatisticsShow> {
    return this.adminStatisticsGetCustomerItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerAdminCustomerStatisticsShowSyliusAdminCustomerStatisticsShow>): CustomerAdminCustomerStatisticsShowSyliusAdminCustomerStatisticsShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteUserCustomerItem()` */
  static readonly AdminDeleteUserCustomerItemPath = '/api/v2/admin/customers/{id}/user';

  /**
   * Removes the Customer resource.
   *
   * Removes the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteUserCustomerItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteUserCustomerItem$Response(params: AdminDeleteUserCustomerItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteUserCustomerItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Customer resource.
   *
   * Removes the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteUserCustomerItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteUserCustomerItem(params: AdminDeleteUserCustomerItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteUserCustomerItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `shopPostCustomerCollection()` */
  static readonly ShopPostCustomerCollectionPath = '/api/v2/shop/customers';

  /**
   * Registers a new customer.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostCustomerCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostCustomerCollection$Json$Json$Response(params: ShopPostCustomerCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopPostCustomerCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Registers a new customer.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostCustomerCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostCustomerCollection$Json$Json(params: ShopPostCustomerCollection$Json$Json$Params, context?: HttpContext): Observable<any> {
    return this.shopPostCustomerCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Registers a new customer.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostCustomerCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostCustomerCollection$Json$Html$Response(params: ShopPostCustomerCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopPostCustomerCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Registers a new customer.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostCustomerCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostCustomerCollection$Json$Html(params: ShopPostCustomerCollection$Json$Html$Params, context?: HttpContext): Observable<any> {
    return this.shopPostCustomerCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Registers a new customer.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostCustomerCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostCustomerCollection$Html$Json$Response(params: ShopPostCustomerCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopPostCustomerCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Registers a new customer.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostCustomerCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostCustomerCollection$Html$Json(params: ShopPostCustomerCollection$Html$Json$Params, context?: HttpContext): Observable<any> {
    return this.shopPostCustomerCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Registers a new customer.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostCustomerCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostCustomerCollection$Html$Html$Response(params: ShopPostCustomerCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopPostCustomerCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Registers a new customer.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostCustomerCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostCustomerCollection$Html$Html(params: ShopPostCustomerCollection$Html$Html$Params, context?: HttpContext): Observable<any> {
    return this.shopPostCustomerCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `shopRequestResetPasswordCustomerCollection()` */
  static readonly ShopRequestResetPasswordCustomerCollectionPath = '/api/v2/shop/customers/reset-password';

  /**
   * Requests password reset.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopRequestResetPasswordCustomerCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopRequestResetPasswordCustomerCollection$Json$Json$Response(params: ShopRequestResetPasswordCustomerCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopRequestResetPasswordCustomerCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Requests password reset.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopRequestResetPasswordCustomerCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopRequestResetPasswordCustomerCollection$Json$Json(params: ShopRequestResetPasswordCustomerCollection$Json$Json$Params, context?: HttpContext): Observable<any> {
    return this.shopRequestResetPasswordCustomerCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Requests password reset.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopRequestResetPasswordCustomerCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopRequestResetPasswordCustomerCollection$Json$Html$Response(params: ShopRequestResetPasswordCustomerCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopRequestResetPasswordCustomerCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Requests password reset.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopRequestResetPasswordCustomerCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopRequestResetPasswordCustomerCollection$Json$Html(params: ShopRequestResetPasswordCustomerCollection$Json$Html$Params, context?: HttpContext): Observable<any> {
    return this.shopRequestResetPasswordCustomerCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Requests password reset.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopRequestResetPasswordCustomerCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopRequestResetPasswordCustomerCollection$Html$Json$Response(params: ShopRequestResetPasswordCustomerCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopRequestResetPasswordCustomerCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Requests password reset.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopRequestResetPasswordCustomerCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopRequestResetPasswordCustomerCollection$Html$Json(params: ShopRequestResetPasswordCustomerCollection$Html$Json$Params, context?: HttpContext): Observable<any> {
    return this.shopRequestResetPasswordCustomerCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Requests password reset.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopRequestResetPasswordCustomerCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopRequestResetPasswordCustomerCollection$Html$Html$Response(params: ShopRequestResetPasswordCustomerCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopRequestResetPasswordCustomerCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Requests password reset.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopRequestResetPasswordCustomerCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopRequestResetPasswordCustomerCollection$Html$Html(params: ShopRequestResetPasswordCustomerCollection$Html$Html$Params, context?: HttpContext): Observable<any> {
    return this.shopRequestResetPasswordCustomerCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `shopUpdateResetPasswordCustomerItem()` */
  static readonly ShopUpdateResetPasswordCustomerItemPath = '/api/v2/shop/customers/reset-password/{token}';

  /**
   * Resets password.
   *
   * Updates the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopUpdateResetPasswordCustomerItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopUpdateResetPasswordCustomerItem$Json$Response(params: ShopUpdateResetPasswordCustomerItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopUpdateResetPasswordCustomerItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Resets password.
   *
   * Updates the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopUpdateResetPasswordCustomerItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopUpdateResetPasswordCustomerItem$Json(params: ShopUpdateResetPasswordCustomerItem$Json$Params, context?: HttpContext): Observable<any> {
    return this.shopUpdateResetPasswordCustomerItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Resets password.
   *
   * Updates the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopUpdateResetPasswordCustomerItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopUpdateResetPasswordCustomerItem$Html$Response(params: ShopUpdateResetPasswordCustomerItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopUpdateResetPasswordCustomerItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Resets password.
   *
   * Updates the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopUpdateResetPasswordCustomerItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopUpdateResetPasswordCustomerItem$Html(params: ShopUpdateResetPasswordCustomerItem$Html$Params, context?: HttpContext): Observable<any> {
    return this.shopUpdateResetPasswordCustomerItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `postCustomerCredentialsItem()` */
  static readonly PostCustomerCredentialsItemPath = '/api/v2/shop/customers/token';

  /**
   * Retrieves the JWT token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerCredentialsItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerCredentialsItem$Response(params?: PostCustomerCredentialsItem$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerShopCustomerTokenRead>> {
    return postCustomerCredentialsItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the JWT token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postCustomerCredentialsItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerCredentialsItem(params?: PostCustomerCredentialsItem$Params, context?: HttpContext): Observable<CustomerShopCustomerTokenRead> {
    return this.postCustomerCredentialsItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerShopCustomerTokenRead>): CustomerShopCustomerTokenRead => r.body)
    );
  }

  /** Path part for operation `shopResendVerificationEmailCustomerCollection()` */
  static readonly ShopResendVerificationEmailCustomerCollectionPath = '/api/v2/shop/customers/verify';

  /**
   * Resends verification email.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopResendVerificationEmailCustomerCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopResendVerificationEmailCustomerCollection$Json$Json$Response(params: ShopResendVerificationEmailCustomerCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopResendVerificationEmailCustomerCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Resends verification email.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopResendVerificationEmailCustomerCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopResendVerificationEmailCustomerCollection$Json$Json(params: ShopResendVerificationEmailCustomerCollection$Json$Json$Params, context?: HttpContext): Observable<any> {
    return this.shopResendVerificationEmailCustomerCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Resends verification email.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopResendVerificationEmailCustomerCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopResendVerificationEmailCustomerCollection$Json$Html$Response(params: ShopResendVerificationEmailCustomerCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopResendVerificationEmailCustomerCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Resends verification email.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopResendVerificationEmailCustomerCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopResendVerificationEmailCustomerCollection$Json$Html(params: ShopResendVerificationEmailCustomerCollection$Json$Html$Params, context?: HttpContext): Observable<any> {
    return this.shopResendVerificationEmailCustomerCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Resends verification email.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopResendVerificationEmailCustomerCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopResendVerificationEmailCustomerCollection$Html$Json$Response(params: ShopResendVerificationEmailCustomerCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopResendVerificationEmailCustomerCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Resends verification email.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopResendVerificationEmailCustomerCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopResendVerificationEmailCustomerCollection$Html$Json(params: ShopResendVerificationEmailCustomerCollection$Html$Json$Params, context?: HttpContext): Observable<any> {
    return this.shopResendVerificationEmailCustomerCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Resends verification email.
   *
   * Creates a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopResendVerificationEmailCustomerCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopResendVerificationEmailCustomerCollection$Html$Html$Response(params: ShopResendVerificationEmailCustomerCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopResendVerificationEmailCustomerCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Resends verification email.
   *
   * Creates a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopResendVerificationEmailCustomerCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopResendVerificationEmailCustomerCollection$Html$Html(params: ShopResendVerificationEmailCustomerCollection$Html$Html$Params, context?: HttpContext): Observable<any> {
    return this.shopResendVerificationEmailCustomerCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `shopVerifyCustomerAccountCustomerItem()` */
  static readonly ShopVerifyCustomerAccountCustomerItemPath = '/api/v2/shop/customers/verify/{token}';

  /**
   * Verifies Customer account.
   *
   * Updates the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopVerifyCustomerAccountCustomerItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopVerifyCustomerAccountCustomerItem$Json$Response(params: ShopVerifyCustomerAccountCustomerItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopVerifyCustomerAccountCustomerItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Verifies Customer account.
   *
   * Updates the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopVerifyCustomerAccountCustomerItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopVerifyCustomerAccountCustomerItem$Json(params: ShopVerifyCustomerAccountCustomerItem$Json$Params, context?: HttpContext): Observable<any> {
    return this.shopVerifyCustomerAccountCustomerItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Verifies Customer account.
   *
   * Updates the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopVerifyCustomerAccountCustomerItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopVerifyCustomerAccountCustomerItem$Html$Response(params: ShopVerifyCustomerAccountCustomerItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopVerifyCustomerAccountCustomerItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Verifies Customer account.
   *
   * Updates the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopVerifyCustomerAccountCustomerItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopVerifyCustomerAccountCustomerItem$Html(params: ShopVerifyCustomerAccountCustomerItem$Html$Params, context?: HttpContext): Observable<any> {
    return this.shopVerifyCustomerAccountCustomerItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `shopGetCustomerItem()` */
  static readonly ShopGetCustomerItemPath = '/api/v2/shop/customers/{id}';

  /**
   * Retrieves a Customer resource.
   *
   * Retrieves a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetCustomerItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCustomerItem$Json$Response(params: ShopGetCustomerItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerShopCustomerShowSyliusShopCustomerShow>> {
    return shopGetCustomerItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Customer resource.
   *
   * Retrieves a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetCustomerItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCustomerItem$Json(params: ShopGetCustomerItem$Json$Params, context?: HttpContext): Observable<CustomerShopCustomerShowSyliusShopCustomerShow> {
    return this.shopGetCustomerItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerShopCustomerShowSyliusShopCustomerShow>): CustomerShopCustomerShowSyliusShopCustomerShow => r.body)
    );
  }

  /**
   * Retrieves a Customer resource.
   *
   * Retrieves a Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetCustomerItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCustomerItem$Html$Response(params: ShopGetCustomerItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerShopCustomerShowSyliusShopCustomerShow>> {
    return shopGetCustomerItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Customer resource.
   *
   * Retrieves a Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetCustomerItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCustomerItem$Html(params: ShopGetCustomerItem$Html$Params, context?: HttpContext): Observable<CustomerShopCustomerShowSyliusShopCustomerShow> {
    return this.shopGetCustomerItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerShopCustomerShowSyliusShopCustomerShow>): CustomerShopCustomerShowSyliusShopCustomerShow => r.body)
    );
  }

  /** Path part for operation `shopPutCustomerItem()` */
  static readonly ShopPutCustomerItemPath = '/api/v2/shop/customers/{id}';

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPutCustomerItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPutCustomerItem$Json$Json$Response(params: ShopPutCustomerItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerShopCustomerShowSyliusShopCustomerShow>> {
    return shopPutCustomerItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPutCustomerItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPutCustomerItem$Json$Json(params: ShopPutCustomerItem$Json$Json$Params, context?: HttpContext): Observable<CustomerShopCustomerShowSyliusShopCustomerShow> {
    return this.shopPutCustomerItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerShopCustomerShowSyliusShopCustomerShow>): CustomerShopCustomerShowSyliusShopCustomerShow => r.body)
    );
  }

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPutCustomerItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPutCustomerItem$Json$Html$Response(params: ShopPutCustomerItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerShopCustomerShowSyliusShopCustomerShow>> {
    return shopPutCustomerItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPutCustomerItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPutCustomerItem$Json$Html(params: ShopPutCustomerItem$Json$Html$Params, context?: HttpContext): Observable<CustomerShopCustomerShowSyliusShopCustomerShow> {
    return this.shopPutCustomerItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerShopCustomerShowSyliusShopCustomerShow>): CustomerShopCustomerShowSyliusShopCustomerShow => r.body)
    );
  }

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPutCustomerItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPutCustomerItem$Html$Json$Response(params: ShopPutCustomerItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerShopCustomerShowSyliusShopCustomerShow>> {
    return shopPutCustomerItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPutCustomerItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPutCustomerItem$Html$Json(params: ShopPutCustomerItem$Html$Json$Params, context?: HttpContext): Observable<CustomerShopCustomerShowSyliusShopCustomerShow> {
    return this.shopPutCustomerItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerShopCustomerShowSyliusShopCustomerShow>): CustomerShopCustomerShowSyliusShopCustomerShow => r.body)
    );
  }

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPutCustomerItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPutCustomerItem$Html$Html$Response(params: ShopPutCustomerItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerShopCustomerShowSyliusShopCustomerShow>> {
    return shopPutCustomerItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Customer resource.
   *
   * Replaces the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPutCustomerItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPutCustomerItem$Html$Html(params: ShopPutCustomerItem$Html$Html$Params, context?: HttpContext): Observable<CustomerShopCustomerShowSyliusShopCustomerShow> {
    return this.shopPutCustomerItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerShopCustomerShowSyliusShopCustomerShow>): CustomerShopCustomerShowSyliusShopCustomerShow => r.body)
    );
  }

  /** Path part for operation `shopPasswordUpdateCustomerItem()` */
  static readonly ShopPasswordUpdateCustomerItemPath = '/api/v2/shop/customers/{id}/password';

  /**
   * Change password for logged in customer.
   *
   * Replaces the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPasswordUpdateCustomerItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPasswordUpdateCustomerItem$Json$Json$Response(params: ShopPasswordUpdateCustomerItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopPasswordUpdateCustomerItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Change password for logged in customer.
   *
   * Replaces the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPasswordUpdateCustomerItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPasswordUpdateCustomerItem$Json$Json(params: ShopPasswordUpdateCustomerItem$Json$Json$Params, context?: HttpContext): Observable<any> {
    return this.shopPasswordUpdateCustomerItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Change password for logged in customer.
   *
   * Replaces the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPasswordUpdateCustomerItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPasswordUpdateCustomerItem$Json$Html$Response(params: ShopPasswordUpdateCustomerItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopPasswordUpdateCustomerItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Change password for logged in customer.
   *
   * Replaces the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPasswordUpdateCustomerItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPasswordUpdateCustomerItem$Json$Html(params: ShopPasswordUpdateCustomerItem$Json$Html$Params, context?: HttpContext): Observable<any> {
    return this.shopPasswordUpdateCustomerItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Change password for logged in customer.
   *
   * Replaces the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPasswordUpdateCustomerItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPasswordUpdateCustomerItem$Html$Json$Response(params: ShopPasswordUpdateCustomerItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopPasswordUpdateCustomerItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Change password for logged in customer.
   *
   * Replaces the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPasswordUpdateCustomerItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPasswordUpdateCustomerItem$Html$Json(params: ShopPasswordUpdateCustomerItem$Html$Json$Params, context?: HttpContext): Observable<any> {
    return this.shopPasswordUpdateCustomerItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Change password for logged in customer.
   *
   * Replaces the Customer resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPasswordUpdateCustomerItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPasswordUpdateCustomerItem$Html$Html$Response(params: ShopPasswordUpdateCustomerItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopPasswordUpdateCustomerItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Change password for logged in customer.
   *
   * Replaces the Customer resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPasswordUpdateCustomerItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPasswordUpdateCustomerItem$Html$Html(params: ShopPasswordUpdateCustomerItem$Html$Html$Params, context?: HttpContext): Observable<any> {
    return this.shopPasswordUpdateCustomerItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Show as OrderShopCartShowSyliusShopCartShow } from '../../models/Order-shop/cart/show_sylius/shop/cart/show';
import { ChangeQuantity as OrderChangeItemQuantityInCartShopCartChangeQuantitySyliusShopCartChangeQuantity } from '../../models/Order/ChangeItemQuantityInCart-shop/cart/change_quantity_sylius/shop/cart/change-quantity';

export interface ShopChangeQuantityOrderItem$Html$Params {
  tokenValue: string;
  orderItemId: string;

/**
 * Locales in this enum are all locales defined in the shop and only enabled ones will work in the given channel in the shop.
 */
  'Accept-Language'?: 'hu_HU';
  
    /**
     * The updated Order resource
     */
    body: OrderChangeItemQuantityInCartShopCartChangeQuantitySyliusShopCartChangeQuantity
}

export function shopChangeQuantityOrderItem$Html(http: HttpClient, rootUrl: string, params: ShopChangeQuantityOrderItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>> {
  const rb = new RequestBuilder(rootUrl, shopChangeQuantityOrderItem$Html.PATH, 'patch');
  if (params) {
    rb.path('tokenValue', params.tokenValue, {"style":"simple","explode":false});
    rb.path('orderItemId', params.orderItemId, {"style":"simple","explode":false});
    rb.header('Accept-Language', params['Accept-Language'], {"style":"simple","explode":false});
    rb.body(params.body, 'application/merge-patch+json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/html', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>;
    })
  );
}

shopChangeQuantityOrderItem$Html.PATH = '/api/v2/shop/orders/{tokenValue}/items/{orderItemId}';

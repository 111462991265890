/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetGatewayConfigItem$Html } from '../fn/gateway-config/admin-get-gateway-config-item-html';
import { AdminGetGatewayConfigItem$Html$Params } from '../fn/gateway-config/admin-get-gateway-config-item-html';
import { adminGetGatewayConfigItem$Json } from '../fn/gateway-config/admin-get-gateway-config-item-json';
import { AdminGetGatewayConfigItem$Json$Params } from '../fn/gateway-config/admin-get-gateway-config-item-json';
import { apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html } from '../fn/gateway-config/api-payment-methods-gateway-config-get-subresource-payment-method-subresource-html';
import { ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html$Params } from '../fn/gateway-config/api-payment-methods-gateway-config-get-subresource-payment-method-subresource-html';
import { apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json } from '../fn/gateway-config/api-payment-methods-gateway-config-get-subresource-payment-method-subresource-json';
import { ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json$Params } from '../fn/gateway-config/api-payment-methods-gateway-config-get-subresource-payment-method-subresource-json';
import { Show as GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow } from '../models/GatewayConfig-admin/gateway_config/show_sylius/admin/gateway_config/show';

@Injectable({ providedIn: 'root' })
export class GatewayConfigService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetGatewayConfigItem()` */
  static readonly AdminGetGatewayConfigItemPath = '/api/v2/admin/gateway-configs/{id}';

  /**
   * Retrieves a GatewayConfig resource.
   *
   * Retrieves a GatewayConfig resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetGatewayConfigItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetGatewayConfigItem$Json$Response(params: AdminGetGatewayConfigItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow>> {
    return adminGetGatewayConfigItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a GatewayConfig resource.
   *
   * Retrieves a GatewayConfig resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetGatewayConfigItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetGatewayConfigItem$Json(params: AdminGetGatewayConfigItem$Json$Params, context?: HttpContext): Observable<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow> {
    return this.adminGetGatewayConfigItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow>): GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow => r.body)
    );
  }

  /**
   * Retrieves a GatewayConfig resource.
   *
   * Retrieves a GatewayConfig resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetGatewayConfigItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetGatewayConfigItem$Html$Response(params: AdminGetGatewayConfigItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow>> {
    return adminGetGatewayConfigItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a GatewayConfig resource.
   *
   * Retrieves a GatewayConfig resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetGatewayConfigItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetGatewayConfigItem$Html(params: AdminGetGatewayConfigItem$Html$Params, context?: HttpContext): Observable<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow> {
    return this.adminGetGatewayConfigItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow>): GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow => r.body)
    );
  }

  /** Path part for operation `apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource()` */
  static readonly ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresourcePath = '/api/v2/admin/payment-methods/{code}/gateway-config';

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json$Response(params: ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow>> {
    return apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json(params: ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json$Params, context?: HttpContext): Observable<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow> {
    return this.apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow>): GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow => r.body)
    );
  }

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html$Response(params: ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow>> {
    return apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a PaymentMethod resource.
   *
   * Retrieves a PaymentMethod resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html(params: ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html$Params, context?: HttpContext): Observable<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow> {
    return this.apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow>): GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow => r.body)
    );
  }

}

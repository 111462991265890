/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteProductVariantItem } from '../fn/product-variant/admin-delete-product-variant-item';
import { AdminDeleteProductVariantItem$Params } from '../fn/product-variant/admin-delete-product-variant-item';
import { adminGetProductVariantCollection$Html } from '../fn/product-variant/admin-get-product-variant-collection-html';
import { AdminGetProductVariantCollection$Html$Params } from '../fn/product-variant/admin-get-product-variant-collection-html';
import { adminGetProductVariantCollection$Json } from '../fn/product-variant/admin-get-product-variant-collection-json';
import { AdminGetProductVariantCollection$Json$Params } from '../fn/product-variant/admin-get-product-variant-collection-json';
import { adminGetProductVariantItem$Html } from '../fn/product-variant/admin-get-product-variant-item-html';
import { AdminGetProductVariantItem$Html$Params } from '../fn/product-variant/admin-get-product-variant-item-html';
import { adminGetProductVariantItem$Json } from '../fn/product-variant/admin-get-product-variant-item-json';
import { AdminGetProductVariantItem$Json$Params } from '../fn/product-variant/admin-get-product-variant-item-json';
import { adminPostProductVariantCollection$Html$Html } from '../fn/product-variant/admin-post-product-variant-collection-html-html';
import { AdminPostProductVariantCollection$Html$Html$Params } from '../fn/product-variant/admin-post-product-variant-collection-html-html';
import { adminPostProductVariantCollection$Html$Json } from '../fn/product-variant/admin-post-product-variant-collection-html-json';
import { AdminPostProductVariantCollection$Html$Json$Params } from '../fn/product-variant/admin-post-product-variant-collection-html-json';
import { adminPostProductVariantCollection$Json$Html } from '../fn/product-variant/admin-post-product-variant-collection-json-html';
import { AdminPostProductVariantCollection$Json$Html$Params } from '../fn/product-variant/admin-post-product-variant-collection-json-html';
import { adminPostProductVariantCollection$Json$Json } from '../fn/product-variant/admin-post-product-variant-collection-json-json';
import { AdminPostProductVariantCollection$Json$Json$Params } from '../fn/product-variant/admin-post-product-variant-collection-json-json';
import { adminPutProductVariantItem$Html$Html } from '../fn/product-variant/admin-put-product-variant-item-html-html';
import { AdminPutProductVariantItem$Html$Html$Params } from '../fn/product-variant/admin-put-product-variant-item-html-html';
import { adminPutProductVariantItem$Html$Json } from '../fn/product-variant/admin-put-product-variant-item-html-json';
import { AdminPutProductVariantItem$Html$Json$Params } from '../fn/product-variant/admin-put-product-variant-item-html-json';
import { adminPutProductVariantItem$Json$Html } from '../fn/product-variant/admin-put-product-variant-item-json-html';
import { AdminPutProductVariantItem$Json$Html$Params } from '../fn/product-variant/admin-put-product-variant-item-json-html';
import { adminPutProductVariantItem$Json$Json } from '../fn/product-variant/admin-put-product-variant-item-json-json';
import { AdminPutProductVariantItem$Json$Json$Params } from '../fn/product-variant/admin-put-product-variant-item-json-json';
import { Index as ProductVariantAdminProductVariantIndexSyliusAdminProductVariantIndex } from '../models/ProductVariant-admin/product_variant/index_sylius/admin/product_variant/index';
import { Show as ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow } from '../models/ProductVariant-admin/product_variant/show_sylius/admin/product_variant/show';
import { Index as ProductVariantShopProductVariantIndexSyliusShopProductVariantIndex } from '../models/ProductVariant-shop/product_variant/index_sylius/shop/product_variant/index';
import { Show as ProductVariantShopProductVariantShowSyliusShopProductVariantShow } from '../models/ProductVariant-shop/product_variant/show_sylius/shop/product_variant/show';
import { shopGetProductVariantCollection$Html } from '../fn/product-variant/shop-get-product-variant-collection-html';
import { ShopGetProductVariantCollection$Html$Params } from '../fn/product-variant/shop-get-product-variant-collection-html';
import { shopGetProductVariantCollection$Json } from '../fn/product-variant/shop-get-product-variant-collection-json';
import { ShopGetProductVariantCollection$Json$Params } from '../fn/product-variant/shop-get-product-variant-collection-json';
import { shopGetProductVariantItem$Html } from '../fn/product-variant/shop-get-product-variant-item-html';
import { ShopGetProductVariantItem$Html$Params } from '../fn/product-variant/shop-get-product-variant-item-html';
import { shopGetProductVariantItem$Json } from '../fn/product-variant/shop-get-product-variant-item-json';
import { ShopGetProductVariantItem$Json$Params } from '../fn/product-variant/shop-get-product-variant-item-json';

@Injectable({ providedIn: 'root' })
export class ProductVariantService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetProductVariantCollection()` */
  static readonly AdminGetProductVariantCollectionPath = '/api/v2/admin/product-variants';

  /**
   * Retrieves the collection of ProductVariant resources.
   *
   * Retrieves the collection of ProductVariant resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductVariantCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductVariantCollection$Json$Response(params?: AdminGetProductVariantCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductVariantAdminProductVariantIndexSyliusAdminProductVariantIndex>>> {
    return adminGetProductVariantCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductVariant resources.
   *
   * Retrieves the collection of ProductVariant resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductVariantCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductVariantCollection$Json(params?: AdminGetProductVariantCollection$Json$Params, context?: HttpContext): Observable<Array<ProductVariantAdminProductVariantIndexSyliusAdminProductVariantIndex>> {
    return this.adminGetProductVariantCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductVariantAdminProductVariantIndexSyliusAdminProductVariantIndex>>): Array<ProductVariantAdminProductVariantIndexSyliusAdminProductVariantIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ProductVariant resources.
   *
   * Retrieves the collection of ProductVariant resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductVariantCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductVariantCollection$Html$Response(params?: AdminGetProductVariantCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductVariantAdminProductVariantIndexSyliusAdminProductVariantIndex>>> {
    return adminGetProductVariantCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductVariant resources.
   *
   * Retrieves the collection of ProductVariant resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductVariantCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductVariantCollection$Html(params?: AdminGetProductVariantCollection$Html$Params, context?: HttpContext): Observable<Array<ProductVariantAdminProductVariantIndexSyliusAdminProductVariantIndex>> {
    return this.adminGetProductVariantCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductVariantAdminProductVariantIndexSyliusAdminProductVariantIndex>>): Array<ProductVariantAdminProductVariantIndexSyliusAdminProductVariantIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostProductVariantCollection()` */
  static readonly AdminPostProductVariantCollectionPath = '/api/v2/admin/product-variants';

  /**
   * Creates a ProductVariant resource.
   *
   * Creates a ProductVariant resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductVariantCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductVariantCollection$Json$Json$Response(params: AdminPostProductVariantCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>> {
    return adminPostProductVariantCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductVariant resource.
   *
   * Creates a ProductVariant resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductVariantCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductVariantCollection$Json$Json(params: AdminPostProductVariantCollection$Json$Json$Params, context?: HttpContext): Observable<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow> {
    return this.adminPostProductVariantCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>): ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow => r.body)
    );
  }

  /**
   * Creates a ProductVariant resource.
   *
   * Creates a ProductVariant resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductVariantCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductVariantCollection$Json$Html$Response(params: AdminPostProductVariantCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>> {
    return adminPostProductVariantCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductVariant resource.
   *
   * Creates a ProductVariant resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductVariantCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostProductVariantCollection$Json$Html(params: AdminPostProductVariantCollection$Json$Html$Params, context?: HttpContext): Observable<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow> {
    return this.adminPostProductVariantCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>): ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow => r.body)
    );
  }

  /**
   * Creates a ProductVariant resource.
   *
   * Creates a ProductVariant resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductVariantCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductVariantCollection$Html$Json$Response(params: AdminPostProductVariantCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>> {
    return adminPostProductVariantCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductVariant resource.
   *
   * Creates a ProductVariant resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductVariantCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductVariantCollection$Html$Json(params: AdminPostProductVariantCollection$Html$Json$Params, context?: HttpContext): Observable<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow> {
    return this.adminPostProductVariantCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>): ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow => r.body)
    );
  }

  /**
   * Creates a ProductVariant resource.
   *
   * Creates a ProductVariant resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostProductVariantCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductVariantCollection$Html$Html$Response(params: AdminPostProductVariantCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>> {
    return adminPostProductVariantCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ProductVariant resource.
   *
   * Creates a ProductVariant resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostProductVariantCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostProductVariantCollection$Html$Html(params: AdminPostProductVariantCollection$Html$Html$Params, context?: HttpContext): Observable<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow> {
    return this.adminPostProductVariantCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>): ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow => r.body)
    );
  }

  /** Path part for operation `adminGetProductVariantItem()` */
  static readonly AdminGetProductVariantItemPath = '/api/v2/admin/product-variants/{code}';

  /**
   * Retrieves a ProductVariant resource.
   *
   * Retrieves a ProductVariant resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductVariantItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductVariantItem$Json$Response(params: AdminGetProductVariantItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>> {
    return adminGetProductVariantItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductVariant resource.
   *
   * Retrieves a ProductVariant resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductVariantItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductVariantItem$Json(params: AdminGetProductVariantItem$Json$Params, context?: HttpContext): Observable<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow> {
    return this.adminGetProductVariantItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>): ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow => r.body)
    );
  }

  /**
   * Retrieves a ProductVariant resource.
   *
   * Retrieves a ProductVariant resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductVariantItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductVariantItem$Html$Response(params: AdminGetProductVariantItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>> {
    return adminGetProductVariantItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductVariant resource.
   *
   * Retrieves a ProductVariant resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductVariantItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductVariantItem$Html(params: AdminGetProductVariantItem$Html$Params, context?: HttpContext): Observable<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow> {
    return this.adminGetProductVariantItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>): ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow => r.body)
    );
  }

  /** Path part for operation `adminPutProductVariantItem()` */
  static readonly AdminPutProductVariantItemPath = '/api/v2/admin/product-variants/{code}';

  /**
   * Replaces the ProductVariant resource.
   *
   * Replaces the ProductVariant resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductVariantItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductVariantItem$Json$Json$Response(params: AdminPutProductVariantItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>> {
    return adminPutProductVariantItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductVariant resource.
   *
   * Replaces the ProductVariant resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductVariantItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductVariantItem$Json$Json(params: AdminPutProductVariantItem$Json$Json$Params, context?: HttpContext): Observable<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow> {
    return this.adminPutProductVariantItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>): ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow => r.body)
    );
  }

  /**
   * Replaces the ProductVariant resource.
   *
   * Replaces the ProductVariant resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductVariantItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductVariantItem$Json$Html$Response(params: AdminPutProductVariantItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>> {
    return adminPutProductVariantItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductVariant resource.
   *
   * Replaces the ProductVariant resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductVariantItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutProductVariantItem$Json$Html(params: AdminPutProductVariantItem$Json$Html$Params, context?: HttpContext): Observable<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow> {
    return this.adminPutProductVariantItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>): ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow => r.body)
    );
  }

  /**
   * Replaces the ProductVariant resource.
   *
   * Replaces the ProductVariant resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductVariantItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductVariantItem$Html$Json$Response(params: AdminPutProductVariantItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>> {
    return adminPutProductVariantItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductVariant resource.
   *
   * Replaces the ProductVariant resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductVariantItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductVariantItem$Html$Json(params: AdminPutProductVariantItem$Html$Json$Params, context?: HttpContext): Observable<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow> {
    return this.adminPutProductVariantItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>): ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow => r.body)
    );
  }

  /**
   * Replaces the ProductVariant resource.
   *
   * Replaces the ProductVariant resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutProductVariantItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductVariantItem$Html$Html$Response(params: AdminPutProductVariantItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>> {
    return adminPutProductVariantItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ProductVariant resource.
   *
   * Replaces the ProductVariant resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutProductVariantItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutProductVariantItem$Html$Html(params: AdminPutProductVariantItem$Html$Html$Params, context?: HttpContext): Observable<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow> {
    return this.adminPutProductVariantItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow>): ProductVariantAdminProductVariantShowSyliusAdminProductVariantShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteProductVariantItem()` */
  static readonly AdminDeleteProductVariantItemPath = '/api/v2/admin/product-variants/{code}';

  /**
   * Removes the ProductVariant resource.
   *
   * Removes the ProductVariant resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteProductVariantItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductVariantItem$Response(params: AdminDeleteProductVariantItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteProductVariantItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the ProductVariant resource.
   *
   * Removes the ProductVariant resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteProductVariantItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteProductVariantItem(params: AdminDeleteProductVariantItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteProductVariantItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `shopGetProductVariantCollection()` */
  static readonly ShopGetProductVariantCollectionPath = '/api/v2/shop/product-variants';

  /**
   * Retrieves the collection of ProductVariant resources.
   *
   * Retrieves the collection of ProductVariant resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductVariantCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductVariantCollection$Json$Response(params?: ShopGetProductVariantCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductVariantShopProductVariantIndexSyliusShopProductVariantIndex>>> {
    return shopGetProductVariantCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductVariant resources.
   *
   * Retrieves the collection of ProductVariant resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductVariantCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductVariantCollection$Json(params?: ShopGetProductVariantCollection$Json$Params, context?: HttpContext): Observable<Array<ProductVariantShopProductVariantIndexSyliusShopProductVariantIndex>> {
    return this.shopGetProductVariantCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductVariantShopProductVariantIndexSyliusShopProductVariantIndex>>): Array<ProductVariantShopProductVariantIndexSyliusShopProductVariantIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of ProductVariant resources.
   *
   * Retrieves the collection of ProductVariant resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductVariantCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductVariantCollection$Html$Response(params?: ShopGetProductVariantCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductVariantShopProductVariantIndexSyliusShopProductVariantIndex>>> {
    return shopGetProductVariantCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ProductVariant resources.
   *
   * Retrieves the collection of ProductVariant resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductVariantCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductVariantCollection$Html(params?: ShopGetProductVariantCollection$Html$Params, context?: HttpContext): Observable<Array<ProductVariantShopProductVariantIndexSyliusShopProductVariantIndex>> {
    return this.shopGetProductVariantCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductVariantShopProductVariantIndexSyliusShopProductVariantIndex>>): Array<ProductVariantShopProductVariantIndexSyliusShopProductVariantIndex> => r.body)
    );
  }

  /** Path part for operation `shopGetProductVariantItem()` */
  static readonly ShopGetProductVariantItemPath = '/api/v2/shop/product-variants/{code}';

  /**
   * Retrieves a ProductVariant resource.
   *
   * Retrieves a ProductVariant resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductVariantItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductVariantItem$Json$Response(params: ShopGetProductVariantItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductVariantShopProductVariantShowSyliusShopProductVariantShow>> {
    return shopGetProductVariantItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductVariant resource.
   *
   * Retrieves a ProductVariant resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductVariantItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductVariantItem$Json(params: ShopGetProductVariantItem$Json$Params, context?: HttpContext): Observable<ProductVariantShopProductVariantShowSyliusShopProductVariantShow> {
    return this.shopGetProductVariantItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductVariantShopProductVariantShowSyliusShopProductVariantShow>): ProductVariantShopProductVariantShowSyliusShopProductVariantShow => r.body)
    );
  }

  /**
   * Retrieves a ProductVariant resource.
   *
   * Retrieves a ProductVariant resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetProductVariantItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductVariantItem$Html$Response(params: ShopGetProductVariantItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductVariantShopProductVariantShowSyliusShopProductVariantShow>> {
    return shopGetProductVariantItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductVariant resource.
   *
   * Retrieves a ProductVariant resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetProductVariantItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetProductVariantItem$Html(params: ShopGetProductVariantItem$Html$Params, context?: HttpContext): Observable<ProductVariantShopProductVariantShowSyliusShopProductVariantShow> {
    return this.shopGetProductVariantItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductVariantShopProductVariantShowSyliusShopProductVariantShow>): ProductVariantShopProductVariantShowSyliusShopProductVariantShow => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Index as PaymentAdminPaymentIndexSyliusAdminPaymentIndex } from '../../models/Payment-admin/payment/index_sylius/admin/payment/index';

export interface AdminGetPaymentCollection$Html$Params {

/**
 * The collection page number
 */
  page?: number;

/**
 * The number of items per page
 */
  itemsPerPage?: number;
  state?: string;
  'state[]'?: Array<string>;
  'order.channel.code'?: string;
  'order.channel.code[]'?: Array<string>;

/**
 * Locales in this enum are all locales defined in the shop and only enabled ones will work in the given channel in the shop.
 */
  'Accept-Language'?: 'hu_HU';
}

export function adminGetPaymentCollection$Html(http: HttpClient, rootUrl: string, params?: AdminGetPaymentCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PaymentAdminPaymentIndexSyliusAdminPaymentIndex>>> {
  const rb = new RequestBuilder(rootUrl, adminGetPaymentCollection$Html.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
    rb.query('state', params.state, {"style":"form","explode":false});
    rb.query('state[]', params['state[]'], {"style":"form","explode":true});
    rb.query('order.channel.code', params['order.channel.code'], {"style":"form","explode":false});
    rb.query('order.channel.code[]', params['order.channel.code[]'], {"style":"form","explode":true});
    rb.header('Accept-Language', params['Accept-Language'], {"style":"simple","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/html', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<PaymentAdminPaymentIndexSyliusAdminPaymentIndex>>;
    })
  );
}

adminGetPaymentCollection$Html.PATH = '/api/v2/admin/payments';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetPaymentMethodTranslationItem$Html } from '../fn/payment-method-translation/admin-get-payment-method-translation-item-html';
import { AdminGetPaymentMethodTranslationItem$Html$Params } from '../fn/payment-method-translation/admin-get-payment-method-translation-item-html';
import { adminGetPaymentMethodTranslationItem$Json } from '../fn/payment-method-translation/admin-get-payment-method-translation-item-json';
import { AdminGetPaymentMethodTranslationItem$Json$Params } from '../fn/payment-method-translation/admin-get-payment-method-translation-item-json';
import { PaymentMethodTranslation } from '../models/payment-method-translation';

@Injectable({ providedIn: 'root' })
export class PaymentMethodTranslationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetPaymentMethodTranslationItem()` */
  static readonly AdminGetPaymentMethodTranslationItemPath = '/api/v2/admin/payment-method-translations/{id}';

  /**
   * Retrieves a PaymentMethodTranslation resource.
   *
   * Retrieves a PaymentMethodTranslation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPaymentMethodTranslationItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentMethodTranslationItem$Json$Response(params: AdminGetPaymentMethodTranslationItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentMethodTranslation>> {
    return adminGetPaymentMethodTranslationItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a PaymentMethodTranslation resource.
   *
   * Retrieves a PaymentMethodTranslation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPaymentMethodTranslationItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentMethodTranslationItem$Json(params: AdminGetPaymentMethodTranslationItem$Json$Params, context?: HttpContext): Observable<PaymentMethodTranslation> {
    return this.adminGetPaymentMethodTranslationItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodTranslation>): PaymentMethodTranslation => r.body)
    );
  }

  /**
   * Retrieves a PaymentMethodTranslation resource.
   *
   * Retrieves a PaymentMethodTranslation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPaymentMethodTranslationItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentMethodTranslationItem$Html$Response(params: AdminGetPaymentMethodTranslationItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentMethodTranslation>> {
    return adminGetPaymentMethodTranslationItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a PaymentMethodTranslation resource.
   *
   * Retrieves a PaymentMethodTranslation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPaymentMethodTranslationItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPaymentMethodTranslationItem$Html(params: AdminGetPaymentMethodTranslationItem$Html$Params, context?: HttpContext): Observable<PaymentMethodTranslation> {
    return this.adminGetPaymentMethodTranslationItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentMethodTranslation>): PaymentMethodTranslation => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetCurrencyCollection$Html } from '../fn/currency/admin-get-currency-collection-html';
import { AdminGetCurrencyCollection$Html$Params } from '../fn/currency/admin-get-currency-collection-html';
import { adminGetCurrencyCollection$Json } from '../fn/currency/admin-get-currency-collection-json';
import { AdminGetCurrencyCollection$Json$Params } from '../fn/currency/admin-get-currency-collection-json';
import { adminGetCurrencyItem$Html } from '../fn/currency/admin-get-currency-item-html';
import { AdminGetCurrencyItem$Html$Params } from '../fn/currency/admin-get-currency-item-html';
import { adminGetCurrencyItem$Json } from '../fn/currency/admin-get-currency-item-json';
import { AdminGetCurrencyItem$Json$Params } from '../fn/currency/admin-get-currency-item-json';
import { adminPostCurrencyCollection$Html$Html } from '../fn/currency/admin-post-currency-collection-html-html';
import { AdminPostCurrencyCollection$Html$Html$Params } from '../fn/currency/admin-post-currency-collection-html-html';
import { adminPostCurrencyCollection$Html$Json } from '../fn/currency/admin-post-currency-collection-html-json';
import { AdminPostCurrencyCollection$Html$Json$Params } from '../fn/currency/admin-post-currency-collection-html-json';
import { adminPostCurrencyCollection$Json$Html } from '../fn/currency/admin-post-currency-collection-json-html';
import { AdminPostCurrencyCollection$Json$Html$Params } from '../fn/currency/admin-post-currency-collection-json-html';
import { adminPostCurrencyCollection$Json$Json } from '../fn/currency/admin-post-currency-collection-json-json';
import { AdminPostCurrencyCollection$Json$Json$Params } from '../fn/currency/admin-post-currency-collection-json-json';
import { Index as CurrencyAdminCurrencyIndexSyliusAdminCurrencyIndex } from '../models/Currency-admin/currency/index_sylius/admin/currency/index';
import { Show as CurrencyAdminCurrencyShowSyliusAdminCurrencyShow } from '../models/Currency-admin/currency/show_sylius/admin/currency/show';
import { Index as CurrencyShopCurrencyIndexSyliusShopCurrencyIndex } from '../models/Currency-shop/currency/index_sylius/shop/currency/index';
import { Show as CurrencyShopCurrencyShowSyliusShopCurrencyShow } from '../models/Currency-shop/currency/show_sylius/shop/currency/show';
import { shopGetCurrencyCollection$Html } from '../fn/currency/shop-get-currency-collection-html';
import { ShopGetCurrencyCollection$Html$Params } from '../fn/currency/shop-get-currency-collection-html';
import { shopGetCurrencyCollection$Json } from '../fn/currency/shop-get-currency-collection-json';
import { ShopGetCurrencyCollection$Json$Params } from '../fn/currency/shop-get-currency-collection-json';
import { shopGetCurrencyItem$Html } from '../fn/currency/shop-get-currency-item-html';
import { ShopGetCurrencyItem$Html$Params } from '../fn/currency/shop-get-currency-item-html';
import { shopGetCurrencyItem$Json } from '../fn/currency/shop-get-currency-item-json';
import { ShopGetCurrencyItem$Json$Params } from '../fn/currency/shop-get-currency-item-json';

@Injectable({ providedIn: 'root' })
export class CurrencyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetCurrencyCollection()` */
  static readonly AdminGetCurrencyCollectionPath = '/api/v2/admin/currencies';

  /**
   * Retrieves the collection of Currency resources.
   *
   * Retrieves the collection of Currency resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCurrencyCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCurrencyCollection$Json$Response(params?: AdminGetCurrencyCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CurrencyAdminCurrencyIndexSyliusAdminCurrencyIndex>>> {
    return adminGetCurrencyCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Currency resources.
   *
   * Retrieves the collection of Currency resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCurrencyCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCurrencyCollection$Json(params?: AdminGetCurrencyCollection$Json$Params, context?: HttpContext): Observable<Array<CurrencyAdminCurrencyIndexSyliusAdminCurrencyIndex>> {
    return this.adminGetCurrencyCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyAdminCurrencyIndexSyliusAdminCurrencyIndex>>): Array<CurrencyAdminCurrencyIndexSyliusAdminCurrencyIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Currency resources.
   *
   * Retrieves the collection of Currency resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCurrencyCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCurrencyCollection$Html$Response(params?: AdminGetCurrencyCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CurrencyAdminCurrencyIndexSyliusAdminCurrencyIndex>>> {
    return adminGetCurrencyCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Currency resources.
   *
   * Retrieves the collection of Currency resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCurrencyCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCurrencyCollection$Html(params?: AdminGetCurrencyCollection$Html$Params, context?: HttpContext): Observable<Array<CurrencyAdminCurrencyIndexSyliusAdminCurrencyIndex>> {
    return this.adminGetCurrencyCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyAdminCurrencyIndexSyliusAdminCurrencyIndex>>): Array<CurrencyAdminCurrencyIndexSyliusAdminCurrencyIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostCurrencyCollection()` */
  static readonly AdminPostCurrencyCollectionPath = '/api/v2/admin/currencies';

  /**
   * Creates a Currency resource.
   *
   * Creates a Currency resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCurrencyCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCurrencyCollection$Json$Json$Response(params: AdminPostCurrencyCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow>> {
    return adminPostCurrencyCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Currency resource.
   *
   * Creates a Currency resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCurrencyCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCurrencyCollection$Json$Json(params: AdminPostCurrencyCollection$Json$Json$Params, context?: HttpContext): Observable<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow> {
    return this.adminPostCurrencyCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow>): CurrencyAdminCurrencyShowSyliusAdminCurrencyShow => r.body)
    );
  }

  /**
   * Creates a Currency resource.
   *
   * Creates a Currency resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCurrencyCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCurrencyCollection$Json$Html$Response(params: AdminPostCurrencyCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow>> {
    return adminPostCurrencyCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Currency resource.
   *
   * Creates a Currency resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCurrencyCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostCurrencyCollection$Json$Html(params: AdminPostCurrencyCollection$Json$Html$Params, context?: HttpContext): Observable<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow> {
    return this.adminPostCurrencyCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow>): CurrencyAdminCurrencyShowSyliusAdminCurrencyShow => r.body)
    );
  }

  /**
   * Creates a Currency resource.
   *
   * Creates a Currency resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCurrencyCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCurrencyCollection$Html$Json$Response(params: AdminPostCurrencyCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow>> {
    return adminPostCurrencyCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Currency resource.
   *
   * Creates a Currency resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCurrencyCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCurrencyCollection$Html$Json(params: AdminPostCurrencyCollection$Html$Json$Params, context?: HttpContext): Observable<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow> {
    return this.adminPostCurrencyCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow>): CurrencyAdminCurrencyShowSyliusAdminCurrencyShow => r.body)
    );
  }

  /**
   * Creates a Currency resource.
   *
   * Creates a Currency resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostCurrencyCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCurrencyCollection$Html$Html$Response(params: AdminPostCurrencyCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow>> {
    return adminPostCurrencyCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Currency resource.
   *
   * Creates a Currency resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostCurrencyCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostCurrencyCollection$Html$Html(params: AdminPostCurrencyCollection$Html$Html$Params, context?: HttpContext): Observable<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow> {
    return this.adminPostCurrencyCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow>): CurrencyAdminCurrencyShowSyliusAdminCurrencyShow => r.body)
    );
  }

  /** Path part for operation `adminGetCurrencyItem()` */
  static readonly AdminGetCurrencyItemPath = '/api/v2/admin/currencies/{code}';

  /**
   * Retrieves a Currency resource.
   *
   * Retrieves a Currency resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCurrencyItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCurrencyItem$Json$Response(params: AdminGetCurrencyItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow>> {
    return adminGetCurrencyItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Currency resource.
   *
   * Retrieves a Currency resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCurrencyItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCurrencyItem$Json(params: AdminGetCurrencyItem$Json$Params, context?: HttpContext): Observable<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow> {
    return this.adminGetCurrencyItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow>): CurrencyAdminCurrencyShowSyliusAdminCurrencyShow => r.body)
    );
  }

  /**
   * Retrieves a Currency resource.
   *
   * Retrieves a Currency resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetCurrencyItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCurrencyItem$Html$Response(params: AdminGetCurrencyItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow>> {
    return adminGetCurrencyItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Currency resource.
   *
   * Retrieves a Currency resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetCurrencyItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetCurrencyItem$Html(params: AdminGetCurrencyItem$Html$Params, context?: HttpContext): Observable<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow> {
    return this.adminGetCurrencyItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrencyAdminCurrencyShowSyliusAdminCurrencyShow>): CurrencyAdminCurrencyShowSyliusAdminCurrencyShow => r.body)
    );
  }

  /** Path part for operation `shopGetCurrencyCollection()` */
  static readonly ShopGetCurrencyCollectionPath = '/api/v2/shop/currencies';

  /**
   * Retrieves the collection of Currency resources.
   *
   * Retrieves the collection of Currency resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetCurrencyCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCurrencyCollection$Json$Response(params?: ShopGetCurrencyCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CurrencyShopCurrencyIndexSyliusShopCurrencyIndex>>> {
    return shopGetCurrencyCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Currency resources.
   *
   * Retrieves the collection of Currency resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetCurrencyCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCurrencyCollection$Json(params?: ShopGetCurrencyCollection$Json$Params, context?: HttpContext): Observable<Array<CurrencyShopCurrencyIndexSyliusShopCurrencyIndex>> {
    return this.shopGetCurrencyCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyShopCurrencyIndexSyliusShopCurrencyIndex>>): Array<CurrencyShopCurrencyIndexSyliusShopCurrencyIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Currency resources.
   *
   * Retrieves the collection of Currency resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetCurrencyCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCurrencyCollection$Html$Response(params?: ShopGetCurrencyCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CurrencyShopCurrencyIndexSyliusShopCurrencyIndex>>> {
    return shopGetCurrencyCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Currency resources.
   *
   * Retrieves the collection of Currency resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetCurrencyCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCurrencyCollection$Html(params?: ShopGetCurrencyCollection$Html$Params, context?: HttpContext): Observable<Array<CurrencyShopCurrencyIndexSyliusShopCurrencyIndex>> {
    return this.shopGetCurrencyCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CurrencyShopCurrencyIndexSyliusShopCurrencyIndex>>): Array<CurrencyShopCurrencyIndexSyliusShopCurrencyIndex> => r.body)
    );
  }

  /** Path part for operation `shopGetCurrencyItem()` */
  static readonly ShopGetCurrencyItemPath = '/api/v2/shop/currencies/{code}';

  /**
   * Retrieves a Currency resource.
   *
   * Retrieves a Currency resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetCurrencyItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCurrencyItem$Json$Response(params: ShopGetCurrencyItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrencyShopCurrencyShowSyliusShopCurrencyShow>> {
    return shopGetCurrencyItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Currency resource.
   *
   * Retrieves a Currency resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetCurrencyItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCurrencyItem$Json(params: ShopGetCurrencyItem$Json$Params, context?: HttpContext): Observable<CurrencyShopCurrencyShowSyliusShopCurrencyShow> {
    return this.shopGetCurrencyItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrencyShopCurrencyShowSyliusShopCurrencyShow>): CurrencyShopCurrencyShowSyliusShopCurrencyShow => r.body)
    );
  }

  /**
   * Retrieves a Currency resource.
   *
   * Retrieves a Currency resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetCurrencyItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCurrencyItem$Html$Response(params: ShopGetCurrencyItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrencyShopCurrencyShowSyliusShopCurrencyShow>> {
    return shopGetCurrencyItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Currency resource.
   *
   * Retrieves a Currency resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetCurrencyItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetCurrencyItem$Html(params: ShopGetCurrencyItem$Html$Params, context?: HttpContext): Observable<CurrencyShopCurrencyShowSyliusShopCurrencyShow> {
    return this.shopGetCurrencyItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrencyShopCurrencyShowSyliusShopCurrencyShow>): CurrencyShopCurrencyShowSyliusShopCurrencyShow => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Show as AdministratorAdminAdminUserShowSyliusAdminAdminUserShow } from '../../models/Administrator-admin/admin_user/show_sylius/admin/admin_user/show';
import { Update as AdministratorAdminAdminUserUpdateSyliusAdminAdminUserUpdate } from '../../models/Administrator-admin/admin_user/update_sylius/admin/admin_user/update';

export interface AdminPutAdministratorItem$Json$Json$Params {

/**
 * Resource identifier
 */
  id: string;
  
    /**
     * The updated Administrator resource
     */
    body: AdministratorAdminAdminUserUpdateSyliusAdminAdminUserUpdate
}

export function adminPutAdministratorItem$Json$Json(http: HttpClient, rootUrl: string, params: AdminPutAdministratorItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>> {
  const rb = new RequestBuilder(rootUrl, adminPutAdministratorItem$Json$Json.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {"style":"simple","explode":false});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AdministratorAdminAdminUserShowSyliusAdminAdminUserShow>;
    })
  );
}

adminPutAdministratorItem$Json$Json.PATH = '/api/v2/admin/administrators/{id}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Show as ZoneAdminZoneShowSyliusAdminZoneShow } from '../../models/Zone-admin/zone/show_sylius/admin/zone/show';

export interface AdminGetZoneItem$Json$Params {

/**
 * Resource identifier
 */
  code: string;
}

export function adminGetZoneItem$Json(http: HttpClient, rootUrl: string, params: AdminGetZoneItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>> {
  const rb = new RequestBuilder(rootUrl, adminGetZoneItem$Json.PATH, 'get');
  if (params) {
    rb.path('code', params.code, {"style":"simple","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ZoneAdminZoneShowSyliusAdminZoneShow>;
    })
  );
}

adminGetZoneItem$Json.PATH = '/api/v2/admin/zones/{code}';

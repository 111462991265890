/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Show as AdjustmentShopCartShowSyliusShopCartShow } from '../models/Adjustment-shop/cart/show_sylius/shop/cart/show';
import { adminAdjustmentsOrderItem$Html } from '../fn/order/admin-adjustments-order-item-html';
import { AdminAdjustmentsOrderItem$Html$Params } from '../fn/order/admin-adjustments-order-item-html';
import { adminAdjustmentsOrderItem$Json } from '../fn/order/admin-adjustments-order-item-json';
import { AdminAdjustmentsOrderItem$Json$Params } from '../fn/order/admin-adjustments-order-item-json';
import { adminCancelOrderItem$Html } from '../fn/order/admin-cancel-order-item-html';
import { AdminCancelOrderItem$Html$Params } from '../fn/order/admin-cancel-order-item-html';
import { adminCancelOrderItem$Json } from '../fn/order/admin-cancel-order-item-json';
import { AdminCancelOrderItem$Json$Params } from '../fn/order/admin-cancel-order-item-json';
import { adminGetOrderCollection$Html } from '../fn/order/admin-get-order-collection-html';
import { AdminGetOrderCollection$Html$Params } from '../fn/order/admin-get-order-collection-html';
import { adminGetOrderCollection$Json } from '../fn/order/admin-get-order-collection-json';
import { AdminGetOrderCollection$Json$Params } from '../fn/order/admin-get-order-collection-json';
import { adminGetOrderItem$Html } from '../fn/order/admin-get-order-item-html';
import { AdminGetOrderItem$Html$Params } from '../fn/order/admin-get-order-item-html';
import { adminGetOrderItem$Json } from '../fn/order/admin-get-order-item-json';
import { AdminGetOrderItem$Json$Params } from '../fn/order/admin-get-order-item-json';
import { adminResendConfirmationOrderItem$Html$Html } from '../fn/order/admin-resend-confirmation-order-item-html-html';
import { AdminResendConfirmationOrderItem$Html$Html$Params } from '../fn/order/admin-resend-confirmation-order-item-html-html';
import { adminResendConfirmationOrderItem$Html$Json } from '../fn/order/admin-resend-confirmation-order-item-html-json';
import { AdminResendConfirmationOrderItem$Html$Json$Params } from '../fn/order/admin-resend-confirmation-order-item-html-json';
import { adminResendConfirmationOrderItem$Json$Html } from '../fn/order/admin-resend-confirmation-order-item-json-html';
import { AdminResendConfirmationOrderItem$Json$Html$Params } from '../fn/order/admin-resend-confirmation-order-item-json-html';
import { adminResendConfirmationOrderItem$Json$Json } from '../fn/order/admin-resend-confirmation-order-item-json-json';
import { AdminResendConfirmationOrderItem$Json$Json$Params } from '../fn/order/admin-resend-confirmation-order-item-json-json';
import { apiOrdersAdjustmentsGetSubresourceOrderSubresource$Html } from '../fn/adjustment/api-orders-adjustments-get-subresource-order-subresource-html';
import { ApiOrdersAdjustmentsGetSubresourceOrderSubresource$Html$Params } from '../fn/adjustment/api-orders-adjustments-get-subresource-order-subresource-html';
import { apiOrdersAdjustmentsGetSubresourceOrderSubresource$Json } from '../fn/adjustment/api-orders-adjustments-get-subresource-order-subresource-json';
import { ApiOrdersAdjustmentsGetSubresourceOrderSubresource$Json$Params } from '../fn/adjustment/api-orders-adjustments-get-subresource-order-subresource-json';
import { apiOrdersItemsGetSubresourceOrderSubresource$Html } from '../fn/order-item/api-orders-items-get-subresource-order-subresource-html';
import { ApiOrdersItemsGetSubresourceOrderSubresource$Html$Params } from '../fn/order-item/api-orders-items-get-subresource-order-subresource-html';
import { apiOrdersItemsGetSubresourceOrderSubresource$Json } from '../fn/order-item/api-orders-items-get-subresource-order-subresource-json';
import { ApiOrdersItemsGetSubresourceOrderSubresource$Json$Params } from '../fn/order-item/api-orders-items-get-subresource-order-subresource-json';
import { apiOrdersPaymentsGetSubresourceOrderSubresource$Html } from '../fn/payment/api-orders-payments-get-subresource-order-subresource-html';
import { ApiOrdersPaymentsGetSubresourceOrderSubresource$Html$Params } from '../fn/payment/api-orders-payments-get-subresource-order-subresource-html';
import { apiOrdersPaymentsGetSubresourceOrderSubresource$Json } from '../fn/payment/api-orders-payments-get-subresource-order-subresource-json';
import { ApiOrdersPaymentsGetSubresourceOrderSubresource$Json$Params } from '../fn/payment/api-orders-payments-get-subresource-order-subresource-json';
import { apiOrdersShipmentsGetSubresourceOrderSubresource$Html } from '../fn/shipment/api-orders-shipments-get-subresource-order-subresource-html';
import { ApiOrdersShipmentsGetSubresourceOrderSubresource$Html$Params } from '../fn/shipment/api-orders-shipments-get-subresource-order-subresource-html';
import { apiOrdersShipmentsGetSubresourceOrderSubresource$Json } from '../fn/shipment/api-orders-shipments-get-subresource-order-subresource-json';
import { ApiOrdersShipmentsGetSubresourceOrderSubresource$Json$Params } from '../fn/shipment/api-orders-shipments-get-subresource-order-subresource-json';
import { getConfigurationOrderItem$Html } from '../fn/order/get-configuration-order-item-html';
import { GetConfigurationOrderItem$Html$Params } from '../fn/order/get-configuration-order-item-html';
import { getConfigurationOrderItem$Json } from '../fn/order/get-configuration-order-item-json';
import { GetConfigurationOrderItem$Json$Params } from '../fn/order/get-configuration-order-item-json';
import { Order } from '../models/order';
import { Show as OrderAdminAdjustmentShowSyliusAdminAdjustmentShow } from '../models/Order-admin/adjustment/show_sylius/admin/adjustment/show';
import { Index as OrderAdminOrderIndexSyliusAdminOrderIndex } from '../models/Order-admin/order/index_sylius/admin/order/index';
import { Show as OrderAdminOrderShowSyliusAdminOrderShow } from '../models/Order-admin/order/show_sylius/admin/order/show';
import { Show as OrderShopCartShowSyliusShopCartShow } from '../models/Order-shop/cart/show_sylius/shop/cart/show';
import { Show as OrderShopOrderAccountShowSyliusShopOrderAccountShow } from '../models/Order-shop/order/account/show_sylius/shop/order/account/show';
import { Index as OrderShopOrderIndexSyliusShopOrderIndex } from '../models/Order-shop/order/index_sylius/shop/order/index';
import { Show as OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow } from '../models/Order-shop/order/show_sylius/shop/order/show_shop/cart/show_sylius/shop/cart/show';
import { Show as OrderItemShopCartShowSyliusShopCartShow } from '../models/OrderItem-shop/cart/show_sylius/shop/cart/show';
import { Show as PaymentAdminPaymentShowSyliusAdminPaymentShow } from '../models/Payment-admin/payment/show_sylius/admin/payment/show';
import { Show as ShipmentAdminShipmentShowSyliusAdminShipmentShow } from '../models/Shipment-admin/shipment/show_sylius/admin/shipment/show';
import { shopAccountChangePaymentMethodOrderItem$Html } from '../fn/order/shop-account-change-payment-method-order-item-html';
import { ShopAccountChangePaymentMethodOrderItem$Html$Params } from '../fn/order/shop-account-change-payment-method-order-item-html';
import { shopAccountChangePaymentMethodOrderItem$Json } from '../fn/order/shop-account-change-payment-method-order-item-json';
import { ShopAccountChangePaymentMethodOrderItem$Json$Params } from '../fn/order/shop-account-change-payment-method-order-item-json';
import { shopAddItemOrderItem$Html$Html } from '../fn/order/shop-add-item-order-item-html-html';
import { ShopAddItemOrderItem$Html$Html$Params } from '../fn/order/shop-add-item-order-item-html-html';
import { shopAddItemOrderItem$Html$Json } from '../fn/order/shop-add-item-order-item-html-json';
import { ShopAddItemOrderItem$Html$Json$Params } from '../fn/order/shop-add-item-order-item-html-json';
import { shopAddItemOrderItem$Json$Html } from '../fn/order/shop-add-item-order-item-json-html';
import { ShopAddItemOrderItem$Json$Html$Params } from '../fn/order/shop-add-item-order-item-json-html';
import { shopAddItemOrderItem$Json$Json } from '../fn/order/shop-add-item-order-item-json-json';
import { ShopAddItemOrderItem$Json$Json$Params } from '../fn/order/shop-add-item-order-item-json-json';
import { shopChangeQuantityOrderItem$Html } from '../fn/order/shop-change-quantity-order-item-html';
import { ShopChangeQuantityOrderItem$Html$Params } from '../fn/order/shop-change-quantity-order-item-html';
import { shopChangeQuantityOrderItem$Json } from '../fn/order/shop-change-quantity-order-item-json';
import { ShopChangeQuantityOrderItem$Json$Params } from '../fn/order/shop-change-quantity-order-item-json';
import { shopCompleteOrderItem$Html } from '../fn/order/shop-complete-order-item-html';
import { ShopCompleteOrderItem$Html$Params } from '../fn/order/shop-complete-order-item-html';
import { shopCompleteOrderItem$Json } from '../fn/order/shop-complete-order-item-json';
import { ShopCompleteOrderItem$Json$Params } from '../fn/order/shop-complete-order-item-json';
import { shopDeleteOrderItem } from '../fn/order/shop-delete-order-item';
import { ShopDeleteOrderItem$Params } from '../fn/order/shop-delete-order-item';
import { shopGetOrderCollection$Html } from '../fn/order/shop-get-order-collection-html';
import { ShopGetOrderCollection$Html$Params } from '../fn/order/shop-get-order-collection-html';
import { shopGetOrderCollection$Json } from '../fn/order/shop-get-order-collection-json';
import { ShopGetOrderCollection$Json$Params } from '../fn/order/shop-get-order-collection-json';
import { shopGetOrderItem$Html } from '../fn/order/shop-get-order-item-html';
import { ShopGetOrderItem$Html$Params } from '../fn/order/shop-get-order-item-html';
import { shopGetOrderItem$Json } from '../fn/order/shop-get-order-item-json';
import { ShopGetOrderItem$Json$Params } from '../fn/order/shop-get-order-item-json';
import { shopPostOrderCollection$Html$Html } from '../fn/order/shop-post-order-collection-html-html';
import { ShopPostOrderCollection$Html$Html$Params } from '../fn/order/shop-post-order-collection-html-html';
import { shopPostOrderCollection$Html$Json } from '../fn/order/shop-post-order-collection-html-json';
import { ShopPostOrderCollection$Html$Json$Params } from '../fn/order/shop-post-order-collection-html-json';
import { shopPostOrderCollection$Json$Html } from '../fn/order/shop-post-order-collection-json-html';
import { ShopPostOrderCollection$Json$Html$Params } from '../fn/order/shop-post-order-collection-json-html';
import { shopPostOrderCollection$Json$Json } from '../fn/order/shop-post-order-collection-json-json';
import { ShopPostOrderCollection$Json$Json$Params } from '../fn/order/shop-post-order-collection-json-json';
import { shopPutOrderItem$Html$Html } from '../fn/order/shop-put-order-item-html-html';
import { ShopPutOrderItem$Html$Html$Params } from '../fn/order/shop-put-order-item-html-html';
import { shopPutOrderItem$Html$Json } from '../fn/order/shop-put-order-item-html-json';
import { ShopPutOrderItem$Html$Json$Params } from '../fn/order/shop-put-order-item-html-json';
import { shopPutOrderItem$Json$Html } from '../fn/order/shop-put-order-item-json-html';
import { ShopPutOrderItem$Json$Html$Params } from '../fn/order/shop-put-order-item-json-html';
import { shopPutOrderItem$Json$Json } from '../fn/order/shop-put-order-item-json-json';
import { ShopPutOrderItem$Json$Json$Params } from '../fn/order/shop-put-order-item-json-json';
import { shopRemoveItemOrderItem } from '../fn/order/shop-remove-item-order-item';
import { ShopRemoveItemOrderItem$Params } from '../fn/order/shop-remove-item-order-item';
import { shopSelectPaymentMethodOrderItem$Html } from '../fn/order/shop-select-payment-method-order-item-html';
import { ShopSelectPaymentMethodOrderItem$Html$Params } from '../fn/order/shop-select-payment-method-order-item-html';
import { shopSelectPaymentMethodOrderItem$Json } from '../fn/order/shop-select-payment-method-order-item-json';
import { ShopSelectPaymentMethodOrderItem$Json$Params } from '../fn/order/shop-select-payment-method-order-item-json';
import { shopSelectShippingMethodOrderItem$Html } from '../fn/order/shop-select-shipping-method-order-item-html';
import { ShopSelectShippingMethodOrderItem$Html$Params } from '../fn/order/shop-select-shipping-method-order-item-html';
import { shopSelectShippingMethodOrderItem$Json } from '../fn/order/shop-select-shipping-method-order-item-json';
import { ShopSelectShippingMethodOrderItem$Json$Params } from '../fn/order/shop-select-shipping-method-order-item-json';

@Injectable({ providedIn: 'root' })
export class OrderService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetOrderCollection()` */
  static readonly AdminGetOrderCollectionPath = '/api/v2/admin/orders';

  /**
   * Retrieves the collection of Order resources.
   *
   * Retrieves the collection of Order resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetOrderCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderCollection$Json$Response(params?: AdminGetOrderCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderAdminOrderIndexSyliusAdminOrderIndex>>> {
    return adminGetOrderCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Order resources.
   *
   * Retrieves the collection of Order resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetOrderCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderCollection$Json(params?: AdminGetOrderCollection$Json$Params, context?: HttpContext): Observable<Array<OrderAdminOrderIndexSyliusAdminOrderIndex>> {
    return this.adminGetOrderCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrderAdminOrderIndexSyliusAdminOrderIndex>>): Array<OrderAdminOrderIndexSyliusAdminOrderIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Order resources.
   *
   * Retrieves the collection of Order resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetOrderCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderCollection$Html$Response(params?: AdminGetOrderCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderAdminOrderIndexSyliusAdminOrderIndex>>> {
    return adminGetOrderCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Order resources.
   *
   * Retrieves the collection of Order resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetOrderCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderCollection$Html(params?: AdminGetOrderCollection$Html$Params, context?: HttpContext): Observable<Array<OrderAdminOrderIndexSyliusAdminOrderIndex>> {
    return this.adminGetOrderCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrderAdminOrderIndexSyliusAdminOrderIndex>>): Array<OrderAdminOrderIndexSyliusAdminOrderIndex> => r.body)
    );
  }

  /** Path part for operation `adminGetOrderItem()` */
  static readonly AdminGetOrderItemPath = '/api/v2/admin/orders/{tokenValue}';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetOrderItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderItem$Json$Response(params: AdminGetOrderItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderAdminOrderShowSyliusAdminOrderShow>> {
    return adminGetOrderItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetOrderItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderItem$Json(params: AdminGetOrderItem$Json$Params, context?: HttpContext): Observable<OrderAdminOrderShowSyliusAdminOrderShow> {
    return this.adminGetOrderItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderAdminOrderShowSyliusAdminOrderShow>): OrderAdminOrderShowSyliusAdminOrderShow => r.body)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetOrderItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderItem$Html$Response(params: AdminGetOrderItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderAdminOrderShowSyliusAdminOrderShow>> {
    return adminGetOrderItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetOrderItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOrderItem$Html(params: AdminGetOrderItem$Html$Params, context?: HttpContext): Observable<OrderAdminOrderShowSyliusAdminOrderShow> {
    return this.adminGetOrderItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderAdminOrderShowSyliusAdminOrderShow>): OrderAdminOrderShowSyliusAdminOrderShow => r.body)
    );
  }

  /** Path part for operation `adminAdjustmentsOrderItem()` */
  static readonly AdminAdjustmentsOrderItemPath = '/api/v2/admin/orders/{tokenValue}/adjustments';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminAdjustmentsOrderItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdjustmentsOrderItem$Json$Response(params: AdminAdjustmentsOrderItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderAdminAdjustmentShowSyliusAdminAdjustmentShow>> {
    return adminAdjustmentsOrderItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminAdjustmentsOrderItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdjustmentsOrderItem$Json(params: AdminAdjustmentsOrderItem$Json$Params, context?: HttpContext): Observable<OrderAdminAdjustmentShowSyliusAdminAdjustmentShow> {
    return this.adminAdjustmentsOrderItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderAdminAdjustmentShowSyliusAdminAdjustmentShow>): OrderAdminAdjustmentShowSyliusAdminAdjustmentShow => r.body)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminAdjustmentsOrderItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdjustmentsOrderItem$Html$Response(params: AdminAdjustmentsOrderItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderAdminAdjustmentShowSyliusAdminAdjustmentShow>> {
    return adminAdjustmentsOrderItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminAdjustmentsOrderItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdjustmentsOrderItem$Html(params: AdminAdjustmentsOrderItem$Html$Params, context?: HttpContext): Observable<OrderAdminAdjustmentShowSyliusAdminAdjustmentShow> {
    return this.adminAdjustmentsOrderItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderAdminAdjustmentShowSyliusAdminAdjustmentShow>): OrderAdminAdjustmentShowSyliusAdminAdjustmentShow => r.body)
    );
  }

  /** Path part for operation `adminCancelOrderItem()` */
  static readonly AdminCancelOrderItemPath = '/api/v2/admin/orders/{tokenValue}/cancel';

  /**
   * Cancels Order.
   *
   * Updates the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCancelOrderItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminCancelOrderItem$Json$Response(params: AdminCancelOrderItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderAdminOrderShowSyliusAdminOrderShow>> {
    return adminCancelOrderItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Cancels Order.
   *
   * Updates the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCancelOrderItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminCancelOrderItem$Json(params: AdminCancelOrderItem$Json$Params, context?: HttpContext): Observable<OrderAdminOrderShowSyliusAdminOrderShow> {
    return this.adminCancelOrderItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderAdminOrderShowSyliusAdminOrderShow>): OrderAdminOrderShowSyliusAdminOrderShow => r.body)
    );
  }

  /**
   * Cancels Order.
   *
   * Updates the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCancelOrderItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminCancelOrderItem$Html$Response(params: AdminCancelOrderItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderAdminOrderShowSyliusAdminOrderShow>> {
    return adminCancelOrderItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Cancels Order.
   *
   * Updates the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCancelOrderItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminCancelOrderItem$Html(params: AdminCancelOrderItem$Html$Params, context?: HttpContext): Observable<OrderAdminOrderShowSyliusAdminOrderShow> {
    return this.adminCancelOrderItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderAdminOrderShowSyliusAdminOrderShow>): OrderAdminOrderShowSyliusAdminOrderShow => r.body)
    );
  }

  /** Path part for operation `apiOrdersPaymentsGetSubresourceOrderSubresource()` */
  static readonly ApiOrdersPaymentsGetSubresourceOrderSubresourcePath = '/api/v2/admin/orders/{tokenValue}/payments';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersPaymentsGetSubresourceOrderSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersPaymentsGetSubresourceOrderSubresource$Json$Response(params: ApiOrdersPaymentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PaymentAdminPaymentShowSyliusAdminPaymentShow>>> {
    return apiOrdersPaymentsGetSubresourceOrderSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersPaymentsGetSubresourceOrderSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersPaymentsGetSubresourceOrderSubresource$Json(params: ApiOrdersPaymentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<Array<PaymentAdminPaymentShowSyliusAdminPaymentShow>> {
    return this.apiOrdersPaymentsGetSubresourceOrderSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentAdminPaymentShowSyliusAdminPaymentShow>>): Array<PaymentAdminPaymentShowSyliusAdminPaymentShow> => r.body)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersPaymentsGetSubresourceOrderSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersPaymentsGetSubresourceOrderSubresource$Html$Response(params: ApiOrdersPaymentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PaymentAdminPaymentShowSyliusAdminPaymentShow>>> {
    return apiOrdersPaymentsGetSubresourceOrderSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersPaymentsGetSubresourceOrderSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersPaymentsGetSubresourceOrderSubresource$Html(params: ApiOrdersPaymentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<Array<PaymentAdminPaymentShowSyliusAdminPaymentShow>> {
    return this.apiOrdersPaymentsGetSubresourceOrderSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentAdminPaymentShowSyliusAdminPaymentShow>>): Array<PaymentAdminPaymentShowSyliusAdminPaymentShow> => r.body)
    );
  }

  /** Path part for operation `adminResendConfirmationOrderItem()` */
  static readonly AdminResendConfirmationOrderItemPath = '/api/v2/admin/orders/{tokenValue}/resend-confirmation-email';

  /**
   * Resends order confirmation email.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminResendConfirmationOrderItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminResendConfirmationOrderItem$Json$Json$Response(params: AdminResendConfirmationOrderItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminResendConfirmationOrderItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Resends order confirmation email.
   *
   * Creates a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminResendConfirmationOrderItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminResendConfirmationOrderItem$Json$Json(params: AdminResendConfirmationOrderItem$Json$Json$Params, context?: HttpContext): Observable<any> {
    return this.adminResendConfirmationOrderItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Resends order confirmation email.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminResendConfirmationOrderItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminResendConfirmationOrderItem$Json$Html$Response(params: AdminResendConfirmationOrderItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminResendConfirmationOrderItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Resends order confirmation email.
   *
   * Creates a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminResendConfirmationOrderItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminResendConfirmationOrderItem$Json$Html(params: AdminResendConfirmationOrderItem$Json$Html$Params, context?: HttpContext): Observable<any> {
    return this.adminResendConfirmationOrderItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Resends order confirmation email.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminResendConfirmationOrderItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminResendConfirmationOrderItem$Html$Json$Response(params: AdminResendConfirmationOrderItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminResendConfirmationOrderItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Resends order confirmation email.
   *
   * Creates a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminResendConfirmationOrderItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminResendConfirmationOrderItem$Html$Json(params: AdminResendConfirmationOrderItem$Html$Json$Params, context?: HttpContext): Observable<any> {
    return this.adminResendConfirmationOrderItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Resends order confirmation email.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminResendConfirmationOrderItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminResendConfirmationOrderItem$Html$Html$Response(params: AdminResendConfirmationOrderItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminResendConfirmationOrderItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Resends order confirmation email.
   *
   * Creates a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminResendConfirmationOrderItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminResendConfirmationOrderItem$Html$Html(params: AdminResendConfirmationOrderItem$Html$Html$Params, context?: HttpContext): Observable<any> {
    return this.adminResendConfirmationOrderItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `apiOrdersShipmentsGetSubresourceOrderSubresource()` */
  static readonly ApiOrdersShipmentsGetSubresourceOrderSubresourcePath = '/api/v2/admin/orders/{tokenValue}/shipments';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersShipmentsGetSubresourceOrderSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsGetSubresourceOrderSubresource$Json$Response(params: ApiOrdersShipmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow>>> {
    return apiOrdersShipmentsGetSubresourceOrderSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersShipmentsGetSubresourceOrderSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsGetSubresourceOrderSubresource$Json(params: ApiOrdersShipmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow>> {
    return this.apiOrdersShipmentsGetSubresourceOrderSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow>>): Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow> => r.body)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersShipmentsGetSubresourceOrderSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsGetSubresourceOrderSubresource$Html$Response(params: ApiOrdersShipmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow>>> {
    return apiOrdersShipmentsGetSubresourceOrderSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersShipmentsGetSubresourceOrderSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsGetSubresourceOrderSubresource$Html(params: ApiOrdersShipmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow>> {
    return this.apiOrdersShipmentsGetSubresourceOrderSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow>>): Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow> => r.body)
    );
  }

  /** Path part for operation `shopAccountChangePaymentMethodOrderItem()` */
  static readonly ShopAccountChangePaymentMethodOrderItemPath = '/api/v2/shop/account/orders/{tokenValue}/payments/{paymentId}';

  /**
   * Change the payment method as logged shop user.
   *
   * Updates the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopAccountChangePaymentMethodOrderItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopAccountChangePaymentMethodOrderItem$Json$Response(params: ShopAccountChangePaymentMethodOrderItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderAccountShowSyliusShopOrderAccountShow>> {
    return shopAccountChangePaymentMethodOrderItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Change the payment method as logged shop user.
   *
   * Updates the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopAccountChangePaymentMethodOrderItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopAccountChangePaymentMethodOrderItem$Json(params: ShopAccountChangePaymentMethodOrderItem$Json$Params, context?: HttpContext): Observable<OrderShopOrderAccountShowSyliusShopOrderAccountShow> {
    return this.shopAccountChangePaymentMethodOrderItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderAccountShowSyliusShopOrderAccountShow>): OrderShopOrderAccountShowSyliusShopOrderAccountShow => r.body)
    );
  }

  /**
   * Change the payment method as logged shop user.
   *
   * Updates the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopAccountChangePaymentMethodOrderItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopAccountChangePaymentMethodOrderItem$Html$Response(params: ShopAccountChangePaymentMethodOrderItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderAccountShowSyliusShopOrderAccountShow>> {
    return shopAccountChangePaymentMethodOrderItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Change the payment method as logged shop user.
   *
   * Updates the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopAccountChangePaymentMethodOrderItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopAccountChangePaymentMethodOrderItem$Html(params: ShopAccountChangePaymentMethodOrderItem$Html$Params, context?: HttpContext): Observable<OrderShopOrderAccountShowSyliusShopOrderAccountShow> {
    return this.shopAccountChangePaymentMethodOrderItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderAccountShowSyliusShopOrderAccountShow>): OrderShopOrderAccountShowSyliusShopOrderAccountShow => r.body)
    );
  }

  /** Path part for operation `shopGetOrderCollection()` */
  static readonly ShopGetOrderCollectionPath = '/api/v2/shop/orders';

  /**
   * Retrieves the collection of Order resources.
   *
   * Retrieves the collection of Order resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetOrderCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderCollection$Json$Response(params?: ShopGetOrderCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderShopOrderIndexSyliusShopOrderIndex>>> {
    return shopGetOrderCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Order resources.
   *
   * Retrieves the collection of Order resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetOrderCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderCollection$Json(params?: ShopGetOrderCollection$Json$Params, context?: HttpContext): Observable<Array<OrderShopOrderIndexSyliusShopOrderIndex>> {
    return this.shopGetOrderCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrderShopOrderIndexSyliusShopOrderIndex>>): Array<OrderShopOrderIndexSyliusShopOrderIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Order resources.
   *
   * Retrieves the collection of Order resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetOrderCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderCollection$Html$Response(params?: ShopGetOrderCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderShopOrderIndexSyliusShopOrderIndex>>> {
    return shopGetOrderCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Order resources.
   *
   * Retrieves the collection of Order resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetOrderCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderCollection$Html(params?: ShopGetOrderCollection$Html$Params, context?: HttpContext): Observable<Array<OrderShopOrderIndexSyliusShopOrderIndex>> {
    return this.shopGetOrderCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrderShopOrderIndexSyliusShopOrderIndex>>): Array<OrderShopOrderIndexSyliusShopOrderIndex> => r.body)
    );
  }

  /** Path part for operation `shopPostOrderCollection()` */
  static readonly ShopPostOrderCollectionPath = '/api/v2/shop/orders';

  /**
   * Pickups a new cart.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostOrderCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostOrderCollection$Json$Json$Response(params: ShopPostOrderCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>> {
    return shopPostOrderCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Pickups a new cart.
   *
   * Creates a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostOrderCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostOrderCollection$Json$Json(params: ShopPostOrderCollection$Json$Json$Params, context?: HttpContext): Observable<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow> {
    return this.shopPostOrderCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>): OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /**
   * Pickups a new cart.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostOrderCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostOrderCollection$Json$Html$Response(params: ShopPostOrderCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>> {
    return shopPostOrderCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Pickups a new cart.
   *
   * Creates a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostOrderCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPostOrderCollection$Json$Html(params: ShopPostOrderCollection$Json$Html$Params, context?: HttpContext): Observable<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow> {
    return this.shopPostOrderCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>): OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /**
   * Pickups a new cart.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostOrderCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostOrderCollection$Html$Json$Response(params: ShopPostOrderCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>> {
    return shopPostOrderCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Pickups a new cart.
   *
   * Creates a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostOrderCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostOrderCollection$Html$Json(params: ShopPostOrderCollection$Html$Json$Params, context?: HttpContext): Observable<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow> {
    return this.shopPostOrderCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>): OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /**
   * Pickups a new cart.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPostOrderCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostOrderCollection$Html$Html$Response(params: ShopPostOrderCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>> {
    return shopPostOrderCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Pickups a new cart.
   *
   * Creates a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPostOrderCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPostOrderCollection$Html$Html(params: ShopPostOrderCollection$Html$Html$Params, context?: HttpContext): Observable<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow> {
    return this.shopPostOrderCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>): OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /** Path part for operation `shopGetOrderItem()` */
  static readonly ShopGetOrderItemPath = '/api/v2/shop/orders/{tokenValue}';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetOrderItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderItem$Json$Response(params: ShopGetOrderItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>> {
    return shopGetOrderItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetOrderItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderItem$Json(params: ShopGetOrderItem$Json$Params, context?: HttpContext): Observable<OrderShopCartShowSyliusShopCartShow> {
    return this.shopGetOrderItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>): OrderShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetOrderItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderItem$Html$Response(params: ShopGetOrderItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>> {
    return shopGetOrderItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetOrderItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetOrderItem$Html(params: ShopGetOrderItem$Html$Params, context?: HttpContext): Observable<OrderShopCartShowSyliusShopCartShow> {
    return this.shopGetOrderItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>): OrderShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /** Path part for operation `shopPutOrderItem()` */
  static readonly ShopPutOrderItemPath = '/api/v2/shop/orders/{tokenValue}';

  /**
   * Addresses cart to given location, logged in Customer does not have to provide an email. Applies coupon to cart.
   *
   * Replaces the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPutOrderItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPutOrderItem$Json$Json$Response(params: ShopPutOrderItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>> {
    return shopPutOrderItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Addresses cart to given location, logged in Customer does not have to provide an email. Applies coupon to cart.
   *
   * Replaces the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPutOrderItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPutOrderItem$Json$Json(params: ShopPutOrderItem$Json$Json$Params, context?: HttpContext): Observable<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow> {
    return this.shopPutOrderItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>): OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /**
   * Addresses cart to given location, logged in Customer does not have to provide an email. Applies coupon to cart.
   *
   * Replaces the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPutOrderItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPutOrderItem$Json$Html$Response(params: ShopPutOrderItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>> {
    return shopPutOrderItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Addresses cart to given location, logged in Customer does not have to provide an email. Applies coupon to cart.
   *
   * Replaces the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPutOrderItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopPutOrderItem$Json$Html(params: ShopPutOrderItem$Json$Html$Params, context?: HttpContext): Observable<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow> {
    return this.shopPutOrderItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>): OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /**
   * Addresses cart to given location, logged in Customer does not have to provide an email. Applies coupon to cart.
   *
   * Replaces the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPutOrderItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPutOrderItem$Html$Json$Response(params: ShopPutOrderItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>> {
    return shopPutOrderItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Addresses cart to given location, logged in Customer does not have to provide an email. Applies coupon to cart.
   *
   * Replaces the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPutOrderItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPutOrderItem$Html$Json(params: ShopPutOrderItem$Html$Json$Params, context?: HttpContext): Observable<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow> {
    return this.shopPutOrderItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>): OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /**
   * Addresses cart to given location, logged in Customer does not have to provide an email. Applies coupon to cart.
   *
   * Replaces the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopPutOrderItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPutOrderItem$Html$Html$Response(params: ShopPutOrderItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>> {
    return shopPutOrderItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Addresses cart to given location, logged in Customer does not have to provide an email. Applies coupon to cart.
   *
   * Replaces the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopPutOrderItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopPutOrderItem$Html$Html(params: ShopPutOrderItem$Html$Html$Params, context?: HttpContext): Observable<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow> {
    return this.shopPutOrderItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>): OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /** Path part for operation `shopDeleteOrderItem()` */
  static readonly ShopDeleteOrderItemPath = '/api/v2/shop/orders/{tokenValue}';

  /**
   * Deletes cart.
   *
   * Removes the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopDeleteOrderItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopDeleteOrderItem$Response(params: ShopDeleteOrderItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return shopDeleteOrderItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes cart.
   *
   * Removes the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopDeleteOrderItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopDeleteOrderItem(params: ShopDeleteOrderItem$Params, context?: HttpContext): Observable<void> {
    return this.shopDeleteOrderItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiOrdersAdjustmentsGetSubresourceOrderSubresource()` */
  static readonly ApiOrdersAdjustmentsGetSubresourceOrderSubresourcePath = '/api/v2/shop/orders/{tokenValue}/adjustments';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersAdjustmentsGetSubresourceOrderSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersAdjustmentsGetSubresourceOrderSubresource$Json$Response(params: ApiOrdersAdjustmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>> {
    return apiOrdersAdjustmentsGetSubresourceOrderSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersAdjustmentsGetSubresourceOrderSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersAdjustmentsGetSubresourceOrderSubresource$Json(params: ApiOrdersAdjustmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<Array<AdjustmentShopCartShowSyliusShopCartShow>> {
    return this.apiOrdersAdjustmentsGetSubresourceOrderSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>): Array<AdjustmentShopCartShowSyliusShopCartShow> => r.body)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersAdjustmentsGetSubresourceOrderSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersAdjustmentsGetSubresourceOrderSubresource$Html$Response(params: ApiOrdersAdjustmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>> {
    return apiOrdersAdjustmentsGetSubresourceOrderSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersAdjustmentsGetSubresourceOrderSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersAdjustmentsGetSubresourceOrderSubresource$Html(params: ApiOrdersAdjustmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<Array<AdjustmentShopCartShowSyliusShopCartShow>> {
    return this.apiOrdersAdjustmentsGetSubresourceOrderSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentShopCartShowSyliusShopCartShow>>): Array<AdjustmentShopCartShowSyliusShopCartShow> => r.body)
    );
  }

  /** Path part for operation `shopCompleteOrderItem()` */
  static readonly ShopCompleteOrderItemPath = '/api/v2/shop/orders/{tokenValue}/complete';

  /**
   * Completes checkout.
   *
   * Updates the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopCompleteOrderItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopCompleteOrderItem$Json$Response(params: ShopCompleteOrderItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>> {
    return shopCompleteOrderItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Completes checkout.
   *
   * Updates the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopCompleteOrderItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopCompleteOrderItem$Json(params: ShopCompleteOrderItem$Json$Params, context?: HttpContext): Observable<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow> {
    return this.shopCompleteOrderItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>): OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /**
   * Completes checkout.
   *
   * Updates the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopCompleteOrderItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopCompleteOrderItem$Html$Response(params: ShopCompleteOrderItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>> {
    return shopCompleteOrderItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Completes checkout.
   *
   * Updates the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopCompleteOrderItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopCompleteOrderItem$Html(params: ShopCompleteOrderItem$Html$Params, context?: HttpContext): Observable<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow> {
    return this.shopCompleteOrderItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>): OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /** Path part for operation `apiOrdersItemsGetSubresourceOrderSubresource()` */
  static readonly ApiOrdersItemsGetSubresourceOrderSubresourcePath = '/api/v2/shop/orders/{tokenValue}/items';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersItemsGetSubresourceOrderSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsGetSubresourceOrderSubresource$Json$Response(params: ApiOrdersItemsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderItemShopCartShowSyliusShopCartShow>>> {
    return apiOrdersItemsGetSubresourceOrderSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersItemsGetSubresourceOrderSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsGetSubresourceOrderSubresource$Json(params: ApiOrdersItemsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<Array<OrderItemShopCartShowSyliusShopCartShow>> {
    return this.apiOrdersItemsGetSubresourceOrderSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrderItemShopCartShowSyliusShopCartShow>>): Array<OrderItemShopCartShowSyliusShopCartShow> => r.body)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersItemsGetSubresourceOrderSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsGetSubresourceOrderSubresource$Html$Response(params: ApiOrdersItemsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrderItemShopCartShowSyliusShopCartShow>>> {
    return apiOrdersItemsGetSubresourceOrderSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersItemsGetSubresourceOrderSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersItemsGetSubresourceOrderSubresource$Html(params: ApiOrdersItemsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<Array<OrderItemShopCartShowSyliusShopCartShow>> {
    return this.apiOrdersItemsGetSubresourceOrderSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrderItemShopCartShowSyliusShopCartShow>>): Array<OrderItemShopCartShowSyliusShopCartShow> => r.body)
    );
  }

  /** Path part for operation `shopAddItemOrderItem()` */
  static readonly ShopAddItemOrderItemPath = '/api/v2/shop/orders/{tokenValue}/items';

  /**
   * Adds Item to cart.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopAddItemOrderItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopAddItemOrderItem$Json$Json$Response(params: ShopAddItemOrderItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>> {
    return shopAddItemOrderItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Adds Item to cart.
   *
   * Creates a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopAddItemOrderItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopAddItemOrderItem$Json$Json(params: ShopAddItemOrderItem$Json$Json$Params, context?: HttpContext): Observable<OrderShopCartShowSyliusShopCartShow> {
    return this.shopAddItemOrderItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>): OrderShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /**
   * Adds Item to cart.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopAddItemOrderItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopAddItemOrderItem$Json$Html$Response(params: ShopAddItemOrderItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>> {
    return shopAddItemOrderItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Adds Item to cart.
   *
   * Creates a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopAddItemOrderItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopAddItemOrderItem$Json$Html(params: ShopAddItemOrderItem$Json$Html$Params, context?: HttpContext): Observable<OrderShopCartShowSyliusShopCartShow> {
    return this.shopAddItemOrderItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>): OrderShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /**
   * Adds Item to cart.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopAddItemOrderItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopAddItemOrderItem$Html$Json$Response(params: ShopAddItemOrderItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>> {
    return shopAddItemOrderItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Adds Item to cart.
   *
   * Creates a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopAddItemOrderItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopAddItemOrderItem$Html$Json(params: ShopAddItemOrderItem$Html$Json$Params, context?: HttpContext): Observable<OrderShopCartShowSyliusShopCartShow> {
    return this.shopAddItemOrderItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>): OrderShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /**
   * Adds Item to cart.
   *
   * Creates a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopAddItemOrderItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopAddItemOrderItem$Html$Html$Response(params: ShopAddItemOrderItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>> {
    return shopAddItemOrderItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Adds Item to cart.
   *
   * Creates a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopAddItemOrderItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopAddItemOrderItem$Html$Html(params: ShopAddItemOrderItem$Html$Html$Params, context?: HttpContext): Observable<OrderShopCartShowSyliusShopCartShow> {
    return this.shopAddItemOrderItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>): OrderShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /** Path part for operation `shopRemoveItemOrderItem()` */
  static readonly ShopRemoveItemOrderItemPath = '/api/v2/shop/orders/{tokenValue}/items/{itemId}';

  /**
   * Removes the Order resource.
   *
   * Removes the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopRemoveItemOrderItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopRemoveItemOrderItem$Response(params: ShopRemoveItemOrderItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return shopRemoveItemOrderItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Order resource.
   *
   * Removes the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopRemoveItemOrderItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopRemoveItemOrderItem(params: ShopRemoveItemOrderItem$Params, context?: HttpContext): Observable<void> {
    return this.shopRemoveItemOrderItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `shopChangeQuantityOrderItem()` */
  static readonly ShopChangeQuantityOrderItemPath = '/api/v2/shop/orders/{tokenValue}/items/{orderItemId}';

  /**
   * Changes quantity of order item.
   *
   * Updates the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopChangeQuantityOrderItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopChangeQuantityOrderItem$Json$Response(params: ShopChangeQuantityOrderItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>> {
    return shopChangeQuantityOrderItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Changes quantity of order item.
   *
   * Updates the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopChangeQuantityOrderItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopChangeQuantityOrderItem$Json(params: ShopChangeQuantityOrderItem$Json$Params, context?: HttpContext): Observable<OrderShopCartShowSyliusShopCartShow> {
    return this.shopChangeQuantityOrderItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>): OrderShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /**
   * Changes quantity of order item.
   *
   * Updates the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopChangeQuantityOrderItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopChangeQuantityOrderItem$Html$Response(params: ShopChangeQuantityOrderItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>> {
    return shopChangeQuantityOrderItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Changes quantity of order item.
   *
   * Updates the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopChangeQuantityOrderItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopChangeQuantityOrderItem$Html(params: ShopChangeQuantityOrderItem$Html$Params, context?: HttpContext): Observable<OrderShopCartShowSyliusShopCartShow> {
    return this.shopChangeQuantityOrderItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopCartShowSyliusShopCartShow>): OrderShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /** Path part for operation `shopSelectPaymentMethodOrderItem()` */
  static readonly ShopSelectPaymentMethodOrderItemPath = '/api/v2/shop/orders/{tokenValue}/payments/{paymentId}';

  /**
   * Selects payment methods for particular payment.
   *
   * Updates the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopSelectPaymentMethodOrderItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopSelectPaymentMethodOrderItem$Json$Response(params: ShopSelectPaymentMethodOrderItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>> {
    return shopSelectPaymentMethodOrderItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Selects payment methods for particular payment.
   *
   * Updates the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopSelectPaymentMethodOrderItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopSelectPaymentMethodOrderItem$Json(params: ShopSelectPaymentMethodOrderItem$Json$Params, context?: HttpContext): Observable<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow> {
    return this.shopSelectPaymentMethodOrderItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>): OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /**
   * Selects payment methods for particular payment.
   *
   * Updates the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopSelectPaymentMethodOrderItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopSelectPaymentMethodOrderItem$Html$Response(params: ShopSelectPaymentMethodOrderItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>> {
    return shopSelectPaymentMethodOrderItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Selects payment methods for particular payment.
   *
   * Updates the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopSelectPaymentMethodOrderItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopSelectPaymentMethodOrderItem$Html(params: ShopSelectPaymentMethodOrderItem$Html$Params, context?: HttpContext): Observable<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow> {
    return this.shopSelectPaymentMethodOrderItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>): OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /** Path part for operation `getConfigurationOrderItem()` */
  static readonly GetConfigurationOrderItemPath = '/api/v2/shop/orders/{tokenValue}/payments/{paymentId}/configuration';

  /**
   * Retrieve payment method configuration.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConfigurationOrderItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfigurationOrderItem$Json$Response(params: GetConfigurationOrderItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return getConfigurationOrderItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve payment method configuration.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConfigurationOrderItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfigurationOrderItem$Json(params: GetConfigurationOrderItem$Json$Params, context?: HttpContext): Observable<Order> {
    return this.getConfigurationOrderItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

  /**
   * Retrieve payment method configuration.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConfigurationOrderItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfigurationOrderItem$Html$Response(params: GetConfigurationOrderItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return getConfigurationOrderItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve payment method configuration.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConfigurationOrderItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfigurationOrderItem$Html(params: GetConfigurationOrderItem$Html$Params, context?: HttpContext): Observable<Order> {
    return this.getConfigurationOrderItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }

  /** Path part for operation `shopSelectShippingMethodOrderItem()` */
  static readonly ShopSelectShippingMethodOrderItemPath = '/api/v2/shop/orders/{tokenValue}/shipments/{shipmentId}';

  /**
   * Selects shipping methods for particular shipment.
   *
   * Updates the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopSelectShippingMethodOrderItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopSelectShippingMethodOrderItem$Json$Response(params: ShopSelectShippingMethodOrderItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>> {
    return shopSelectShippingMethodOrderItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Selects shipping methods for particular shipment.
   *
   * Updates the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopSelectShippingMethodOrderItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopSelectShippingMethodOrderItem$Json(params: ShopSelectShippingMethodOrderItem$Json$Params, context?: HttpContext): Observable<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow> {
    return this.shopSelectShippingMethodOrderItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>): OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow => r.body)
    );
  }

  /**
   * Selects shipping methods for particular shipment.
   *
   * Updates the Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopSelectShippingMethodOrderItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopSelectShippingMethodOrderItem$Html$Response(params: ShopSelectShippingMethodOrderItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>> {
    return shopSelectShippingMethodOrderItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Selects shipping methods for particular shipment.
   *
   * Updates the Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopSelectShippingMethodOrderItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  shopSelectShippingMethodOrderItem$Html(params: ShopSelectShippingMethodOrderItem$Html$Params, context?: HttpContext): Observable<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow> {
    return this.shopSelectShippingMethodOrderItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow>): OrderShopOrderShowSyliusShopOrderShowShopCartShowSyliusShopCartShow => r.body)
    );
  }

}

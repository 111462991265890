/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Index as ChannelPricingLogEntryAdminChannelPricingLogEntryIndexSyliusAdminChannelPricingLogEntryIndex } from '../../models/ChannelPricingLogEntry-admin/channel_pricing_log_entry/index_sylius/admin/channel_pricing_log_entry/index';

export interface AdminGetChannelPricingLogEntryCollection$Json$Params {

/**
 * The collection page number
 */
  page?: number;

/**
 * The number of items per page
 */
  itemsPerPage?: number;
  'channelPricing.channelCode'?: string;
  'channelPricing.channelCode[]'?: Array<string>;
  'channelPricing.productVariant.code'?: string;
  'channelPricing.productVariant.code[]'?: Array<string>;

/**
 * Locales in this enum are all locales defined in the shop and only enabled ones will work in the given channel in the shop.
 */
  'Accept-Language'?: 'hu_HU';
}

export function adminGetChannelPricingLogEntryCollection$Json(http: HttpClient, rootUrl: string, params?: AdminGetChannelPricingLogEntryCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChannelPricingLogEntryAdminChannelPricingLogEntryIndexSyliusAdminChannelPricingLogEntryIndex>>> {
  const rb = new RequestBuilder(rootUrl, adminGetChannelPricingLogEntryCollection$Json.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
    rb.query('channelPricing.channelCode', params['channelPricing.channelCode'], {"style":"form","explode":false});
    rb.query('channelPricing.channelCode[]', params['channelPricing.channelCode[]'], {"style":"form","explode":true});
    rb.query('channelPricing.productVariant.code', params['channelPricing.productVariant.code'], {"style":"form","explode":false});
    rb.query('channelPricing.productVariant.code[]', params['channelPricing.productVariant.code[]'], {"style":"form","explode":true});
    rb.header('Accept-Language', params['Accept-Language'], {"style":"simple","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ChannelPricingLogEntryAdminChannelPricingLogEntryIndexSyliusAdminChannelPricingLogEntryIndex>>;
    })
  );
}

adminGetChannelPricingLogEntryCollection$Json.PATH = '/api/v2/admin/channel-pricing-log-entries';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetShopBillingDataItem$Html } from '../fn/shop-billing-data/admin-get-shop-billing-data-item-html';
import { AdminGetShopBillingDataItem$Html$Params } from '../fn/shop-billing-data/admin-get-shop-billing-data-item-html';
import { adminGetShopBillingDataItem$Json } from '../fn/shop-billing-data/admin-get-shop-billing-data-item-json';
import { AdminGetShopBillingDataItem$Json$Params } from '../fn/shop-billing-data/admin-get-shop-billing-data-item-json';
import { apiChannelsShopBillingDataGetSubresourceChannelSubresource$Html } from '../fn/shop-billing-data/api-channels-shop-billing-data-get-subresource-channel-subresource-html';
import { ApiChannelsShopBillingDataGetSubresourceChannelSubresource$Html$Params } from '../fn/shop-billing-data/api-channels-shop-billing-data-get-subresource-channel-subresource-html';
import { apiChannelsShopBillingDataGetSubresourceChannelSubresource$Json } from '../fn/shop-billing-data/api-channels-shop-billing-data-get-subresource-channel-subresource-json';
import { ApiChannelsShopBillingDataGetSubresourceChannelSubresource$Json$Params } from '../fn/shop-billing-data/api-channels-shop-billing-data-get-subresource-channel-subresource-json';
import { Show as ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow } from '../models/ShopBillingData-admin/shop_billing_data/read_sylius/admin/shop_billing_data/show';
import { Show as ShopBillingDataAdminShopBillingDataShowSyliusAdminShopBillingDataShow } from '../models/ShopBillingData-admin/shop_billing_data/show_sylius/admin/shop_billing_data/show';

@Injectable({ providedIn: 'root' })
export class ShopBillingDataService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiChannelsShopBillingDataGetSubresourceChannelSubresource()` */
  static readonly ApiChannelsShopBillingDataGetSubresourceChannelSubresourcePath = '/api/v2/admin/channels/{code}/shop-billing-data';

  /**
   * Retrieves a Channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChannelsShopBillingDataGetSubresourceChannelSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChannelsShopBillingDataGetSubresourceChannelSubresource$Json$Response(params: ApiChannelsShopBillingDataGetSubresourceChannelSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow>> {
    return apiChannelsShopBillingDataGetSubresourceChannelSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiChannelsShopBillingDataGetSubresourceChannelSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChannelsShopBillingDataGetSubresourceChannelSubresource$Json(params: ApiChannelsShopBillingDataGetSubresourceChannelSubresource$Json$Params, context?: HttpContext): Observable<ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow> {
    return this.apiChannelsShopBillingDataGetSubresourceChannelSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow>): ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow => r.body)
    );
  }

  /**
   * Retrieves a Channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiChannelsShopBillingDataGetSubresourceChannelSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChannelsShopBillingDataGetSubresourceChannelSubresource$Html$Response(params: ApiChannelsShopBillingDataGetSubresourceChannelSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow>> {
    return apiChannelsShopBillingDataGetSubresourceChannelSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Channel resource.
   *
   * Retrieves a Channel resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiChannelsShopBillingDataGetSubresourceChannelSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiChannelsShopBillingDataGetSubresourceChannelSubresource$Html(params: ApiChannelsShopBillingDataGetSubresourceChannelSubresource$Html$Params, context?: HttpContext): Observable<ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow> {
    return this.apiChannelsShopBillingDataGetSubresourceChannelSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow>): ShopBillingDataAdminShopBillingDataReadSyliusAdminShopBillingDataShow => r.body)
    );
  }

  /** Path part for operation `adminGetShopBillingDataItem()` */
  static readonly AdminGetShopBillingDataItemPath = '/api/v2/admin/shop-billing-datas/{id}';

  /**
   * Retrieves a ShopBillingData resource.
   *
   * Retrieves a ShopBillingData resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShopBillingDataItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShopBillingDataItem$Json$Response(params: AdminGetShopBillingDataItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShopBillingDataAdminShopBillingDataShowSyliusAdminShopBillingDataShow>> {
    return adminGetShopBillingDataItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ShopBillingData resource.
   *
   * Retrieves a ShopBillingData resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShopBillingDataItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShopBillingDataItem$Json(params: AdminGetShopBillingDataItem$Json$Params, context?: HttpContext): Observable<ShopBillingDataAdminShopBillingDataShowSyliusAdminShopBillingDataShow> {
    return this.adminGetShopBillingDataItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShopBillingDataAdminShopBillingDataShowSyliusAdminShopBillingDataShow>): ShopBillingDataAdminShopBillingDataShowSyliusAdminShopBillingDataShow => r.body)
    );
  }

  /**
   * Retrieves a ShopBillingData resource.
   *
   * Retrieves a ShopBillingData resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShopBillingDataItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShopBillingDataItem$Html$Response(params: AdminGetShopBillingDataItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShopBillingDataAdminShopBillingDataShowSyliusAdminShopBillingDataShow>> {
    return adminGetShopBillingDataItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ShopBillingData resource.
   *
   * Retrieves a ShopBillingData resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShopBillingDataItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShopBillingDataItem$Html(params: AdminGetShopBillingDataItem$Html$Params, context?: HttpContext): Observable<ShopBillingDataAdminShopBillingDataShowSyliusAdminShopBillingDataShow> {
    return this.adminGetShopBillingDataItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShopBillingDataAdminShopBillingDataShowSyliusAdminShopBillingDataShow>): ShopBillingDataAdminShopBillingDataShowSyliusAdminShopBillingDataShow => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetPromotionTranslationItem$Html } from '../fn/promotion-translation/admin-get-promotion-translation-item-html';
import { AdminGetPromotionTranslationItem$Html$Params } from '../fn/promotion-translation/admin-get-promotion-translation-item-html';
import { adminGetPromotionTranslationItem$Json } from '../fn/promotion-translation/admin-get-promotion-translation-item-json';
import { AdminGetPromotionTranslationItem$Json$Params } from '../fn/promotion-translation/admin-get-promotion-translation-item-json';
import { PromotionTranslation } from '../models/promotion-translation';

@Injectable({ providedIn: 'root' })
export class PromotionTranslationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetPromotionTranslationItem()` */
  static readonly AdminGetPromotionTranslationItemPath = '/api/v2/admin/promotion-translations/{id}';

  /**
   * Retrieves a PromotionTranslation resource.
   *
   * Retrieves a PromotionTranslation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPromotionTranslationItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionTranslationItem$Json$Response(params: AdminGetPromotionTranslationItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionTranslation>> {
    return adminGetPromotionTranslationItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a PromotionTranslation resource.
   *
   * Retrieves a PromotionTranslation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPromotionTranslationItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionTranslationItem$Json(params: AdminGetPromotionTranslationItem$Json$Params, context?: HttpContext): Observable<PromotionTranslation> {
    return this.adminGetPromotionTranslationItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionTranslation>): PromotionTranslation => r.body)
    );
  }

  /**
   * Retrieves a PromotionTranslation resource.
   *
   * Retrieves a PromotionTranslation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPromotionTranslationItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionTranslationItem$Html$Response(params: AdminGetPromotionTranslationItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionTranslation>> {
    return adminGetPromotionTranslationItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a PromotionTranslation resource.
   *
   * Retrieves a PromotionTranslation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPromotionTranslationItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionTranslationItem$Html(params: AdminGetPromotionTranslationItem$Html$Params, context?: HttpContext): Observable<PromotionTranslation> {
    return this.adminGetPromotionTranslationItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionTranslation>): PromotionTranslation => r.body)
    );
  }

}

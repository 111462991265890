/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Adjustment } from '../models/adjustment';
import { Index as AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex } from '../models/Adjustment-admin/shipment/index_sylius/admin/shipment/index';
import { adminGetShipmentCollection$Html } from '../fn/shipment/admin-get-shipment-collection-html';
import { AdminGetShipmentCollection$Html$Params } from '../fn/shipment/admin-get-shipment-collection-html';
import { adminGetShipmentCollection$Json } from '../fn/shipment/admin-get-shipment-collection-json';
import { AdminGetShipmentCollection$Json$Params } from '../fn/shipment/admin-get-shipment-collection-json';
import { adminGetShipmentItem$Html } from '../fn/shipment/admin-get-shipment-item-html';
import { AdminGetShipmentItem$Html$Params } from '../fn/shipment/admin-get-shipment-item-html';
import { adminGetShipmentItem$Json } from '../fn/shipment/admin-get-shipment-item-json';
import { AdminGetShipmentItem$Json$Params } from '../fn/shipment/admin-get-shipment-item-json';
import { adminResendConfirmationShipmentItem$Html$Html } from '../fn/shipment/admin-resend-confirmation-shipment-item-html-html';
import { AdminResendConfirmationShipmentItem$Html$Html$Params } from '../fn/shipment/admin-resend-confirmation-shipment-item-html-html';
import { adminResendConfirmationShipmentItem$Html$Json } from '../fn/shipment/admin-resend-confirmation-shipment-item-html-json';
import { AdminResendConfirmationShipmentItem$Html$Json$Params } from '../fn/shipment/admin-resend-confirmation-shipment-item-html-json';
import { adminResendConfirmationShipmentItem$Json$Html } from '../fn/shipment/admin-resend-confirmation-shipment-item-json-html';
import { AdminResendConfirmationShipmentItem$Json$Html$Params } from '../fn/shipment/admin-resend-confirmation-shipment-item-json-html';
import { adminResendConfirmationShipmentItem$Json$Json } from '../fn/shipment/admin-resend-confirmation-shipment-item-json-json';
import { AdminResendConfirmationShipmentItem$Json$Json$Params } from '../fn/shipment/admin-resend-confirmation-shipment-item-json-json';
import { adminShipShipmentItem$Html } from '../fn/shipment/admin-ship-shipment-item-html';
import { AdminShipShipmentItem$Html$Params } from '../fn/shipment/admin-ship-shipment-item-html';
import { adminShipShipmentItem$Json } from '../fn/shipment/admin-ship-shipment-item-json';
import { AdminShipShipmentItem$Json$Params } from '../fn/shipment/admin-ship-shipment-item-json';
import { apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html } from '../fn/adjustment/api-orders-shipments-adjustments-get-subresource-order-subresource-html';
import { ApiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html$Params } from '../fn/adjustment/api-orders-shipments-adjustments-get-subresource-order-subresource-html';
import { apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json } from '../fn/adjustment/api-orders-shipments-adjustments-get-subresource-order-subresource-json';
import { ApiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json$Params } from '../fn/adjustment/api-orders-shipments-adjustments-get-subresource-order-subresource-json';
import { apiOrdersShipmentsGetSubresourceOrderSubresource$Html } from '../fn/shipment/api-orders-shipments-get-subresource-order-subresource-html';
import { ApiOrdersShipmentsGetSubresourceOrderSubresource$Html$Params } from '../fn/shipment/api-orders-shipments-get-subresource-order-subresource-html';
import { apiOrdersShipmentsGetSubresourceOrderSubresource$Json } from '../fn/shipment/api-orders-shipments-get-subresource-order-subresource-json';
import { ApiOrdersShipmentsGetSubresourceOrderSubresource$Json$Params } from '../fn/shipment/api-orders-shipments-get-subresource-order-subresource-json';
import { apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html } from '../fn/adjustment/api-shipments-adjustments-get-subresource-shipment-subresource-html';
import { ApiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html$Params } from '../fn/adjustment/api-shipments-adjustments-get-subresource-shipment-subresource-html';
import { apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json } from '../fn/adjustment/api-shipments-adjustments-get-subresource-shipment-subresource-json';
import { ApiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json$Params } from '../fn/adjustment/api-shipments-adjustments-get-subresource-shipment-subresource-json';
import { Index as ShipmentAdminShipmentIndexSyliusAdminShipmentIndex } from '../models/Shipment-admin/shipment/index_sylius/admin/shipment/index';
import { Show as ShipmentAdminShipmentShowSyliusAdminShipmentShow } from '../models/Shipment-admin/shipment/show_sylius/admin/shipment/show';
import { Show as ShipmentShopShipmentShowSyliusShopShipmentShow } from '../models/Shipment-shop/shipment/show_sylius/shop/shipment/show';
import { shopGetShipmentItem$Html } from '../fn/shipment/shop-get-shipment-item-html';
import { ShopGetShipmentItem$Html$Params } from '../fn/shipment/shop-get-shipment-item-html';
import { shopGetShipmentItem$Json } from '../fn/shipment/shop-get-shipment-item-json';
import { ShopGetShipmentItem$Json$Params } from '../fn/shipment/shop-get-shipment-item-json';

@Injectable({ providedIn: 'root' })
export class ShipmentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiOrdersShipmentsGetSubresourceOrderSubresource()` */
  static readonly ApiOrdersShipmentsGetSubresourceOrderSubresourcePath = '/api/v2/admin/orders/{tokenValue}/shipments';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersShipmentsGetSubresourceOrderSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsGetSubresourceOrderSubresource$Json$Response(params: ApiOrdersShipmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow>>> {
    return apiOrdersShipmentsGetSubresourceOrderSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersShipmentsGetSubresourceOrderSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsGetSubresourceOrderSubresource$Json(params: ApiOrdersShipmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow>> {
    return this.apiOrdersShipmentsGetSubresourceOrderSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow>>): Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow> => r.body)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersShipmentsGetSubresourceOrderSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsGetSubresourceOrderSubresource$Html$Response(params: ApiOrdersShipmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow>>> {
    return apiOrdersShipmentsGetSubresourceOrderSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersShipmentsGetSubresourceOrderSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsGetSubresourceOrderSubresource$Html(params: ApiOrdersShipmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow>> {
    return this.apiOrdersShipmentsGetSubresourceOrderSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow>>): Array<ShipmentAdminShipmentShowSyliusAdminShipmentShow> => r.body)
    );
  }

  /** Path part for operation `apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource()` */
  static readonly ApiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresourcePath = '/api/v2/admin/orders/{tokenValue}/shipments/{shipments}/adjustments';

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json$Response(params: ApiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Adjustment>>> {
    return apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json(params: ApiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json$Params, context?: HttpContext): Observable<Array<Adjustment>> {
    return this.apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Adjustment>>): Array<Adjustment> => r.body)
    );
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html$Response(params: ApiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Adjustment>>> {
    return apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Order resource.
   *
   * Retrieves a Order resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html(params: ApiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html$Params, context?: HttpContext): Observable<Array<Adjustment>> {
    return this.apiOrdersShipmentsAdjustmentsGetSubresourceOrderSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Adjustment>>): Array<Adjustment> => r.body)
    );
  }

  /** Path part for operation `adminGetShipmentCollection()` */
  static readonly AdminGetShipmentCollectionPath = '/api/v2/admin/shipments';

  /**
   * Retrieves the collection of Shipment resources.
   *
   * Retrieves the collection of Shipment resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShipmentCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShipmentCollection$Json$Response(params?: AdminGetShipmentCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShipmentAdminShipmentIndexSyliusAdminShipmentIndex>>> {
    return adminGetShipmentCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Shipment resources.
   *
   * Retrieves the collection of Shipment resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShipmentCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShipmentCollection$Json(params?: AdminGetShipmentCollection$Json$Params, context?: HttpContext): Observable<Array<ShipmentAdminShipmentIndexSyliusAdminShipmentIndex>> {
    return this.adminGetShipmentCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShipmentAdminShipmentIndexSyliusAdminShipmentIndex>>): Array<ShipmentAdminShipmentIndexSyliusAdminShipmentIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Shipment resources.
   *
   * Retrieves the collection of Shipment resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShipmentCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShipmentCollection$Html$Response(params?: AdminGetShipmentCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ShipmentAdminShipmentIndexSyliusAdminShipmentIndex>>> {
    return adminGetShipmentCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Shipment resources.
   *
   * Retrieves the collection of Shipment resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShipmentCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShipmentCollection$Html(params?: AdminGetShipmentCollection$Html$Params, context?: HttpContext): Observable<Array<ShipmentAdminShipmentIndexSyliusAdminShipmentIndex>> {
    return this.adminGetShipmentCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ShipmentAdminShipmentIndexSyliusAdminShipmentIndex>>): Array<ShipmentAdminShipmentIndexSyliusAdminShipmentIndex> => r.body)
    );
  }

  /** Path part for operation `adminGetShipmentItem()` */
  static readonly AdminGetShipmentItemPath = '/api/v2/admin/shipments/{id}';

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShipmentItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShipmentItem$Json$Response(params: AdminGetShipmentItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShipmentAdminShipmentShowSyliusAdminShipmentShow>> {
    return adminGetShipmentItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShipmentItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShipmentItem$Json(params: AdminGetShipmentItem$Json$Params, context?: HttpContext): Observable<ShipmentAdminShipmentShowSyliusAdminShipmentShow> {
    return this.adminGetShipmentItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShipmentAdminShipmentShowSyliusAdminShipmentShow>): ShipmentAdminShipmentShowSyliusAdminShipmentShow => r.body)
    );
  }

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetShipmentItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShipmentItem$Html$Response(params: AdminGetShipmentItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShipmentAdminShipmentShowSyliusAdminShipmentShow>> {
    return adminGetShipmentItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetShipmentItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetShipmentItem$Html(params: AdminGetShipmentItem$Html$Params, context?: HttpContext): Observable<ShipmentAdminShipmentShowSyliusAdminShipmentShow> {
    return this.adminGetShipmentItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShipmentAdminShipmentShowSyliusAdminShipmentShow>): ShipmentAdminShipmentShowSyliusAdminShipmentShow => r.body)
    );
  }

  /** Path part for operation `apiShipmentsAdjustmentsGetSubresourceShipmentSubresource()` */
  static readonly ApiShipmentsAdjustmentsGetSubresourceShipmentSubresourcePath = '/api/v2/admin/shipments/{id}/adjustments';

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json$Response(params: ApiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex>>> {
    return apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json(params: ApiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json$Params, context?: HttpContext): Observable<Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex>> {
    return this.apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex>>): Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex> => r.body)
    );
  }

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html$Response(params: ApiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex>>> {
    return apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html(params: ApiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html$Params, context?: HttpContext): Observable<Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex>> {
    return this.apiShipmentsAdjustmentsGetSubresourceShipmentSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex>>): Array<AdjustmentAdminShipmentIndexSyliusAdminShipmentIndex> => r.body)
    );
  }

  /** Path part for operation `adminResendConfirmationShipmentItem()` */
  static readonly AdminResendConfirmationShipmentItemPath = '/api/v2/admin/shipments/{id}/resend-confirmation-email';

  /**
   * Resends shipment confirmation email.
   *
   * Creates a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminResendConfirmationShipmentItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminResendConfirmationShipmentItem$Json$Json$Response(params: AdminResendConfirmationShipmentItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminResendConfirmationShipmentItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Resends shipment confirmation email.
   *
   * Creates a Shipment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminResendConfirmationShipmentItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminResendConfirmationShipmentItem$Json$Json(params: AdminResendConfirmationShipmentItem$Json$Json$Params, context?: HttpContext): Observable<any> {
    return this.adminResendConfirmationShipmentItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Resends shipment confirmation email.
   *
   * Creates a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminResendConfirmationShipmentItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminResendConfirmationShipmentItem$Json$Html$Response(params: AdminResendConfirmationShipmentItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminResendConfirmationShipmentItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Resends shipment confirmation email.
   *
   * Creates a Shipment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminResendConfirmationShipmentItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminResendConfirmationShipmentItem$Json$Html(params: AdminResendConfirmationShipmentItem$Json$Html$Params, context?: HttpContext): Observable<any> {
    return this.adminResendConfirmationShipmentItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Resends shipment confirmation email.
   *
   * Creates a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminResendConfirmationShipmentItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminResendConfirmationShipmentItem$Html$Json$Response(params: AdminResendConfirmationShipmentItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminResendConfirmationShipmentItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Resends shipment confirmation email.
   *
   * Creates a Shipment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminResendConfirmationShipmentItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminResendConfirmationShipmentItem$Html$Json(params: AdminResendConfirmationShipmentItem$Html$Json$Params, context?: HttpContext): Observable<any> {
    return this.adminResendConfirmationShipmentItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Resends shipment confirmation email.
   *
   * Creates a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminResendConfirmationShipmentItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminResendConfirmationShipmentItem$Html$Html$Response(params: AdminResendConfirmationShipmentItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminResendConfirmationShipmentItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Resends shipment confirmation email.
   *
   * Creates a Shipment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminResendConfirmationShipmentItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminResendConfirmationShipmentItem$Html$Html(params: AdminResendConfirmationShipmentItem$Html$Html$Params, context?: HttpContext): Observable<any> {
    return this.adminResendConfirmationShipmentItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `adminShipShipmentItem()` */
  static readonly AdminShipShipmentItemPath = '/api/v2/admin/shipments/{id}/ship';

  /**
   * Ships Shipment.
   *
   * Updates the Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminShipShipmentItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminShipShipmentItem$Json$Response(params: AdminShipShipmentItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminShipShipmentItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Ships Shipment.
   *
   * Updates the Shipment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminShipShipmentItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminShipShipmentItem$Json(params: AdminShipShipmentItem$Json$Params, context?: HttpContext): Observable<any> {
    return this.adminShipShipmentItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Ships Shipment.
   *
   * Updates the Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminShipShipmentItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminShipShipmentItem$Html$Response(params: AdminShipShipmentItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminShipShipmentItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Ships Shipment.
   *
   * Updates the Shipment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminShipShipmentItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminShipShipmentItem$Html(params: AdminShipShipmentItem$Html$Params, context?: HttpContext): Observable<any> {
    return this.adminShipShipmentItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `shopGetShipmentItem()` */
  static readonly ShopGetShipmentItemPath = '/api/v2/shop/shipments/{id}';

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetShipmentItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetShipmentItem$Json$Response(params: ShopGetShipmentItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ShipmentShopShipmentShowSyliusShopShipmentShow>> {
    return shopGetShipmentItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetShipmentItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetShipmentItem$Json(params: ShopGetShipmentItem$Json$Params, context?: HttpContext): Observable<ShipmentShopShipmentShowSyliusShopShipmentShow> {
    return this.shopGetShipmentItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShipmentShopShipmentShowSyliusShopShipmentShow>): ShipmentShopShipmentShowSyliusShopShipmentShow => r.body)
    );
  }

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetShipmentItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetShipmentItem$Html$Response(params: ShopGetShipmentItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ShipmentShopShipmentShowSyliusShopShipmentShow>> {
    return shopGetShipmentItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Shipment resource.
   *
   * Retrieves a Shipment resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetShipmentItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetShipmentItem$Html(params: ShopGetShipmentItem$Html$Params, context?: HttpContext): Observable<ShipmentShopShipmentShowSyliusShopShipmentShow> {
    return this.shopGetShipmentItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShipmentShopShipmentShowSyliusShopShipmentShow>): ShipmentShopShipmentShowSyliusShopShipmentShow => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { shopContactContactCollection$Html$Html } from '../fn/contact/shop-contact-contact-collection-html-html';
import { ShopContactContactCollection$Html$Html$Params } from '../fn/contact/shop-contact-contact-collection-html-html';
import { shopContactContactCollection$Html$Json } from '../fn/contact/shop-contact-contact-collection-html-json';
import { ShopContactContactCollection$Html$Json$Params } from '../fn/contact/shop-contact-contact-collection-html-json';
import { shopContactContactCollection$Json$Html } from '../fn/contact/shop-contact-contact-collection-json-html';
import { ShopContactContactCollection$Json$Html$Params } from '../fn/contact/shop-contact-contact-collection-json-html';
import { shopContactContactCollection$Json$Json } from '../fn/contact/shop-contact-contact-collection-json-json';
import { ShopContactContactCollection$Json$Json$Params } from '../fn/contact/shop-contact-contact-collection-json-json';

@Injectable({ providedIn: 'root' })
export class ContactService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `shopContactContactCollection()` */
  static readonly ShopContactContactCollectionPath = '/api/v2/shop/contact';

  /**
   * Sends contact request.
   *
   * Creates a Contact resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopContactContactCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopContactContactCollection$Json$Json$Response(params: ShopContactContactCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopContactContactCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Sends contact request.
   *
   * Creates a Contact resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopContactContactCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopContactContactCollection$Json$Json(params: ShopContactContactCollection$Json$Json$Params, context?: HttpContext): Observable<any> {
    return this.shopContactContactCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Sends contact request.
   *
   * Creates a Contact resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopContactContactCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopContactContactCollection$Json$Html$Response(params: ShopContactContactCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopContactContactCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Sends contact request.
   *
   * Creates a Contact resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopContactContactCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  shopContactContactCollection$Json$Html(params: ShopContactContactCollection$Json$Html$Params, context?: HttpContext): Observable<any> {
    return this.shopContactContactCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Sends contact request.
   *
   * Creates a Contact resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopContactContactCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopContactContactCollection$Html$Json$Response(params: ShopContactContactCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopContactContactCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Sends contact request.
   *
   * Creates a Contact resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopContactContactCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopContactContactCollection$Html$Json(params: ShopContactContactCollection$Html$Json$Params, context?: HttpContext): Observable<any> {
    return this.shopContactContactCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /**
   * Sends contact request.
   *
   * Creates a Contact resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopContactContactCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopContactContactCollection$Html$Html$Response(params: ShopContactContactCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return shopContactContactCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Sends contact request.
   *
   * Creates a Contact resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopContactContactCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  shopContactContactCollection$Html$Html(params: ShopContactContactCollection$Html$Html$Params, context?: HttpContext): Observable<any> {
    return this.shopContactContactCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}

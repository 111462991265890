/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetTaxonTranslationItem$Html } from '../fn/taxon-translation/admin-get-taxon-translation-item-html';
import { AdminGetTaxonTranslationItem$Html$Params } from '../fn/taxon-translation/admin-get-taxon-translation-item-html';
import { adminGetTaxonTranslationItem$Json } from '../fn/taxon-translation/admin-get-taxon-translation-item-json';
import { AdminGetTaxonTranslationItem$Json$Params } from '../fn/taxon-translation/admin-get-taxon-translation-item-json';
import { Show as TaxonTranslationAdminTaxonShowSyliusAdminTaxonShowAdminTaxonTranslationShowSyliusAdminTaxonTranslationShow } from '../models/TaxonTranslation-admin/taxon/show_sylius/admin/taxon/show_admin/taxon_translation/show_sylius/admin/taxon_translation/show';

@Injectable({ providedIn: 'root' })
export class TaxonTranslationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetTaxonTranslationItem()` */
  static readonly AdminGetTaxonTranslationItemPath = '/api/v2/admin/taxon-translations/{id}';

  /**
   * Retrieves a TaxonTranslation resource.
   *
   * Retrieves a TaxonTranslation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxonTranslationItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonTranslationItem$Json$Response(params: AdminGetTaxonTranslationItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonTranslationAdminTaxonShowSyliusAdminTaxonShowAdminTaxonTranslationShowSyliusAdminTaxonTranslationShow>> {
    return adminGetTaxonTranslationItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a TaxonTranslation resource.
   *
   * Retrieves a TaxonTranslation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxonTranslationItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonTranslationItem$Json(params: AdminGetTaxonTranslationItem$Json$Params, context?: HttpContext): Observable<TaxonTranslationAdminTaxonShowSyliusAdminTaxonShowAdminTaxonTranslationShowSyliusAdminTaxonTranslationShow> {
    return this.adminGetTaxonTranslationItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonTranslationAdminTaxonShowSyliusAdminTaxonShowAdminTaxonTranslationShowSyliusAdminTaxonTranslationShow>): TaxonTranslationAdminTaxonShowSyliusAdminTaxonShowAdminTaxonTranslationShowSyliusAdminTaxonTranslationShow => r.body)
    );
  }

  /**
   * Retrieves a TaxonTranslation resource.
   *
   * Retrieves a TaxonTranslation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetTaxonTranslationItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonTranslationItem$Html$Response(params: AdminGetTaxonTranslationItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<TaxonTranslationAdminTaxonShowSyliusAdminTaxonShowAdminTaxonTranslationShowSyliusAdminTaxonTranslationShow>> {
    return adminGetTaxonTranslationItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a TaxonTranslation resource.
   *
   * Retrieves a TaxonTranslation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetTaxonTranslationItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetTaxonTranslationItem$Html(params: AdminGetTaxonTranslationItem$Html$Params, context?: HttpContext): Observable<TaxonTranslationAdminTaxonShowSyliusAdminTaxonShowAdminTaxonTranslationShowSyliusAdminTaxonTranslationShow> {
    return this.adminGetTaxonTranslationItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<TaxonTranslationAdminTaxonShowSyliusAdminTaxonShowAdminTaxonTranslationShowSyliusAdminTaxonTranslationShow>): TaxonTranslationAdminTaxonShowSyliusAdminTaxonShowAdminTaxonTranslationShowSyliusAdminTaxonTranslationShow => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Index as CustomerAdminCustomerIndexSyliusAdminCustomerIndex } from '../../models/Customer-admin/customer/index_sylius/admin/customer/index';

export interface AdminGetCustomerCollection$Json$Params {

/**
 * The collection page number
 */
  page?: number;

/**
 * The number of items per page
 */
  itemsPerPage?: number;
  'group.name'?: string;
  'group.name[]'?: Array<string>;
}

export function adminGetCustomerCollection$Json(http: HttpClient, rootUrl: string, params?: AdminGetCustomerCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CustomerAdminCustomerIndexSyliusAdminCustomerIndex>>> {
  const rb = new RequestBuilder(rootUrl, adminGetCustomerCollection$Json.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
    rb.query('group.name', params['group.name'], {"style":"form","explode":false});
    rb.query('group.name[]', params['group.name[]'], {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<CustomerAdminCustomerIndexSyliusAdminCustomerIndex>>;
    })
  );
}

adminGetCustomerCollection$Json.PATH = '/api/v2/admin/customers';

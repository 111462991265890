/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminArchivePromotionItem$Html } from '../fn/promotion/admin-archive-promotion-item-html';
import { AdminArchivePromotionItem$Html$Params } from '../fn/promotion/admin-archive-promotion-item-html';
import { adminArchivePromotionItem$Json } from '../fn/promotion/admin-archive-promotion-item-json';
import { AdminArchivePromotionItem$Json$Params } from '../fn/promotion/admin-archive-promotion-item-json';
import { adminDeletePromotionItem } from '../fn/promotion/admin-delete-promotion-item';
import { AdminDeletePromotionItem$Params } from '../fn/promotion/admin-delete-promotion-item';
import { adminGetPromotionCollection$Html } from '../fn/promotion/admin-get-promotion-collection-html';
import { AdminGetPromotionCollection$Html$Params } from '../fn/promotion/admin-get-promotion-collection-html';
import { adminGetPromotionCollection$Json } from '../fn/promotion/admin-get-promotion-collection-json';
import { AdminGetPromotionCollection$Json$Params } from '../fn/promotion/admin-get-promotion-collection-json';
import { adminGetPromotionItem$Html } from '../fn/promotion/admin-get-promotion-item-html';
import { AdminGetPromotionItem$Html$Params } from '../fn/promotion/admin-get-promotion-item-html';
import { adminGetPromotionItem$Json } from '../fn/promotion/admin-get-promotion-item-json';
import { AdminGetPromotionItem$Json$Params } from '../fn/promotion/admin-get-promotion-item-json';
import { adminPostPromotionCollection$Html$Html } from '../fn/promotion/admin-post-promotion-collection-html-html';
import { AdminPostPromotionCollection$Html$Html$Params } from '../fn/promotion/admin-post-promotion-collection-html-html';
import { adminPostPromotionCollection$Html$Json } from '../fn/promotion/admin-post-promotion-collection-html-json';
import { AdminPostPromotionCollection$Html$Json$Params } from '../fn/promotion/admin-post-promotion-collection-html-json';
import { adminPostPromotionCollection$Json$Html } from '../fn/promotion/admin-post-promotion-collection-json-html';
import { AdminPostPromotionCollection$Json$Html$Params } from '../fn/promotion/admin-post-promotion-collection-json-html';
import { adminPostPromotionCollection$Json$Json } from '../fn/promotion/admin-post-promotion-collection-json-json';
import { AdminPostPromotionCollection$Json$Json$Params } from '../fn/promotion/admin-post-promotion-collection-json-json';
import { adminPutPromotionItem$Html$Html } from '../fn/promotion/admin-put-promotion-item-html-html';
import { AdminPutPromotionItem$Html$Html$Params } from '../fn/promotion/admin-put-promotion-item-html-html';
import { adminPutPromotionItem$Html$Json } from '../fn/promotion/admin-put-promotion-item-html-json';
import { AdminPutPromotionItem$Html$Json$Params } from '../fn/promotion/admin-put-promotion-item-html-json';
import { adminPutPromotionItem$Json$Html } from '../fn/promotion/admin-put-promotion-item-json-html';
import { AdminPutPromotionItem$Json$Html$Params } from '../fn/promotion/admin-put-promotion-item-json-html';
import { adminPutPromotionItem$Json$Json } from '../fn/promotion/admin-put-promotion-item-json-json';
import { AdminPutPromotionItem$Json$Json$Params } from '../fn/promotion/admin-put-promotion-item-json-json';
import { adminRestorePromotionItem$Html } from '../fn/promotion/admin-restore-promotion-item-html';
import { AdminRestorePromotionItem$Html$Params } from '../fn/promotion/admin-restore-promotion-item-html';
import { adminRestorePromotionItem$Json } from '../fn/promotion/admin-restore-promotion-item-json';
import { AdminRestorePromotionItem$Json$Params } from '../fn/promotion/admin-restore-promotion-item-json';
import { apiPromotionsCouponsGetSubresourcePromotionSubresource$Html } from '../fn/promotion-coupon/api-promotions-coupons-get-subresource-promotion-subresource-html';
import { ApiPromotionsCouponsGetSubresourcePromotionSubresource$Html$Params } from '../fn/promotion-coupon/api-promotions-coupons-get-subresource-promotion-subresource-html';
import { apiPromotionsCouponsGetSubresourcePromotionSubresource$Json } from '../fn/promotion-coupon/api-promotions-coupons-get-subresource-promotion-subresource-json';
import { ApiPromotionsCouponsGetSubresourcePromotionSubresource$Json$Params } from '../fn/promotion-coupon/api-promotions-coupons-get-subresource-promotion-subresource-json';
import { Index as PromotionAdminPromotionIndexSyliusAdminPromotionIndex } from '../models/Promotion-admin/promotion/index_sylius/admin/promotion/index';
import { Read as PromotionAdminPromotionReadSyliusAdminPromotionRead } from '../models/Promotion-admin/promotion/read_sylius/admin/promotion/read';
import { Show as PromotionAdminPromotionShowSyliusAdminPromotionShow } from '../models/Promotion-admin/promotion/show_sylius/admin/promotion/show';
import { Show as PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow } from '../models/PromotionCoupon-admin/promotion_coupon/show_sylius/admin/promotion_coupon/show';

@Injectable({ providedIn: 'root' })
export class PromotionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetPromotionCollection()` */
  static readonly AdminGetPromotionCollectionPath = '/api/v2/admin/promotions';

  /**
   * Retrieves the collection of Promotion resources.
   *
   * Retrieves the collection of Promotion resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPromotionCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionCollection$Json$Response(params?: AdminGetPromotionCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PromotionAdminPromotionIndexSyliusAdminPromotionIndex>>> {
    return adminGetPromotionCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Promotion resources.
   *
   * Retrieves the collection of Promotion resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPromotionCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionCollection$Json(params?: AdminGetPromotionCollection$Json$Params, context?: HttpContext): Observable<Array<PromotionAdminPromotionIndexSyliusAdminPromotionIndex>> {
    return this.adminGetPromotionCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PromotionAdminPromotionIndexSyliusAdminPromotionIndex>>): Array<PromotionAdminPromotionIndexSyliusAdminPromotionIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Promotion resources.
   *
   * Retrieves the collection of Promotion resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPromotionCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionCollection$Html$Response(params?: AdminGetPromotionCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PromotionAdminPromotionIndexSyliusAdminPromotionIndex>>> {
    return adminGetPromotionCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Promotion resources.
   *
   * Retrieves the collection of Promotion resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPromotionCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionCollection$Html(params?: AdminGetPromotionCollection$Html$Params, context?: HttpContext): Observable<Array<PromotionAdminPromotionIndexSyliusAdminPromotionIndex>> {
    return this.adminGetPromotionCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PromotionAdminPromotionIndexSyliusAdminPromotionIndex>>): Array<PromotionAdminPromotionIndexSyliusAdminPromotionIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostPromotionCollection()` */
  static readonly AdminPostPromotionCollectionPath = '/api/v2/admin/promotions';

  /**
   * Creates a Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostPromotionCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostPromotionCollection$Json$Json$Response(params: AdminPostPromotionCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>> {
    return adminPostPromotionCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostPromotionCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostPromotionCollection$Json$Json(params: AdminPostPromotionCollection$Json$Json$Params, context?: HttpContext): Observable<PromotionAdminPromotionShowSyliusAdminPromotionShow> {
    return this.adminPostPromotionCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>): PromotionAdminPromotionShowSyliusAdminPromotionShow => r.body)
    );
  }

  /**
   * Creates a Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostPromotionCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostPromotionCollection$Json$Html$Response(params: AdminPostPromotionCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>> {
    return adminPostPromotionCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostPromotionCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostPromotionCollection$Json$Html(params: AdminPostPromotionCollection$Json$Html$Params, context?: HttpContext): Observable<PromotionAdminPromotionShowSyliusAdminPromotionShow> {
    return this.adminPostPromotionCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>): PromotionAdminPromotionShowSyliusAdminPromotionShow => r.body)
    );
  }

  /**
   * Creates a Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostPromotionCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostPromotionCollection$Html$Json$Response(params: AdminPostPromotionCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>> {
    return adminPostPromotionCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostPromotionCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostPromotionCollection$Html$Json(params: AdminPostPromotionCollection$Html$Json$Params, context?: HttpContext): Observable<PromotionAdminPromotionShowSyliusAdminPromotionShow> {
    return this.adminPostPromotionCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>): PromotionAdminPromotionShowSyliusAdminPromotionShow => r.body)
    );
  }

  /**
   * Creates a Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostPromotionCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostPromotionCollection$Html$Html$Response(params: AdminPostPromotionCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>> {
    return adminPostPromotionCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostPromotionCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostPromotionCollection$Html$Html(params: AdminPostPromotionCollection$Html$Html$Params, context?: HttpContext): Observable<PromotionAdminPromotionShowSyliusAdminPromotionShow> {
    return this.adminPostPromotionCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>): PromotionAdminPromotionShowSyliusAdminPromotionShow => r.body)
    );
  }

  /** Path part for operation `adminGetPromotionItem()` */
  static readonly AdminGetPromotionItemPath = '/api/v2/admin/promotions/{code}';

  /**
   * Retrieves a Promotion resource.
   *
   * Retrieves a Promotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPromotionItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionItem$Json$Response(params: AdminGetPromotionItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>> {
    return adminGetPromotionItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Promotion resource.
   *
   * Retrieves a Promotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPromotionItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionItem$Json(params: AdminGetPromotionItem$Json$Params, context?: HttpContext): Observable<PromotionAdminPromotionShowSyliusAdminPromotionShow> {
    return this.adminGetPromotionItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>): PromotionAdminPromotionShowSyliusAdminPromotionShow => r.body)
    );
  }

  /**
   * Retrieves a Promotion resource.
   *
   * Retrieves a Promotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPromotionItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionItem$Html$Response(params: AdminGetPromotionItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>> {
    return adminGetPromotionItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Promotion resource.
   *
   * Retrieves a Promotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPromotionItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPromotionItem$Html(params: AdminGetPromotionItem$Html$Params, context?: HttpContext): Observable<PromotionAdminPromotionShowSyliusAdminPromotionShow> {
    return this.adminGetPromotionItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>): PromotionAdminPromotionShowSyliusAdminPromotionShow => r.body)
    );
  }

  /** Path part for operation `adminPutPromotionItem()` */
  static readonly AdminPutPromotionItemPath = '/api/v2/admin/promotions/{code}';

  /**
   * Replaces the Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutPromotionItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutPromotionItem$Json$Json$Response(params: AdminPutPromotionItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>> {
    return adminPutPromotionItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutPromotionItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutPromotionItem$Json$Json(params: AdminPutPromotionItem$Json$Json$Params, context?: HttpContext): Observable<PromotionAdminPromotionShowSyliusAdminPromotionShow> {
    return this.adminPutPromotionItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>): PromotionAdminPromotionShowSyliusAdminPromotionShow => r.body)
    );
  }

  /**
   * Replaces the Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutPromotionItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutPromotionItem$Json$Html$Response(params: AdminPutPromotionItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>> {
    return adminPutPromotionItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutPromotionItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutPromotionItem$Json$Html(params: AdminPutPromotionItem$Json$Html$Params, context?: HttpContext): Observable<PromotionAdminPromotionShowSyliusAdminPromotionShow> {
    return this.adminPutPromotionItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>): PromotionAdminPromotionShowSyliusAdminPromotionShow => r.body)
    );
  }

  /**
   * Replaces the Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutPromotionItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutPromotionItem$Html$Json$Response(params: AdminPutPromotionItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>> {
    return adminPutPromotionItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutPromotionItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutPromotionItem$Html$Json(params: AdminPutPromotionItem$Html$Json$Params, context?: HttpContext): Observable<PromotionAdminPromotionShowSyliusAdminPromotionShow> {
    return this.adminPutPromotionItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>): PromotionAdminPromotionShowSyliusAdminPromotionShow => r.body)
    );
  }

  /**
   * Replaces the Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutPromotionItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutPromotionItem$Html$Html$Response(params: AdminPutPromotionItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>> {
    return adminPutPromotionItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Promotion resource.
   *
   * Example configuration for `total_of_items_from_taxon` rule type:
   *
   * ```
   * {
   *     "type": "total_of_items_from_taxon",
   *     "configuration": {
   *         "channel-code": {
   *             "taxon": "taxon-code",
   *             "amount": int
   *         }
   *     }
   * }
   * ```
   *
   * Example configuration for `order_fixed_discount` action type:
   *
   * ```
   * {
   *     "type": "order_fixed_discount",
   *     "configuration": {
   *         "channel-code": {
   *             "amount": int,
   *         }
   *     }
   * }
   * ```
   *                     
   *
   *  Allowed rule types: `cart_quantity`, `customer_group`, `nth_order`, `shipping_country`, `has_taxon`, `total_of_items_from_taxon`, `contains_product`, `item_total` 
   *
   *  Allowed action types: `order_fixed_discount`, `unit_fixed_discount`, `order_percentage_discount`, `unit_percentage_discount`, `shipping_percentage_discount`
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutPromotionItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutPromotionItem$Html$Html(params: AdminPutPromotionItem$Html$Html$Params, context?: HttpContext): Observable<PromotionAdminPromotionShowSyliusAdminPromotionShow> {
    return this.adminPutPromotionItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionAdminPromotionShowSyliusAdminPromotionShow>): PromotionAdminPromotionShowSyliusAdminPromotionShow => r.body)
    );
  }

  /** Path part for operation `adminDeletePromotionItem()` */
  static readonly AdminDeletePromotionItemPath = '/api/v2/admin/promotions/{code}';

  /**
   * Removes the Promotion resource.
   *
   * Removes the Promotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeletePromotionItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeletePromotionItem$Response(params: AdminDeletePromotionItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeletePromotionItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Promotion resource.
   *
   * Removes the Promotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeletePromotionItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeletePromotionItem(params: AdminDeletePromotionItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeletePromotionItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminArchivePromotionItem()` */
  static readonly AdminArchivePromotionItemPath = '/api/v2/admin/promotions/{code}/archive';

  /**
   * Archives Promotion.
   *
   * Updates the Promotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminArchivePromotionItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminArchivePromotionItem$Json$Response(params: AdminArchivePromotionItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionAdminPromotionReadSyliusAdminPromotionRead>> {
    return adminArchivePromotionItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Archives Promotion.
   *
   * Updates the Promotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminArchivePromotionItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminArchivePromotionItem$Json(params: AdminArchivePromotionItem$Json$Params, context?: HttpContext): Observable<PromotionAdminPromotionReadSyliusAdminPromotionRead> {
    return this.adminArchivePromotionItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionAdminPromotionReadSyliusAdminPromotionRead>): PromotionAdminPromotionReadSyliusAdminPromotionRead => r.body)
    );
  }

  /**
   * Archives Promotion.
   *
   * Updates the Promotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminArchivePromotionItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminArchivePromotionItem$Html$Response(params: AdminArchivePromotionItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionAdminPromotionReadSyliusAdminPromotionRead>> {
    return adminArchivePromotionItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Archives Promotion.
   *
   * Updates the Promotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminArchivePromotionItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminArchivePromotionItem$Html(params: AdminArchivePromotionItem$Html$Params, context?: HttpContext): Observable<PromotionAdminPromotionReadSyliusAdminPromotionRead> {
    return this.adminArchivePromotionItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionAdminPromotionReadSyliusAdminPromotionRead>): PromotionAdminPromotionReadSyliusAdminPromotionRead => r.body)
    );
  }

  /** Path part for operation `apiPromotionsCouponsGetSubresourcePromotionSubresource()` */
  static readonly ApiPromotionsCouponsGetSubresourcePromotionSubresourcePath = '/api/v2/admin/promotions/{code}/coupons';

  /**
   * Retrieves a Promotion resource.
   *
   * Retrieves a Promotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPromotionsCouponsGetSubresourcePromotionSubresource$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPromotionsCouponsGetSubresourcePromotionSubresource$Json$Response(params: ApiPromotionsCouponsGetSubresourcePromotionSubresource$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>>> {
    return apiPromotionsCouponsGetSubresourcePromotionSubresource$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Promotion resource.
   *
   * Retrieves a Promotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPromotionsCouponsGetSubresourcePromotionSubresource$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPromotionsCouponsGetSubresourcePromotionSubresource$Json(params: ApiPromotionsCouponsGetSubresourcePromotionSubresource$Json$Params, context?: HttpContext): Observable<Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return this.apiPromotionsCouponsGetSubresourcePromotionSubresource$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>>): Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> => r.body)
    );
  }

  /**
   * Retrieves a Promotion resource.
   *
   * Retrieves a Promotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPromotionsCouponsGetSubresourcePromotionSubresource$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPromotionsCouponsGetSubresourcePromotionSubresource$Html$Response(params: ApiPromotionsCouponsGetSubresourcePromotionSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>>> {
    return apiPromotionsCouponsGetSubresourcePromotionSubresource$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Promotion resource.
   *
   * Retrieves a Promotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPromotionsCouponsGetSubresourcePromotionSubresource$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPromotionsCouponsGetSubresourcePromotionSubresource$Html(params: ApiPromotionsCouponsGetSubresourcePromotionSubresource$Html$Params, context?: HttpContext): Observable<Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>> {
    return this.apiPromotionsCouponsGetSubresourcePromotionSubresource$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>>): Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow> => r.body)
    );
  }

  /** Path part for operation `adminRestorePromotionItem()` */
  static readonly AdminRestorePromotionItemPath = '/api/v2/admin/promotions/{code}/restore';

  /**
   * Restores Archived Promotion.
   *
   * Updates the Promotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminRestorePromotionItem$Json()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminRestorePromotionItem$Json$Response(params: AdminRestorePromotionItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionAdminPromotionReadSyliusAdminPromotionRead>> {
    return adminRestorePromotionItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Restores Archived Promotion.
   *
   * Updates the Promotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminRestorePromotionItem$Json$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminRestorePromotionItem$Json(params: AdminRestorePromotionItem$Json$Params, context?: HttpContext): Observable<PromotionAdminPromotionReadSyliusAdminPromotionRead> {
    return this.adminRestorePromotionItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionAdminPromotionReadSyliusAdminPromotionRead>): PromotionAdminPromotionReadSyliusAdminPromotionRead => r.body)
    );
  }

  /**
   * Restores Archived Promotion.
   *
   * Updates the Promotion resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminRestorePromotionItem$Html()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminRestorePromotionItem$Html$Response(params: AdminRestorePromotionItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<PromotionAdminPromotionReadSyliusAdminPromotionRead>> {
    return adminRestorePromotionItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Restores Archived Promotion.
   *
   * Updates the Promotion resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminRestorePromotionItem$Html$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  adminRestorePromotionItem$Html(params: AdminRestorePromotionItem$Html$Params, context?: HttpContext): Observable<PromotionAdminPromotionReadSyliusAdminPromotionRead> {
    return this.adminRestorePromotionItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromotionAdminPromotionReadSyliusAdminPromotionRead>): PromotionAdminPromotionReadSyliusAdminPromotionRead => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Index as ProductReviewShopProductReviewIndexSyliusShopProductReviewIndex } from '../../models/ProductReview-shop/product_review/index_sylius/shop/product_review/index';

export interface ShopGetProductReviewCollection$Html$Params {

/**
 * The collection page number
 */
  page?: number;

/**
 * The number of items per page
 */
  itemsPerPage?: number;
  reviewSubject?: string;
  'reviewSubject[]'?: Array<string>;
  'order[createdAt]'?: 'asc' | 'desc';
}

export function shopGetProductReviewCollection$Html(http: HttpClient, rootUrl: string, params?: ShopGetProductReviewCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductReviewShopProductReviewIndexSyliusShopProductReviewIndex>>> {
  const rb = new RequestBuilder(rootUrl, shopGetProductReviewCollection$Html.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
    rb.query('reviewSubject', params.reviewSubject, {"style":"form","explode":false});
    rb.query('reviewSubject[]', params['reviewSubject[]'], {"style":"form","explode":true});
    rb.query('order[createdAt]', params['order[createdAt]'], {"style":"form","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/html', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ProductReviewShopProductReviewIndexSyliusShopProductReviewIndex>>;
    })
  );
}

shopGetProductReviewCollection$Html.PATH = '/api/v2/shop/product-reviews';

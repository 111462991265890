/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Show as GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow } from '../../models/GatewayConfig-admin/gateway_config/show_sylius/admin/gateway_config/show';

export interface ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html$Params {

/**
 * PaymentMethod identifier
 */
  code: string;

/**
 * Locales in this enum are all locales defined in the shop and only enabled ones will work in the given channel in the shop.
 */
  'Accept-Language'?: 'hu_HU';
}

export function apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html(http: HttpClient, rootUrl: string, params: ApiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow>> {
  const rb = new RequestBuilder(rootUrl, apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html.PATH, 'get');
  if (params) {
    rb.path('code', params.code, {"style":"simple","explode":false});
    rb.header('Accept-Language', params['Accept-Language'], {"style":"simple","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/html', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GatewayConfigAdminGatewayConfigShowSyliusAdminGatewayConfigShow>;
    })
  );
}

apiPaymentMethodsGatewayConfigGetSubresourcePaymentMethodSubresource$Html.PATH = '/api/v2/admin/payment-methods/{code}/gateway-config';

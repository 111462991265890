/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Show as PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow } from '../../models/PromotionCoupon-admin/promotion_coupon/show_sylius/admin/promotion_coupon/show';

export interface ApiPromotionsCouponsGetSubresourcePromotionSubresource$Html$Params {

/**
 * Promotion identifier
 */
  code: string;

/**
 * The collection page number
 */
  page?: number;

/**
 * The number of items per page
 */
  itemsPerPage?: number;

/**
 * Locales in this enum are all locales defined in the shop and only enabled ones will work in the given channel in the shop.
 */
  'Accept-Language'?: 'hu_HU';
}

export function apiPromotionsCouponsGetSubresourcePromotionSubresource$Html(http: HttpClient, rootUrl: string, params: ApiPromotionsCouponsGetSubresourcePromotionSubresource$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>>> {
  const rb = new RequestBuilder(rootUrl, apiPromotionsCouponsGetSubresourcePromotionSubresource$Html.PATH, 'get');
  if (params) {
    rb.path('code', params.code, {"style":"simple","explode":false});
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
    rb.header('Accept-Language', params['Accept-Language'], {"style":"simple","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/html', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponShowSyliusAdminPromotionCouponShow>>;
    })
  );
}

apiPromotionsCouponsGetSubresourcePromotionSubresource$Html.PATH = '/api/v2/admin/promotions/{code}/coupons';

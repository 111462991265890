/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Index as PromotionCouponAdminPromotionCouponIndexSyliusAdminPromotionCouponIndex } from '../../models/PromotionCoupon-admin/promotion_coupon/index_sylius/admin/promotion_coupon/index';

export interface AdminGetPromotionCouponCollection$Json$Params {

/**
 * The collection page number
 */
  page?: number;

/**
 * The number of items per page
 */
  itemsPerPage?: number;
  'order[code]'?: 'asc' | 'desc';
  'order[expiresAt]'?: 'asc' | 'desc';
  'order[usageLimit]'?: 'asc' | 'desc';
  'order[perCustomerUsageLimit]'?: 'asc' | 'desc';
  'order[used]'?: 'asc' | 'desc';

/**
 * Get a collection of promotion coupons for promotion
 */
  promotion?: string;
}

export function adminGetPromotionCouponCollection$Json(http: HttpClient, rootUrl: string, params?: AdminGetPromotionCouponCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponIndexSyliusAdminPromotionCouponIndex>>> {
  const rb = new RequestBuilder(rootUrl, adminGetPromotionCouponCollection$Json.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
    rb.query('order[code]', params['order[code]'], {"style":"form","explode":false});
    rb.query('order[expiresAt]', params['order[expiresAt]'], {"style":"form","explode":false});
    rb.query('order[usageLimit]', params['order[usageLimit]'], {"style":"form","explode":false});
    rb.query('order[perCustomerUsageLimit]', params['order[perCustomerUsageLimit]'], {"style":"form","explode":false});
    rb.query('order[used]', params['order[used]'], {"style":"form","explode":false});
    rb.query('promotion', params.promotion, {"style":"form","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<PromotionCouponAdminPromotionCouponIndexSyliusAdminPromotionCouponIndex>>;
    })
  );
}

adminGetPromotionCouponCollection$Json.PATH = '/api/v2/admin/promotion-coupons';

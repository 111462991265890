/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Show as ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow } from '../../models/ProductTaxon-admin/product_taxon/show_sylius/admin/product_taxon/show';
import { Update as ProductTaxonAdminProductTaxonUpdateSyliusAdminProductTaxonUpdate } from '../../models/ProductTaxon-admin/product_taxon/update_sylius/admin/product_taxon/update';

export interface AdminPutProductTaxonItem$Json$Html$Params {

/**
 * Resource identifier
 */
  id: string;
  
    /**
     * The updated ProductTaxon resource
     */
    body: ProductTaxonAdminProductTaxonUpdateSyliusAdminProductTaxonUpdate
}

export function adminPutProductTaxonItem$Json$Html(http: HttpClient, rootUrl: string, params: AdminPutProductTaxonItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>> {
  const rb = new RequestBuilder(rootUrl, adminPutProductTaxonItem$Json$Html.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {"style":"simple","explode":false});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/html', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ProductTaxonAdminProductTaxonShowSyliusAdminProductTaxonShow>;
    })
  );
}

adminPutProductTaxonItem$Json$Html.PATH = '/api/v2/admin/product-taxons/{id}';

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Index as CatalogPromotionAdminCatalogPromotionIndexSyliusAdminCatalogPromotionIndex } from '../../models/CatalogPromotion-admin/catalog_promotion/index_sylius/admin/catalog_promotion/index';

export interface AdminGetCatalogPromotionCollection$Html$Params {

/**
 * The collection page number
 */
  page?: number;

/**
 * The number of items per page
 */
  itemsPerPage?: number;
  name?: string;
  code?: string;
  state?: string;
  'state[]'?: Array<string>;
  enabled?: boolean;
  'startDate[before]'?: string;
  'startDate[strictly_before]'?: string;
  'startDate[after]'?: string;
  'startDate[strictly_after]'?: string;
  'endDate[before]'?: string;
  'endDate[strictly_before]'?: string;
  'endDate[after]'?: string;
  'endDate[strictly_after]'?: string;
  channel?: string;
  'order[code]'?: 'asc' | 'desc';
  'order[name]'?: 'asc' | 'desc';
  'order[startDate]'?: 'asc' | 'desc';
  'order[endDate]'?: 'asc' | 'desc';
  'order[priority]'?: 'asc' | 'desc';
}

export function adminGetCatalogPromotionCollection$Html(http: HttpClient, rootUrl: string, params?: AdminGetCatalogPromotionCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CatalogPromotionAdminCatalogPromotionIndexSyliusAdminCatalogPromotionIndex>>> {
  const rb = new RequestBuilder(rootUrl, adminGetCatalogPromotionCollection$Html.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('itemsPerPage', params.itemsPerPage, {"style":"form","explode":false});
    rb.query('name', params.name, {"style":"form","explode":false});
    rb.query('code', params.code, {"style":"form","explode":false});
    rb.query('state', params.state, {"style":"form","explode":false});
    rb.query('state[]', params['state[]'], {"style":"form","explode":true});
    rb.query('enabled', params.enabled, {"style":"form","explode":false});
    rb.query('startDate[before]', params['startDate[before]'], {"style":"form","explode":false});
    rb.query('startDate[strictly_before]', params['startDate[strictly_before]'], {"style":"form","explode":false});
    rb.query('startDate[after]', params['startDate[after]'], {"style":"form","explode":false});
    rb.query('startDate[strictly_after]', params['startDate[strictly_after]'], {"style":"form","explode":false});
    rb.query('endDate[before]', params['endDate[before]'], {"style":"form","explode":false});
    rb.query('endDate[strictly_before]', params['endDate[strictly_before]'], {"style":"form","explode":false});
    rb.query('endDate[after]', params['endDate[after]'], {"style":"form","explode":false});
    rb.query('endDate[strictly_after]', params['endDate[strictly_after]'], {"style":"form","explode":false});
    rb.query('channel', params.channel, {"style":"form","explode":false});
    rb.query('order[code]', params['order[code]'], {"style":"form","explode":false});
    rb.query('order[name]', params['order[name]'], {"style":"form","explode":false});
    rb.query('order[startDate]', params['order[startDate]'], {"style":"form","explode":false});
    rb.query('order[endDate]', params['order[endDate]'], {"style":"form","explode":false});
    rb.query('order[priority]', params['order[priority]'], {"style":"form","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/html', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<CatalogPromotionAdminCatalogPromotionIndexSyliusAdminCatalogPromotionIndex>>;
    })
  );
}

adminGetCatalogPromotionCollection$Html.PATH = '/api/v2/admin/catalog-promotions';

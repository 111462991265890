/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetProductAssociationTypeTranslationItem$Html } from '../fn/product-association-type-translation/admin-get-product-association-type-translation-item-html';
import { AdminGetProductAssociationTypeTranslationItem$Html$Params } from '../fn/product-association-type-translation/admin-get-product-association-type-translation-item-html';
import { adminGetProductAssociationTypeTranslationItem$Json } from '../fn/product-association-type-translation/admin-get-product-association-type-translation-item-json';
import { AdminGetProductAssociationTypeTranslationItem$Json$Params } from '../fn/product-association-type-translation/admin-get-product-association-type-translation-item-json';
import { ProductAssociationTypeTranslation } from '../models/product-association-type-translation';

@Injectable({ providedIn: 'root' })
export class ProductAssociationTypeTranslationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetProductAssociationTypeTranslationItem()` */
  static readonly AdminGetProductAssociationTypeTranslationItemPath = '/api/v2/admin/product-association-type-translations/{id}';

  /**
   * Retrieves a ProductAssociationTypeTranslation resource.
   *
   * Retrieves a ProductAssociationTypeTranslation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAssociationTypeTranslationItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationTypeTranslationItem$Json$Response(params: AdminGetProductAssociationTypeTranslationItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationTypeTranslation>> {
    return adminGetProductAssociationTypeTranslationItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAssociationTypeTranslation resource.
   *
   * Retrieves a ProductAssociationTypeTranslation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAssociationTypeTranslationItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationTypeTranslationItem$Json(params: AdminGetProductAssociationTypeTranslationItem$Json$Params, context?: HttpContext): Observable<ProductAssociationTypeTranslation> {
    return this.adminGetProductAssociationTypeTranslationItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationTypeTranslation>): ProductAssociationTypeTranslation => r.body)
    );
  }

  /**
   * Retrieves a ProductAssociationTypeTranslation resource.
   *
   * Retrieves a ProductAssociationTypeTranslation resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetProductAssociationTypeTranslationItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationTypeTranslationItem$Html$Response(params: AdminGetProductAssociationTypeTranslationItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductAssociationTypeTranslation>> {
    return adminGetProductAssociationTypeTranslationItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ProductAssociationTypeTranslation resource.
   *
   * Retrieves a ProductAssociationTypeTranslation resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetProductAssociationTypeTranslationItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetProductAssociationTypeTranslationItem$Html(params: AdminGetProductAssociationTypeTranslationItem$Html$Params, context?: HttpContext): Observable<ProductAssociationTypeTranslation> {
    return this.adminGetProductAssociationTypeTranslationItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductAssociationTypeTranslation>): ProductAssociationTypeTranslation => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminGetChannelPriceHistoryConfigItem$Html } from '../fn/channel-price-history-config/admin-get-channel-price-history-config-item-html';
import { AdminGetChannelPriceHistoryConfigItem$Html$Params } from '../fn/channel-price-history-config/admin-get-channel-price-history-config-item-html';
import { adminGetChannelPriceHistoryConfigItem$Json } from '../fn/channel-price-history-config/admin-get-channel-price-history-config-item-json';
import { AdminGetChannelPriceHistoryConfigItem$Json$Params } from '../fn/channel-price-history-config/admin-get-channel-price-history-config-item-json';
import { adminPutChannelPriceHistoryConfigItem$Html$Html } from '../fn/channel-price-history-config/admin-put-channel-price-history-config-item-html-html';
import { AdminPutChannelPriceHistoryConfigItem$Html$Html$Params } from '../fn/channel-price-history-config/admin-put-channel-price-history-config-item-html-html';
import { adminPutChannelPriceHistoryConfigItem$Html$Json } from '../fn/channel-price-history-config/admin-put-channel-price-history-config-item-html-json';
import { AdminPutChannelPriceHistoryConfigItem$Html$Json$Params } from '../fn/channel-price-history-config/admin-put-channel-price-history-config-item-html-json';
import { adminPutChannelPriceHistoryConfigItem$Json$Html } from '../fn/channel-price-history-config/admin-put-channel-price-history-config-item-json-html';
import { AdminPutChannelPriceHistoryConfigItem$Json$Html$Params } from '../fn/channel-price-history-config/admin-put-channel-price-history-config-item-json-html';
import { adminPutChannelPriceHistoryConfigItem$Json$Json } from '../fn/channel-price-history-config/admin-put-channel-price-history-config-item-json-json';
import { AdminPutChannelPriceHistoryConfigItem$Json$Json$Params } from '../fn/channel-price-history-config/admin-put-channel-price-history-config-item-json-json';
import { Show as ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow } from '../models/ChannelPriceHistoryConfig-admin/channel_price_history_config/show_sylius/admin/channel_price_history_config/show';

@Injectable({ providedIn: 'root' })
export class ChannelPriceHistoryConfigService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetChannelPriceHistoryConfigItem()` */
  static readonly AdminGetChannelPriceHistoryConfigItemPath = '/api/v2/admin/channel-price-history-configs/{id}';

  /**
   * Retrieves a ChannelPriceHistoryConfig resource.
   *
   * Retrieves a ChannelPriceHistoryConfig resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetChannelPriceHistoryConfigItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelPriceHistoryConfigItem$Json$Response(params: AdminGetChannelPriceHistoryConfigItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow>> {
    return adminGetChannelPriceHistoryConfigItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ChannelPriceHistoryConfig resource.
   *
   * Retrieves a ChannelPriceHistoryConfig resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetChannelPriceHistoryConfigItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelPriceHistoryConfigItem$Json(params: AdminGetChannelPriceHistoryConfigItem$Json$Params, context?: HttpContext): Observable<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow> {
    return this.adminGetChannelPriceHistoryConfigItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow>): ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow => r.body)
    );
  }

  /**
   * Retrieves a ChannelPriceHistoryConfig resource.
   *
   * Retrieves a ChannelPriceHistoryConfig resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetChannelPriceHistoryConfigItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelPriceHistoryConfigItem$Html$Response(params: AdminGetChannelPriceHistoryConfigItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow>> {
    return adminGetChannelPriceHistoryConfigItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ChannelPriceHistoryConfig resource.
   *
   * Retrieves a ChannelPriceHistoryConfig resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetChannelPriceHistoryConfigItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetChannelPriceHistoryConfigItem$Html(params: AdminGetChannelPriceHistoryConfigItem$Html$Params, context?: HttpContext): Observable<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow> {
    return this.adminGetChannelPriceHistoryConfigItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow>): ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow => r.body)
    );
  }

  /** Path part for operation `adminPutChannelPriceHistoryConfigItem()` */
  static readonly AdminPutChannelPriceHistoryConfigItemPath = '/api/v2/admin/channel-price-history-configs/{id}';

  /**
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutChannelPriceHistoryConfigItem$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutChannelPriceHistoryConfigItem$Json$Json$Response(params: AdminPutChannelPriceHistoryConfigItem$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow>> {
    return adminPutChannelPriceHistoryConfigItem$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutChannelPriceHistoryConfigItem$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutChannelPriceHistoryConfigItem$Json$Json(params: AdminPutChannelPriceHistoryConfigItem$Json$Json$Params, context?: HttpContext): Observable<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow> {
    return this.adminPutChannelPriceHistoryConfigItem$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow>): ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow => r.body)
    );
  }

  /**
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutChannelPriceHistoryConfigItem$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutChannelPriceHistoryConfigItem$Json$Html$Response(params: AdminPutChannelPriceHistoryConfigItem$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow>> {
    return adminPutChannelPriceHistoryConfigItem$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutChannelPriceHistoryConfigItem$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPutChannelPriceHistoryConfigItem$Json$Html(params: AdminPutChannelPriceHistoryConfigItem$Json$Html$Params, context?: HttpContext): Observable<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow> {
    return this.adminPutChannelPriceHistoryConfigItem$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow>): ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow => r.body)
    );
  }

  /**
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutChannelPriceHistoryConfigItem$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutChannelPriceHistoryConfigItem$Html$Json$Response(params: AdminPutChannelPriceHistoryConfigItem$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow>> {
    return adminPutChannelPriceHistoryConfigItem$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutChannelPriceHistoryConfigItem$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutChannelPriceHistoryConfigItem$Html$Json(params: AdminPutChannelPriceHistoryConfigItem$Html$Json$Params, context?: HttpContext): Observable<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow> {
    return this.adminPutChannelPriceHistoryConfigItem$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow>): ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow => r.body)
    );
  }

  /**
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPutChannelPriceHistoryConfigItem$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutChannelPriceHistoryConfigItem$Html$Html$Response(params: AdminPutChannelPriceHistoryConfigItem$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow>> {
    return adminPutChannelPriceHistoryConfigItem$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * Replaces the ChannelPriceHistoryConfig resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPutChannelPriceHistoryConfigItem$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPutChannelPriceHistoryConfigItem$Html$Html(params: AdminPutChannelPriceHistoryConfigItem$Html$Html$Params, context?: HttpContext): Observable<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow> {
    return this.adminPutChannelPriceHistoryConfigItem$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow>): ChannelPriceHistoryConfigAdminChannelPriceHistoryConfigShowSyliusAdminChannelPriceHistoryConfigShow => r.body)
    );
  }

}

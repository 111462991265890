/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteLocaleItem } from '../fn/locale/admin-delete-locale-item';
import { AdminDeleteLocaleItem$Params } from '../fn/locale/admin-delete-locale-item';
import { adminGetLocaleCollection$Html } from '../fn/locale/admin-get-locale-collection-html';
import { AdminGetLocaleCollection$Html$Params } from '../fn/locale/admin-get-locale-collection-html';
import { adminGetLocaleCollection$Json } from '../fn/locale/admin-get-locale-collection-json';
import { AdminGetLocaleCollection$Json$Params } from '../fn/locale/admin-get-locale-collection-json';
import { adminGetLocaleItem$Html } from '../fn/locale/admin-get-locale-item-html';
import { AdminGetLocaleItem$Html$Params } from '../fn/locale/admin-get-locale-item-html';
import { adminGetLocaleItem$Json } from '../fn/locale/admin-get-locale-item-json';
import { AdminGetLocaleItem$Json$Params } from '../fn/locale/admin-get-locale-item-json';
import { adminPostLocaleCollection$Html$Html } from '../fn/locale/admin-post-locale-collection-html-html';
import { AdminPostLocaleCollection$Html$Html$Params } from '../fn/locale/admin-post-locale-collection-html-html';
import { adminPostLocaleCollection$Html$Json } from '../fn/locale/admin-post-locale-collection-html-json';
import { AdminPostLocaleCollection$Html$Json$Params } from '../fn/locale/admin-post-locale-collection-html-json';
import { adminPostLocaleCollection$Json$Html } from '../fn/locale/admin-post-locale-collection-json-html';
import { AdminPostLocaleCollection$Json$Html$Params } from '../fn/locale/admin-post-locale-collection-json-html';
import { adminPostLocaleCollection$Json$Json } from '../fn/locale/admin-post-locale-collection-json-json';
import { AdminPostLocaleCollection$Json$Json$Params } from '../fn/locale/admin-post-locale-collection-json-json';
import { Index as LocaleAdminLocaleIndexSyliusAdminLocaleIndex } from '../models/Locale-admin/locale/index_sylius/admin/locale/index';
import { Show as LocaleAdminLocaleShowSyliusAdminLocaleShow } from '../models/Locale-admin/locale/show_sylius/admin/locale/show';
import { Index as LocaleShopLocaleIndexSyliusShopLocaleIndex } from '../models/Locale-shop/locale/index_sylius/shop/locale/index';
import { Show as LocaleShopLocaleShowSyliusShopLocaleShow } from '../models/Locale-shop/locale/show_sylius/shop/locale/show';
import { shopGetLocaleCollection$Html } from '../fn/locale/shop-get-locale-collection-html';
import { ShopGetLocaleCollection$Html$Params } from '../fn/locale/shop-get-locale-collection-html';
import { shopGetLocaleCollection$Json } from '../fn/locale/shop-get-locale-collection-json';
import { ShopGetLocaleCollection$Json$Params } from '../fn/locale/shop-get-locale-collection-json';
import { shopGetLocaleItem$Html } from '../fn/locale/shop-get-locale-item-html';
import { ShopGetLocaleItem$Html$Params } from '../fn/locale/shop-get-locale-item-html';
import { shopGetLocaleItem$Json } from '../fn/locale/shop-get-locale-item-json';
import { ShopGetLocaleItem$Json$Params } from '../fn/locale/shop-get-locale-item-json';

@Injectable({ providedIn: 'root' })
export class LocaleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminGetLocaleCollection()` */
  static readonly AdminGetLocaleCollectionPath = '/api/v2/admin/locales';

  /**
   * Retrieves the collection of Locale resources.
   *
   * Retrieves the collection of Locale resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetLocaleCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetLocaleCollection$Json$Response(params?: AdminGetLocaleCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LocaleAdminLocaleIndexSyliusAdminLocaleIndex>>> {
    return adminGetLocaleCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Locale resources.
   *
   * Retrieves the collection of Locale resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetLocaleCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetLocaleCollection$Json(params?: AdminGetLocaleCollection$Json$Params, context?: HttpContext): Observable<Array<LocaleAdminLocaleIndexSyliusAdminLocaleIndex>> {
    return this.adminGetLocaleCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LocaleAdminLocaleIndexSyliusAdminLocaleIndex>>): Array<LocaleAdminLocaleIndexSyliusAdminLocaleIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Locale resources.
   *
   * Retrieves the collection of Locale resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetLocaleCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetLocaleCollection$Html$Response(params?: AdminGetLocaleCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LocaleAdminLocaleIndexSyliusAdminLocaleIndex>>> {
    return adminGetLocaleCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Locale resources.
   *
   * Retrieves the collection of Locale resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetLocaleCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetLocaleCollection$Html(params?: AdminGetLocaleCollection$Html$Params, context?: HttpContext): Observable<Array<LocaleAdminLocaleIndexSyliusAdminLocaleIndex>> {
    return this.adminGetLocaleCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LocaleAdminLocaleIndexSyliusAdminLocaleIndex>>): Array<LocaleAdminLocaleIndexSyliusAdminLocaleIndex> => r.body)
    );
  }

  /** Path part for operation `adminPostLocaleCollection()` */
  static readonly AdminPostLocaleCollectionPath = '/api/v2/admin/locales';

  /**
   * Creates a Locale resource.
   *
   * Creates a Locale resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostLocaleCollection$Json$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostLocaleCollection$Json$Json$Response(params: AdminPostLocaleCollection$Json$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LocaleAdminLocaleShowSyliusAdminLocaleShow>> {
    return adminPostLocaleCollection$Json$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Locale resource.
   *
   * Creates a Locale resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostLocaleCollection$Json$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostLocaleCollection$Json$Json(params: AdminPostLocaleCollection$Json$Json$Params, context?: HttpContext): Observable<LocaleAdminLocaleShowSyliusAdminLocaleShow> {
    return this.adminPostLocaleCollection$Json$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocaleAdminLocaleShowSyliusAdminLocaleShow>): LocaleAdminLocaleShowSyliusAdminLocaleShow => r.body)
    );
  }

  /**
   * Creates a Locale resource.
   *
   * Creates a Locale resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostLocaleCollection$Json$Html()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostLocaleCollection$Json$Html$Response(params: AdminPostLocaleCollection$Json$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<LocaleAdminLocaleShowSyliusAdminLocaleShow>> {
    return adminPostLocaleCollection$Json$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Locale resource.
   *
   * Creates a Locale resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostLocaleCollection$Json$Html$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminPostLocaleCollection$Json$Html(params: AdminPostLocaleCollection$Json$Html$Params, context?: HttpContext): Observable<LocaleAdminLocaleShowSyliusAdminLocaleShow> {
    return this.adminPostLocaleCollection$Json$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocaleAdminLocaleShowSyliusAdminLocaleShow>): LocaleAdminLocaleShowSyliusAdminLocaleShow => r.body)
    );
  }

  /**
   * Creates a Locale resource.
   *
   * Creates a Locale resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostLocaleCollection$Html$Json()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostLocaleCollection$Html$Json$Response(params: AdminPostLocaleCollection$Html$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LocaleAdminLocaleShowSyliusAdminLocaleShow>> {
    return adminPostLocaleCollection$Html$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Locale resource.
   *
   * Creates a Locale resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostLocaleCollection$Html$Json$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostLocaleCollection$Html$Json(params: AdminPostLocaleCollection$Html$Json$Params, context?: HttpContext): Observable<LocaleAdminLocaleShowSyliusAdminLocaleShow> {
    return this.adminPostLocaleCollection$Html$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocaleAdminLocaleShowSyliusAdminLocaleShow>): LocaleAdminLocaleShowSyliusAdminLocaleShow => r.body)
    );
  }

  /**
   * Creates a Locale resource.
   *
   * Creates a Locale resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminPostLocaleCollection$Html$Html()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostLocaleCollection$Html$Html$Response(params: AdminPostLocaleCollection$Html$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<LocaleAdminLocaleShowSyliusAdminLocaleShow>> {
    return adminPostLocaleCollection$Html$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Locale resource.
   *
   * Creates a Locale resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminPostLocaleCollection$Html$Html$Response()` instead.
   *
   * This method sends `text/html` and handles request body of type `text/html`.
   */
  adminPostLocaleCollection$Html$Html(params: AdminPostLocaleCollection$Html$Html$Params, context?: HttpContext): Observable<LocaleAdminLocaleShowSyliusAdminLocaleShow> {
    return this.adminPostLocaleCollection$Html$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocaleAdminLocaleShowSyliusAdminLocaleShow>): LocaleAdminLocaleShowSyliusAdminLocaleShow => r.body)
    );
  }

  /** Path part for operation `adminGetLocaleItem()` */
  static readonly AdminGetLocaleItemPath = '/api/v2/admin/locales/{code}';

  /**
   * Retrieves a Locale resource.
   *
   * Retrieves a Locale resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetLocaleItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetLocaleItem$Json$Response(params: AdminGetLocaleItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LocaleAdminLocaleShowSyliusAdminLocaleShow>> {
    return adminGetLocaleItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Locale resource.
   *
   * Retrieves a Locale resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetLocaleItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetLocaleItem$Json(params: AdminGetLocaleItem$Json$Params, context?: HttpContext): Observable<LocaleAdminLocaleShowSyliusAdminLocaleShow> {
    return this.adminGetLocaleItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocaleAdminLocaleShowSyliusAdminLocaleShow>): LocaleAdminLocaleShowSyliusAdminLocaleShow => r.body)
    );
  }

  /**
   * Retrieves a Locale resource.
   *
   * Retrieves a Locale resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetLocaleItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetLocaleItem$Html$Response(params: AdminGetLocaleItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<LocaleAdminLocaleShowSyliusAdminLocaleShow>> {
    return adminGetLocaleItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Locale resource.
   *
   * Retrieves a Locale resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetLocaleItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetLocaleItem$Html(params: AdminGetLocaleItem$Html$Params, context?: HttpContext): Observable<LocaleAdminLocaleShowSyliusAdminLocaleShow> {
    return this.adminGetLocaleItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocaleAdminLocaleShowSyliusAdminLocaleShow>): LocaleAdminLocaleShowSyliusAdminLocaleShow => r.body)
    );
  }

  /** Path part for operation `adminDeleteLocaleItem()` */
  static readonly AdminDeleteLocaleItemPath = '/api/v2/admin/locales/{code}';

  /**
   * Removes the Locale resource.
   *
   * Removes the Locale resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteLocaleItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteLocaleItem$Response(params: AdminDeleteLocaleItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminDeleteLocaleItem(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Locale resource.
   *
   * Removes the Locale resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteLocaleItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteLocaleItem(params: AdminDeleteLocaleItem$Params, context?: HttpContext): Observable<void> {
    return this.adminDeleteLocaleItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `shopGetLocaleCollection()` */
  static readonly ShopGetLocaleCollectionPath = '/api/v2/shop/locales';

  /**
   * Retrieves the collection of Locale resources.
   *
   * Retrieves the collection of Locale resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetLocaleCollection$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetLocaleCollection$Json$Response(params?: ShopGetLocaleCollection$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LocaleShopLocaleIndexSyliusShopLocaleIndex>>> {
    return shopGetLocaleCollection$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Locale resources.
   *
   * Retrieves the collection of Locale resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetLocaleCollection$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetLocaleCollection$Json(params?: ShopGetLocaleCollection$Json$Params, context?: HttpContext): Observable<Array<LocaleShopLocaleIndexSyliusShopLocaleIndex>> {
    return this.shopGetLocaleCollection$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LocaleShopLocaleIndexSyliusShopLocaleIndex>>): Array<LocaleShopLocaleIndexSyliusShopLocaleIndex> => r.body)
    );
  }

  /**
   * Retrieves the collection of Locale resources.
   *
   * Retrieves the collection of Locale resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetLocaleCollection$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetLocaleCollection$Html$Response(params?: ShopGetLocaleCollection$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LocaleShopLocaleIndexSyliusShopLocaleIndex>>> {
    return shopGetLocaleCollection$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Locale resources.
   *
   * Retrieves the collection of Locale resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetLocaleCollection$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetLocaleCollection$Html(params?: ShopGetLocaleCollection$Html$Params, context?: HttpContext): Observable<Array<LocaleShopLocaleIndexSyliusShopLocaleIndex>> {
    return this.shopGetLocaleCollection$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LocaleShopLocaleIndexSyliusShopLocaleIndex>>): Array<LocaleShopLocaleIndexSyliusShopLocaleIndex> => r.body)
    );
  }

  /** Path part for operation `shopGetLocaleItem()` */
  static readonly ShopGetLocaleItemPath = '/api/v2/shop/locales/{code}';

  /**
   * Retrieves a Locale resource.
   *
   * Retrieves a Locale resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetLocaleItem$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetLocaleItem$Json$Response(params: ShopGetLocaleItem$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LocaleShopLocaleShowSyliusShopLocaleShow>> {
    return shopGetLocaleItem$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Locale resource.
   *
   * Retrieves a Locale resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetLocaleItem$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetLocaleItem$Json(params: ShopGetLocaleItem$Json$Params, context?: HttpContext): Observable<LocaleShopLocaleShowSyliusShopLocaleShow> {
    return this.shopGetLocaleItem$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocaleShopLocaleShowSyliusShopLocaleShow>): LocaleShopLocaleShowSyliusShopLocaleShow => r.body)
    );
  }

  /**
   * Retrieves a Locale resource.
   *
   * Retrieves a Locale resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `shopGetLocaleItem$Html()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetLocaleItem$Html$Response(params: ShopGetLocaleItem$Html$Params, context?: HttpContext): Observable<StrictHttpResponse<LocaleShopLocaleShowSyliusShopLocaleShow>> {
    return shopGetLocaleItem$Html(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Locale resource.
   *
   * Retrieves a Locale resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `shopGetLocaleItem$Html$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  shopGetLocaleItem$Html(params: ShopGetLocaleItem$Html$Params, context?: HttpContext): Observable<LocaleShopLocaleShowSyliusShopLocaleShow> {
    return this.shopGetLocaleItem$Html$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocaleShopLocaleShowSyliusShopLocaleShow>): LocaleShopLocaleShowSyliusShopLocaleShow => r.body)
    );
  }

}
